<template>
  <div class="py-4 container-fluid">
      <div class="row">
          <div class="col-lg-5">
              <carousel
                  :items="[
                  {
                      img: image2,
                      title: 'Get started with Argon',
                      description:
                      'There’s nothing I really wanted to do in life that I wasn’t able to get good at.',
                      icon: {
                      component: 'ni ni-camera-compact text-dark',
                      background: 'bg-white'
                      }
                  },
                  {
                      img: image1,
                      title: 'Faster way to create web pages',
                      description:
                      'That’s my skill. I’m not really specifically talented at anything except for the ability to learn.',
                      icon: {
                      component: 'ni ni-bulb-61 text-dark',
                      background: 'bg-white'
                      }
                  },
                  {
                      img: image3,
                      title: 'Share with us your design tips!',
                      description:
                      'Don’t be afraid to be wrong because you can’t learn anything from a compliment.',
                      icon: {
                      component: 'ni ni-trophy text-dark',
                      background: 'bg-white'
                      }
                  }
                  ]"
              />
          </div>
      </div>

    <div class="row">
        <div class="col-md-2">
        <label for="projectName" class="form-label" style="color:white;">Dari Tgl</label>
        <argon-input type="date" placeholder="Date" v-model="filter.startDate" />
        </div>
        <div class="col-md-2">
        <label for="projectName" class="form-label" style="color:white;">Sampai Tgl</label>
        <argon-input type="date" placeholder="Date" v-model="filter.endDate" />
        </div>
        <div class="col-md-4">
        <label for="projectName" class="form-label" style="color:white;">Action</label>
        <div class="my-auto mt-4 ms-auto mt-lg-0">
            <div class="my-auto ms-auto">
            <a
                href="#"
                class="mb-0 btn bg-gradient-success btn-sm"
                @click="handleFilter"
            >+&nbsp; Filter</a>
            <button
                type="button"
                class="mx-1 mb-0 btn btn-outline-success btn-sm"
                @click="handleReset"
            >
                Reset
            </button>
            </div>
        </div>
        </div>
    </div>

      <div class="row">
          <div class="col-lg-3 col-md-6 col-12 mb-3">
                <default-counter-card
                id="status3"
                :count="totalOrder"
                color="primary"
                title="Total Order"
                />
            </div>
            <div class="col-lg-3 col-md-6 col-12 mb-3">
                <default-counter-card
                id="status4"
                :count="orderApproved"
                title="Order Approved"
                />
            </div>
            <div class="col-lg-3 col-md-6 col-12 mb-3">
                <default-counter-card
                id="status1"
                color="danger"
                :count="orderRejected"
                title="Order Reject / Cancel"
                />
            </div>
            <div class="col-lg-3 col-md-6 col-12 mb-3">
                <default-counter-card
                id="status2"
                :count="orderPending"
                color="info"
                title="Order Pending"
                />
            </div>
      </div>

      <div class="row">

          <div class="col-lg-5 mb-3">
              <carousel
                  :items="[
                  {
                      img: image2,
                      title: 'Info Admin',
                      description:
                      'There s nothing I really wanted to do in life that I wasn t able to get good at.',
                      icon: {
                      component: 'ni ni-camera-compact text-dark',
                      background: 'bg-white'
                      }
                  },
                  {
                      img: image1,
                      title: 'Faster way to increase sales',
                      description:
                      'That s my skill. I m not really specifically talented at anything except for the ability to learn.',
                      icon: {
                      component: 'ni ni-bulb-61 text-dark',
                      background: 'bg-white'
                      }
                  },
                  {
                      img: image3,
                      title: 'Share with us your sales tips!',
                      description:
                      'Don t be afraid to be wrong because you cant learn anything from a compliment.',
                      icon: {
                      component: 'ni ni-trophy text-dark',
                      background: 'bg-white'
                      }
                  }
                  ]"
              />
          </div>
          <div class="col-md-7 mb-3">
              <revenue-chart-card />
          </div>
          <div class="col-lg-12 mb-5 col-sm-12">
              <channels-chart-card />
          </div>
      
      </div>
  </div>
</template>

<script>
// import MiniStatisticsCard from "../../darlink/Cards/MiniStatisticsCard.vue";
import setTooltip from "@/assets/js/tooltip.js";
import DefaultCounterCard from "@/darlink/Cards/DefaultCounterCard.vue";
// import MixedChart from "../pages/components/MixedChart.vue";
// import Choices from "choices.js";
import ChannelsChartCard from "./components/ChannelsChartCard.vue";
import RevenueChartCard from "./components/RevenueChartCard.vue";
import Carousel from "../dashboards/components/Carousel.vue";

import image2 from "../../assets/img/img-2.jpg";
import image1 from "../../assets/img/img-1.jpg";
import image3 from "../../assets/img/img-3.jpg";
import ArgonInput from "@/components/ArgonInput.vue";
import axios from "axios";

export default {
name: "Dashboard",
components: {
  DefaultCounterCard,
  RevenueChartCard,
  ChannelsChartCard,
  Carousel,
  ArgonInput,
},
data() {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0]; // Get the date in YYYY-MM-DD format

    return {
        image2,
        image1,
        image3,
        totalOrder: 0,
        orderApproved: 0,
        orderRejected: 0,
        orderPending: 0,
        newAgent: 0,
        newASOBranches: 0,
        totalASOCentral: 0,
        newFincoyPIC: 0,
        topFincoyHO: [],
        topASOCentral: [],
        filter: {
          startDate: "",
          endDate: "",
        },
        currentDate: formattedDate,
    };
},
beforeMount() {
    if (localStorage.getItem("SideMenuType") !== "Fincoy") {
        this.$router.go(-1);
    }
},
mounted() {
//   if (document.getElementById("choices-category")) {
//     var element = document.getElementById("choices-category");
//     new Choices(element, {
//       searchEnabled: false,
//       allowHTML: true,
//     });
//   }
    this.filter.startDate = this.filter.startDate || this.currentDate;
    this.filter.endDate = this.filter.endDate || this.currentDate;
    setTimeout(() => {
      this.loadMetric(this.filter);
    }, 2000);
    setTooltip(this.$store.state.bootstrap);
},
methods: {
    handleFilter() {
      this.filter.startDate = this.filter.startDate || this.currentDate;
      this.filter.endDate = this.filter.endDate || this.currentDate;

      this.loadMetric(this.filter)
    },
    handleReset() {
      this.filter.startDate = this.currentDate;
      this.filter.endDate = this.currentDate;

      this.loadMetric(this.filter)
    },
    loadMetric(params_filter = {startDate: "", endDate: ""}) {
      var token = window.localStorage.getItem("TOKEN")
      axios
        // .get("https://api.darlink.id/v1/aso-central/metric", {
        .get(`https://api.darlink.id/v1/fincoy/metric?start=${params_filter.startDate}&end=${params_filter.endDate}`, {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          }
        })
        .then((response) => {
          if (response.data.code != 200) {
            return
          }

          this.totalOrder = response.data.content.total_order
          this.orderApproved = response.data.content.approved_order
          this.orderRejected = response.data.content.rejected_order
          this.orderPending = response.data.content.assigned_order

          this.topFincoyHO = response.data.content.top_fincoy_ho
          this.topASOCentral = response.data.content.top_aso_branches
        })
        .catch((err) => {
          console.log(err.toJSON());
        });
    }
  }
};
</script>
