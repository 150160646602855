<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-12">
            <div class="mt-4 card card-body">
              <div>
                <h6 class="mb-0">Show data Widthdrawal</h6>
                <p class="text-sm mb-0">
                  Name: 
                  <b>{{ dataAgent.displayname }}</b> 
                  <br />
                  Type:
                  <b>{{ dataOrder.user_type }}</b>
                  <br />
                  Phone:
                  <b>{{ dataAgent.phone }}</b>
                  <br />
                  Email:
                  <b>{{ dataAgent.email }}</b>
                  <br />
                  Bank Account Number:
                  <b>{{ dataOrder.bank_account }}</b>
                  <br />
                  Bank Account Name:
                  <b>{{ dataOrder.bank_account_holder }}</b>
                  <br />
                  Bank Name:
                  <b>{{ dataOrder.bank_name }}</b>
                  <br />
                  <br />
                  Job:
                  <b>{{ dataAgent.job }}</b>
                  <br />
                  Location:
                  <b>{{ dataAgent.location}}</b>
                  <br />
                  <b>Total Withdrawal: </b>
                  <b>{{ amount }}</b>
                  <br />
                  <b>Before Balance: </b>
                  <b>{{ before_balance }}</b>
                  <br />
                  <b>After Balance: </b>
                  <b>{{ after_balance }}</b>
                  <br />
                  <br />
                  Created Date:
                  <b id="created_date">{{ created ? created : '' }}</b>
                  <br />
                  <!-- Updated Date:
                  <b id="updated_date">{{ updated ? updated : '' }}</b> -->
                </p>
              </div>

              <!--Image-->
              <div v-if="dataAgent.personal_id_scan">
                  <img :src="dataAgent.personal_id_scan" alt="ID Card Document" style="width: 20%; height: auto;"/>
              </div>

              <div v-if="dataAgent.bank_account_scan">
                  <img :src="dataAgent.bank_account_scan" alt="Bank Document" style="width: 20%; height: auto;"/>
              </div>

              <div class="modal-footer mt-2">
                <button
                  type="button"
                  @click="$router.push({ name: 'superadmin.process-withdraw' })"
                  class="btn bg-gradient-secondary btn-sm"
                >
                  Kembali
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    data(){
      return{
        dataOrder: {
          agent: {}, // Initialize agent object
          aso_branch: {},
          fincoy_pic: {},
          fincoy_ho: {},
        },
        before_balance: "",
        after_balance: "",
        amount: "",
        dataAgent: "",
        created: "",
        updated: "",
        confirmed: "",
        rejected: "",
      }
    },
    beforeMount() {
      if (localStorage.getItem("SideMenuType") !== "SuperAdmin") {
          this.$router.go(-1);
      }
    },
    mounted(){
      this.getDataOrder()
    },
    computed: {
      orderID() {
        if (!this.$route.params.orderList) {
          return null
        }
        return this.$route.params.orderList
      },
      
    },
    methods: {
      getDataOrder() {
        var token = window.localStorage.getItem("TOKEN")
        axios
          .get("https://api.darlink.id/v1/withdraw/" +  this.orderID, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token,
              // allow cors 
              // "Access-Control-Allow-Origin": "*",
              // "Access-Control-Allow-Credentials": true,
            }
          })
          .then((response) => {
            this.dataOrder = response.data.content;
            this.agent_id = this.dataOrder.user_id;
            let total_amount = this.dataOrder.before_balance - this.dataOrder.after_balance;
            this.before_balance = this.dataOrder.before_balance.toLocaleString("id-ID", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
            });
            this.after_balance = this.dataOrder.after_balance.toLocaleString("id-ID", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
            });
            this.amount = total_amount.toLocaleString("id-ID", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
            });
            this.created = new Date(this.dataOrder.created_at).toLocaleDateString("id-ID", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    // hour
                    hour: "numeric",
                    // minute
                    minute: "numeric",
                  });
            this.updated = new Date(this.dataOrder.updated_at).toLocaleDateString("id-ID", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    // hour
                    hour: "numeric",
                    // minute
                    minute: "numeric",
                  });

            this.getDataAgent(this.agent_id, this.dataOrder.user_type)
          })
          .catch((err) => {
            console.log(err);
          }
        );
      },
      getDataAgent(user_id, user_type) {
        var token = window.localStorage.getItem("TOKEN")

        let url = "";
        if(user_type == "agent")
        {
          url = "https://api.darlink.id/v1/agent/" + user_id;
        }
        else
        {
          url = "https://api.darlink.id/v1/aso-branch/" + user_id;
        }

        axios
          .get(url, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token,
              // allow cors 
              // "Access-Control-Allow-Origin": "*",
              // "Access-Control-Allow-Credentials": true,
            }
          })
          .then((response) => {
            this.dataAgent = response.data.content;
          })
          .catch((err) => {
            console.log(err);
          }
        );
      },
      withdrawPlatform() {
        var token = window.localStorage.getItem("TOKEN")
        axios
          .post("https://api.darlink.id/v1/order/" +  this.orderID + "/complete", {}, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token,
              // Authorization: "TEST_FINCOY_PIC123456789"
              // allow cors 
              // "Access-Control-Allow-Origin": "*",
              // "Access-Control-Allow-Credentials": true,
            }
          })
          .then((response) => {
            // SWEETALLERT
            this.$swal({
              title: "Berhasil",
              text: "Pencairan berhasil dilakukan",
              icon: "success",
              confirmButtonText: "Tutup",
              confirmButtonColor: "#3085d6",
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.push({ name: "Fincoy Order Pencairan Dana Darlink" })
              }
            });
            this.$router.push({ name: "Fincoy Order Pencairan Dana Darlink" })
            if (response.data.code != 200) {
              return
            }
          })
          .catch(() => {
            
              // this.$swal({
              this.$swal({
                title: "Gagal",
                text: "pencairan sudah dilakukan",
                icon: "error",
                confirmButtonText: "Tutup",
                confirmButtonColor: "#3085d6",
              });
            })
      }
    }
  }
</script>