<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <!-- <div class="col-md-6"> -->
          <!-- card -->
          <!-- <div class="mt-4 card card-body">
              <h6 class="mb-0">Show data order</h6>
                <div class="mt-4">
                  <div class="">
                    <div class="">
                      <h5 id="" class="title">
                        Nama Branch: {{ dataOrder.aso_branch_id }}
                      </h5>
                    </div>
                    <div class="body">
                      <div>
                        <h6>Agent: {{ dataOrder.agent_id }}</h6>
                        <p class="text-sm mb-0">
                          Nama PIC:
                          <b>Herman</b> <br />
                          Nomor HP
                          <b>1234567890</b>
                        </p>
                        <p class="text-sm">
                          NIK/NPK :
                          <b>243598234</b>
                        </p>
                        <p class="text-sm">
                          Jumlah Anggota :
                          <b>32</b>
                        </p>
                      </div>
                      <p>Jalan Jend. Sudirman</p>
                    </div>
                  </div>
                </div>
            </div>
          </div> -->
          <div class="col-md-12">
            <div class="mt-4 card card-body">
              <div ref="orderDetailExport">
                <p class="text-sm mb-0">
                  <span>Nama Konsumen:</span>
                  <b>{{ dataOrder.client_name }}</b>
                  <br />
                  <span>NIK:</span>
                  <b>{{ dataOrder.client_id_code }}</b>
                  <br />
                  <span>Nomor HP:</span>
                  <b>{{ dataOrder.client_phone }}</b>
                  <br />
                  <span>Alamat:</span>
                  <b>{{ dataOrder.client_address }}</b>
                  <br />
                  <span>Provinsi:</span>
                  <b>{{ dataOrder.client_region }}</b>
                  <br />
                  <span>Kota / Kabupaten:</span>
                  <b>{{ dataOrder.client_location }}</b>
                  <br />
                  <span>Kode POS:</span>
                  <b>{{ dataOrder.client_postal_code }}</b>
                  <br />
                  <span>Pekerjaan:</span>
                  <b>{{ dataOrder.client_occupation }}</b>
                  <br />
                  <br />

                  Alamat Domisili:
                  <b>{{ dataOrder.domicile_address }}</b>
                  <br />
                  Provinsi Domisili:
                  <b>{{ dataOrder.domicile_region }}</b>
                  <br />
                  Kota / Kabupaten Domisili:
                  <b>{{ dataOrder.domicile_location }}</b>
                  <br />
                  Kode POS Domisili:
                  <b>{{ dataOrder.domicile_postal_code }}</b>
                  <br />
                  <br />

                  Alamat STNK:
                  <b>{{ dataOrder.vehicle_address }}</b>
                  <br />
                  Provinsi STNK:
                  <b>{{ dataOrder.vehicle_region }}</b>
                  <br />
                  Kota / Kabupaten STNK:
                  <b>{{ dataOrder.vehicle_location }}</b>
                  <br />
                  Kode POS STNK:
                  <b>{{ dataOrder.vehicle_postal_code }}</b>
                  <br />
                  <br />

                  <span>Leased Item:</span>
                  <b>{{ dataOrder.leased_item_name }}</b>
                  <br />
                  <span>Leased Brand:</span>
                  <b>{{ dataOrder.leased_item_brand }}</b>
                  <br />
                  <span>Leased Item Type:</span>
                  <b>{{ dataOrder.leased_item_type }}</b>
                  <br />
                  <span>Leased Year:</span>
                  <b>{{ dataOrder.leased_item_year }}</b>
                  <br />
                  <span>Leased BPKB:</span>
                  <b>{{
                    dataOrder.leased_item_ownership_holder == "self_holder"
                      ? "SENDIRI"
                      : "ORANG LAIN"
                  }}</b>
                  <br />
                  <span>Leased Tenor:</span>
                  <b>{{ dataOrder.tenor }} Bulan</b>
                  <br />
                  <b>Total Pinjaman: </b>
                  <b>{{ total_loan }}</b>
                  <br />
                  <br />
                  <span>Created Date:</span>
                  <b id="created_date">{{ created ? created : "" }}</b>
                  <br />
                  <!-- Updated Date:
                  <b id="updated_date">{{ updated ? updated : '' }}</b>
                  <br />
                  Confirmed Date:
                  <b id="confirmed_date">{{ confirmed ? confirmed : '' }}</b>
                  <br />
                  Rejected Date:
                  <b id="rejected_date">{{ rejected ? rejected : '' }}</b>
                  <br />
                  Alasan:
                  <b>{{ dataOrder.reason ? dataOrder.reason : '-' }}</b> -->
                </p>

                <!--Image-->
                <!-- <div v-if="dataOrder.additional_document"> -->
                <!-- <img :src="dataOrder.additional_document" alt="Additional Document" style="width: 20%; height: auto;" @load="exportToPDF"/> -->
                <!-- <img :src="dataOrder.additional_document" alt="Additional Document" style="width: 20%; height: auto;" />
                </div> -->

                <tr v-for="(docs, i) in dataOrder.additional_document" :key="i">
                  <div v-if="docs">
                    <img
                      :src="docs"
                      alt="Additional Document"
                      style="width: 100%; height: auto"
                    />
                  </div>
                </tr>
                <!-- <tr>
                  <div>
                    <img
                      src="https://images.unsplash.com/photo-1713098098833-f45d180ca9a2?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt="Additional Document"
                      style="width: 20%; height: auto"
                    />
                  </div>
                </tr> -->
              </div>

              <div class="modal-footer mt-2">
                <button
                  type="button"
                  @click="$router.push({ name: 'Fincoy Order Proses' })"
                  class="btn bg-gradient-secondary btn-sm"
                >
                  Kembali
                </button>
                <button
                  type="button"
                  class="btn bg-success-secondary btn-sm"
                  @click="exportToPDF"
                >
                  Download PDF
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import html2pdf from 'html2pdf.js';
import jsPDF from "jspdf";
// import html2canvas from 'html2canvas';
import domtoimage from "dom-to-image";

export default {
  data() {
    return {
      dataOrder: [],
      created: "",
      updated: "",
      confirmed: "",
      rejected: "",
      total_loan: "",
    };
  },
  beforeMount() {
    if (localStorage.getItem("SideMenuType") !== "Fincoy") {
      this.$router.go(-1);
    }
  },
  mounted() {
    this.getDataOrder();
  },
  computed: {
    orderID() {
      if (!this.$route.params.orderList) {
        return null;
      }
      return this.$route.params.orderList;
    },
  },
  methods: {
    async exportToPDF() {
      const element = this.$refs.orderDetailExport;
      if (!element || !element.innerHTML) {
        console.error("Invalid element provided.");
        return;
      }

      const pdf = new jsPDF({
        unit: "mm",
        format: "a4",
        orientation: "portrait",
      });

      const fontSize = 20;
      this.setElementFontSize(element, fontSize);

      try {
        // Wait for any external images to be loaded before converting the content to an image
        await this.waitForImagesToLoad(element);

        // Use dom-to-image to render the content as an image
        const imgDataUrl = await domtoimage.toPng(element, { cacheBust: true });

        // Add the image to the PDF
        const imgProps = pdf.getImageProperties(imgDataUrl);
        const pdfWidth = 190; // Adjust the width of the image in the PDF if needed
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgDataUrl, "PNG", 10, 10, pdfWidth, pdfHeight);

        // Download the PDF
        pdf.save("order_" + this.orderID + ".pdf");

        this.resetElementFontSize(element);
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    },
    setElementFontSize(element, fontSize) {
      if (!element) return;
      element.style.fontSize = `${fontSize}px`;
      for (const childElement of element.children) {
        this.setElementFontSize(childElement, fontSize);
      }
    },
    resetElementFontSize(element) {
      if (!element) return;
      element.style.fontSize = null;
      for (const childElement of element.children) {
        this.resetElementFontSize(childElement);
      }
    },
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    waitForImagesToLoad(element) {
      // Helper function to wait for images to be loaded
      return new Promise((resolve, reject) => {
        const images = element.querySelectorAll("img");
        let loadedCount = 0;

        const onImageLoad = () => {
          loadedCount++;
          if (loadedCount === images.length) {
            resolve();
          }
        };

        images.forEach((img) => {
          if (img.complete) {
            onImageLoad();
          } else {
            img.onload = onImageLoad;
            img.onerror = reject;
          }
        });
      });
    },
    getDataOrder() {
      // var token = window.localStorage.getItem("TOKEN")
      axios
        .get("https://api.darlink.id/v1/order/" + this.orderID, {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            // Authorization: token,// Authorization: token
            Authorization: "TEST_FINCOY_PIC123456789",
            // allow cors
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Credentials": true,
          },
        })
        .then((response) => {
          this.dataOrder = response.data.content;
          this.total_loan = this.dataOrder.total_loan.toLocaleString("id-ID", {
            style: "currency",
            currency: "IDR",
            minimumFractionDigits: 2,
          });
          this.created = new Date(this.dataOrder.created_at).toLocaleDateString(
            "id-ID",
            {
              year: "numeric",
              month: "long",
              day: "numeric",
              // hour
              hour: "numeric",
              // minute
              minute: "numeric",
            }
          );
          this.updated = new Date(this.dataOrder.updated_at).toLocaleDateString(
            "id-ID",
            {
              year: "numeric",
              month: "long",
              day: "numeric",
              // hour
              hour: "numeric",
              // minute
              minute: "numeric",
            }
          );
          this.confirmed = new Date(
            this.dataOrder.updated_at
          ).toLocaleDateString("id-ID", {
            year: "numeric",
            month: "long",
            day: "numeric",
            // hour
            hour: "numeric",
            // minute
            minute: "numeric",
          });
          this.rejected = new Date(
            this.dataOrder.rejected_at
          ).toLocaleDateString("id-ID", {
            year: "numeric",
            month: "long",
            day: "numeric",
            // hour
            hour: "numeric",
            // minute
            minute: "numeric",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ambilOrder() {
      var token = window.localStorage.getItem("TOKEN");
      axios
        .post(
          "https://api.darlink.id/v1/order/" + this.orderID + "/confirm",
          {},
          {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token,
              // allow cors
              // "Access-Control-Allow-Origin": "*",
              // "Access-Control-Allow-Credentials": true,
            },
          }
        )
        .then((response) => {
          // SWEETALLERT
          this.$swal({
            title: "Berhasil",
            text: "Order berhasil diambil",
            icon: "success",
            confirmButtonText: "Tutup",
            confirmButtonColor: "#3085d6",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push({ name: "Fincoy Order Assign" });
            }
          });
          this.$router.push({ name: "Fincoy Order Assign" });
          if (response.data.code != 200) {
            return;
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.validation
          ) {
            const validationErrors = err.response.data.validation;
            let errorMessage = "Validation Error:\n";
            validationErrors.forEach((error) => {
              errorMessage += `${error.mesg}\n`;
            });
            this.$swal({
              title: "Validation Error",
              text: errorMessage,
              icon: "error",
              confirmButtonText: "OK",
              confirmButtonColor: "#d33",
            });
          } else {
            this.$swal({
              title: "Error",
              text: err.response.data.message,
              icon: "error",
              confirmButtonText: "OK",
              confirmButtonColor: "#d33",
            });
          }
        });
    },
  },
};
</script>
