<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="mx-auto col-lg-12 col-12">
        <div class="mt-4 card card-body">
          <h6 class="mb-0">Ubah Info</h6>
          <hr class="my-3 horizontal dark" />

          <div class="mt-4 row">
            <div class="col-12 col-md-12">
                <h5>Targeting</h5>
            </div>
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label for="projectName" class="form-label">ASO Central </label>
                    <select v-model="aso_central" class="form-control" @change="onChangeCentral($event)">
                      <option value="all">All</option>
                      <template v-for="dataCentral in asoCentral" :key="dataCentral.id">
                        <option :value="dataCentral.id">{{dataCentral.name}}</option>
                      </template>
                  </select>
                </div>
            </div>

            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label for="projectName" class="form-label">Target </label>
                    <select v-model="target_opt" class="form-control" @change="onChange($event)">
                      <option value=""></option>
                      <option value="fincoy_pic">Fincoy PIC</option>
                      <option value="branch">ASO Cabang</option>
                  </select>
                </div>
            </div>
          </div>

          <div v-if="show" class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                  <label for="projectName" class="form-label">ASO Cabang (*Kosongkan jika untuk semua Cabang)</label>
                  
                  <select v-model="aso_cabang" id="aso_cabang" class="form-control">
                  <template v-for="dataCabang in asoCabang" :key="dataCabang.id">
                    <option :value="dataCabang.id">{{dataCabang.displayname}}</option>
                  </template>
                </select>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="form-group">
                  <label for="projectName" class="form-label">Agent (*Boleh di kosongkan jika untuk semua Agent)</label>
                  <select v-model="target_agent" id="target_agent" class="form-control">
                    <option value=""></option>
                    <option value="yes">Ya, semua agent</option>
                    <option value="no">Tidak</option>
                </select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group">
              <label>Status info</label>
              <div class="form-check form-switch ms-1">
                <input
                  id="flexSwitchCheckDefault"
                  v-model="isActive"
                  class="form-check-input"
                  type="checkbox"
                  data-type="warning"
                  data-content="Once a project is made private, you cannot revert it to a public project."
                  data-title="Warning"
                  data-icon="ni ni-bell-55"
                />
                <label
                  class="form-check-label"
                  for="flexSwitchCheckDefault"
                >
                  <span
                    class="badge badge-md"
                    :class="{
                      'badge-danger': !isActive,
                      'badge-success': isActive,
                    }"
                  >
                    {{ (isActive) ? 'AKTIF' : 'NON-AKTIF' }}
                  </span>
                </label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-8">
              <label for="projectName" class="form-label">Judul</label>
              <input id="projectName" v-model="title" type="text" class="form-control" />
            </div>

            <div class="col-4">
              <label for="projectName" class="form-label">Position</label>
              <input id="projectName" v-model="position" type="number" class="form-control" placeholder="Optional" />
            </div>
          </div>
          <label class="mt-4">Keterangan</label>
          <div id="editor">
            <QuillEditor
              v-model:content="description"
              content-type="text"
              toolbar="minimal"
              theme="snow"
            />
          </div>
          <label class="mt-4 form-label">Upload Banner (.jpg, .png)</label>
          <label style="color: red;">* upload dengan size 1234px X 234px  (*Kosongkan jika tidak merubah Banner)</label>
          <div v-if="cover"><a :href="cover" target="_blank"><u>Current Banner</u></a></div>
          <form
            id="uploadId"
            class="form-control dropzone"
          >
            <div class="fallback">
              <input name="file" type="file" @change="handleFileChange" multiple />
            </div>
          </form>
          <div class="mt-4 d-flex justify-content-end">
            <button type="button" name="button" class="m-0 btn btn-light"
                @click="$router.push({ name: 'superadmin.management-info' })"
            >
              Batal
            </button>
            <button
              type="button"
              name="button"
              class="m-0 btn bg-gradient-success ms-2"
              @click="submit"
              :disabled="buttonDisabled"
            >
              <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span v-else>Ubah Annoucement</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import flatPickr from "vue-flatpickr-component";
import Dropzone from "dropzone";
import Choices from "choices.js";
import { QuillEditor } from '@vueup/vue-quill';
import axios from 'axios';

export default {
  name: "FincoyHoNew",
  components: {
    // flatPickr,
    QuillEditor
  },
  data() {
    return {
      isLoading: false,
      date: "",
      endDate: "",
      config: {
        allowInput: true,
      },
      listHo: [],
      asoCentral: [],
      asoCabang: [],
      title: "",
      description: "",
      position: "",
      idcard: "",
      fincoy_ho: "",
      isActive: false,
      orderPic: "",
      buttonDisabled: true,
      show: false,
      target_opt: "",
      aso_central: "",
      aso_cabang: "",
      target_agent: "",
      audience: [
        {
          type: "",
          id: null,
          children: [],
        }
      ],
      cover: "",
    };
  },
  computed: {
    infoID() {
      if (!this.$route.params.announcement) {
        return null
      }
      return this.$route.params.announcement
    }
  },
  mounted() {
    this.countPic();
    this.loadAnnoucement()

    var token = window.localStorage.getItem("TOKEN")
    axios.get('https://api.darlink.id/v1/fincoy', {
      withCredentials: true,
      headers: {
        Accept: "application/json",
        Authorization: token
      }
    }).then((response) => {
      console.log(response.data.content);
      this.listHo = response.data.content;
    });
    // if (document.getElementById("editor")) {
    //   new Quill("#editor", {
    //     theme: "snow", // Specify theme in configuration
    //   });
    // }    

    axios.get('https://api.darlink.id/v1/aso-central?status=active', {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          Authorization: token
        }
    }).then((response) => {
        this.asoCentral = response.data.content;
    })
    
    if (document.getElementById("choices-category")) {
      var element = document.getElementById("choices-category");
      new Choices(element, {
        searchEnabled: false,
        allowHTML: true,
        itemSelectText: '',
      });
    }
    
    if (document.getElementById("choices-aso")) {
      var element1 = document.getElementById("choices-aso");
      new Choices(element1, {
        searchEnabled: false,
        allowHTML: true,
        itemSelectText: '',
        position: 'auto',
      });
    }

    this.initializeDropzone();
    // Dropzone.autoDiscover = false;
    // var drop = document.getElementById("dropzone");
    // new Dropzone(drop, {
    //   url: "/file/post",
    //   addRemoveLinks: true,
    // });
  },
  methods: {
    countPic() {
      var token = window.localStorage.getItem("TOKEN");
      axios
        .get("https://api.darlink.id/v1/fincoy/pic", {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          }
        })
        .then((response) => {
          this.orderPic = response.data.content;
          this.buttonDisabled = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loadAnnoucement() {
      var token = window.localStorage.getItem("TOKEN")

      if (!this.infoID) {
        return
      }

      axios
        .get("https://api.darlink.id/v1/announcement/" + this.infoID,
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          }
        })
        .then((response) => {
          if (response.data.code != 200) {
            return
          }
          
          this.title = response.data.content.title
          this.description = response.data.content.description
          this.isActive = response.data.content.is_active
          this.position = response.data.content.position
          this.cover = response.data.content.cover

          if(response.data.content.audiences[0].id)
          {
            if(response.data.content.audiences[0].childrens != undefined)
            {
              this.aso_central = response.data.content.audiences[0].id
              if(response.data.content.audiences[0].childrens[0] != 'aso_branch')
              {
                if(response.data.content.audiences[0].childrens[0] == 'agent')
                {
                  this.show = true;
                  this.target_opt = "branch"
                  this.loadBranchInfo(response.data.content.audiences[0].id)
                  this.target_agent = "yes";
                }
                else
                {
                  this.show = false;
                  this.target_opt = "fincoy_pic"
                }
              }
              else
              {
                this.show = true;
                this.target_opt = "branch"
                this.loadBranchList(response.data.content.audiences[0].id)
              }
            }
            else
            {
              this.show = true;
              this.target_opt = "branch"
              this.aso_cabang = response.data.content.audiences[0].type.id
              this.target_agent = "no";
              this.loadBranchInfo(response.data.content.audiences[0].id)
            }
          }
          else
          {
            this.show = false;
            this.aso_central = "all";
          }
        })
        .catch((err) => {
          console.log(err.response)
        });
    },
    loadBranchInfo(id) {
      var token = window.localStorage.getItem("TOKEN")

      if (!this.infoID) {
        return
      }

      axios
        .get("https://api.darlink.id/v1/aso-branch/" + id,
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          }
        })
        .then((response) => {
          if (response.data.code != 200) {
            return
          }
          
          this.aso_central = response.data.content.aso_central_id
          this.aso_cabang = response.data.content.id
          this.loadBranchList(this.aso_central)
        })
        .catch((err) => {
          console.log(err.response)
        });
    },
    loadBranchList(id) {
      var token = window.localStorage.getItem("TOKEN")

      axios.get('https://api.darlink.id/v1/aso-central/'+id+'/aso-branch?status=active', {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          }
      }).then((response) => {
          this.asoCabang = response.data.content;
      })
    },
    randomString(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      const charactersLength = characters.length;

      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return result;
    },
    generateCodefincoy() {
      // this.username = this.randomString(3);
      // this.password = this.username;

      // console.log("fincoy user = ", this.username)
    },
    initializeDropzone() {
      Dropzone.autoDiscover = false;
      const drop = document.getElementById("uploadId");
      const dropzone = new Dropzone(drop, {
        url: "https://api.darlink.id/v1/assets/upload",
        addRemoveLinks: true,
        acceptedFiles: 'image/*',
        sending: (file, xhr, formData) => {
          formData.append("context", "ANNOUNCEMENT_COVER"); // Include the context parameter in the request
        },
        success: (file, response) => {
          console.log("Upload success:", response); // Handle the successful response
          // this.MOUDocs = response.content.url;
          this.idcard = response.content.name;
          console.log("id card = ", this.idcard);
        },
      });
      // Add any additional configuration or event listeners as needed

      // Move the handleFileChange logic here or modify it accordingly
      dropzone.on("addedfile", (file) => {
        // this.MOUDocs = file;
        console.log(file);
      });
    },
    onChangeCentral(event) {
      var token = window.localStorage.getItem("TOKEN")
      let id_central = event.target.value

      axios.get('https://api.darlink.id/v1/aso-central/'+id_central+'/aso-branch?status=active', {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          }
      }).then((response) => {
          this.asoCabang = response.data.content;
      })
    },
    onChange(event) {
      if (event.target.value === "fincoy_pic") {
        this.show = false;
        this.aso_cabang = "";
        this.target_agent =  "";
        
      } else {
        this.show = true;
      }
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      const dropzone = Dropzone.forElement("#uploadId");
      dropzone.removeAllFiles();
      dropzone.addFile(file);
    },
    submit() {
      this.isLoading = true;
      var token = window.localStorage.getItem("TOKEN")

      if(this.target_opt == "")
      {
        this.isLoading = false;
        this.$swal({
          title: "Invalid Target",
          text: "Please choose target Announcement.",
          icon: "warning",
          confirmButtonText: "OK",
          confirmButtonColor: "#3085d6",
        });
        return;
      }

      const picData = new FormData();
      
      picData.append("title", this.title);
      picData.append("description", this.description);
      picData.append("cover", this.idcard);
      picData.append("position", this.position);

      let targeting_type = "aso_central";
      let targeting_id = this.aso_central;
      let targeting = "";
      let targeting_status = false
      if(this.target_opt == "branch")
      {
        if(this.target_agent == "" || this.target_agent == "no")
        {
          if(this.aso_cabang != "")
          {
            targeting_id = this.aso_cabang
            targeting_type = "aso_branch";
            targeting_status = true
          }
          else
          {
            targeting_status = false
            targeting = "aso_branch"
          }
        }
        else
        {
          targeting_id = this.aso_cabang
          targeting_type = "aso_branch";
          targeting_status = false
          targeting = "agent"
        }
      }
      else
      {
        targeting_status = false
        targeting = "fincoy_pic"
      }
      
      // Create the audience object
      if(targeting_status == false)
      {
        const audience = [{
          type: targeting_type,
          id: targeting_id,
          childrens: [targeting]
        }];
        // Append the audience to picData
        picData.append("audiences", JSON.stringify(audience));
      }
      else
      {
        const audience = [{
          type: targeting_type,
          id: targeting_id
        }];
        // Append the audience to picData
        picData.append("audiences", JSON.stringify(audience));
      }
      
      picData.append("status", (this.isActive) ? 'active' : 'inactive');

      axios
        .post("https://api.darlink.id/v1/announcement/" + this.infoID, picData,
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          }
        })
        .then((response) => {
          this.isLoading = false;
          // SWEETALLERT
          this.$swal({
            title: "Berhasil",
            text: "Perubahan Announcement berhasil dilakukan",
            icon: "success",
            confirmButtonText: "Tutup",
            confirmButtonColor: "#3085d6",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push({ name: "superadmin.management-info" })
            }
          });

          if (response.data.code != 201) {
            return
          }
          console.log(response)
          this.$router.push({ name: "superadmin.management-info" })
        })
        .catch((err) => {
          this.isLoading = false;
          if (err.response && err.response.data && err.response.data.validation) {
            const validationErrors = err.response.data.validation;
            let errorMessage = "Validation Error:\n";
            validationErrors.forEach((error) => {
              errorMessage += `${error.mesg}\n`;
            });
            this.$swal({
              title: "Validation Error",
              text: errorMessage,
              icon: "error",
              confirmButtonText: "OK",
              confirmButtonColor: "#d33",
            });
          } else {
            this.$swal({
              title: "Error",
              text: err.response.data.message,
              icon: "error",
              confirmButtonText: "OK",
              confirmButtonColor: "#d33",
            });
          }
        });
    }
  }
};
</script>
