<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="col-12" id="orderDetailExport">
        <div class="row">
          <div class="col-md-6">
            <!-- card -->
            <div class="mt-4 card card-body">
              <h6 class="mb-0">Show data order</h6>
                <div class="mt-4">
                  <div class="">
                    <div class="">
                      <h5 id="" class="title">
                        Nama Branch: {{ dataOrder.aso_branch_id }}
                      </h5>
                    </div>
                    <div class="body">
                      <div>
                        <h6>Agent: {{ dataOrder.agent_id }}</h6>
                        <p class="text-sm mb-0">
                          Nama PIC:
                          <b>Herman</b> <br />
                          Nomor HP
                          <b>1234567890</b>
                        </p>
                        <p class="text-sm">
                          NIK/NPK :
                          <b>243598234</b>
                        </p>
                        <p class="text-sm">
                          Jumlah Anggota :
                          <b>32</b>
                        </p>
                      </div>
                      <p>Jalan Jend. Sudirman</p>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mt-4 card card-body">
              <div>
                <p class="text-sm mb-0">
                  Nama Nasabah:
                  <b>{{ dataOrder.client_name }}</b> 
                  <br />
                  Nomor HP:
                  <b>{{ dataOrder.client_phone }}</b>
                  <br />
                  Alamat:
                  <b>{{ dataOrder.client_address }}</b>
                  <br />
                  Provinsi:
                  <b>{{ dataOrder.client_region }}</b>
                  <br />
                  <br />
                  Leased Item:
                  <b>{{ dataOrder.leased_item_name }}</b>
                  <br />
                  Leased Brand:
                  <b>{{ dataOrder.leased_item_brand}}</b>
                  <br />
                  Leased Item Type:
                  <b>{{ dataOrder.leased_item_type }}</b>
                  <br />
                  Leased Year:
                  <b>{{ dataOrder.leased_item_year }}</b>
                  <br />
                  Leased Item Type:
                  <b>{{ dataOrder.tenor }}</b>
                  <br />
                  <b>Total Pinjaman: </b>
                  <b>{{ dataOrder.total_loan }}</b>
                  <br />
                  <br />
                  Created Date:
                  <b>{{ dataOrder.created_at }}</b>
                  <br />
                  Updated Date:
                  <b>{{ dataOrder.updated_at ? dataOrder.updated_at : '' }}</b>
                  <br />
                  Confirmed Date:
                  <b>{{ dataOrder.confirmed_at ? dataOrder.confirmed_at : '' }}</b>
                  <br />
                  Rejected Date:
                  <b>{{ dataOrder.rejected_at ? dataOrder.rejected_at : '' }}</b>
                  <br />
                  Alasan:
                  <b>{{ dataOrder.reason ? dataOrder.reason : '' }}</b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer mt-2">
        <button
          type="button"
          @click="$router.push({ name: 'Fincoy Order Pencairan Dana Darlink' })"
          class="btn bg-gradient-secondary btn-sm"
        >
          Kembali
        </button>
        <button
          type="button"
          class="btn bg-success-secondary btn-sm"
          @click="exportToPDF"
        >
          Download PDF
        </button>
        <button
          type="button"
          @click="withdrawPlatform()"
          class="btn bg-gradient-primary btn-sm"
        >
          Pencairan Dana ke Konsumen
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import html2pdf from 'html2pdf.js';
  export default {
    data(){
      return{
        dataOrder: [],
      }
    },
    beforeMount() {
      if (localStorage.getItem("SideMenuType") !== "Fincoy") {
          this.$router.go(-1);
      }
    },
    mounted(){
      this.getDataOrder()
    },
    computed: {
      orderID() {
        if (!this.$route.params.orderList) {
          return null
        }
        return this.$route.params.orderList
      },
      
    },
    methods: {
      async exportToPDF() {
        html2pdf(document.getElementById("orderDetailExport"), {
          margin: 1,
          filename: "order.pdf",
        });
      },
      getDataOrder() {
        var token = window.localStorage.getItem("TOKEN")
        axios
          .get("https://api.darlink.id/v1/order/" +  this.orderID, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token,// Authorization: token
              // Authorization: "TEST_FINCOY_PIC123456789"
              // allow cors 
              // "Access-Control-Allow-Origin": "*",
              // "Access-Control-Allow-Credentials": true,
            }
          })
          .then((response) => {
            this.dataOrder = response.data.content
            
          })
          .catch((err) => {
            console.log(err);
          }
        );
      },
      withdrawPlatform() {
        var token = window.localStorage.getItem("TOKEN")
        axios
          .post("https://api.darlink.id/v1/order/" +  this.orderID + "/fund-user", {}, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token,
              // Authorization: "TEST_FINCOY_PIC123456789"
              // allow cors 
              // "Access-Control-Allow-Origin": "*",
              // "Access-Control-Allow-Credentials": true,
            }
          })
          .then((response) => {
            // SWEETALLERT
            this.$swal({
              title: "Berhasil",
              text: "Pencairan berhasil dilakukan",
              icon: "success",
              confirmButtonText: "Tutup",
              confirmButtonColor: "#3085d6",
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.push({ name: "Fincoy Order Pencairan Dana Darlink" })
              }
            });
            this.$router.push({ name: "Fincoy Order Pencairan Dana Darlink" })
            if (response.data.code != 200) {
              return
            }
          })
          .catch(() => {
            
              // this.$swal({
              this.$swal({
                title: "Gagal",
                text: "pencairan sudah dilakukan",
                icon: "error",
                confirmButtonText: "Tutup",
                confirmButtonColor: "#3085d6",
              });
            })
      }
    }
  }
</script>