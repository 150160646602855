<template>
  <div class="py-2 container-fluid">
    <div class="row">
          <div class="col-md-12">
            <div class="mt-4 card card-body">
              <div>
                <p class="text-sm mb-0">
                  Nama ASO Cabang:
                  <b>{{ dataOrder.displayname }}</b> 
                  <br />
                  Nama PIC Cabang:
                  <b>{{ dataOrder.pic_name }}</b> 
                  <br />
                  Jenis ASO Cabang:
                  <b>{{ dataOrder.type }}</b> 
                  <br />
                  NIK:
                  <b>{{ dataOrder.personal_id }}</b> 
                  <br />
                  Nomor HP:
                  <b>{{ dataOrder.phone }}</b>
                  <br />
                  Email:
                  <b>{{ dataOrder.email }}</b>
                  <br />
                  Bank Account Number:
                  <b>{{ dataOrder.bank_account }}</b>
                  <br />
                  Bank Account Name:
                  <b>{{ dataOrder.bank_account_holder }}</b>
                  <br />
                  Bank Name:
                  <b>{{ dataOrder.bank_name }}</b>
                  <br />
                  Alamat:
                  <b>{{ dataOrder.address }}</b>
                  <br />
                  Lokasi:
                  <b>{{ dataOrder.location }}</b>
                  <br />
                  Jenis Organisasi:
                  <b>{{ dataOrder.organization_type }}</b>
                  <br />
                  Nama Organisasi:
                  <b>{{ dataOrder.organization_name }}</b>
                  <br />
                  <br />
                  Jumlah Karayawan:
                  <b>{{ dataOrder.total_employees }}</b>
                  <br />
                  Fee Persentase Cabang:
                  <b>{{ dataOrder.branch_fee_cut}} %</b>
                  <br />
                  Fee Persentase Agent:
                  <b>{{ dataOrder.agent_fee_cut }} %</b>
                  <br />
                  Created Date:
                  <b id="created_date">{{ created ? created : '' }}</b>
                  <br />
                  Updated Date:
                  <b id="updated_date">{{ updated ? updated : '' }}</b>
                  <br />
                </p>
              </div>

              <!--Image-->
              <div v-if="dataOrder.profile_image">
                  <img :src="dataOrder.profile_image" alt="Profile" style="width: 20%; height: auto;"/>
              </div>
              <div v-if="dataOrder.personal_id_scan">
                  <img :src="dataOrder.personal_id_scan" alt="ID Card Document" style="width: 20%; height: auto;"/>
              </div>
              <br />
              <div v-if="dataOrder.bank_account_scan">
                  <img :src="dataOrder.bank_account_scan" alt="Bank Document" style="width: 20%; height: auto;"/>
              </div>

              <div class="modal-footer mt-2">
                <button
                  type="button"
                  @click="$router.push({ name: 'aso.branches' })"
                  class="btn bg-gradient-secondary btn-sm"
                >
                  Kembali
                </button>
              </div>
            </div>

          </div>
        </div>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    data(){
      return{
        dataOrder: {
          agent: {}, // Initialize agent object
          aso_branch: {},
          fincoy_pic: {},
          fincoy_ho: {},
        },
        created: "",
        updated: "",
        confirmed: "",
        rejected: "",
      }
    },
    beforeMount() {
      if (localStorage.getItem("SideMenuType") !== "AsoCentral") {
          this.$router.go(-1);
      }
    },
    mounted(){
      this.getDataOrder()
    },
    computed: {
      orderID() {
        if (!this.$route.params.orderList) {
          return null
        }
        return this.$route.params.orderList
      },
      
    },
    methods: {
      getDataOrder() {
        var token = window.localStorage.getItem("TOKEN")
        axios
          .get("https://api.darlink.id/v1/aso-branch/" +  this.orderID, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token,
              // allow cors 
              // "Access-Control-Allow-Origin": "*",
              // "Access-Control-Allow-Credentials": true,
            }
          })
          .then((response) => {
            this.dataOrder = response.data.content;
            this.dataOrder.agent = response.data.content.agent; // Assign agent data
            this.dataOrder.aso_branch = response.data.content.aso_branch; // Assign ASO Branch data
            this.dataOrder.fincoy_pic = response.data.content.handled_by;
            this.dataOrder.fincoy_ho = response.data.content.handled_from;
            
            this.created = new Date(this.dataOrder.created_at).toLocaleDateString("id-ID", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    // hour
                    hour: "numeric",
                    // minute
                    minute: "numeric",
                  });
            this.updated = new Date(this.dataOrder.updated_at).toLocaleDateString("id-ID", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    // hour
                    hour: "numeric",
                    // minute
                    minute: "numeric",
                  });
            this.confirmed = new Date(this.dataOrder.updated_at).toLocaleDateString("id-ID", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    // hour
                    hour: "numeric",
                    // minute
                    minute: "numeric",
                  });
            this.rejected = new Date(this.dataOrder.rejected_at).toLocaleDateString("id-ID", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    // hour
                    hour: "numeric",
                    // minute
                    minute: "numeric",
                  });
          })
          .catch((err) => {
            console.log(err);
          }
        );
      },
    }
  }
</script>