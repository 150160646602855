<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Semua Agent</h5>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <div class="row">
                <div class="col-lg-4" style="margin: 10px;">
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      v-model="searchQuery"
                      placeholder="Search Fincoy PIC..."
                    />
                  </div>
                  
                  <a
                    href="#"
                    class="mb-0 btn bg-gradient-success btn-sm"
                    style="margin-right: 10px;"
                    @click="performSearch"
                    >+&nbsp; search</a
                  >
                </div>
              </div>

              <table id="products-list" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Nama ASO Cabang</th>
                    <th>Nama Agent</th>
                    <th>Nomor Hp</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody></tbody>
                <tfoot>
                  <tr>
                    <th>Nama ASO Cabang</th>
                    <th>Nama Agent</th>
                    <th>Nomor Hp</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </tfoot>
              </table>

              <!-- DataTable's built-in pagination -->
              <nav aria-label="Page navigation">
                <ul class="pagination justify-content-end">
                  <li class="page-item" :class="{ disabled: page === 1 }">
                    <a class="page-link" href="javascript:void(0);" @click="loadPage(page - 1)">
                      Prev
                    </a>
                  </li>
                  <li class="page-item" v-for="pageNumber in totalPages" :key="pageNumber" :class="{ active: pageNumber === page }">
                    <a class="page-link" href="javascript:void(0);" @click="loadPage(pageNumber)">
                      {{ pageNumber }}
                    </a>
                  </li>
                  <li class="page-item" :class="{ disabled: page === totalPages }">
                    <a class="page-link" href="javascript:void(0);" @click="loadPage(page + 1)">
                      Next
                    </a>
                  </li>
                </ul>
              </nav>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="loading-overlay" v-if="isLoading">
    <!-- You can use a loading spinner or any other loading content here -->
    <div class="spinner"></div>
  </div>
</template>

<style scoped>
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .spinner {
    /* Style your loading spinner here */
    border: 5px solid rgba(0, 0, 0, 0.3);
    border-top: 5px solid #3498db; /* Blue color for the spinner */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
</style>

<script>
import { DataTable } from "simple-datatables";
import Swal from 'sweetalert2';
import setTooltip from "@/assets/js/tooltip.js";
import axios from 'axios';

var columns = [
  {
    select: 0,
    render: function (data) {
      return `<div class="d-flex">
          <h6 class="my-auto ms-3">${data}</h6>
        </div>
      `
    }
  },
  {
    select: [1,2],
    render: function (data) {
      return `<span class="text-sm">${data}</span>
      `
    }
  },
  {
    select: 3,
    render: function (data) {
      let status = (data === 'true') ? "AKTIF" :  "TIDAK AKTIF"
      let color = (data === 'true') ? "badge-success" :  "badge-danger"
      return `
      <span class="badge ${color} badge-sm">
        ${status}
      </span>
      `
    },
  },
  {
    select: 4,
    render: function (data) {
      let datas = JSON.parse(data)
      return `
        <!-- button edit modal -->
        <a
            class="text-sm"
            href="javascript: void(0);"
          >
          <span
            class="mx-3"
          >
            <i
            class="fas fa-eye text-secondary data-bs-toggle="tooltip" data-bs-placement="top" title="Detail"
              data-action="edit"
              data-id="${datas.id}"
            ></i>
          </span>
        </a>
        <a
            href="javascript:;"
            class="mx-3"
          >
            <i 
              data-id="${datas.id}"
              data-name="${datas.displayname}"
              data-action="approve" 
              class="fas fa-check text-secondary text-success"></i>
          </a>
          <a
            href="javascript:;"
            class="mx-3"
          >
            <i 
              data-id="${datas.id}"
              data-name="${datas.displayname}"
              data-action="reject" 
              class="fas fa-times text-secondary text-warning"></i>
          </a>
      `
    }
  },
]

var labels = {
  placeholder: "Cari Agent...",
  perPage: "Menampilkan {select} Agent per halaman",
  noRows: "Tidak ada Agent yang ditampilkan",
  info: "Menampilkan {start} ke {end} dari {rows} Agent (Halaman {page} dari {pages} halaman)"
}

export default {
  name: "Agents",
  data() {
    return {
      datatable: null,
      agents: [],
      isLoading: false,
      page: 1, // Current page
      totalPages: 1, // Total number of pages
      searchQuery: '',
    }
  },
  beforeMount() {
    if (localStorage.getItem("SideMenuType") !== "AsoCentral") {
        this.$router.go(-1);
    }
  },
  mounted() {
    if (document.getElementById("products-list")) {
      this.datatable = new DataTable("#products-list", {
        processing: this.isLoading,
        searchable: false,
        fixedHeight: false,
        perPage: 16,
        columns: columns,
        labels: labels,
        paging: false,
        filters: {
          // Add the search filter
          search: this.searchQuery,
        },
      });
      this.datatable.body.addEventListener("click", this.handleAction);
      this.toggleLoading()

      setTimeout(() => {
        this.loadAgent()
      }, 1000)
    }
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.datatable.destroy()
    this.datatable = null
  },
  methods: {
    handleAction(e) {
      console.log('id = ', e.target.getAttribute("data-id"));
      if (e.target.getAttribute("data-action") === "approve") {
        let id = e.target.getAttribute("data-id");
        let name = e.target.getAttribute("data-name")
        Swal.fire({
          title: 'Konfirmasi',
          text: `Anda yakin ingin mengaktifkan agent "${name}?"`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Batal',
        }).then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            var token = window.localStorage.getItem("TOKEN");
            axios
            .post("https://api.darlink.id/v1/agent/"+id+"/activate", 
            null,
            {
              withCredentials: true,
              headers: {
                Accept: "application/json",
                Authorization: token
              }
            })
            .then((response) => {
              this.isLoading = false;
              // if response code not 200
              if (response.data.code != 200) {
                // show error message
                // sweetalert error
                this.$swal({
                  title: "Gagal",
                  text: response.data.message,
                  icon: "error",
                  button: "OK",
                })
                return
              }
              this.$swal({
                title: "Berhasil",
                text: "Berhasil Aktivasi Agent",
                icon: "success",
                confirmButtonText: "OK",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload()
                }
              });
              this.$router.push({ name: "aso.agent" })
              // reload page after 1000ms
              setTimeout(() => {
                window.location.reload()
              }, 4000)
            })
            .catch((error) => {
              this.isLoading = false;
              // if error
              // show error message
              this.$store.dispatch("show-error", error)
            })
          }
        })
      }
      if (e.target.getAttribute("data-action") === "reject") {
        let id = e.target.getAttribute("data-id");
        let name = e.target.getAttribute("data-name")
        Swal.fire({
          title: 'Konfirmasi',
          text: `Anda yakin ingin menonaktifkan agent "${name}"?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Batal',
        }).then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            var token = window.localStorage.getItem("TOKEN");
            axios
            .post("https://api.darlink.id/v1/agent/"+id+"/deactivate", 
            null,
            {
              withCredentials: true,
              headers: {
                Accept: "application/json",
                Authorization: token
              }
            })
            .then((response) => {
              this.isLoading = false;
              // if response code not 200
              if (response.data.code != 200) {
                // show error message
                // sweetalert error
                this.$swal({
                  title: "Gagal",
                  text: response.data.message,
                  icon: "error",
                  button: "OK",
                })
                return
              }
              this.$swal({
                title: "Berhasil",
                text: "Berhasil Non aktifkan Agent",
                icon: "success",
                confirmButtonText: "OK",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload()
                }
              });
              this.$router.push({ name: "aso.agent" })
              // reload page after 1000ms
              setTimeout(() => {
                window.location.reload()
              }, 4000)
            })
            .catch((error) => {
              this.isLoading = false;
              // if error
              // show error message
              this.$store.dispatch("show-error", error)
            })
          }
        })
      }
      if (e.target.getAttribute("data-action") === "edit") {
        let id = parseInt(e.target.getAttribute("data-id"), 10)
        this.edit(id)
      }
    },
    edit(id) {
      this.$router.push({
        name: "aso.agent.detail",
        params: {
          orderList: id
        }
      })
    },
    toggleLoading() {
      if (!this.datatable) {
        return
      }
      if (!this.isLoading) {
        this.isLoading = true
        this.datatable?.setMessage("Memuat...")
        return
      }
      this.isLoading = false
    },
    performSearch() {
      if (this.datatable) {
        this.destroyDataTable(); // Destroy the current DataTable instance
      }
      
      if (document.getElementById("products-list")) {
        this.datatable = new DataTable("#products-list", {
          processing: this.isLoading,
          searchable: false,
          fixedHeight: false,
          perPage: 16,
          columns: columns,
          labels: labels,
          paging: false,
          filters: {
            // Add the search filter
            search: this.searchQuery,
          },
        });

        this.page = 1;

        this.datatable.body.addEventListener("click", this.handleAction)
        this.toggleLoading()
        this.loadAgent()
      }
    },
    loadAgent() {
      var token = window.localStorage.getItem("TOKEN")
      axios
        .get("https://api.darlink.id/v1/agent", {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          },
          params: {
            page: this.page, // Include the page parameter
            search: this.searchQuery, // Add the search query parameter
          },
        })
        .then((response) => {
          this.toggleLoading()
          if (response.data.code != 200) {
            return
          }

          if (response.data.content === null) {
            this.datatable.setMessage("Tidak ada data yang ditampilkan");
            return;
          }

          this.agents = response.data.content
          this.totalPages = response.data.pagination.total_pages; // Update total pages

          this.updateDataTable();
          // this.datatable.insert({
          //   data: this.agents.map((agent) => {
          //     return [
          //       // "",
          //       agent.aso_branch.displayname,
          //       agent.displayname,
          //       agent.phone,
          //       (agent.is_active) ? 'true' : 'false',
          //       `${agent.id}`
          //     ]
          //   })
          // })
        })
        .catch((err) => {
          this.toggleLoading()
          console.log(err)
        });
    },
    destroyDataTable() {
      if (this.datatable) {
        this.datatable.destroy();
        this.datatable = null;
      }
    },
    // Function to update the DataTable
    updateDataTable() {
      // if (this.datatable) {
      //   this.datatable.destroy();
      // }

      // this.datatable = new DataTable("#products-list", {
      //   processing: this.isLoading,
      //   searchable: true,
      //   fixedHeight: false,
      //   perPage: 16,
      //   columns: columns,
      //   labels: labels,
      // });

      // this.datatable.body.addEventListener("click", this.handleAction);
      this.datatable.body.innerHTML = '';
      this.datatable.insert({
        data: this.agents.map((agent) => {
          return [
            // "",
            agent.aso_branch_name,
            agent.displayname,
            agent.phone,
            (agent.is_active) ? 'true' : 'false',
            // `${agent.id}`
            JSON.stringify(agent),
          ]
        })
      });
    },
    // Function to load data for a specific page
    loadPage(page) {
      if (page < 1 || page > this.totalPages) {
        return;
      }

      this.page = page;
      // this.loadAgent();
      if (this.datatable) {
        this.destroyDataTable(); // Destroy the current DataTable instance
      }
      
      if (document.getElementById("products-list")) {
        this.datatable = new DataTable("#products-list", {
          processing: this.isLoading,
          searchable: false,
          fixedHeight: false,
          perPage: 16,
          columns: columns,
          labels: labels,
          paging: false,
          filters: {
            // Add the search filter
            search: this.searchQuery,
          },
        });
        this.datatable.body.addEventListener("click", this.handleAction)
        this.toggleLoading()
        this.loadAgent()
      }
    },
  }
};
</script>
