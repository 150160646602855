import { createRouter, createWebHistory } from "vue-router";

import Landing from "../views/dashboards/Landing.vue";
import Default from "../views/dashboards/Default.vue";
import Automotive from "../views/dashboards/Automotive.vue";
import SmartHome from "../views/dashboards/SmartHome.vue";
import VRDefault from "../views/dashboards/vr/VRDefault.vue";
import VRInfo from "../views/dashboards/vr/VRInfo.vue";
import CRM from "../views/dashboards/CRM.vue";
import Overview from "../views/pages/profile/Overview.vue";
import Teams from "../views/pages/profile/Teams.vue";
import Projects from "../views/pages/profile/Projects.vue";
import General from "../views/pages/projects/General.vue";
import Timeline from "../views/pages/projects/Timeline.vue";
import NewProject from "../views/pages/projects/NewProject.vue";
import Pricing from "../views/pages/Pricing.vue";
import RTL from "../views/pages/Rtl.vue";
import Charts from "../views/pages/Charts.vue";
import SweetAlerts from "../views/pages/SweetAlerts.vue";
import Notifications from "../views/pages/Notifications.vue";
import Kanban from "../views/applications/Kanban.vue";
import Wizard from "../views/applications/wizard/Wizard.vue";
import DataTables from "../views/applications/DataTables.vue";
import Calendar from "../views/applications/Calendar.vue";
import Analytics from "../views/applications/analytics/Analytics.vue";
import EcommerceOverview from "../views/ecommerce/overview/Overview.vue";
import NewProduct from "../views/ecommerce/products/NewProduct.vue";
import EditProduct from "../views/ecommerce/EditProduct.vue";
import ProductPage from "../views/ecommerce/ProductPage.vue";
import ProductsList from "../views/ecommerce/ProductsList.vue";
import OrderDetails from "../views/ecommerce/Orders/OrderDetails";
import OrderList from "../views/ecommerce/Orders/OrderList";
import Referral from "../views/ecommerce/Referral";
import Reports from "../views/pages/Users/Reports.vue";
import NewUser from "../views/pages/Users/NewUser.vue";
import Settings from "../views/pages/Account/Settings.vue";
import Billing from "../views/pages/Account/Billing.vue";
import Invoice from "../views/pages/Account/Invoice.vue";
import Security from "../views/pages/Account/Security.vue";
import Widgets from "../views/pages/Widgets.vue";
import Basic from "../views/auth/signin/Basic.vue";
import Cover from "../views/auth/signin/Cover.vue";
import Illustration from "../views/auth/signin/Illustration.vue";
import ResetBasic from "../views/auth/reset/Basic.vue";
import ResetCover from "../views/auth/reset/Cover.vue";
import ResetIllustration from "../views/auth/reset/Illustration.vue";
import VerificationBasic from "../views/auth/verification/Basic.vue";
import VerificationCover from "../views/auth/verification/Cover.vue";
import VerificationIllustration from "../views/auth/verification/Illustration.vue";
import SignupBasic from "../views/auth/signup/Basic.vue";
import SignupCover from "../views/auth/signup/Cover.vue";
import SignupIllustration from "../views/auth/signup/Illustration.vue";
import Error404 from "../views/auth/error/Error404.vue";
import Error500 from "../views/auth/error/Error500.vue";
import lockBasic from "../views/auth/lock/Basic.vue";
import lockCover from "../views/auth/lock/Cover.vue";
import lockIllustration from "../views/auth/lock/Illustration.vue";

//superAdmin
import Dashboard from "../views/superadmin/dashboard.vue";
import SuperadminPrivacy from "../views/auth/signin/privacy_policy.vue";
import SuperadminTerm from "../views/auth/signin/term_condition.vue";
import SuperadminLogin from "../views/auth/signin/superadmin.vue";
import SuperadminAsoCentral from "../views/superadmin/aso_central/AsoCentralList.vue";
import SuperadminNewAsoCentral from "../views/superadmin/aso_central/NewAsoCentral.vue";
import SuperadminEditAsoCentral from "../views/superadmin/aso_central/EditAsoCentral.vue";
import SuperadminAsoCabang from "../views/superadmin/aso_cabang/AsoCabangList.vue";
import SuperadminFincoy from "../views/superadmin/fincoy_ho/FincoyList.vue";
import SuperadminFincoyNew from "../views/superadmin/fincoy_ho/NewFincoy.vue";
import SuperadminFincoyEdit from "../views/superadmin/fincoy_ho/EditFincoy.vue";
import SuperadminWD from "../views/superadmin/proses_withdraw/proses_withdraw.vue";
import Superadminmanagementinfo from "../views/superadmin/management_info/ListManagementInfo.vue";
import SuperadminmanagementinfoEdit from "../views/superadmin/management_info/EditManagementInfo.vue";
import SuperadminmanagementinfoNew from "../views/superadmin/management_info/new-management-info.vue";
import ReportByAsoCentralSA from "../views/superadmin/Report/order/ByAsoCentral.vue";
import ReportByAsoCabangSA from "../views/superadmin/Report/order/ByAsoCabang.vue";
import ReportByAsoAgentSA from "../views/superadmin/Report/order/ByAgent.vue";
import ReportOrder from "../views/superadmin/Report/order/ReportOrder.vue";
import ReportByFincoyHo from "../views/superadmin/Report/fincoy/FincoyHo.vue";
import ReportByFincoyPic from "../views/superadmin/Report/fincoy/FincoyPic.vue";
import SuperadminStaff from "../views/superadmin/staff/StaffList.vue";
import SuperadminStaffNew from "../views/superadmin/staff/NewStaff.vue";
import SuperadminStaffEdit from "../views/superadmin/staff/EditStaff.vue";
import SuperadminRole from "../views/superadmin/staff/RoleList.vue";
import SuperadminRoleNew from "../views/superadmin/staff/NewRole.vue";
import SuperadminRoleEdit from "../views/superadmin/staff/EditRole.vue";
import SuperadminOrderPencairanDanaUser from "../views/superadmin/order/pencairan-dana/user.vue";
import SuperadminOrderPencairanDanaUserDetail from "../views/superadmin/order/pencairan-dana/user-detail.vue";
import SuperadminOrderCompleted from "../views/superadmin/order/completed/List.vue";
import SuperadminOrderCompletedDetail from "../views/superadmin/order/completed/Detail.vue";
import SuperadminAsoAgent from "../views/superadmin/agent/AgentList.vue";
import SuperadminAsoAgentDetail from "../views/superadmin/agent/Detail.vue";
import SuperadminAsoCabangDetail from "../views/superadmin/aso_cabang/Detail.vue";
import SuperadminFinocyPicDetail from "../views/superadmin/pic_fincoy/Detail.vue";
import SuperadminWDDetail from "../views/superadmin/proses_withdraw/Detail.vue";
import SuperadminSettings from "../views/superadmin/Settings.vue";

//ASO
import AsoDashboard from "../views/aso/dashboard.vue";
// import AsoCentralLogin from "../views/auth/signin/asocentral.vue";
import AsoCabang from "../views/aso/aso_cabang/AsoCabangList.vue";
import AsoNewAsoCabang from "../views/aso/aso_cabang/NewAsoCabang.vue";
import AsoEditAsoCabang from "../views/aso/aso_cabang/EditAsoCabang.vue";
import AsoViewAsoCabang from "../views/aso/aso_cabang/Detail.vue";
import AsoFincoy from "../views/aso/pic_fincoy/FincoyList.vue";
import AsoFincoyNew from "../views/aso/pic_fincoy/NewFincoy.vue";
import AsoFincoyEdit from "../views/aso/pic_fincoy/EditFincoy.vue";
import SuperadminFincoyPic from "../views/superadmin/pic_fincoy/FincoyList.vue";
import AsoOrder from "../views/aso/order/OrderList.vue";
import AsoAgent from "../views/aso/agent/AgentList.vue";
import AsoAgentDetail from "../views/aso/agent/Detail.vue";
import Asomanagementinfo from "../views/aso/management_info/ListManagementInfo.vue";
import AsomanagementinfoNew from "../views/aso/management_info/new-management-info.vue";
import AsomanagementinfoEdit from "../views/aso/management_info/EditManagementInfo.vue";
import ReportAsoByAsoCabang from "../views/aso/Report/ByAsoCabang.vue";
import ReportAsoByAsoAgent from "../views/aso/Report/ByAgent.vue";
import ReportAsoOrder from "../views/aso/Report/ReportOrder.vue";
import AsoStaff from "../views/aso/staff/StaffList.vue";
import AsoStaffNew from "../views/aso/staff/NewStaff.vue";
import AsoRole from "../views/aso/staff/RoleList.vue";
import AsoRoleNew from "../views/aso/staff/NewRole.vue";
import AsoLog from "../views/aso/log/HistoryLog.vue";
import AsoOrderPending from "../views/aso/order/pending/List.vue";
import AsoOrderPendingDetail from "../views/aso/order/pending/AssignOrder.vue";
import AsoOrderPendingShow from "../views/aso/order/pending/Detail.vue";
import AsoOrderAssign from "../views/aso/order/assign/List.vue";
import AsoOrderAssignDetail from "../views/aso/order/assign/Detail.vue";
import AsoOrderProcess from "../views/aso/order/process/List.vue";
import AsoOrderProcessDetail from "../views/aso/order/process/Detail.vue";
import AsoOrderReject from "../views/aso/order/reject/List.vue";
import AsoOrderRejectDetail from "../views/aso/order/reject/Detail.vue";
import AsoOrderApproved from "../views/aso/order/approved/List.vue";
import AsoOrderApprovedDetail from "../views/aso/order/approved/Detail.vue";
import AsoOrderCompleted from "../views/aso/order/completed/List.vue";
import AsoOrderCompletedDetail from "../views/aso/order/completed/Detail.vue";
import AsoOrderPencairanDanaUser from "../views/aso/order/OrderPencairanDanaUser.vue";
import AsoOrderPencairanDanaUserDetail from "../views/aso/order/pencairan-dana/user-detail.vue";
import AsoOrderPencairanDanaDarlink from "../views/aso/order/OrderPencairanDanaDarlink.vue";
import AsoOrderPencairanDanaDarlinkDetail from "../views/aso/order/pencairan-dana/darlink-detail.vue";
import AsoUpdatePassword from "../views/aso/UpdatePassword.vue";

//Fincoy
// import FincoyLogin from "../views/auth/signin/fincoy.vue";
import FincoyDashboard from "../views/fincoy/dashboard.vue";
import FincoyProfile from "../views/fincoy/Profile.vue";
import FincoyUpdatePassword from "../views/fincoy/UpdatePassword.vue";
import FincoyPriceList from "../views/fincoy/PriceList.vue";
import FincoyOrderAssign from "../views/fincoy/Order/assign/List.vue";
import FincoyOrderAssignTakeOrder from "../views/fincoy/Order/assign/Detail.vue";
import FincoyOrderProsess from "../views/fincoy/Order/proses/List.vue";
import FincoyOrderProsesDetail from "../views/fincoy/Order/proses/Detail.vue";
import FincoyOrderReject from "../views/fincoy/Order/reject/List.vue";
import FincoyOrderRejectDetail from "../views/fincoy/Order/reject/Detail.vue";
import FincoyOrderApproved from "../views/fincoy/Order/approve/List.vue";
import FincoyOrderApprovedDetail from "../views/fincoy/Order/approve/Detail.vue";
import FincoyOrderPencairanDanaUser from "../views/fincoy/Order/pencairan-dana/user.vue";
import FincoyOrderPencairanDanaUserDetail from "../views/fincoy/Order/pencairan-dana/user-detail.vue";
import FincoyOrderPencairanDanaDarlink from "../views/fincoy/Order/pencairan-dana/darlink.vue";
import FincoyOrderPencairanDanaDarlinkDetail from "../views/fincoy/Order/pencairan-dana/darlink-detail.vue";
import FincoyOrderCompleted from "../views/fincoy/Order/completed/List.vue";
import FincoyOrderCompletedDetail from "../views/fincoy/Order/completed/Detail.vue";

import NotFound from '../views/404.vue'; // Not Found

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/authentication/signin"
  },

  //Super Admin
  {
    path: "/policy",
    name: "superadmin.privacy",
    component: SuperadminPrivacy
  },
  {
    path: "/terms",
    name: "superadmin.term",
    component: SuperadminTerm
  },
  {
    path: "/authentication/signin",
    name: "superadmin.login",
    component: SuperadminLogin
  },
  {
    path: "/superadmin/dashboard",
    name: "superadmin.dashboard",
    component: Dashboard,
    meta: {
      page: "Dasbor"
    }
  },
  {
    path: "/superadmin/aso-central",
    name: "superadmin.aso-central",
    component: SuperadminAsoCentral,
    meta: {
      page: "ASO Central"
    }
  },
  {
    path: "/superadmin/new-aso-central",
    name: "superadmin.aso-central.create",
    component: SuperadminNewAsoCentral,
    meta: {
      page: "Tambah ASO Central"
    }
  },
  {
    path: "/superadmin/aso-central/:asoCentral/edit",
    name: "superadmin.aso-central.edit",
    component: SuperadminEditAsoCentral,
    meta: {
      page: "Ubah ASO Central"
    }
  },
  {
    path: "/superadmin/aso-cabang",
    name: "superadmin.aso-branch",
    component: SuperadminAsoCabang,
    meta: {
      page: "ASO Cabang"
    }
  },
  {
    path: "/superadmin/fincoy-ho",
    name: "superadmin.fincoy-ho",
    component: SuperadminFincoy,
    meta: {
      page: "Fincoy HO (Head Office)"
    }
  },
  {
    path: "/superadmin/new-fincoy-ho",
    name: "superadmin.fincoy-ho.create",
    component: SuperadminFincoyNew,
    meta: {
      page: "Tambah Fincoy HO"
    }
  },
  {
    path: "/superadmin/fincoy-ho/:fincoyHO/edit",
    name: "superadmin.fincoy-ho.edit",
    component: SuperadminFincoyEdit,
    meta: {
      page: "Ubah Fincoy HO"
    }
  },
  {
    path: "/superadmin/prosess-wd",
    name: "superadmin.process-withdraw",
    component: SuperadminWD,
    meta: {
      page: "Process Withdraw"
    }
  },
  {
    path: "/superadmin/managementinfo-list",
    name: "superadmin.management-info",
    component: Superadminmanagementinfo,
    meta: {
      page: "Management Info"
    }
  },
  {
    path: "/superadmin/management-info-new",
    name: "superadmin.management-info.create",
    component: SuperadminmanagementinfoNew,
    meta: {
      page: "Buat Management Info"
    }
  },
  {
    path: "/superadmin/management-info/:announcement/edit",
    name: "superadmin.management-info.edit",
    component: SuperadminmanagementinfoEdit,
    meta: {
      page: "Management Info"
    }
  },
  {
    path: "/superadmin/report/by-aso-central",
    name: "superadmin.report.aso-central",
    component: ReportByAsoCentralSA,
    meta: {
      page: "Report By ASO Central"
    }
  },
  {
    path: "/superadmin/report/by-aso-cabang",
    name: "superadmin.report.aso-branch",
    component: ReportByAsoCabangSA,
    meta: {
      page: "Report By ASO Branch"
    }
  },
  {
    path: "/superadmin/report/by-agent",
    name: "superadmin.report.agent",
    component: ReportByAsoAgentSA,
    meta: {
      page: "Report By Agent"
    }
  },
  {
    path: "/superadmin/report/report-order",
    name: "superadmin.report.order",
    component: ReportOrder
  },
  {
    path: "/superadmin/report/fincoy-ho",
    name: "superadmin.report.fincoy-ho",
    component: ReportByFincoyHo
  },
  {
    path: "/superadmin/report/fincoy-pic",
    name: "superadmin.report.fincoy-pic",
    component: ReportByFincoyPic
  },
  {
    path: "/superadmin/staff",
    name: "superadmin.staff",
    component: SuperadminStaff,
    meta: {
      page: "Semua Staff"
    }
  },
  {
    path: "/superadmin/new-staff",
    name: "superadmin.staff.create",
    component: SuperadminStaffNew,
    meta: {
      page: "Tambah Staff"
    }
  },
  {
    path: "/superadmin/edit-staff",
    name: "superadmin.staff.edit",
    component: SuperadminStaffEdit,
    meta: {
      page: "Edit Staff"
    }
  },
  {
    path: "/superadmin/role-staff",
    name: "superadmin.staff.role",
    component: SuperadminRole,
    meta: {
      page: "Semua Role Staff"
    }
  },
  {
    path: "/superadmin/new-role-staff",
    name: "superadmin.staff.role.create",
    component: SuperadminRoleNew,
    meta: {
      page: "Tambah Role Staff"
    }
  },
  {
    path: "/superadmin/edit-role-staff",
    name: "superadmin.staff.role.edit",
    component: SuperadminRoleEdit,
    meta: {
      page: "Edit Role Staff"
    }
  },
  {
    path: "/superadmin/order/completed",
    name: "superadmin.order-completed",
    component: SuperadminOrderCompleted
  },
  {
    path: "/superadmin/order/completed/show/:orderList",
    name: "superadmin.order-completed.detail",
    component: SuperadminOrderCompletedDetail
  },
  {
    path: "/superadmin/order/pencairan-dana-user",
    name: "Superadmin Pencairan Dana User",
    component: SuperadminOrderPencairanDanaUser
  },
  {
    path: "/superadmin/order/pencairan-dana-user/:orderList",
    name: "superadmin.order-pencairan-dana-user.detail",
    component: SuperadminOrderPencairanDanaUserDetail
  },
  {
    path: "/superadmin/fincoy-pic",
    name: "superadmin.list-fincoy-pic",
    component: SuperadminFincoyPic
  },
  {
    path: "/superadmin/aso-agent",
    name: "superadmin.aso-agent",
    component: SuperadminAsoAgent
  },
  {
    path: "/superadmin/aso-agent/show/:orderList",
    name: "superadmin.aso-agent.detail",
    component: SuperadminAsoAgentDetail
  },
  {
    path: "/superadmin/aso-cabang/show/:orderList",
    name: "superadmin.aso-branch.detail",
    component: SuperadminAsoCabangDetail
  },
  {
    path: "/superadmin/fincoy-ho/show/:orderList",
    name: "superadmin.fincoy-pic.detail",
    component: SuperadminFinocyPicDetail
  },
  {
    path: "/superadmin/prosess-wd/show/:orderList",
    name: "superadmin.process-withdraw.detail",
    component: SuperadminWDDetail
  },
  {
    path: "/superadmin/settings",
    name: "superadmin.settings",
    component: SuperadminSettings
  },

  //End SUper Admin

  //ASO Central
  // {
  //   path: "/authentication/signin/asocentral",
  //   name: "AsoCentralLogin",
  //   component: AsoCentralLogin
  // },
  {
    path: "/aso/dashboard",
    name: "aso.dashboard",
    component: AsoDashboard,
    meta: {
      page: "Dasbor"
    }
  },
  {
    path: "/aso/update-password",
    name: "aso.update-password",
    component: AsoUpdatePassword
  },
  {
    path: "/aso/aso-cabang",
    name: "aso.branches",
    component: AsoCabang,
    meta: {
      page: "Aso Cabang"
    }
  },
  {
    path: "/aso/new-aso-cabang",
    name: "aso.branches.create",
    component: AsoNewAsoCabang,
    meta: {
      page: "Tambah Aso Cabang"
    }
  },
  {
    path: "/aso/edit-aso-cabang/:id",
    name: "aso.branches.edit",
    component: AsoEditAsoCabang,
    meta: {
      page: "Ubah Aso Cabang"
    }
  },
  {
    path: "/aso/view-aso-cabang/:orderList",
    name: "aso.branches.detail",
    component: AsoViewAsoCabang,
    meta: {
      page: "View Aso Cabang"
    }
  },
  {
    path: "/aso/fincoy-pic",
    name: "aso.fincoy-pic",
    component: AsoFincoy,
    meta: {
      page: "Fincoy PIC"
    }
  },
  {
    path: "/aso/new-fincoy-pic",
    name: "aso.fincoy-pic.create",
    component: AsoFincoyNew,
    meta: {
      page: "Tambah Fincoy PIC"
    }
  },
  {
    path: "/aso/edit-fincoy-pic/:id",
    name: "aso.fincoy-pic.edit",
    component: AsoFincoyEdit,
    meta: {
      page: "Ubah Fincoy PIC"
    }
  },
  {
    path: "/aso/order-list",
    name: "aso.order",
    component: AsoOrder,
    meta: {
      page: "Order"
    }
  },
  {
    path: "/aso/aso-agent",
    name: "aso.agent",
    component: AsoAgent,
    meta: {
      page: "Agent"
    }
  },
  {
    path: "/aso/aso-agent",
    name: "aso.agent.detail",
    component: AsoAgentDetail,
    meta: {
      page: "Agent"
    }
  },
  {
    path: "/aso/managementinfo-list",
    name: "aso.management-info",
    component: Asomanagementinfo,
    meta: {
      page: "Management Info"
    }
  },
  {
    path: "/aso/management-info-new",
    name: "aso.management-info.create",
    component: AsomanagementinfoNew,
    meta: {
      page: "Buat Management Info"
    }
  },
  // {
  //   path: "/aso/managementinfo-new",
  //   name: "aso.management-info.create",
  //   component: AsomanagementinfoNew,
  //   meta: {
  //     page: "Tambah Management Info"
  //   }
  // },
  {
    path: "/aso/managementinfo-edit",
    name: "aso.management-info.edit",
    component: AsomanagementinfoEdit,
    meta: {
      page: "Ubah Management Info"
    }
  },
  {
    path: "/aso/report/by-aso-cabang",
    name: "aso.report.branches",
    component: ReportAsoByAsoCabang,
    meta: {
      page: "Report Central By Aso Cabang",
    }
  },
  {
    path: "/aso/report/by-agent",
    name: "aso.report.agent",
    component: ReportAsoByAsoAgent,
    meta: {
      page: "Report Central By Agent",
    }
  },
  {
    path: "/aso/report/report-order",
    name: "aso.report.order",
    component: ReportAsoOrder,
    meta: {
      page: "Report Central Order",
    }
  },
  {
    path: "/aso/aso-staff",
    name: "aso.staff",
    component: AsoStaff,
    meta: {
      page: "Staff"
    }
  },
  {
    path: "/aso/new-aso-staff",
    name: "aso.staff.create",
    component: AsoStaffNew,
    meta: {
      page: "Tambah Staff"
    }
  },
  {
    path: "/aso/aso-role-staff",
    name: "aso.staff.role",
    component: AsoRole,
    meta: {
      page: "Role Staff"
    }
  },
  {
    path: "/aso/new-aso-role-staff",
    name: "aso.staff.role.create",
    component: AsoRoleNew,
    meta: {
      page: "Tambah Role Staff"
    }
  },
  {
    path: "/aso/log-history",
    name: "aso.histories",
    component: AsoLog,
    meta: {
      page: "History Log"
    }
  },
  //End ASO Central


  //Fincoy Menu
  // {
  //   path: "/authentication/signin/fincoy",
  //   name: "FincoyLogin",
  //   component: FincoyLogin
  // },
  {
    path: "/fincoy/dashboard",
    name: "Fincoy Dashboard",
    component: FincoyDashboard
  },
  {
    path: "/fincoy/profile",
    name: "Fincoy Profile",
    component: FincoyProfile
  },
  {
    path: "/fincoy/update-password",
    name: "Fincoy Update Password",
    component: FincoyUpdatePassword
  },
  {
    path: "/fincoy/price-list",
    name: "Fincoy Price List",
    component: FincoyPriceList
  },
  {
    path: "/fincoy/order/assign",
    name: "Fincoy Order Assign",
    component: FincoyOrderAssign
  },
  {
    path: "/fincoy/order/assign/take-order/:orderList",
    name: "fincoy.takeorder",
    component: FincoyOrderAssignTakeOrder
  },
  {
    path: "/fincoy/order/proses",
    name: "Fincoy Order Proses",
    component: FincoyOrderProsess
  },
  {
    path: "/fincoy/order/proses/:orderList",
    name: "Fincoy Order Proses Detail",
    component: FincoyOrderProsesDetail
  },
  {
    path: "/fincoy/order/reject",
    name: "Fincoy Order Reject",
    component: FincoyOrderReject
  },
  {
    path: "/fincoy/order/reject/:orderList",
    name: "Fincoy Order Reject Detail",
    component: FincoyOrderRejectDetail
  },
  {
    path: "/fincoy/order/approved",
    name: "Fincoy Order Approved",
    component: FincoyOrderApproved
  },
  {
    path: "/fincoy/order/approved/:orderList",
    name: "Fincoy Order Approved Detail",
    component: FincoyOrderApprovedDetail
  },
  {
    path: "/fincoy/order/pencairan-dana-user",
    name: "Fincoy Order Pencairan Dana User",
    component: FincoyOrderPencairanDanaUser
  },
  {
    path: "/fincoy/order/pencairan-dana-user/:orderList",
    name: "Fincoy Order Pencairan Dana User Detail",
    component: FincoyOrderPencairanDanaUserDetail
  },
  {
    path: "/fincoy/order/pencairan-dana-darlink",
    name: "Fincoy Order Pencairan Dana Darlink",
    component: FincoyOrderPencairanDanaDarlink
  },
  {
    path: "/fincoy/order/pencairan-dana-darlink/:orderList",
    name: "Fincoy Order Pencairan Dana Detail",
    component: FincoyOrderPencairanDanaDarlinkDetail
  },
  {
    path: "/fincoy/order/completed",
    name: "Fincoy Order Completed",
    component: FincoyOrderCompleted
  },
  {
    path: "/fincoy/order/completed/:orderList",
    name: "Fincoy Order Completed Detail",
    component: FincoyOrderCompletedDetail
  },

  //End Fincoy Menu

  // Start Order Menu
  {
    path: "/aso/order-pending",
    name: "aso.order-pending",
    component: AsoOrderPending,
    meta: {
      page: "Staff"
    }
  },
  {
    path: "/aso/order-pending/show/:orderList",
    name: "aso.order-pending.show",
    component: AsoOrderPendingShow,
    meta: {
      page: "Staff"
    }
  },
  {
    path: "/aso/order-pending/:orderList",
    name: "aso.order-pending.detail",
    component: AsoOrderPendingDetail,
    meta: {
      page: "Staff"
    }
  },
  {
    path: "/aso/order-assign",
    name: "aso.order-assign",
    component: AsoOrderAssign,
    meta: {
      page: "Staff"
    }
  },
  {
    path: "/aso/order-assign/show/:orderList",
    name: "aso.order-assign.detail",
    component: AsoOrderAssignDetail,
    meta: {
      page: "Staff"
    }
  },
  {
    path: "/aso/order-process",
    name: "aso.order-process",
    component: AsoOrderProcess,
    meta: {
      page: "Staff"
    }
  },
  {
    path: "/aso/order-process/show/:orderList",
    name: "aso.order-process.detail",
    component: AsoOrderProcessDetail,
    meta: {
      page: "Staff"
    }
  },
  {
    path: "/aso/order-reject",
    name: "aso.order-reject",
    component: AsoOrderReject,
    meta: {
      page: "Staff"
    }
  },
  {
    path: "/aso/order-reject/show/:orderList",
    name: "aso.order-reject.detail",
    component: AsoOrderRejectDetail,
    meta: {
      page: "Staff"
    }
  },
  {
    path: "/aso/order-approved",
    name: "aso.order-approved",
    component: AsoOrderApproved,
    meta: {
      page: "Staff"
    }
  },
  {
    path: "/aso/order-approved/show/:orderList",
    name: "aso.order-approved.detail",
    component: AsoOrderApprovedDetail,
    meta: {
      page: "Staff"
    }
  },
  {
    path: "/aso/order-completed",
    name: "aso.order-completed",
    component: AsoOrderCompleted,
    meta: {
      page: "Staff"
    }
  },
  {
    path: "/aso/order-completed/show/:orderList",
    name: "aso.order-completed.detail",
    component: AsoOrderCompletedDetail,
    meta: {
      page: "Staff"
    }
  },
  {
    path: "/aso/order/pencairan-dana-user",
    name: "Aso Order Pencairan Dana User",
    component: AsoOrderPencairanDanaUser
  },
  {
    path: "/aso/order/pencairan-dana-user/:orderList",
    name: "Aso Order Pencairan Dana User Detail",
    component: AsoOrderPencairanDanaUserDetail
  },
  {
    path: "/aso/order/pencairan-dana-darlink",
    name: "Aso Order Pencairan Dana Darlink",
    component: AsoOrderPencairanDanaDarlink
  },
  {
    path: "/aso/order/pencairan-dana-darlink/:orderList",
    name: "Aso Order Pencairan Dana Darlink Detail",
    component: AsoOrderPencairanDanaDarlinkDetail
  },
  
  // End Order Menu

  {
    path: "/dashboards/dashboard-default",
    name: "Default",
    component: Default
  },
  {
    path: "/dashboards/landing",
    name: "Landing",
    component: Landing
  },
  {
    path: "/dashboards/automotive",
    name: "Automotive",
    component: Automotive
  },
  {
    path: "/dashboards/smart-home",
    name: "Smart Home",
    component: SmartHome
  },
  {
    path: "/dashboards/vr/vr-default",
    name: "VR Default",
    component: VRDefault
  },
  {
    path: "/dashboards/vr/vr-info",
    name: "VR Info",
    component: VRInfo
  },
  {
    path: "/dashboards/crm",
    name: "CRM",
    component: CRM
  },
  {
    path: "/pages/profile/overview",
    name: "Profile Overview",
    component: Overview
  },
  {
    path: "/pages/profile/teams",
    name: "Teams",
    component: Teams
  },
  {
    path: "/pages/profile/projects",
    name: "All Projects",
    component: Projects
  },
  {
    path: "/pages/projects/general",
    name: "General",
    component: General
  },
  {
    path: "/pages/projects/timeline",
    name: "Timeline",
    component: Timeline
  },
  {
    path: "/pages/projects/new-project",
    name: "New Project",
    component: NewProject
  },
  {
    path: "/pages/pricing-page",
    name: "Pricing Page",
    component: Pricing
  },
  {
    path: "/pages/rtl-page",
    name: "RTL",
    component: RTL
  },
  {
    path: "/pages/charts",
    name: "Charts",
    component: Charts
  },
  {
    path: "/pages/widgets",
    name: "Widgets",
    component: Widgets
  },
  {
    path: "/pages/sweet-alerts",
    name: "Sweet Alerts",
    component: SweetAlerts
  },
  {
    path: "/pages/notifications",
    name: "Notifications",
    component: Notifications
  },
  {
    path: "/applications/kanban",
    name: "Kanban",
    component: Kanban
  },
  {
    path: "/applications/wizard",
    name: "Wizard",
    component: Wizard
  },
  {
    path: "/applications/data-tables",
    name: "Data Tables",
    component: DataTables
  },
  {
    path: "/applications/calendar",
    name: "Calendar",
    component: Calendar
  },
  {
    path: "/applications/analytics",
    name: "Analytics",
    component: Analytics
  },
  {
    path: "/ecommerce/overview",
    name: "Overview",
    component: EcommerceOverview
  },
  {
    path: "/ecommerce/products/new-product",
    name: "New Product",
    component: NewProduct
  },
  {
    path: "/ecommerce/products/edit-product",
    name: "Edit Product",
    component: EditProduct
  },
  {
    path: "/ecommerce/products/product-page",
    name: "Product Page",
    component: ProductPage
  },
  {
    path: "/ecommerce/products/products-list",
    name: "Products List",
    component: ProductsList
  },
  {
    path: "/ecommerce/Orders/order-details",
    name: "Order Details",
    component: OrderDetails
  },
  {
    path: "/ecommerce/Orders/order-list",
    name: "Order List",
    component: OrderList
  },
  {
    path: "/ecommerce/referral",
    name: "Referral",
    component: Referral
  },
  {
    path: "/pages/users/reports",
    name: "Reports",
    component: Reports
  },
  {
    path: "/pages/users/new-user",
    name: "New User",
    component: NewUser
  },
  {
    path: "/pages/account/settings",
    name: "Settings",
    component: Settings
  },
  {
    path: "/pages/account/billing",
    name: "Billing",
    component: Billing
  },
  {
    path: "/pages/account/invoice",
    name: "Invoice",
    component: Invoice
  },
  {
    path: "/pages/account/Security",
    name: "Security",
    component: Security
  },
  {
    path: "/authentication/signin/basic",
    name: "Signin Basic",
    component: Basic
  },
  {
    path: "/authentication/signin/cover",
    name: "Signin Cover",
    component: Cover
  },
  {
    path: "/authentication/signin/illustration",
    name: "Signin Illustration",
    component: Illustration
  },
  {
    path: "/authentication/reset/basic",
    name: "Reset Basic",
    component: ResetBasic
  },
  {
    path: "/authentication/reset/cover",
    name: "Reset Cover",
    component: ResetCover
  },
  {
    path: "/authentication/reset/illustration",
    name: "Reset Illustration",
    component: ResetIllustration
  },
  {
    path: "/authentication/lock/basic",
    name: "Lock Basic",
    component: lockBasic
  },
  {
    path: "/authentication/lock/cover",
    name: "Lock Cover",
    component: lockCover
  },
  {
    path: "/authentication/lock/illustration",
    name: "Lock Illustration",
    component: lockIllustration
  },
  {
    path: "/authentication/verification/basic",
    name: "Verification Basic",
    component: VerificationBasic
  },
  {
    path: "/authentication/verification/cover",
    name: "Verification Cover",
    component: VerificationCover
  },
  {
    path: "/authentication/verification/illustration",
    name: "Verification Illustration",
    component: VerificationIllustration
  },
  {
    path: "/authentication/signup/basic",
    name: "Signup Basic",
    component: SignupBasic
  },
  {
    path: "/authentication/signup/cover",
    name: "Signup Cover",
    component: SignupCover
  },
  {
    path: "/authentication/signup/illustration",
    name: "Signup Illustration",
    component: SignupIllustration
  },
  {
    path: "/authentication/error/error404",
    name: "Error Error404",
    component: Error404
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound
  },
  {
    path: "/authentication/error/error500",
    name: "Error Error500",
    component: Error500
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active"
});

// console.log({router})
// console.log({routes})

export default router;
