<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Semua Aso Cabang</h5>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <div class="row">
                <div class="col-lg-4" style="margin: 10px;">
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      v-model="searchQuery"
                      placeholder="Search ASO Cabang..."
                    />
                  </div>
                  
                  <a
                    href="#"
                    class="mb-0 btn bg-gradient-success btn-sm"
                    style="margin-right: 10px;"
                    @click="performSearch"
                    >+&nbsp; search</a
                  >
                </div>
              </div>

              <table id="aso-branch-list" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Nama Aso Cabang</th>
                    <th>Type</th>
                    <th>Nama PIC</th>
                    <th>Nomor Hp</th>
                    <th>Aso Central</th>
                    <th>Jumlah Agent</th>
                    <th>Lokasi</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                </tbody>
                <tfoot>
                </tfoot>
              </table>

              <!-- DataTable's built-in pagination -->
              <nav aria-label="Page navigation">
                <ul class="pagination justify-content-end">
                  <li class="page-item" :class="{ disabled: page === 1 }">
                    <a class="page-link" href="javascript:void(0);" @click="loadPage(page - 1)">
                      Prev
                    </a>
                  </li>
                  <li class="page-item" v-for="pageNumber in totalPages" :key="pageNumber" :class="{ active: pageNumber === page }">
                    <a class="page-link" href="javascript:void(0);" @click="loadPage(pageNumber)">
                      {{ pageNumber }}
                    </a>
                  </li>
                  <li class="page-item" :class="{ disabled: page === totalPages }">
                    <a class="page-link" href="javascript:void(0);" @click="loadPage(page + 1)">
                      Next
                    </a>
                  </li>
                </ul>
              </nav>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- button edit modal -->
    <div
      id="view-aso"
      class="modal fade"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-lg-10">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="ModalLabel" class="modal-title">
              ASO Central Jakarta
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div>
              <h6>ASO Central Jakarta</h6>
              <p class="text-sm mb-0">
                Nama PIC
                <b>Herman</b> Nomor HP
                <b>1234567890</b>
              </p>
              <p class="text-sm">
                NIK/NPK :
                <b>243598234</b>
              </p>

              <p class="text-sm">
                Jumlah Anggota :
                <b>32</b>
              </p>
            </div>
            <p>Jalan Alamat nya/Lokasi.</p>

            <div>
              <p class="text-sm mb-0">
                Status
                <span class="badge badge-success badge-sm">Aktif</span>
              </p>
              <p class="text-sm">
                NIK/NPK :
                <b>243598234</b>
              </p>
            </div>

          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn bg-gradient-secondary btn-sm"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End button edit modal -->
  </div>

  <div class="loading-overlay" v-if="isLoading">
    <!-- You can use a loading spinner or any other loading content here -->
    <div class="spinner"></div>
  </div>
</template>

<style scoped>
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .spinner {
    /* Style your loading spinner here */
    border: 5px solid rgba(0, 0, 0, 0.3);
    border-top: 5px solid #3498db; /* Blue color for the spinner */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
</style>

<script>
import { DataTable } from "simple-datatables";
import Swal from 'sweetalert2';
import setTooltip from "@/assets/js/tooltip.js";
import axios from 'axios';

var labels = {
  placeholder: "Cari ASO Cabang...",
  perPage: "Menampilkan {select} ASO Cabang per halaman",
  noRows: "Tidak ada ASO Cabang yang ditampilkan",
  info: "Menampilkan {start} ke {end} dari {rows} ASO Cabang (Halaman {page} dari {pages} halaman)"
}

export default {
  name: "SuperadminAsoCabang",
  data () {
    return {
      asoBranches: [],
      isLoading: false,
      page: 1, // Current page
      totalPages: 1, // Total number of pages
      datatable: null,
      searchQuery: '',
    }
  },
  beforeMount() {
    if (localStorage.getItem("SideMenuType") !== "SuperAdmin") {
        this.$router.go(-1);
    }
  },
  mounted() {
    if (document.getElementById("aso-branch-list")) {
      this.datatable = new DataTable("#aso-branch-list", {
        processing: this.isLoading,
        searchable: false,
        fixedHeight: false,
        perPage: 16,
        columns: this.setupColumns(),
        labels: labels,
        paging: false,
        filters: {
          // Add the search filter
          search: this.searchQuery,
        },
      });
      this.datatable.body.addEventListener("click", this.handleAction);
      this.toggleLoading()

      setTimeout(() => {
        this.loadASOBranches()
      }, 1000)
    }
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.datatable.destroy()
    this.datatable = null
  },
  methods: {
    async getAsoData(data) {
      try {
        const token = window.localStorage.getItem("TOKEN");
        const response = await axios.get(
          "https://api.darlink.id/v1/aso-central/" + data,
          {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token,
            },
          }
        );
        console.log('get aso central = ', response.data.content.name);
        return response.data.content.name;
      } catch (err) {
        console.log(err.response);
        return null; // Return null or handle error as needed
      }
    },
    setupColumns() {
      var columns = [
        {
          select: 0,
          render: function (data) {
            return `<div class="d-flex">
                <h6 class="my-auto ms-3">${data}</h6>
              </div>
            `
          }
        },
        {
          select: [1,2,3,4,5],
          render: function (data) {
            return `<span class="text-sm">${data}</span>
            `
          }
        },
        {
          select: 4,
          render: function (data) {
            return `<span class="text-sm">${data}</span>
            `
          }
        },
        {
          select: 5,
          render: function (data) {
            return `<span class="text-sm">${data}</span>
            `
          }
        },
        {
          select: 7,
          render: function (data) {
            let status = (data === 'true') ? "AKTIF" :  "TIDAK AKTIF"
            let color = (data === 'true') ? "badge-success" :  "badge-danger"
            return `
            <span class="badge ${color} badge-sm">
              ${status}
            </span>
            `
          },
        },
        {
          select: 8,
          render: function (data) {
            let datas = JSON.parse(data)
            return `
              <!-- button edit modal -->
                <a
                  class="text-sm"
                  href="javascript: void(0);"
                >
                  <span
                    class="mx-3"
                  >
                    <i
                    class="fas fa-eye text-secondary data-bs-toggle="tooltip" data-bs-placement="top" title="Detail"
                      data-action="edit"
                      data-id="${datas.id}"
                    ></i>
                  </span>
                </a>

                <a
                  class="text-sm"
                  href="javascript: void(0);"
                >
                  <span
                    class="mx-3"
                  >
                  <i
                    class="fas fa-check text-success data-bs-toggle="tooltip" data-bs-placement="top" title="Detail"
                      data-action="approve"
                      data-id="${datas.id}"
                      data-name="${datas.displayname}"
                    ></i>
                </a>
                <a
                  href="javascript:;"
                  class="mx-3"
                >
                  <i 
                    data-id=${datas.id}
                    data-name="${datas.displayname}"
                    data-action="deactive" 
                    class="fas fa-times text-secondary text-warning"></i>
                </a>
                <a
                    class="text-sm"
                    href="javascript:void(0);"

                  >
                    <i
                      class="fas fa-trash text-danger"
                      data-action="delete"
                      data-id="${datas.id}"
                      data-name="${datas.displayname}"
                    ></i>
                  </a>
            `
          }
        },
      ]

      return columns;
    },
    handleAction(e) {
      if (e.target.getAttribute("data-action") === "approve") {
        let id = e.target.getAttribute("data-id");
        let name = e.target.getAttribute("data-name")
        Swal.fire({
          title: 'Konfirmasi',
          text: `Anda yakin ingin konfirmasi aso cabang "${name}"?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Batal',
        }).then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;

            var token = window.localStorage.getItem("TOKEN");
            axios
            .post("https://api.darlink.id/v1/aso-branch/"+id+"/activate", 
            null,
            {
              withCredentials: true,
              headers: {
                Accept: "application/json",
                Authorization: token
              }
            })
            .then((response) => {
              this.isLoading = false;
              // if response code not 200
              if (response.data.code != 200) {
                // show error message
                // sweetalert error
                this.$swal({
                  title: "Gagal",
                  text: response.data.message,
                  icon: "error",
                  button: "OK",
                })
                return
              }
              this.$swal({
                title: "Berhasil",
                text: "Berhasil Aktivasi ASO Branch",
                icon: "success",
                confirmButtonText: "OK",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload()
                }
              });
              this.$router.push({ name: "superadmin.aso-branch" })
              // reload page after 1000ms
              setTimeout(() => {
                window.location.reload()
              }, 4000)
            })
            .catch((error) => {
                this.isLoading = false;
              // if error
              // show error message
              this.$store.dispatch("show-error", error)
            })
          }
        })
      }
      if (e.target.getAttribute("data-action") === "deactive") {
        let id = e.target.getAttribute("data-id");
        let name = e.target.getAttribute("data-name")
        Swal.fire({
          title: 'Konfirmasi',
          text: `Anda yakin ingin nonaktifkan aso cabang "${name}"?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Batal',
        }).then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;

            var token = window.localStorage.getItem("TOKEN");
            axios
            .post("https://api.darlink.id/v1/aso-branch/"+id+"/deactivate", 
            null,
            {
              withCredentials: true,
              headers: {
                Accept: "application/json",
                Authorization: token
              }
            })
            .then((response) => {
              this.isLoading = false;
              // if response code not 200
              if (response.data.code != 200) {
                // show error message
                // sweetalert error
                this.$swal({
                  title: "Gagal",
                  text: response.data.message,
                  icon: "error",
                  button: "OK",
                })
                return
              }
              this.$swal({
                title: "Berhasil",
                text: "Berhasil Non aktifkan ASO Branch",
                icon: "success",
                confirmButtonText: "OK",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload()
                }
              });
              this.$router.push({ name: "superadmin.aso-branch" })
              // reload page after 1000ms
              setTimeout(() => {
                window.location.reload()
              }, 4000)
            })
            .catch((error) => {
              this.isLoading = false;
              // if error
              // show error message
              this.$store.dispatch("show-error", error)
            })
          }
        })
      }
      if (e.target.getAttribute("data-action") === "edit") {
        let id = parseInt(e.target.getAttribute("data-id"), 10)
        this.edit(id)
      }
      if (e.target.getAttribute("data-action") === "delete") {
        let id = parseInt(e.target.getAttribute("data-id"), 10)
        let name = e.target.getAttribute("data-name")

        this.deleteAso(id, name)
      }
    },
    edit(id) {
      this.$router.push({
        name: "superadmin.aso-branch.detail",
        params: {
          orderList: id
        }
      })
    },
    deleteAso(id, name) {
      this.$swal({
        title: `Anda yakin akan hapus "${name}" ?`,
        showCancelButton: true,
        confirmButtonText: "Hapus",
        cancelButtonText: "Batal",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-danger",
          cancelButton: "btn bg-gradient-success",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true;
          //  this.removeASOCentral(id)
          var token = window.localStorage.getItem("TOKEN")

          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `https://api.darlink.id/v1/aso-branch/${id}/remove`,
            headers: { 
              'Accept': 'application/json', 
              'Authorization': token
            }
          };

          axios.request(config)
          .then((response) => {
            this.isLoading = false;
            console.log(response)

            this.$swal({
              title: "Berhasil",
              text: "ASO Cabang berhasil dihapus",
              icon: "success",
              confirmButtonText: "Tutup",
              confirmButtonColor: "#3085d6",
            }).then((result) => {
              if (result.isConfirmed) {
                // this.$router.push({ name: "superadmin.aso-central" })
                window.location.reload()
              }
            });
            
            setTimeout(() => {
              window.location.reload()
            }, 2000)
          })
          .catch((err) => {
            this.isLoading = false;
            if (err.response && err.response.data && err.response.data.validation) {
                const validationErrors = err.response.data.validation;
                let errorMessage = "Validation Error:\n";
                validationErrors.forEach((error) => {
                  errorMessage += `${error.mesg}\n`;
                });
                this.$swal({
                  title: "Validation Error",
                  text: errorMessage,
                  icon: "error",
                  confirmButtonText: "OK",
                  confirmButtonColor: "#d33",
                });
              } else {
                this.$swal({
                  title: "Error",
                  text: err.response.data.message,
                  icon: "error",
                  confirmButtonText: "OK",
                  confirmButtonColor: "#d33",
                });
              }
          });
        }
      });
    },
    toggleLoading() {
      if (!this.datatable) {
        return
      }
      if (!this.isLoading) {
        this.isLoading = true
        this.datatable.setMessage("Memuat...")
        return
      }
      this.isLoading = false
    },
    performSearch() {
      if (this.datatable) {
        this.destroyDataTable(); // Destroy the current DataTable instance
      }
      
      if (document.getElementById("aso-branch-list")) {
        this.datatable = new DataTable("#aso-branch-list", {
          processing: this.isLoading,
          searchable: false,
          fixedHeight: false,
          perPage: 16,
          columns: this.setupColumns(),
          labels: labels,
          paging: false,
          filters: {
            // Add the search filter
            search: this.searchQuery,
          },
        });

        this.page = 1;

        this.datatable.body.addEventListener("click", this.handleAction)
        this.toggleLoading()
        this.loadASOBranches()
      }
    },
    loadASOBranches() {
      var token = window.localStorage.getItem("TOKEN")
      axios
        .get("https://api.darlink.id/v1/aso-branch", {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          },
          params: {
            page: this.page, // Include the page parameter
            search: this.searchQuery, // Add the search query parameter
          },
        })
        .then((response) => {
          if (!this.datatable) {
            return
          }
          this.toggleLoading()
          if (response.data.code != 200) {
            return
          }

          if (response.data.tag === "user.aso-branch.empty") {
            this.totalPages = 1;
            this.datatable.setMessage("Tidak ada data yang ditampilkan");
            return;
          }

          this.asoBranches = response.data.content
          this.totalPages = response.data.pagination.total_pages; // Update total pages
          
          this.updateDataTable();
          // this.datatable.insert({
          //   data: this.asoBranches.map((asoBranch) => {
          //     return [
          //       asoBranch.displayname,
          //       asoBranch.pic_name,
          //       asoBranch.phone,
          //       asoBranch.aso_central_name,
          //      `${asoBranch.total_agent}`,
          //       asoBranch.location,
          //       (asoBranch.is_active) ? 'true' : 'false',
          //       `${asoBranch.id}`
          //     ]
          //   })
          // })
        })
        .catch((err) => {
          this.toggleLoading()
          console.log(err);
        });
    },
    destroyDataTable() {
      if (this.datatable) {
        this.datatable.destroy();
        this.datatable = null;
      }
    },
    // Function to update the DataTable
    async updateDataTable() {
      if (!this.datatable || !this.asoBranches.length) {
        return;
      }
      
      this.datatable.body.innerHTML = '';

      const tableData = this.asoBranches.map(async (asoBranch) => {
        const asoData = await this.getAsoData(asoBranch.aso_central_id); // Fetch ASO Central name

        return [
          asoBranch.displayname,
          asoBranch.type,
          asoBranch.pic_name,
          asoBranch.phone,
          asoData, // Insert fetched ASO Central name
          `${asoBranch.total_agent}`,
          asoBranch.location,
          (asoBranch.is_active) ? 'true' : 'false',
          JSON.stringify(asoBranch), // Ensure asoBranch is stringified correctly
        ];
      });

      Promise.all(tableData)
        .then((formattedData) => {
          this.datatable.insert({ data: formattedData });
        })
        .catch((error) => {
          console.error('Error formatting data:', error);
        });
    },

    // Function to load data for a specific page
    loadPage(page) {
      if (page < 1 || page > this.totalPages) {
        return;
      }

      this.page = page;
      
      if (this.datatable) {
        this.destroyDataTable(); // Destroy the current DataTable instance
      }
      
      if (document.getElementById("aso-branch-list")) {
        this.datatable = new DataTable("#aso-branch-list", {
          processing: this.isLoading,
          searchable: false,
          fixedHeight: false,
          perPage: 16,
          columns: this.setupColumns(),
          labels: labels,
          paging: false,
          filters: {
            // Add the search filter
            search: this.searchQuery,
          },
        });
        this.datatable.body.addEventListener("click", this.handleAction)
        this.toggleLoading()
        this.loadASOBranches()
      }
    },
    showSwal(type) {
      console.log("asdasdf asdfasdf asdfasdf");
      if (type === "basic") {
        this.$swal({
          title: "Any fool can use a computer",
          type: type,
        });
      } else if (type === "success-message") {
        this.$swal({
          icon: "success",
          title: "Good Job!",
          text: "You clicked the button!",
          type: type,
        });
      } else if (type === "custom-html") {
        this.$swal({
          icon: "info",
          title: "<strong>HTML <u>example</u></strong>",
          html:
            "You can use <b>bold text</b>, " +
            '<a href="//sweetalert2.github.io">links</a> ' +
            "and other HTML tags",
          type: type,
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!',
          confirmButtonAriaLabel: "Thumbs up, great!",
          cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
          cancelButtonAriaLabel: "Thumbs down",
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        });
      } else if (type === "input-field") {
        this.$swal({
          title: "Submit your Github username",
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Look up",
          showLoaderOnConfirm: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
          preConfirm: (login) => {
            return fetch(`//api.github.com/users/${login}`)
              .then((response) => {
                if (!response.ok) {
                  throw new Error(response.statusText);
                }
                return response.json();
              })
              .catch((error) => {
                this.$swal.showValidationMessage(`Request failed: ${error}`);
              });
          },
          allowOutsideClick: () => !this.$swal.isLoading(),
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal({
              title: `${result.value.login}'s avatar`,
              imageUrl: result.value.avatar_url,
            });
          }
        });
      } else if (type === "title-and-text") {
        this.$swal({
          title: "Sweet!",
          text: "Modal with a custom image.",
          imageUrl: "https://unsplash.it/400/200",
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
        });
      } else if (type === "auto-close") {
        let timerInterval;
        this.$swal({
          title: "Auto close alert!",
          html: "I will close in <b></b> milliseconds.",
          timer: 2000,
          timerProgressBar: true,
          didOpen: () => {
            this.$swal.showLoading();
            const b = this.$swal.getHtmlContainer().querySelector("b");
            timerInterval = setInterval(() => {
              b.textContent = this.$swal.getTimerLeft();
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        });
      } else if (type === "warning-message-and-confirmation") {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            });
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === this.$swal.DismissReason.cancel
          ) {
            this.$swal({
              title: "Cancelled!",
              text: "Your imaginary file is safe :)",
              icon: "error",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            });
          }
        });
      } else if (type === "warning-message-and-cancel") {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancel",
          confirmButtonText: "Yes, delete it!",
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            });
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === this.$swal.DismissReason.cancel
          ) {
            this.$swal.dismiss;
          }
        });
      } else if (type === "rtl-language") {
        this.$swal({
          title: "هل تريد الاستمرار؟",
          icon: "question",
          iconHtml: "؟",
          confirmButtonText: "نعم",
          cancelButtonText: "لا",
          showCancelButton: true,
          showCloseButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        });
      }
    },
  },
};
</script>
