<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="mx-auto col-lg-12 col-12">
        <div class="mt-4 card card-body">
          <h6 class="mb-0">Edit Fincoy PIC</h6>
          <hr class="my-3 horizontal dark" />

          <div class="mt-4 row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                  <label for="projectName" class="form-label">Nama Fincoy HO </label>
                  <select v-model="fincoy_ho" class="form-control">
                    <template v-for="dataHo in listHo" :key="dataHo.id">
                      <option :value="dataHo.id">{{dataHo.name}}</option>
                    </template>
                  </select>
              </div>
              <div class="form-group">
                <label>Status Fincoy PIC</label>
                <div class="form-check form-switch ms-1">
                  <input
                    id="flexSwitchCheckDefault"
                    v-model="isActive"
                    class="form-check-input"
                    type="checkbox"
                    data-type="warning"
                    data-content="Once a project is made private, you cannot revert it to a public project."
                    data-title="Warning"
                    data-icon="ni ni-bell-55"
                    disabled
                  />
                  <label
                    class="form-check-label"
                    for="flexSwitchCheckDefault"
                  >
                    <span
                      class="badge badge-md"
                      :class="{
                        'badge-danger': !isActive,
                        'badge-success': isActive,
                      }"
                    >
                      {{ (isActive) ? 'AKTIF' : 'NON-AKTIF' }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label for="projectName" class="form-label">Username</label>
              <input id="projectName" v-model="username" type="text" class="form-control" />
            </div>
            <div class="col-6">
              <label for="projectName" class="form-label">Password</label>
              <input id="projectName" v-model="password" type="password" class="form-control" />
            </div>
            <div class="col-6">
              <label for="projectName" class="form-label">Nama PIC</label>
              <input id="projectName" v-model="pic_name" type="text" class="form-control" />
            </div>
            <div class="col-6">
              <label for="projectName" class="form-label">NIK/NIP</label>
              <input id="projectName" v-model="nik" type="text" class="form-control" maxlength="16"/>
            </div>
            <div class="col-6">
              <label class="form-label">Tanggal Lahir PIC</label>
              <flat-pickr
                v-model="endDate"
                class="form-control datetimepicker"
                placeholder="Please select end date"
                :config="config"
              ></flat-pickr>
            </div>
            <div class="col-6">
              <label for="projectName" class="form-label">Email PIC</label>
              <input id="projectName" v-model="email" type="text" class="form-control" />
            </div>
            <div class="col-6">
              <label for="projectName" class="form-label">Email Company</label>
              <input id="projectName" v-model="email_company" type="text" class="form-control" />
            </div>
            <div class="col-6">
              <label for="projectName" class="form-label">Lokasi Company</label>
              <input id="projectName" v-model="location" type="text" class="form-control" />
            </div>
            <div class="col-6">
              <label for="projectName" class="form-label">Jabatan PIC</label>
              <input id="projectName" v-model="job" type="text" class="form-control" />
            </div>
          </div>
          <label class="mt-4">Alamat Kantor</label>
          <div id="editor">
            <QuillEditor
              v-model:content="address"
              content-type="text"
              toolbar="minimal"
              theme="snow"
            />
          </div>
          <label class="mt-4 form-label">Upload Company ID CARD (.jpg, .png)</label>
          <div v-if="company_scan"><a :href="company_scan" target="_blank"><u>Existing Image</u></a></div>
          <form
            id="uploadId"
            class="form-control dropzone"
          >
            <div class="fallback">
              <input name="file" type="file" @change="handleFileChange" multiple />
            </div>
          </form>
          <div class="mt-4 d-flex justify-content-end">
            <!-- <router-link to="{ name: 'aso.fincoy-pic' }"> -->
              <button type="button" name="button" class="m-0 btn btn-light" @click="$router.push({ name: 'aso.fincoy-pic' })">
                Batal
              </button>
            <!-- </router-link> -->
            <button
              type="button"
              name="button"
              class="m-0 btn bg-gradient-success ms-2"
              @click="submit"
              :disabled="buttonDisabled"
            >
              <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span v-else>Ubah Fincoy PIC</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import Dropzone from "dropzone";
import Choices from "choices.js";
import { QuillEditor } from '@vueup/vue-quill';
import axios from 'axios';

export default {
  name: "FincoyHoNew",
  components: {
    flatPickr,
    QuillEditor
  },
  data() {
    return {
      date: "",
      endDate: "",
      config: {
        allowInput: true,
      },
      listHo: [],
      username_old: "",
      username: "",
      password: "",
      pic_name: "",
      phonenumber: "",
      email: "",
      email_company: "",
      nik: "",
      job: "",
      address: "",
      idcard: "",
      fincoy_ho: "",
      isActive: false,
      orderPic: "",
      location: "",
      buttonDisabled: true,
      isLoading: false,
      company_scan: "",
    };
  },
  mounted() {
    var token = window.localStorage.getItem("TOKEN");
    axios.get('https://api.darlink.id/v1/fincoy?status=active', {
      withCredentials: true,
      headers: {
        Accept: "application/json",
        Authorization: token
      }
    }).then((response) => {
      console.log(response.data.content);
      this.listHo = response.data.content;
    });
    axios.get(`https://api.darlink.id/v1/fincoy/pic/${this.$route.params.id}`, {
      withCredentials: true,
      headers: {
        Accept: "application/json",
        Authorization: token
      }
    }).then((response) => {
      if (response.data.code != 200) {
        return
      }
      if (response.data.content) {
        this.username = response.data.content.username;
        this.username_old = response.data.content.username;
        this.pic_name = response.data.content.displayname;
        this.email = response.data.content.email;
        this.address = response.data.content.address;
        this.endDate = response.data.content.dob;
        this.nik = response.data.content.personal_id;
        // this.idcard = response.data.content.personal_id_scan;
        this.job = response.data.content.department;
        this.location = response.data.content.location;
        this.isActive = response.data.content.is_active;
        this.fincoy_ho = response.data.content.fincoy_ho_id;
        this.email = response.data.content.email;
        this.email_company = response.data.content.company_email;
        this.company_scan = response.data.content.company_id_scan;
      }
      // this.displayName = response.data.content.displayName;
      // alert(this.displayName);
    }).catch((error) => {
      this.$router.go(-1);
      console.log(error);
    });
    this.countPic();
    // if (document.getElementById("editor")) {
    //   new Quill("#editor", {
    //     theme: "snow", // Specify theme in configuration
    //   });
    // }    

    if (document.getElementById("choices-category")) {
      var element = document.getElementById("choices-category");
      new Choices(element, {
        searchEnabled: false,
        allowHTML: true,
        itemSelectText: '',
      });
    }
    
    if (document.getElementById("choices-aso")) {
      var element1 = document.getElementById("choices-aso");
      new Choices(element1, {
        searchEnabled: false,
        allowHTML: true,
        itemSelectText: '',
        position: 'auto',
      });
    }

    this.initializeDropzone();
    // Dropzone.autoDiscover = false;
    // var drop = document.getElementById("dropzone");
    // new Dropzone(drop, {
    //   url: "/file/post",
    //   addRemoveLinks: true,
    // });
  },
  methods: {
    countPic() {
      var token = window.localStorage.getItem("TOKEN")
      axios
        .get("https://api.darlink.id/v1/fincoy/pic", {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          }
        })
        .then((response) => {
          this.orderPic = response.data.content;
          this.buttonDisabled = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    randomString(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      const charactersLength = characters.length;

      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return result;
    },
    generateCodefincoy() {
      this.username = this.randomString(3);
    },
    initializeDropzone() {
      Dropzone.autoDiscover = false;
      const drop = document.getElementById("uploadId");
      const dropzone = new Dropzone(drop, {
        url: "https://api.darlink.id/v1/assets/upload",
        addRemoveLinks: true,
        acceptedFiles: 'image/*',
        sending: (file, xhr, formData) => {
          formData.append("context", "COMPANY_ID_CARD"); // Include the context parameter in the request
        },
        success: (file, response) => {
          console.log("Upload success:", response); // Handle the successful response
          // this.MOUDocs = response.content.url;
          this.idcard = response.content.name;
        },
      });
      // Add any additional configuration or event listeners as needed

      // Move the handleFileChange logic here or modify it accordingly
      dropzone.on("addedfile", (file) => {
        // this.MOUDocs = file;
        console.log(file);
      });
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      const dropzone = Dropzone.forElement("#uploadId");
      dropzone.removeAllFiles();
      dropzone.addFile(file);
    },
    validateEmail(email) {
      // Regular expression for validating an Email
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    validateEmail2(email_company) {
      // Regular expression for validating a Phone number
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email_company);
    },
    validateNik(nik) {
      // Regular expression for validating a Phone number
      const phoneRegex = /^\d{16}$/;
      return phoneRegex.test(nik);
    },
    submit() {
      this.isLoading= true;
      // this.generateCodefincoy();
      var token = window.localStorage.getItem("TOKEN")

      //Get File name
      // const fileName = this.MOUDocs ? this.MOUDocs.name : '';

      // Add email and phone format validation
      if (!this.validateEmail(this.email)) {
        this.isLoading= false;
        this.$swal({
          title: "Invalid Email",
          text: "Please enter a valid email address.",
          icon: "warning",
          confirmButtonText: "OK",
          confirmButtonColor: "#3085d6",
        });
        return;
      }

      if (!this.validateEmail2(this.email_company)) {
        this.isLoading= false;
        this.$swal({
          title: "Invalid Email",
          text: "Please enter a valid email company address.",
          icon: "warning",
          confirmButtonText: "OK",
          confirmButtonColor: "#3085d6",
        });
        return;
      }

      if (!this.validateNik(this.nik)) {
        this.isLoading= false;
        this.$swal({
          title: "Invalid NIK",
          text: "Please enter a valid 16-digit number.",
          icon: "warning",
          confirmButtonText: "OK",
          confirmButtonColor: "#3085d6",
        });
        return;
      }

      const picData = new FormData();

      // picData.append("displayname", this.fincoy_ho);
      picData.append("displayname", this.pic_name);
      // picData.append("username", `${this.username}-${this.orderPic.length + 1}`);

      if(this.username !== this.username_old)
      {
        picData.append("username", this.username);
      }
      
      if(this.password !== "")
      {
        picData.append("password", this.password);
      }
      
      picData.append("email", this.email);
      picData.append("address", this.address);
      picData.append("personal_id", this.nik);
      // picData.append("personal_id_scan", this.idcard);
      picData.append("company_id_scan", this.idcard);
      picData.append("location", this.location);
      picData.append("address", this.address);
      picData.append("department", this.job);
      picData.append("dob", this.endDate);
      picData.append("company_email", this.email_company);
      
      picData.append("status", (this.isActive) ? 'active' : 'inactive');

      axios
        .post(`https://api.darlink.id/v1/fincoy/${this.fincoy_ho}/pic/${this.$route.params.id}`, picData,
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          }
        })
        .then((response) => {
          this.isLoading= false;
          // SWEETALLERT
          this.$swal({
            title: "Berhasil",
            text: "Fincoy PIC berhasil diubah",
            icon: "success",
            confirmButtonText: "Tutup",
            confirmButtonColor: "#3085d6",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push({ name: "aso.fincoy-pic" })
            }
          });
          console.log("sini nih = ", response)
          this.$router.push({ name: "aso.fincoy-pic" })
        })
        .catch((err) => {
          this.isLoading= false;
          if (err.response && err.response.data && err.response.data.validation) {
            const validationErrors = err.response.data.validation;
            let errorMessage = "Validation Error:\n";
            validationErrors.forEach((error) => {
              errorMessage += `${error.mesg}\n`;
            });
            this.$swal({
              title: "Validation Error",
              text: errorMessage,
              icon: "error",
              confirmButtonText: "OK",
              confirmButtonColor: "#d33",
            });
          } else {
            this.$swal({
              title: "Error",
              text: err.response.data.message,
              icon: "error",
              confirmButtonText: "OK",
              confirmButtonColor: "#d33",
            });
          }
        });
    }
  }
};
</script>
