<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-md-2">
        <label for="projectName" class="form-label" style="color:white;">Dari Tgl</label>
        <argon-input type="date" placeholder="Date" v-model="filter.startDate" />
      </div>
      <div class="col-md-2">
        <label for="projectName" class="form-label" style="color:white;">Sampai Tgl</label>
        <argon-input type="date" placeholder="Date" v-model="filter.endDate" />
      </div>
      <div class="col-md-4">
        <label for="projectName" class="form-label" style="color:white;">Action</label>
        <div class="my-auto mt-4 ms-auto mt-lg-0">
          <div class="my-auto ms-auto">
            <a
              href="#"
              class="mb-0 btn bg-gradient-success btn-sm"
              @click="handleFilter"
            >+&nbsp; Filter</a>
            <button
              type="button"
              class="mx-1 mb-0 btn btn-outline-success btn-sm"
              @click="handleReset"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3 col-md-6 col-12 mb-3">
        <default-counter-card
          id="status3"
          :count="totalOrder"
          color="primary"
          title="Total Order"
        />
      </div>
      <div class="col-lg-3 col-md-6 col-12 mb-3">
        <default-counter-card
          id="status4"
          :count="orderApproved"
          title="Order Approved"
        />
      </div>
      <div class="col-lg-3 col-md-6 col-12 mb-3">
        <default-counter-card
          id="status1"
          color="danger"
          :count="orderRejected"
          title="Order Reject / Order Cancel"
        />
      </div>
      <div class="col-lg-3 col-md-6 col-12 mb-3">
        <default-counter-card
          id="status2"
          :count="orderPending"
          color="info"
          title="Order Pending"
        />
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <mini-statistics-card
          title="Agent Baru"
          :value="newAgent ? newAgent : `0`"
          :icon="{
            component: 'ni ni-circle-08',
            background: 'bg-gradient-primary',
            shape: 'rounded-circle'
          }"
        />
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <mini-statistics-card
          title="ASO Cabang Baru"
          :value="newASOBranches ? newASOBranches : `0`"
          :icon="{
            component: 'ni ni-circle-08',
            background: 'bg-gradient-faded-dark',
            shape: 'rounded-circle'
          }"
        />
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <mini-statistics-card
          title="Fincoy PIC Baru"
          :value="newFincoyPIC ? newFincoyPIC : `0`"
          :icon="{
            component: 'ni ni-circle-08',
            background: 'bg-gradient-warning',
            shape: 'rounded-circle'
          }"
        />
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <mini-statistics-card
          title="Total ASO Central"
          :value="totalASOCentral ? totalASOCentral : `0`"
          :icon="{
            component: 'ni ni-circle-08',
            background: 'bg-gradient-success',
            shape: 'rounded-circle'
          }"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <bar-chart-horizontal
          title="Order ASO Central"
          :chart="topASOChart"
        />
      </div>
      <div class="col-lg-6 col-sm-6">
        <channels-chart-card 
          title="Fincoy Take Order"
          :chart="topSixFincoyHOChart"
        />
      </div>

    </div>
  </div>
</template>

<script>
import MiniStatisticsCard from "../../darlink/Cards/MiniStatisticsCard.vue";
import DefaultCounterCard from "@/darlink/Cards/DefaultCounterCard.vue";
import BarChartHorizontal from "../superadmin/components/BarChartHorizontal.vue";
import ChannelsChartCard from "../superadmin/components/ChannelsChartCard.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import axios from "axios";
// import axios from "./../../libs/axios.js";

export default {
  name: "Dashboard",
  components: {
    MiniStatisticsCard,
    DefaultCounterCard,
    BarChartHorizontal,
    // MixedChart,
    ChannelsChartCard,
    ArgonInput,
  },
  data() {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0]; // Get the date in YYYY-MM-DD format

    return {
      totalOrder: 0,
      orderApproved: 0,
      orderRejected: 0,
      orderPending: 0,
      newAgent: 0,
      newASOBranches: 0,
      totalASOCentral: 0,
      newFincoyPIC: 0,
      topFincoyHO: [],
      topASOCentral: [],
      filter: {
        startDate: "",
        endDate: "",
      },
      currentDate: formattedDate,
    };
  },
  computed: {
    topSixFincoyHOChart () {
      return {
        labels: this.topFincoyHOLabel,
        datasets: [{
          label: 'Order Approved',
          data: this.topFincoyHOOrders,
        }],
      }
    },
    topFincoyHOLabel() {
      if (this.topFincoyHO.length < 1) {
        return []
      }
      return this.topFincoyHO.map((fincoyHO) => {
        return fincoyHO.name
      })
    },
    topFincoyHOOrders() {
      if (this.topFincoyHO.length < 1) {
        return []
      }
      return this.topFincoyHO.map((fincoyHO) => {
        return fincoyHO.approved_order
      })
    },
    topASOCentralLabel() {
      if (this.topASOCentral.length < 1) {
        return []
      }
      return this.topASOCentral.map((asoCentral) => {
        return asoCentral.name
      })
    },
    topASOCentralOrders() {
      if (this.topASOCentral.length < 1) {
        return []
      }
      return this.topASOCentral.map((asoCentral) => {
        return asoCentral.approved_order
      })
    },
    topASOChart () {
      return {
        labels: this.topASOCentralLabel,
        datasets: {
          label: 'Order Approved',
          data: this.topASOCentralOrders,
        },
      }
    },
  },
  beforeMount() {
    if (localStorage.getItem("SideMenuType") !== "SuperAdmin") {
      this.$router.go(-1);
    }
  },
  mounted() {
    this.filter.startDate = this.filter.startDate || this.currentDate;
    this.filter.endDate = this.filter.endDate || this.currentDate;
    setTimeout(() => {
      this.loadMetric(this.filter);
    }, 2000);
    // if (document.getElementById("choices-category")) {
    //   var element = document.getElementById("choices-category");
    //   new Choices(element, {
    //     searchEnabled: false,
    //     allowHTML: true,
    //   });
    // }
    // setTooltip(this.$store.state.bootstrap);
  },
  methods: {
    handleFilter() {
      this.filter.startDate = this.filter.startDate || this.currentDate;
      this.filter.endDate = this.filter.endDate || this.currentDate;

      this.loadMetric(this.filter)
    },
    handleReset() {
      this.filter.startDate = this.currentDate;
      this.filter.endDate = this.currentDate;

      this.loadMetric(this.filter)
    },
    loadMetric(params_filter = {startDate: "", endDate: ""}) {
      var token = window.localStorage.getItem("TOKEN")
      axios
        // .get("https://api.darlink.id/v1/admin/metric", {
        .get(`https://api.darlink.id/v1/admin/metric?start=${params_filter.startDate}&end=${params_filter.endDate}`, {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          }
        })
        .then((response) => {
          if (response.data.code != 200) {
            return
          }

          this.totalOrder = response.data.content.total_order
          this.orderApproved = response.data.content.approved_order
          this.orderRejected = response.data.content.rejected_order
          this.orderPending = response.data.content.pending_order
          this.newAgent = response.data.content.new_agent
          this.newASOBranches = response.data.content.new_aso_branch
          this.newFincoyPIC = response.data.content.new_fincoy_pic
          this.totalASOCentral = response.data.content.new_aso_central

          this.topFincoyHO = response.data.content.top_fincoy_ho
          this.topASOCentral = response.data.content.top_aso_branches
        })
        .catch((err) => {
          console.log(err.toJSON());
        });
    }
  }
};
</script>
