<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="mx-auto col-lg-12 col-12">
          <div class="mt-4 card card-body">
            <h6 class="mb-0">Update Price List - Fincoy 1 Jakarta</h6>
            <hr class="my-3 horizontal dark" />
            
            <label class="mt-2 form-label">Upload Berkas MOU (.pdf) <span style="color: red;">*max 2MB</span></label>
            <div v-if="exist_file"><a :href="exist_file" target="_blank"><u>Exist Price List</u></a></div>
            <form
              id="uploadMOUInput"
              class="form-control dropzone"
            >
              <div class="fallback">
                <input name="file" type="file" @change="handleFileChange" multiple />
              </div>
            </form>
            <div class="mt-2 d-flex justify-content-end">
              <router-link to="./aso-central">
                <button type="button" name="button" class="m-0 btn btn-light">
                  Batal
                </button>
              </router-link>
              <button
                type="button"
                name="button"
                class="m-0 btn bg-gradient-success ms-2"
                @click="submit"
              >

                <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span v-else>Update Price List</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  //import flatPickr from "vue-flatpickr-component";
  import Dropzone from "dropzone";
  import axios from 'axios';
  
  export default {
    name: "FincoyPricelist",
    components: {
       //flatPickr,
    },
    data() {
      return {
        date: "",
        endDate: "",
        MOUDocs: "",
        config: {
          allowInput: true,
        },
        exist_file: "",
        isLoading: false,
      };
    },
    beforeMount() {
        if (localStorage.getItem("SideMenuType") !== "Fincoy") {
            this.$router.go(-1);
        }
    },
    mounted() {
      this.initializeDropzone();
      this.loadData();
    },
    methods: {
      initializeDropzone() {
        Dropzone.autoDiscover = false;
        const drop = document.getElementById("uploadMOUInput");
        const dropzone = new Dropzone(drop, {
          url: "https://api.darlink.id/v1/assets/upload",
          addRemoveLinks: true,
          acceptedFiles: '.pdf',
          sending: (file, xhr, formData) => {
            formData.append("context", "PRICELIST"); // Include the context parameter in the request
          },
          success: (file, response) => {
            console.log("Upload success:", response); // Handle the successful response
            // this.MOUDocs = response.content.url;
            this.MOUDocs = response.content.name;
          },
        });
        // Add any additional configuration or event listeners as needed

        // Move the handleFileChange logic here or modify it accordingly
        dropzone.on("addedfile", (file) => {
          // this.MOUDocs = file;
          console.log(file);
        });
      },
      handleFileChange(event) {
        const file = event.target.files[0];
        const dropzone = Dropzone.forElement("#uploadMOUInput");
        dropzone.removeAllFiles();
        dropzone.addFile(file);
      },
      toggleLoading() {
        if (!this.datatable) {
          return
        }
        if (!this.isLoading) {
          this.isLoading = true
          this.datatable?.setMessage("Memuat...")
          return
        }
        this.isLoading = false
      },
      isError(tag) {
        let message = this.validations.find(validation => {
          if (validation.tag === tag) {
            return true
          }
          return false
        })

        if (!message) {
          return false
        }

        return true
      },
      loadData() {
        var token = window.localStorage.getItem("TOKEN")
        axios.get("https://api.darlink.id/v1/fincoy/pic/info", {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          }
        }).then((response) => {
          this.exist_file = response.data.content.pricelist;

        }).catch((error) => {
          console.log(error);
        })
      },
      submit() {
        this.isLoading = true;
        var token = window.localStorage.getItem("TOKEN")

        //Get File name
        // const fileName = this.MOUDocs ? this.MOUDocs.name : '';

        const fincoyHO = new FormData();

        fincoyHO.append("pricelist", this.MOUDocs);

        axios
          .post("https://api.darlink.id/v1/fincoy/pricelist", fincoyHO,
          {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token
            }
          })
          .then((response) => {
            this.isLoading = false;
            console.log(response)
            this.$swal({
              title: "Berhasil",
              text: "Pricelist berhasil diupdate",
              icon: "success",
              confirmButtonText: "Tutup",
              confirmButtonColor: "#3085d6",
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.push({ name: "Fincoy Price List" })
              }
            });
            this.$router.push({ name: "Fincoy Price List" })
          })
          .catch((err) => {
            this.isLoading = false;
            if (err.response && err.response.data && err.response.data.validation) {
              const validationErrors = err.response.data.validation;
              let errorMessage = "Validation Error:\n";
              validationErrors.forEach((error) => {
                errorMessage += `${error.mesg}\n`;
              });
              this.$swal({
                title: "Validation Error",
                text: errorMessage,
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "#d33",
              });
            } else {
              this.$swal({
                title: "Error",
                text: err.response.data.message,
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "#d33",
              });
            }
          });
      }
    }
  };
  </script>
  