<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="mx-auto col-lg-9 col-12">
          <div class="mt-4 card card-body">
            <h6 class="mb-0">Edit Role Staff</h6>
            <hr class="my-3 horizontal dark" />
  
            <div class="mt-4 row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>Status Role Staff</label>
                  <div class="form-check form-switch ms-1">
                    <input
                      id="flexSwitchCheckDefault"
                      v-model="isActive"
                      class="form-check-input"
                      type="checkbox"
                      data-type="warning"
                      data-content="Once a project is made private, you cannot revert it to a public project."
                      data-title="Warning"
                      data-icon="ni ni-bell-55"
                    />
                    <label
                      class="form-check-label"
                      for="flexSwitchCheckDefault"
                    >
                      <span
                        class="badge badge-md"
                        :class="{
                          'badge-danger': !isActive,
                          'badge-success': isActive,
                        }"
                      >
                        {{ isActive ? 'AKTIF' : 'NON-AKTIF' }}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
  
            <div class="row">
              <div class="col-6">
                <label for="projectName" class="form-label">Nama Role</label>
                <input id="projectName" v-model="displayname" type="text" class="form-control" />
              </div>
              <div class="col-6">
                <label class="form-label">Pilih Role</label>
                <div class="form-group">
                  <template v-if="rolesWithActive">
                    <template v-for="role in rolesWithActive" :key="role.value">
                      <div class="d-flex">
                        <input type="checkbox" :value="role.value" :checked="role.active ? true : false" @change="handleRoleSelection(role)" v-model="role.active">
                        <span class="ms-2">{{role.label}}</span>
                      </div>
                    </template>
                  </template>
                </div>
              </div>
  
              <div class="mt-4 d-flex justify-content-end">
                <router-link to="./role-staff">
                  <button type="button" name="button" class="m-0 btn btn-light">
                    Batal
                  </button>
                </router-link>
                <button
                  type="button"
                  name="button"
                  class="m-0 btn bg-gradient-success ms-2"
                  @click="submit"
                >
                  Ubah Role Staff
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  // import ArgonCheckbox from "@/components/ArgonCheckboxCustom.vue";
  import axios from 'axios';
  
  export default {
    name: "NewAsoCentral",
    components: {
      // ArgonCheckbox,
    },
    computed: {
        roleId() {
            if (!this.$route.params.roleId) {
                return null
            }
            return this.$route.params.roleId
        }
    },
    data() {
      return {
        displayname: "",
        isActive: false,
        roles: [
          { value: "dashboard", label: "Dashboard" },
          { value: "perm_aso_central", label: "Aso Central" },
          { value: "perm_aso_branch", label: "Aso Cabang" },
          { value: "perm_fincoy_ho", label: "Fincoy HO" },
          { value: "perm_withdraw", label: "Proses Withdraw" },
          { value: "perm_management_info", label: "Management Info" },
          { value: "perm_staff", label: "Staff" },
          { value: "perm_report", label: "Report" },
          // Add other roles here
        ],
        selectedRoles: [],
        modifiedSelectedRoles: [],
        rolesWithActive: [],
      };
    },
    mounted() {
      var token = window.localStorage.getItem("TOKEN");
      if (!this.roleId) {
          return
      }
      axios.get(`https://api.darlink.id/v1/admin/role/${this.roleId}`, {
        withCredentials: true,
        headers: {
            Accept: "application/json",
            Authorization: token
        }
      }).then((response) => {
        this.displayname = response.data.content.name;
        this.isActive = response.data.content.is_active;
        this.selectedRoles = response.data.content.permissions;
      }).then(() => {
        this.modifiedSelectedRoles = this.selectedRoles.map((result) => {
          return 'perm_' + result 
        });
        this.rolesWithActive = this.roles.map((result) => {
          if (this.modifiedSelectedRoles.includes(result.value)) {
            return { ...result, active: true }
          }else {
            return { ...result, active: false }
          }
        });
        console.log(this.rolesWithActive);
      }).catch((error) => {
          console.log(error);
      })
      if (localStorage.getItem("SideMenuType") !== "SuperAdmin") {
          this.$router.go(-1);
      }
    },
    methods: {
      handleRoleSelection(role) {
        if (role.active) {
          this.modifiedSelectedRoles = [...this.modifiedSelectedRoles, role.value];
        } else {
          this.modifiedSelectedRoles = this.modifiedSelectedRoles.filter(item => item !== role.value);
        }
      },
      submit() {
        var token = window.localStorage.getItem("TOKEN")
  
        // Create the payload object
        const roleData = new FormData();
  
        roleData.append("name", this.displayname);
        roleData.append("grants", JSON.stringify(this.modifiedSelectedRoles));
        roleData.append("status", this.isActive ? 'active' : 'inactive');
  
        // Send the payload to the API
        axios
          .post(`https://api.darlink.id/v1/admin/role/${this.roleId}`, roleData, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token,
            },
          })
          .then((response) => {
            // Handle the API response
            this.$swal({
              title: "Berhasil",
              text: "Role Staff berhasil diupdate",
              icon: "success",
              confirmButtonText: "Tutup",
              confirmButtonColor: "#3085d6",
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.push({ name: "superadmin.staff.role" });
              }
            });
            if (response.data.code != 201) {
              return;
            }
            console.log(response);
          })
          .catch((err) => {
            // Handle any errors
            if (err.response && err.response.data && err.response.data.validation) {
              const validationErrors = err.response.data.validation;
              let errorMessage = "Validation Error:\n";
              validationErrors.forEach((error) => {
                errorMessage += `${error.mesg}\n`;
              });
              this.$swal({
                title: "Validation Error",
                text: errorMessage,
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "#d33",
              });
            } else {
              this.$swal({
                title: "Error",
                text: err.response.data.message,
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "#d33",
              });
            }
            console.error(err);
          });
      },
    },
  };
  </script>
  