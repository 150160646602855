<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="mx-auto col-lg-12 col-12">
          <div class="mt-4 card card-body">
            <h6 class="mb-0">Settings Page</h6>
            <hr class="my-3 horizontal dark" />
            <div class="row">
              <div class="col-6">
                <label for="projectName" class="form-label">Persentase pembagian Fincoy PIC (dalam persen)</label>
                <input id="projectName" type="text" class="form-control" v-model="picName" />
              </div>
              <div class="col-6">
                <label for="projectName" class="form-label">Durasi Waktu penarikan data ke pending (dalam menit)</label>
                <input id="projectName" type="text" class="form-control" v-model="personalID" />
              </div>
            </div>
            <div class="mt-4 d-flex justify-content-end">
              <router-link to="./dashboard">
                <button type="button" name="button" class="m-0 btn btn-light">
                  Batal
                </button>
              </router-link>
              <button
                type="button"
                name="button"
                class="m-0 btn bg-gradient-success ms-2"
                @click="submit"
              >
                Update Data
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
//   import flatPickr from "vue-flatpickr-component";
//   import Dropzone from "dropzone";
  import Choices from "choices.js";
  import Quill from "quill";
  import axios from "axios";
  
  export default {
    name: "Settings",
    components: {
    //   flatPickr,
    },
    data() {
      return {
        date: "",
        endDate: "",
        config: {
          allowInput: true,
        },
        isActive: false,
        picName: "50",
        personalID: "10",
      };
    },
    methods: {
      loadData() {
        var token = window.localStorage.getItem("TOKEN")
        axios.get("https://api.darlink.id/v1/settings", {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          }
        }).then((response) => {
          console.log(response.data);
          this.picName = response.data.content[1].value;
          this.personalID = response.data.content[0].value;
        }).catch((error) => {
          console.log(error);
        })
      },
      submit() {
        var token = window.localStorage.getItem("TOKEN")
        const formData = [];
        
        formData.push({
          name: "assignment_proportion",
          value: this.picName,
        });

        formData.push({
          name: "assignment_timeout",
          value: this.personalID,
        });

        axios.post("https://api.darlink.id/v1/settings", formData ,{
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          }
        }).then(() => {
          this.$swal({
            title: "Berhasil",
            text: "Ubah profil Fincoy berhasil dilakukan",
            icon: "success",
            confirmButtonText: "Tutup",
            confirmButtonColor: "#3085d6",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push({ name: "superadmin.settings" })
            }
          });
        }).catch((error) => {
          console.log(error);
        })
      }
    }
    ,
    beforeMount() {
      if (localStorage.getItem("SideMenuType") !== "SuperAdmin") {
          this.$router.go(-1);
      }
    },
    mounted() {
      this.loadData();
      if (document.getElementById("editor")) {
        new Quill("#editor", {
          theme: "snow", // Specify theme in configuration
        });
      }
  
      if (document.getElementById("choices-category")) {
        var element = document.getElementById("choices-category");
        new Choices(element, {
          searchEnabled: false,
          allowHTML: true,
          itemSelectText: '',
        });
      }
  
      if (document.getElementById("choices-aso")) {
        var element1 = document.getElementById("choices-aso");
        new Choices(element1, {
          searchEnabled: false,
          allowHTML: true,
          itemSelectText: '',
          position: 'auto',
        });
      }
    },
  };
  </script>
  