<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-12">
            <!-- card -->
            <div class="mt-4 card card-body">
              <h6 class="mb-0">Show data order</h6>
                <div class="mt-4">
                  <div class="">
                    <div class="">
                      <h5 id="" class="title">
                        Nama Branch: {{ dataOrder.aso_branch.displayname }}
                      </h5>
                    </div>
                    <div class="body">
                      <div>
                        <h6>Agent: {{ dataOrder.agent.displayname }}</h6>
                        <p class="text-sm mb-0">
                          Nama PIC:
                          <b>Herman</b> <br />
                          Nomor HP
                          <b>1234567890</b>
                        </p>
                        <p class="text-sm">
                          NIK/NPK :
                          <b>243598234</b>
                        </p>
                        <p class="text-sm">
                          Jumlah Anggota :
                          <b>32</b>
                        </p>
                      </div>
                      <p>Jalan Jend. Sudirman</p>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="mt-4 card card-body">
              <div>
                <p class="text-sm mb-0">
                  Nama Konsumen:
                  <b>{{ dataOrder.client_name }}</b> 
                  <br />
                  NIK:
                  <b>{{ dataOrder.client_id_code }}</b> 
                  <br />
                  Nomor HP:
                  <b>{{ dataOrder.client_phone }}</b>
                  <br />
                  Alamat:
                  <b>{{ dataOrder.client_address }}</b>
                  <br />
                  Provinsi:
                  <b>{{ dataOrder.client_region }}</b>
                  <br />
                  Kota / Kabupaten:
                  <b>{{ dataOrder.client_location }}</b>
                  <br />
                  Kode POS:
                  <b>{{ dataOrder.client_postal_code }}</b>
                  <br />
                  <br />
                  Leased Item:
                  <b>{{ dataOrder.leased_item_name }}</b>
                  <br />
                  Leased Brand:
                  <b>{{ dataOrder.leased_item_brand}}</b>
                  <br />
                  Leased Item Type:
                  <b>{{ dataOrder.leased_item_type }}</b>
                  <br />
                  Leased Year:
                  <b>{{ dataOrder.leased_item_year }}</b>
                  <br />
                  Leased BPKB:
                  <b>{{ dataOrder.leased_item_ownership_holder == "self_holder" ? "SENDIRI" : "ORANG LAIN" }}</b>
                  <br />
                  Leased Tenor:
                  <b>{{ dataOrder.tenor }} Bulan</b>
                  <br />
                  <b>Total Pinjaman: </b>
                  <b>{{ dataOrder.total_loan }}</b>
                  <br />
                  <br />
                  Created Date:
                  <b id="created_date">{{ created ? created : '' }}</b>
                  <br />
                  Updated Date:
                  <b id="updated_date">{{ updated ? updated : '' }}</b>
                  <br />
                  Confirmed Date:
                  <b id="confirmed_date">{{ confirmed ? confirmed : '' }}</b>
                  <br />
                  Rejected Date:
                  <b id="rejected_date">{{ rejected ? rejected : '' }}</b>
                  <br />
                  Alasan:
                  <b>{{ dataOrder.reason ? dataOrder.reason : '-' }}</b>
                </p>
              </div>

              <!--Image-->
              <div v-if="dataOrder.additional_document">
                  <img :src="dataOrder.additional_document" alt="Additional Document" style="width: 20%; height: auto;"/>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    data(){
      return{
        dataOrder: {
          agent: {}, // Initialize agent object
          aso_branch: {},
        },
        created: "",
        updated: "",
        confirmed: "",
        rejected: "",
      }
    },
    beforeMount() {
      if (localStorage.getItem("SideMenuType") !== "AsoCentral") {
          this.$router.go(-1);
      }
    },
    mounted(){
      this.getDataOrder()
    },
    computed: {
      orderID() {
        if (!this.$route.params.orderList) {
          return null
        }
        return this.$route.params.orderList
      },
      
    },
    methods: {
      getDataOrder() {
        var token = window.localStorage.getItem("TOKEN")
        axios
          .get("https://api.darlink.id/v1/order/" +  this.orderID, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token,
              // allow cors 
              // "Access-Control-Allow-Origin": "*",
              // "Access-Control-Allow-Credentials": true,
            }
          })
          .then((response) => {
            this.dataOrder = response.data.content;
            this.dataOrder.agent = response.data.content.agent; // Assign agent data
            this.dataOrder.aso_branch = response.data.content.aso_branch; // Assign ASO Branch data
            
            this.created = new Date(this.dataOrder.created_at).toLocaleDateString("id-ID", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    // hour
                    hour: "numeric",
                    // minute
                    minute: "numeric",
                  });
            this.updated = new Date(this.dataOrder.updated_at).toLocaleDateString("id-ID", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    // hour
                    hour: "numeric",
                    // minute
                    minute: "numeric",
                  });
            this.confirmed = new Date(this.dataOrder.updated_at).toLocaleDateString("id-ID", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    // hour
                    hour: "numeric",
                    // minute
                    minute: "numeric",
                  });
            this.rejected = new Date(this.dataOrder.rejected_at).toLocaleDateString("id-ID", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    // hour
                    hour: "numeric",
                    // minute
                    minute: "numeric",
                  });
          })
          .catch((err) => {
            console.log(err);
          }
        );
      },
    }
  }
</script>