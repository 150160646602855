<template>
  <div class="py-2 container-md">
    <!-- make 2 row  -->

    <div class="row">
      <div class="col-md-12">
        <!-- card -->
        <div class="mt-4 card card-body">
          <h6 class="mb-0">Show data order</h6>
          <div class="mt-4">
            <div class="">
              <div class="">
                <h5 id="" class="title">
                  Nama Branch: {{ dataOrder.aso_branch.displayname }}
                </h5>
              </div>
              <div class="body">
                <div>
                  <h6>Agent: </h6>
                  <p class="text-sm mb-0">
                    Nama PIC:
                    <b>{{ dataOrder.agent.displayname }}</b> <br />
                    Nomor HP
                    <b>{{ dataOrder.agent.phone }}</b>
                  </p>
                  <p class="text-sm">
                    NIK/NPK :
                    <b>{{ dataOrder.agent.personal_id }}</b>
                  </p>
                </div>
                <p>{{ dataOrder.agent.address }}</p>
              </div>

              <!--FINCOy PIC-->
              <h5 id="" class="title">
                Nama Fincoy: {{ dataOrder.fincoy_ho.name }}
              </h5>
              <div class="body">
                <div>
                  <h6>Fincoy PIC: </h6>
                  <p class="text-sm mb-0">
                    Nama PIC:
                    <b>{{ dataOrder.fincoy_pic.displayname }}</b> <br />
                    Lokasi :
                    <b>{{ dataOrder.fincoy_pic.location }}</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="mt-4 card card-body">
          <div ref="contentToPrint">
            <div>
              <p class="text-sm mb-0">
                Nama Konsumen:
                <b>{{ dataOrder.client_name }}</b> 
                <br />
                NIK:
                <b>{{ dataOrder.client_id_code }}</b> 
                <br />
                Nomor HP:
                <b>{{ dataOrder.client_phone }}</b>
                <br />
                Alamat:
                <b>{{ dataOrder.client_address }}</b>
                <br />
                Provinsi:
                <b>{{ dataOrder.client_region }}</b>
                <br />
                Kota / Kabupaten:
                <b>{{ dataOrder.client_location }}</b>
                <br />
                Kode POS:
                <b>{{ dataOrder.client_postal_code }}</b>
                <br />
                <br />

                Alamat Domisili:
                  <b>{{ dataOrder.domicile_address }}</b>
                  <br />
                  Provinsi Domisili:
                  <b>{{ dataOrder.domicile_region }}</b>
                  <br />
                  Kota / Kabupaten Domisili:
                  <b>{{ dataOrder.domicile_location }}</b>
                  <br />
                  Kode POS Domisili:
                  <b>{{ dataOrder.domicile_postal_code }}</b>
                  <br />
                  <br />

                  Alamat STNK:
                  <b>{{ dataOrder.vehicle_address }}</b>
                  <br />
                  Provinsi STNK:
                  <b>{{ dataOrder.vehicle_region }}</b>
                  <br />
                  Kota / Kabupaten STNK:
                  <b>{{ dataOrder.vehicle_location }}</b>
                  <br />
                  Kode POS STNK:
                  <b>{{ dataOrder.vehicle_postal_code }}</b>
                  <br />
                  <br />

                Leased Item:
                <b>{{ dataOrder.leased_item_name }}</b>
                <br />
                Leased Brand:
                <b>{{ dataOrder.leased_item_brand}}</b>
                <br />
                Leased Item Type:
                <b>{{ dataOrder.leased_item_type }}</b>
                <br />
                Leased Year:
                <b>{{ dataOrder.leased_item_year }}</b>
                <br />
                Leased BPKB:
                <b>{{ dataOrder.leased_item_ownership_holder == "self_holder" ? "SENDIRI" : "ORANG LAIN" }}</b>
                <br />
                Leased Tenor:
                <b>{{ dataOrder.tenor }} Bulan</b>
                <br />
                <b>Total Pinjaman: </b>
                <b>{{ dataOrder.total_loan }}</b>
                <br />
                <br />
                Created Date:
                <b id="created_date">{{ created ? created : '' }}</b>
                <br />
                Approved Date:
                <b id="updated_date">{{ updated ? updated : '' }}</b>
                <br />
                <!-- Confirmed Date:
                <b id="confirmed_date">{{ confirmed ? confirmed : '' }}</b>
                <br />
                Rejected Date:
                <b id="rejected_date">{{ rejected ? rejected : '' }}</b>
                <br /> -->
                Alasan:
                <b>{{ dataOrder.reason ? dataOrder.reason : '-' }}</b>
              </p>
            </div>
          </div>

          <!--Image-->
            <div v-for="docs,i in dataOrder.additional_document" :key="i">
                <div v-if="docs">
                  <img :src="docs" alt="Additional Document" style="width: 20%; height: auto;"/>
                </div>
            </div>

          <div class="modal-footer mt-2">
            <button
              type="button"
              @click="$router.push({ name: 'aso.order-approved' })"
              class="btn bg-gradient-secondary btn-sm"
            >
              Kembali
            </button>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
  import axios from 'axios'
  import jsPDF from 'jspdf';
  import html2canvas from 'html2canvas';
  export default {
    data(){
      return{
        dataOrder: {
          agent: {}, // Initialize agent object
          aso_branch: {},
          fincoy_pic: {},
          fincoy_ho: {},
        },
        created: "",
        updated: "",
        confirmed: "",
        rejected: "",
      }
    },
    beforeMount() {
      if (localStorage.getItem("SideMenuType") !== "AsoCentral") {
          this.$router.go(-1);
      }
    },
    mounted(){
      this.getDataOrder()
    },
    computed: {
      orderID() {
        if (!this.$route.params.orderList) {
          return null
        }
        return this.$route.params.orderList
      },
      
    },
    methods: {
      async generatePDF() {
        // Mendapatkan elemen HTML yang ingin dicetak
        const element = this.$refs.contentToPrint;

        // Membuat objek jsPDF baru
        const pdf = new jsPDF();

        // Opsi untuk menyusun halaman PDF
        const options = {
          margin: 10,
          filename: 'file.pdf',
          image: { type: 'jpeg', quality: 1 },
          html2canvas: { scale: 0.5 },
          jsPDF: { unit: 'mm', format: 'a3', orientation: 'portrait' }
        };

        // Fungsi untuk mengubah elemen HTML menjadi gambar menggunakan html2canvas dan menambahkannya ke PDF
        const canvas = await html2canvas(element, options.html2canvas);

        // Tambahkan gambar canvas ke PDF
        pdf.addImage(canvas.toDataURL('image/jpeg', 1), 'JPEG', options.margin, options.margin, canvas.width * 0.5, canvas.height * 0.5);

        // Menyimpan PDF
        pdf.save(options.filename);
      },
      getDataOrder() {
        var token = window.localStorage.getItem("TOKEN")
        axios
          .get("https://api.darlink.id/v1/order/" +  this.orderID, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token,
              // allow cors 
              // "Access-Control-Allow-Origin": "*",
              // "Access-Control-Allow-Credentials": true,
            }
          })
          .then((response) => {
            this.dataOrder = response.data.content;
            this.dataOrder.agent = response.data.content.agent; // Assign agent data
            this.dataOrder.aso_branch = response.data.content.aso_branch; // Assign ASO Branch data
            this.dataOrder.fincoy_pic = response.data.content.handled_by;
            this.dataOrder.fincoy_ho = response.data.content.handled_from;
            
            this.created = new Date(this.dataOrder.created_at).toLocaleDateString("id-ID", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    // hour
                    hour: "numeric",
                    // minute
                    minute: "numeric",
                  });
            this.updated = new Date(this.dataOrder.approved_at).toLocaleDateString("id-ID", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    // hour
                    hour: "numeric",
                    // minute
                    minute: "numeric",
                  });
            this.confirmed = new Date(this.dataOrder.updated_at).toLocaleDateString("id-ID", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    // hour
                    hour: "numeric",
                    // minute
                    minute: "numeric",
                  });
            this.rejected = new Date(this.dataOrder.rejected_at).toLocaleDateString("id-ID", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    // hour
                    hour: "numeric",
                    // minute
                    minute: "numeric",
                  });
          })
          .catch((err) => {
            console.log(err);
          }
        );
      },
      withdrawPlatform() {
        var token = window.localStorage.getItem("TOKEN")
        axios
          .post("https://api.darlink.id/v1/order/" +  this.orderID + "/fund-platform", {}, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token,
              // Authorization: "TEST_FINCOY_PIC123456789"
              // allow cors 
              // "Access-Control-Allow-Origin": "*",
              // "Access-Control-Allow-Credentials": true,
            }
          })
          .then((response) => {
            // SWEETALLERT
            this.$swal({
              title: "Berhasil",
              text: "Pencairan berhasil dilakukan",
              icon: "success",
              confirmButtonText: "Tutup",
              confirmButtonColor: "#3085d6",
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.push({ name: "Fincoy Order Pencairan Dana Darlink" })
              }
            });
            this.$router.push({ name: "Fincoy Order Pencairan Dana Darlink" })
            if (response.data.code != 200) {
              return
            }
          })
          .catch(() => {
            
              // this.$swal({
              this.$swal({
                title: "Gagal",
                text: "pencairan sudah dilakukan",
                icon: "error",
                confirmButtonText: "Tutup",
                confirmButtonColor: "#3085d6",
              });
            })
      },
      ambilOrder() {
        var token = window.localStorage.getItem("TOKEN")
        axios
          .post("https://api.darlink.id/v1/order/" +  this.orderID + "/retract", {}, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token,
              // allow cors 
              // "Access-Control-Allow-Origin": "*",
              // "Access-Control-Allow-Credentials": true,
            }
          })
          .then((response) => {
            // SWEETALLERT
            this.$swal({
              title: "Berhasil",
              text: "Order berhasil ditarik kembali",
              icon: "success",
              confirmButtonText: "Tutup",
              confirmButtonColor: "#3085d6",
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.push({ name: "aso.order-approved" })
              }
            });
            this.$router.push({ name: "aso.order-approved" })
            if (response.data.code != 200) {
              return
            }
          })
          .catch((err) => {
            if (err.response && err.response.data && err.response.data.validation) {
              const validationErrors = err.response.data.validation;
              let errorMessage = "Validation Error:\n";
              validationErrors.forEach((error) => {
                errorMessage += `${error.mesg}\n`;
              });
              this.$swal({
                title: "Validation Error",
                text: errorMessage,
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "#d33",
              });
            } else {
              this.$swal({
                title: "Error",
                text: err.response.data.message,
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "#d33",
              });
            }
          });
      }
    }
  }
</script>