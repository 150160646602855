<template>
	<div class="py-4 container-fluid">
		<div class="row">
			<div></div>
			<div class="mx-auto col-lg-9 col-12">
				<div class="mt-4 card card-body">
					<h6 class="mb-0">Edit Aso Central</h6>
					<hr class="my-3 horizontal dark" />
					<div v-if="isLoading" style="height: 500px" class="d-flex justify-content-center align-items-center">Sedang memuat...</div>
					<div v-else>
						<label for="aso_central_name_input" class="form-label">Nama ASO Central</label>
						<input
							id="aso_central_name_input"
							v-model="dataDetailASO.name"
							type="text"
							class="form-control"
							:class="{ 'is-invalid': isError('aso-central.create.name') }"
						/>
						<div class="invalid-feedback">Invalid input!</div>
						<div class="mt-4 row">
							<div class="col-12 col-md-6">
								<div class="form-group">
									<label>Status Aso Central</label>
									<div class="form-check form-switch ms-1">
										<input
											id="flexSwitchCheckDefault"
											v-model="isActive"
											class="form-check-input"
											type="checkbox"
											data-type="warning"
											data-content="Once a project is made private, you cannot revert it to a public project."
											data-title="Warning"
											data-icon="ni ni-bell-55"
										/>
										<label class="form-check-label" for="flexSwitchCheckDefault">
											<span
												class="badge badge-md"
												:class="{
													'badge-danger': !isActive,
													'badge-success': isActive,
												}"
											>
												{{ this.isActive ? "AKTIF" : "NON-AKTIF" }}
											</span>
										</label>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-6">
								<label for="pic_name_input" class="form-label">Nama PIC</label>
								<input
									id="pic_name_input"
									v-model="dataDetailASO.pic_name"
									type="text"
									class="form-control"
									:class="{ 'is-invalid': isError('aso-central.create.pic_name') }"
								/>
								<div class="invalid-feedback">Invalid input!</div>
							</div>
							<div class="col-6">
								<label for="email_input" class="form-label">Email</label>
								<input
									id="email_input"
									v-model="dataDetailASO.email"
									type="text"
									class="form-control"
									:class="{ 'is-invalid': isError('aso-central.create.email') }"
								/>
								<div class="invalid-feedback">Invalid input!</div>
							</div>
							<!-- <div class="col-6">
                <label for="username_input" class="form-label">Username</label>
                <input
                  id="username_input"
                  v-model="username"
                  type="hidden"
                  class="form-control"
                  :class="{ 'is-invalid': isError('aso-central.create.username') }"
                />
                <div class="invalid-feedback">
                  Invalid input!
                </div>
              </div>
              <div class="col-6">
                <label for="password_input" class="form-label">password</label>
                <input
                  id="password_input"
                  v-model="password"
                  type="hidden"
                  class="form-control"
                  :class="{ 'is-invalid': isError('aso-central.create.password') }"
                />
              </div> -->
							<div class="col-6">
								<label for="personal_id_input" class="form-label">NIK/NPK</label>
								<input
									id="personal_id_input"
									v-model="dataDetailASO.personal_id"
									type="text"
									class="form-control"
									:class="{ 'is-invalid': isError('aso-central.create.personal_id') }"
									maxlength="16"
								/>
								<div class="invalid-feedback">Invalid input!</div>
							</div>
							<div class="col-6">
								<label for="phone_input" class="form-label">Nomor HP PIC</label>
								<input
									id="phone_input"
									v-model="dataDetailASO.phone"
									type="text"
									class="form-control"
									:class="{ 'is-invalid': isError('aso-central.create.phone') }"
									maxlength="13"
								/>
								<div class="invalid-feedback">Invalid input!</div>
							</div>
							<!-- <div class="col-6">
                <label for="total_member_input" class="form-label">Jumlah Anggota</label>
                <input
                  id="total_member_input"
                  v-model="totalMember"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': isError('aso-central.create.total_member') }"
                />
                <div class="invalid-feedback">
                  Invalid input!
                </div>
              </div>
              <div class="col-6">
                <label for="location_input" class="form-label">Daerah/Lokasi</label>
                <input
                  id="location_input"
                  v-model="location"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': isError('aso-central.create.location') }"
                />
                <div class="invalid-feedback">
                  Invalid input!
                </div>
              </div> -->
							<!-- <div class="col-6">
                <label for="organization_type_input" class="form-label">Jenis Organisasi</label>
                <input
                  id="organization_type_input"
                  v-model="organizationType"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': isError('aso-central.create.organization_type') }"
                />
                <div class="invalid-feedback">
                  Invalid input!
                </div>
              </div>
              <div class="col-6">
                <label for="organization_name_input" class="form-label">Nama Organisasi</label>
                <input
                  id="organization_name_input"
                  v-model="organizationName"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': isError('aso-central.create.organization_name') }"
                />
                <div class="invalid-feedback">
                  Invalid input!
                </div>
              </div> -->
						</div>
						<label class="mt-4">Alamat Kantor</label>
						<textarea v-model="dataDetailASO.address" class="form-control" rows="5"></textarea>
					</div>
					<div class="mt-4 d-flex justify-content-end">
						<button type="button" name="button" class="m-0 btn btn-light" @click="$router.push({ name: 'superadmin.aso-central' })">
							Batal
						</button>
						<button type="button" name="button" class="m-0 btn bg-gradient-success ms-2" @click="submit()">
							<span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
							<span v-else>Ubah Aso Central</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";

export default {
	name: "EditAsoCentral",
	components: {},
	data() {
		return {
			asoCentralName: "",
			picName: "",
			email: "",
			organizationName: "",
			organizationType: "",
			totalMember: "",
			personalID: "",
			location: "",
			address: "",
			phone: "",
			isActive: false,
			// Validations
			validations: [],
			isLoading: false,
			dataDetailASO: {},
		};
	},
	computed: {
		asoCentralID() {
			if (!this.$route.params.asoCentral) {
				return null;
			}
			return this.$route.params.asoCentral;
		},
	},
	watch: {
		activeModel(newVal) {
			console.log("activeModel changed:", newVal);
		},
	},
	beforeMount() {
		if (localStorage.getItem("SideMenuType") !== "SuperAdmin") {
			this.$router.go(-1);
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.loadASOCentralById();
		});
	},
	methods: {
		isError(tag) {
			let message = this.validations.find((validation) => {
				if (validation.tag === tag) {
					return true;
				}
				return false;
			});

			if (!message) {
				return false;
			}

			return true;
		},
		validateEmail(email) {
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			return emailRegex.test(email);
		},
		validatePhone(phone) {
			const phoneRegex = /^\d{11,13}$/;
			return phoneRegex.test(phone);
		},
		validateNik(personalID) {
			const phoneRegex = /^\d{16}$/;
			return phoneRegex.test(personalID);
		},
		async loadASOCentralById() {
			var token = window.localStorage.getItem("TOKEN");
			if (!this.asoCentralID) {
				return;
			}

			try {
				this.isLoading = true;
				const config = {
					methods: "GET",
					url: `https://api.darlink.id/v1/aso-central/${this.asoCentralID}`,
					withCredentials: true,
					headers: {
						Accept: "application/json",
						Authorization: token,
					},
				};
				const response = await axios.request(config);
				const data = response?.data.content;
				this.isActive = data.is_active;
				this.dataDetailASO = data;
				this.isLoading = false;
			} catch (error) {
				console.log("this is the error: ", error.response);
				this.isLoading = false;
			}
		},
		async submit() {
			// this.isLoading = true;
			var token = window.localStorage.getItem("TOKEN");

			// Add email and phone format validation
			if (!this.validateEmail(this.dataDetailASO.email)) {
				this.isLoading = false;
				this.$swal({
					title: "Invalid Email",
					text: "Please enter a valid email address.",
					icon: "warning",
					confirmButtonText: "OK",
					confirmButtonColor: "#3085d6",
				});
				return;
			}

			if (!this.validatePhone(this.dataDetailASO.phone)) {
				this.isLoading = false;
				this.$swal({
					title: "Invalid Phone Number",
					text: "Please enter a valid max 13-digit phone number.",
					icon: "warning",
					confirmButtonText: "OK",
					confirmButtonColor: "#3085d6",
				});
				return;
			}

			if (!this.validateNik(this.dataDetailASO.personal_id)) {
				this.isLoading = false;
				this.$swal({
					title: "Invalid NIK",
					text: "Please enter a valid 16-digit number.",
					icon: "warning",
					confirmButtonText: "OK",
					confirmButtonColor: "#3085d6",
				});
				return;
			}

			const asoCentral = new FormData();

			asoCentral.append("name", this.dataDetailASO.name);
			asoCentral.append("email", this.dataDetailASO.email);
			asoCentral.append("pic_name", this.dataDetailASO.pic_name);
			asoCentral.append("personal_id", this.dataDetailASO.personal_id);
			asoCentral.append("phone", this.dataDetailASO.phone);
			asoCentral.append("address", this.dataDetailASO.address);
			asoCentral.append("status", this.isActive ? "active" : "inactive");
			asoCentral.append("organization_name", this.dataDetailASO.organization_name);
			asoCentral.append("organization_type", this.dataDetailASO.organization_type);
			asoCentral.append("total_member", this.dataDetailASO.total_member);
			asoCentral.append("location", this.dataDetailASO.location);

			try {
				const config = {
					method: "POST",
					url: `https://api.darlink.id/v1/aso-central/${this.asoCentralID}`,
					data: asoCentral,
					withCredentials: true,
					headers: {
						Accept: "application/json",
						Authorization: token,
					},
				};
				const response = await axios.request(config);
				if (response.status === 200) {
					this.$swal({
						title: "Berhasil",
						text: "Aso Central berhasil diubah",
						icon: "success",
						confirmButtonText: "Tutup",
						confirmButtonColor: "#3085d6",
					}).then((result) => {
						if (result.isConfirmed) {
							this.$router.push({ name: "superadmin.aso-central" });
						}
					});
					this.$router.push({ name: "superadmin.aso-central" });
				}
			} catch (error) {
				this.$swal({
					title: "Error",
					text: error.response?.data.message,
					icon: "error",
					confirmButtonText: "OK",
					confirmButtonColor: "#d33",
				});
			}
		},
	},
};
</script>
