<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Order List Pending</h5>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <label for="projectName" class="form-label">Dari Tgl</label>
                <argon-input type="date" placeholder="Date" v-model="filter.startDate" />
              </div>
              <div class="col-md-2">
                <label for="projectName" class="form-label">Sampai Tgl</label>
                <argon-input type="date" placeholder="Date" v-model="filter.endDate" />
              </div>
              <div class="col-md-4">
                <label for="projectName" class="form-label">Aso Cabang</label>
                <select
                  id="choices-aso"
                  class="form-control"
                  name="choices-aso"
                  v-model="filter.asoBranch"
                >
                  <option value="">All</option>
                  <option v-for="(dataAsoBranch, index) in asoBranch" :value="dataAsoBranch.id" :key="index">{{ dataAsoBranch.displayname }}</option>
                </select>
              </div>
              <div class="col-md-4">
                <label for="projectName" class="form-label">Action</label>
                <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <a
                    href="#"
                    class="mb-0 btn bg-gradient-success btn-sm"
                    @click="handleFilter"
                    >+&nbsp; Filter</a
                  >
                  <button
                    type="button"
                    class="mx-1 mb-0 btn btn-outline-success btn-sm"
                    @click="handleReset"
                  >
                    Reset
                  </button>
                  
                  <!-- <button
                    type="button"
                    class="mx-1 mb-0 btn btn-outline-success btn-sm"
                    data-bs-original-title="Preview product"
                    data-bs-toggle="modal"
                    data-bs-target="#view-fincoy"
                  >
                        Fincoy Assign
                  </button> -->

                  <!-- <button
                    class="mt-1 mb-0 btn btn-outline-success btn-sm export mt-sm-0"
                    data-type="csv"
                    type="button"
                    name="button"
                  ><i class="text-success text-gradient ni ni-chart-bar-32"></i>
                    Export
                  </button> -->
                </div>
              </div>
              </div>
            </div>
          </div>
          
          <div class="px-0 pb-0 card-body pt-0">
            <div class="table-responsive">
              <div class="row">
                <div class="col-lg-4" style="margin: 10px;">
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      v-model="searchQuery"
                      placeholder="Search ..."
                    />
                  </div>
                  
                  <a
                    href="#"
                    class="mb-0 btn bg-gradient-success btn-sm"
                    style="margin-right: 10px;"
                    @click="performSearch"
                    >+&nbsp; search</a
                  >
                </div>
              </div>

              <table id="products-list" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Aso Cabang</th>
                    <th>Nama Konsumen</th>
                    <th>Reference Fincoy HO</th>
                    <th>Reference Fincoy PIC</th>
                    <th>T. Pinjaman</th>
                    <th>Nama Kendaraan</th>
                    <th>Nama Agent</th>
                    <th>Tanggal Dibuat</th>
                    <!-- <th>Total Fee</th>
                    <th>Fee Aso Cabang</th> -->
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- <td>
                    <div class="d-flex align-items-center">
                    <argon-checkbox />
                        <p class="text-xs font-weight-bold ms-2 mb-0">ASO Cabang Jakarta</p>
                    </div>
                  </td> -->
                  <!-- <tr>
                    <td>
                        <div class="d-flex align-items-center">
                        <argon-checkbox />
                            <p class="text-xs font-weight-bold ms-2 mb-0">ASO Cabang Jakarta</p>
                        </div>
                    </td>
                    <td class="text-sm">Herman</td>
                    <td class="text-sm">
                      <div class="d-flex">
                        <h6 class="my-auto">Rp.100.000.000</h6>
                      </div>
                    </td>
                    <td class="text-sm">Yamaha nMax</td>
                    <td class="text-sm"><span class="badge badge-info badge-sm">Low</span></td>
                    <td class="text-sm">-</td>
                    <td class="text-sm">-</td>
                    <td class="text-sm">1.000.000</td>
                    <td class="text-sm">100.000</td>
                    <td><span class="badge badge-success badge-sm">Approve</span></td>
                    <td class="text-sm">
                      <a
                        href="javascript:;"
                        data-bs-original-title="Preview product"
                        data-bs-toggle="modal"
                        data-bs-target="#view-aso"
                      >
                        <i class="fas fa-eye text-secondary"></i>
                      </a>
                    </td>
                  </tr> -->
                  
                </tbody>
                <tfoot>
                  <tr>
                    <th>Aso Cabang</th>
                    <th>Nama Konsumen</th>
                    <th>T. Pinjaman</th>
                    <th>Nama Kendaraan</th>
                    <th>Status Client</th>
                    <th>Fincoy Ho</th>
                    <th>Fincoy Pic</th>
                    <th>Total Fee</th>
                    <th>Fee Aso Cabang</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </tfoot>
              </table>

              <!-- DataTable's built-in pagination -->
              <nav aria-label="Page navigation">
                <ul class="pagination justify-content-end">
                  <li class="page-item" :class="{ disabled: page === 1 }">
                    <a class="page-link" href="javascript:void(0);" @click="loadPage(page - 1)">
                      Prev
                    </a>
                  </li>
                  <li class="page-item" v-for="pageNumber in totalPages" :key="pageNumber" :class="{ active: pageNumber === page }">
                    <a class="page-link" href="javascript:void(0);" @click="loadPage(pageNumber)">
                      {{ pageNumber }}
                    </a>
                  </li>
                  <li class="page-item" :class="{ disabled: page === totalPages }">
                    <a class="page-link" href="javascript:void(0);" @click="loadPage(page + 1)">
                      Next
                    </a>
                  </li>
                </ul>
              </nav>

            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- button edit modal -->    
    <div
      id="view-aso"
      class="modal fade"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-lg-10">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="ModalLabel" class="modal-title">
              ASO Cabang  Jakarta
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div>
              <h6>ASO Cabang  Jakarta</h6>
              <p class="text-sm mb-0">
                Nama PIC
                <b>Herman</b> Nomor HP
                <b>1234567890</b>
              </p>
              <p class="text-sm">
                NIK/NPK :
                <b>243598234</b>
              </p>

              <p class="text-sm">
                Jumlah Anggota :
                <b>32</b>
              </p>
            </div>
            <p>Jalan Alamat nya/Lokasi.</p>

            <div>
              <p class="text-sm mb-0">
                Status  
                <span class="badge badge-success badge-sm">Aktif</span>
              </p>
              <p class="text-sm">
                NIK/NPK :
                <b>243598234</b>
              </p>
            </div>
            
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn bg-gradient-secondary btn-sm"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End button edit modal -->

    <div
      class="modal fade"
      tabindex="-1"
      aria-hidden="true"
      :class="{ 'is-active': showModal }"
    >
      <div class="modal-dialog modal-lg mt-lg-10">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="ModalLabel" class="modal-title">
              <!-- Assign {{ selectedAso.name }} -->
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="px-0 pb-0 card-body pt-0">
                <div class="table-responsive">
                    <table id="products-list" class="table table-flush">
                        <thead class="thead-light">
                        <tr>
                            <th>Fincoy HO</th>
                            <th>Fincoy PIC</th>
                        </tr>
                        </thead>
                        <tbody>
                             <!-- looping for fincoy  -->
                            <tr v-for="fincoy,i in personInCharges" :key="i">
                                <td>
                                    <div class="d-flex align-items-center">
                                    <argon-checkbox />
                                        <p class="text-xs font-weight-bold ms-2 mb-0">{{ fincoy.displayname }}</p>
                                    </div>
                                </td>
                                <td class="text-sm">{{ fincoy.username }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn bg-gradient-primary btn-sm"
              data-bs-dismiss="modal"
              @click="assignFincoy()"
            >
              Assign
            </button>
            <button
              type="button"
              class="btn bg-gradient-secondary btn-sm"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import { DataTable } from "simple-datatables";
  import setTooltip from "@/assets/js/tooltip.js";
  import ArgonInput from "@/components/ArgonInput.vue";
  import ArgonCheckbox from "@/components/ArgonCheckbox.vue";
  import axios from 'axios';
  var columns = [
    {
      select: 0,
      render: function (data) {
        return `<div class="d-flex">
            <h6 class="my-auto">${data}</h6>
          </div>
        `
      }
    },
    {
      select: [1,2,3,4,5,6],
      render: function (data) {
        return `<span class="text-sm">${data}</span>
        `
      }
    },
    // row 2 text right
    {
      select: 2,
      render: function (data) {
        return `<span class="text-sm text-right">${data}</span>
        `
      }
    },
    // {
    //   select: 4,
    //   render: function (data) {
    //     // make badge color based on status value if high then use badge danger, if low use badge info
    //     // let status high = badge-danger, status low = badge-info
    //     let status = (data === 'LOW') ? "badge-info" :  "badge-danger"
    //     return `
    //     <span class="badge ${status} badge-sm">
    //       ${data}
    //     </span>
    //     `
    //   },
    // },
    // row 8 text align right and add tooltip
    {
      select: 7,
      render: function (data) {
        return `
        <span class="text-sm text-right" data-bs-toggle="tooltip" data-bs-placement="top" title="${data}">
          ${data}
        </span>
        `
      },
    },
    {
      select: 8,
      render: function (data) {
        return `
          <!-- button edit modal -->
          <a
            class="text-sm"
            href="javascript:;"
           
          >
            <i 
              data-action="edit"
              data-id="${data}"
              class="fas fa-eye text-secondary"></i>
          </a>
          <!-- End button edit modal -->

          <a
          class="text-sm"
          href="javascript: void(0);"
        >
          <span
            class="mx-3"
          >
            <i
             class="fas fa-user-edit text-secondary data-bs-toggle="tooltip" data-bs-placement="top" title="Assign ke Fincoy"
              data-action="assign"
              data-id="${data}"
            ></i>
          </span>
        </a>
        `
      }
    },
  ]
  
  var labels = {
    placeholder: "Cari Data...",
    perPage: "Menampilkan {select} ASO Cabang per halaman",
    noRows: "Tidak ada Data yang ditampilkan",
    info: "Menampilkan {start} ke {end} dari {rows} ASO Cabang (Halaman {page} dari {pages} halaman)"
  }
  
  export default {
    name: "AsoCentral",
    components: {
      ArgonInput,
      ArgonCheckbox,
    },
    data() {
      return {
        datatable: null,
        isLoading: false,
        personInCharges: [],
        orderLists: [],
        selectedAso: null,
        modalId: null,
        selectedData: {}, // Data yang akan ditampilkan di modal
        showModal: false,
        asoBranch: [],
        filter: {
          startDate: "",
          endDate: "",
          asoBranch: "",
        },
        page: 1, // Current page
        totalPages: 1, // Total number of pages
        searchQuery: '',
      }
    },
    beforeMount() {
      if (localStorage.getItem("SideMenuType") !== "AsoCentral") {
          this.$router.go(-1);
      }
    },
    mounted() {
      if (document.getElementById("products-list")) {
        this.datatable = new DataTable("#products-list", {
          processing: this.isLoading,
          searchable: false,
          fixedHeight: false,
          perPage: 16,
          columns: columns,
          labels: labels,
          paging: false,
          filters: {
            // Add the search filter
            search: this.searchQuery,
          },
        });
        this.datatable.body.addEventListener("click", this.handleAction)
        this.toggleLoading()
        this.loadOrderList()
        this.loadAsoBranch()
      }
      setTooltip(this.$store.state.bootstrap);
      this.fincoyList()
    },
    beforeUnmount() {
      this.datatable.destroy()
      this.datatable = null
    },
    methods: {
      loadAsoBranch() {
        var token = window.localStorage.getItem("TOKEN")
        axios.get('https://api.darlink.id/v1/aso-branch?status=active', {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          }
        }).then((response) => {
          this.asoBranch = response.data.content;
        })
      },
      handleReset() {
        this.filter.startDate = ""
        this.filter.endDate = ""
        this.filter.asoBranch = ""
        
        if (this.datatable) {
          this.destroyDataTable(); // Destroy the current DataTable instance
        }
        
        if (document.getElementById("products-list")) {
          this.datatable = new DataTable("#products-list", {
            processing: this.isLoading,
            searchable: false,
            fixedHeight: false,
            perPage: 16,
            columns: columns,
            labels: labels,
            paging: false,
            filters: {
              // Add the search filter
              search: this.searchQuery,
            },
          });
          this.datatable.body.addEventListener("click", this.handleAction)
          this.toggleLoading()
          this.loadOrderList()
        }
      },
      handleFilter() {
        if (this.datatable) {
          this.destroyDataTable(); // Destroy the current DataTable instance
        }
        
        if (document.getElementById("products-list")) {
          this.datatable = new DataTable("#products-list", {
            processing: this.isLoading,
            searchable: false,
            fixedHeight: false,
            perPage: 16,
            columns: columns,
            labels: labels,
            paging: false,
            filters: {
              // Add the search filter
              search: this.searchQuery,
            },
          });
          this.datatable.body.addEventListener("click", this.handleAction)
          this.toggleLoading()
          this.loadOrderListFilter(this.filter)
        }
      },
      toggleLoading() {
        if (!this.datatable) {
          return
        }
        if (!this.isLoading) {
          this.isLoading = true
          this.datatable.setMessage("Memuat...")
          return
        }
        this.isLoading = false
      },
      handleAction(e) {
        if (e.target.getAttribute("data-action") === "assign") {
          let id = parseInt(e.target.getAttribute("data-id"), 10)
          // let id = parseInt(e.target.dataset.id)  
          console.log(id, e.target)
          this.assignOrder(id)
        }
        // modal handle
        if (e.target.getAttribute("data-action") === "edit") {
          let id = parseInt(e.target.getAttribute("data-id"), 10)
          this.editOrder(id)
        }
      },
      editOrder(id) {
        this.$router.push({
          name: "aso.order-pending.show",
          params: {
            orderList: id
          }
        })
      },
      assignOrder(id) {
        this.$router.push({
          name: "aso.order-pending.detail",
          params: {
            orderList: id
          }
        })
      },
      performSearch() {
        if (this.datatable) {
          this.destroyDataTable(); // Destroy the current DataTable instance
        }
        
        if (document.getElementById("products-list")) {
          this.datatable = new DataTable("#products-list", {
            processing: this.isLoading,
            searchable: false,
            fixedHeight: false,
            perPage: 16,
            columns: columns,
            labels: labels,
            paging: false,
            filters: {
              // Add the search filter
              search: this.searchQuery,
            },
          });

          this.page = 1;

          this.datatable.body.addEventListener("click", this.handleAction)
          this.toggleLoading()
          if (Object.keys(this.filter).some(key => this.filter[key] !== "")) {
            // Check if any property in the filter object is not empty
            this.loadOrderListFilter(this.filter);
          } else {
            this.loadOrderList();
          }
        }
      },
      loadOrderListFilter(params_filter = {startDate: "", endDate: "", asoBranch: ""}) {
        var token = window.localStorage.getItem("TOKEN");
        axios
          .get(`https://api.darlink.id/v1/order?status=pending&start=${params_filter.startDate}&end=${params_filter.endDate}&aso_branch=${params_filter.asoBranch}`, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token,
            },
            params: {
              page: this.page, // Include the page parameter
              search: this.searchQuery, // Add the search query parameter
            },
          })
          .then((response) => {
            if (!this.datatable) {
              return;
            }
            this.toggleLoading();
            if (response.data.code != 200) {
              return;
            }
            if (response.data.tag === "content.order.empty") {
              this.datatable.setMessage("Tidak ada data yang ditampilkan");
              return;
            }
            this.orderLists = response.data.content;
            this.totalPages = response.data.pagination.total_pages; // Update total pages

            this.updateDataTableFilter();
            // this.datatable.insert({
            //   data: this.orderLists.map((orderList) => {
            //     return [
            //       orderList.aso_branch ? orderList.aso_branch.displayname : "", // Check if aso_branch exists
            //       orderList.client_name,
            //       orderList.preferred_handler_from ? orderList.preferred_handler_from.name : "",
            //       orderList.preferred_handler ? orderList.preferred_handler.displayname : "",
            //       orderList.total_loan.toLocaleString("id-ID", {
            //         style: "currency",
            //         currency: "IDR",
            //         minimumFractionDigits: 2,
            //       }),
            //       orderList.leased_item_name,
            //       orderList.agent ? orderList.agent.displayname : "", // Check if agent exists
            //       new Date(orderList.created_at).toLocaleDateString("id-ID", {
            //         year: "numeric",
            //         month: "long",
            //         day: "numeric",
            //         hour: "numeric",
            //         minute: "numeric",
            //       }),
            //       // orderList.total_fee.toLocaleString("id-ID", {
            //       //   style: "currency",
            //       //   currency: "IDR",
            //       //   minimumFractionDigits: 2,
            //       // }),
            //       // orderList.aso_central_fee.toLocaleString("id-ID", {
            //       //   style: "currency",
            //       //   currency: "IDR",
            //       //   minimumFractionDigits: 2,
            //       // }),
            //       `${orderList.id}`,
            //     ];
            //   }),
            // });
          })
          .catch((err) => {
            this.toggleLoading();
            console.log(err);
          });
      },
      loadOrderList() {
        var token = window.localStorage.getItem("TOKEN");
        axios
          .get(`https://api.darlink.id/v1/order?status=pending`, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token,
            },
            params: {
              page: this.page, // Include the page parameter
              search: this.searchQuery, // Add the search query parameter
            },
          })
          .then((response) => {
            if (!this.datatable) {
              return;
            }
            this.toggleLoading();
            if (response.data.code != 200) {
              return;
            }
            if (response.data.tag === "content.order.empty") {
              this.datatable.setMessage("Tidak ada data yang ditampilkan");
              return;
            }
            this.orderLists = response.data.content;
            this.totalPages = response.data.pagination.total_pages; // Update total pages

            this.updateDataTable();
            // this.datatable.insert({
            //   data: this.orderLists.map((orderList) => {
            //     return [
            //       orderList.aso_branch ? orderList.aso_branch.displayname : "", // Check if aso_branch exists
            //       orderList.client_name,
            //       orderList.preferred_handler_from ? orderList.preferred_handler_from.name : "",
            //       orderList.preferred_handler ? orderList.preferred_handler.location : "",
            //       orderList.total_loan.toLocaleString("id-ID", {
            //         style: "currency",
            //         currency: "IDR",
            //         minimumFractionDigits: 2,
            //       }),
            //       orderList.leased_item_name,
            //       orderList.agent ? orderList.agent.displayname : "", // Check if agent exists
            //       new Date(orderList.created_at).toLocaleDateString("id-ID", {
            //         year: "numeric",
            //         month: "long",
            //         day: "numeric",
            //         hour: "numeric",
            //         minute: "numeric",
            //       }),
            //       // orderList.total_fee.toLocaleString("id-ID", {
            //       //   style: "currency",
            //       //   currency: "IDR",
            //       //   minimumFractionDigits: 2,
            //       // }),
            //       // orderList.aso_central_fee.toLocaleString("id-ID", {
            //       //   style: "currency",
            //       //   currency: "IDR",
            //       //   minimumFractionDigits: 2,
            //       // }),
            //       `${orderList.id}`,
            //     ];
            //   }),
            // });
          })
          .catch((err) => {
            this.toggleLoading();
            console.log(err);
          });
      },
      destroyDataTable() {
        if (this.datatable) {
          this.datatable.destroy();
          this.datatable = null;
        }
      },
      // Function to update the DataTable
      updateDataTableFilter() {
        // if (this.datatable) {
        //   this.datatable.destroy();
        // }

        // this.datatable = new DataTable("#products-list", {
        //   processing: this.isLoading,
        //   searchable: true,
        //   fixedHeight: false,
        //   perPage: 16,
        //   columns: columns,
        //   labels: labels,
        // });

        // this.datatable.body.addEventListener("click", this.handleAction);
        this.datatable.body.innerHTML = '';
        this.datatable.insert({
            data: this.orderLists.map((orderList) => {
              return [
                orderList.aso_branch ? orderList.aso_branch.displayname : "", // Check if aso_branch exists
                orderList.client_name,
                orderList.preferred_handler_from ? orderList.preferred_handler_from.name : "",
                orderList.preferred_handler ? orderList.preferred_handler.displayname : "",
                orderList.total_loan.toLocaleString("id-ID", {
                  style: "currency",
                  currency: "IDR",
                  minimumFractionDigits: 2,
                }),
                orderList.leased_item_name,
                orderList.agent ? orderList.agent.displayname : "", // Check if agent exists
                new Date(orderList.created_at).toLocaleDateString("id-ID", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                }),
                // orderList.total_fee.toLocaleString("id-ID", {
                //   style: "currency",
                //   currency: "IDR",
                //   minimumFractionDigits: 2,
                // }),
                // orderList.aso_central_fee.toLocaleString("id-ID", {
                //   style: "currency",
                //   currency: "IDR",
                //   minimumFractionDigits: 2,
                // }),
                `${orderList.id}`,
              ];
            }),
          });
      },
      updateDataTable() {
        // if (this.datatable) {
        //   this.datatable.destroy();
        // }

        // this.datatable = new DataTable("#products-list", {
        //   processing: this.isLoading,
        //   searchable: true,
        //   fixedHeight: false,
        //   perPage: 16,
        //   columns: columns,
        //   labels: labels,
        // });

        // this.datatable.body.addEventListener("click", this.handleAction);
        this.datatable.body.innerHTML = '';
        this.datatable.insert({
          data: this.orderLists.map((orderList) => {
            return [
              orderList.aso_branch ? orderList.aso_branch.displayname : "", // Check if aso_branch exists
              orderList.client_name,
              orderList.preferred_handler_from ? orderList.preferred_handler_from.name : "",
              orderList.preferred_handler ? orderList.preferred_handler.location : "",
              orderList.total_loan.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 2,
              }),
              orderList.leased_item_name,
              orderList.agent ? orderList.agent.displayname : "", // Check if agent exists
              new Date(orderList.created_at).toLocaleDateString("id-ID", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
              }),
              // orderList.total_fee.toLocaleString("id-ID", {
              //   style: "currency",
              //   currency: "IDR",
              //   minimumFractionDigits: 2,
              // }),
              // orderList.aso_central_fee.toLocaleString("id-ID", {
              //   style: "currency",
              //   currency: "IDR",
              //   minimumFractionDigits: 2,
              // }),
              `${orderList.id}`,
            ];
          }),
        });
      },
      // Function to load data for a specific page
      loadPage(page) {
        if (page < 1 || page > this.totalPages) {
          return;
        }

        this.page = page;
        
        if (this.datatable) {
          this.destroyDataTable(); // Destroy the current DataTable instance
        }
        
        if (document.getElementById("products-list")) {
          this.datatable = new DataTable("#products-list", {
            processing: this.isLoading,
            searchable: false,
            fixedHeight: false,
            perPage: 16,
            columns: columns,
            labels: labels,
            paging: false,
            filters: {
              // Add the search filter
              search: this.searchQuery,
            },
          });
          this.datatable.body.addEventListener("click", this.handleAction)
          this.toggleLoading()

          if (Object.keys(this.filter).some(key => this.filter[key] !== "")) {
            // Check if any property in the filter object is not empty
            this.loadOrderListFilter(this.filter);
          } else {
            this.loadOrderList();
          }
        }
      },
      fincoyList() {
      var token = window.localStorage.getItem("TOKEN")
      axios
        .get("https://api.darlink.id/v1/aso-central/1/fincoy-pic", {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          }
        })
        .then((response) => {
          this.personInCharges = response.data.content
          
        })
        .catch((err) => {
          console.log(err);
        });
      },
      // assign fincoy with send assignees with value id fincoy from checkbox
      assignFincoy() {
        var token = window.localStorage.getItem("TOKEN")
        var assignees = []
        var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')
        for (var i = 0; i < checkboxes.length; i++) {
          assignees.push(checkboxes[i].value)
        }
        axios
          .post("https://api.darlink.id/v1/order/1/assign", {
            assignees: assignees
          }, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token
            }
          })
          .then((response) => {
            if (response.data.code != 200) {
              return
            }
            this.toggleLoading()
            this.personInCharges = response.data.content
            
          })
          .catch((err) => {
            this.toggleLoading()
            console.log(err);
          });
      },
    }
  };
  </script>
  