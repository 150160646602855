<template>
  <main class="main-content main-content-bg mt-0">
    <div
      class="page-header min-vh-100"
      style="
        background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-basic.jpg');
      "
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10 col-md-7 mt-2">
            <div class="card border-0 mb-0" style="text-align: center;">
              <div class="card-header bg-transparent">
                <img
                  :src="logoWhite"
                  class="navbar-brand-img h-100"
                  alt="main_logo"
                />
                <h5 class="text-dark text-center mt-2 mb-3">
                  Term Condition
                </h5>
              </div>
              <div class="card-body px-lg-5 pt-0" style="text-align: left;">
                <div class="text-center text-muted mb-4">
                  <!-- <small>Content</small> -->
                </div>
                <div class="mb-3">
                    <ol style="list-style-type:none;">
<li>SYARAT DAN KETENTUAN</li>
<li>Dengan mengunjungi, menggunakan dan/atau mendaftar di Website Darlink (sebagaimana didefinisikan di bawah), Anda (atau "Pengguna") setuju untuk mematuhi dan terikat oleh Syarat dan Ketentuan ini.</li>
<li>Harap baca Syarat dan Ketentuan ini dengan cermat karena berisi informasi penting tentang hak dan kewajiban hukum Anda.</li>
<li>Syarat dan Ketentuan ini ("Syarat dan Ketentuan") merupakan klausula baku yang mengikat secara hukum antara Anda dan PT Langgeng Warna Utama ("Darlink") yang mengatur akses Anda ke dan penggunaan situs Darlink, termasuk subdomainnya, dan situs lain yang melaluinya Darlink menyediakan layanannya (secara kolektif, "Website Darlink").</li>
<li>Pengumpulan dan penggunaan informasi pribadi kami sehubungan dengan akses Anda ke dan penggunaan Website Darlink dijelaskan dalam Kebijakan Privasi kami.</li>
<li>1.UMUM</li>
<li>1.Anda memiliki kebebasan penuh untuk memilih menggunakan Website Darlink atau tidak, menggunakan layanan yang tersedia pada Website Darlink atau tidak, atau berhenti menggunakan Website Darlink.</li>
<li>2.Anda setuju bahwa Anda diberikan akses dan waktu yang cukup untuk membaca Syarat dan Ketentuan ini, atau untuk mencari nasihat hukum tentang kebijakan, aturan, undang-undang atau peraturan yang berlaku yang mungkin relevan bagi Anda dalam menggunakan Website Darlink. Anda setuju bahwa Anda tidak bergantung pada pernyataan hukum atau fakta apa pun yang dibuat oleh Darlink terkait dengan Website Darlink.</li>
<li>3.Anda bertanggung jawab penuh atas pilihan dan tindakan Anda sebelum, selama dan setelah partisipasi Anda dalam penggunaan layanan yang disediakan oleh Darlink.</li>
<li>2.CAKUPAN LAYANAN DARLINK</li>
<li>Darlink adalah platform web e-commerce dan online marketplace yang mendukung layanan agent dalam melakukan pemasaran produk pembiayaan ("Produk Darlink") dengan cara memfasilitasi dan/atau menghubungkan interaksi antara Lembaga pembiayaan dengan calon konsumen.</li>
<li>Produk Darlink tersebut dipublikasikan pada Aplikasi Darlink dengan menawarkan layanan Darlink kepada Pengguna, antara lain:</li>
<li>Pemasaran produk pembiayaan melalui Aplikasi Darlink</li>
<li>Aplikasi Darlink memberikan layanan pemasaran produk pembiayaan yang mana calon konsumen dapat bertanya kepada agent untuk mengetahui berapa kisaran  pencairan unit yang akan diajukan kemudian ASO Central akan mendistribusikan orderan ke Lembaga pembiayaan</li>
<li>Cek kisaran pencairan oleh tim kami dilakukan dengan cara pemeriksaan kendaraan (unit, tahun dan wilayah) dan pengambilan foto kendaraan untuk memberi penilaian terhadap kendaraan yang akan diajukan melalui layanan Darlink.</li>
<li>Website Darlink dapat berisi tautan ke situs atau sumber daya pihak ketiga ("Layanan Pihak Ketiga"). Layanan Pihak Ketiga tersebut mungkin tunduk pada syarat dan ketentuan serta kebijakan privasi yang berbeda. Darlink tidak bertanggung jawab atau berkewajiban atas ketersediaan atau keakuratan Layanan Pihak Ketiga tersebut, atau konten, produk, atau layanan yang tersedia dari Layanan Pihak Ketiga tersebut. Tautan ke Layanan Pihak Ketiga tersebut bukan merupakan dukungan Darlink atas Layanan Pihak Ketiga tersebut.</li>
<li>Karena sifat internet, Darlink tidak dapat menjamin ketersediaan dan aksesibilitas Website Darlink yang berkelanjutan dan tidak terputus. Darlink dapat membatasi ketersediaan Website Darlink atau area atau fitur tertentu, jika ini diperlukan mengingat batas kapasitas, keamanan atau integritas server kami, atau untuk melakukan tindakan pemeliharaan yang memastikan berfungsinya Darlink dengan baik atau lebih baik. Darlink dapat meningkatkan dan memodifikasi Website Darlink dan memperkenalkan layanan Darlink baru dari waktu ke waktu.</li>
<li>3.KUALIFIKASI DAN VERIFIKASI PENGGUNA</li>
<li>Sebagai Pengguna Website Darlink, Anda harus berusia minimal 21 tahun agar dapat membuat perjanjian yang mengikat berdasarkan hukum Indonesia yang berlaku untuk mengakses dan menggunakan Website Darlink atau mendaftarkan Akun Darlink. Dengan mengakses atau menggunakan Website Darlink, Anda menyatakan dan menjamin bahwa Anda telah berusia 21 tahun dan memiliki kapasitas dan wewenang hukum untuk tunduk pada Syarat dan Ketentuan ini.</li>
<li>Jika Anda mendaftarkan menggunakan Website Darlink untuk entitas atau badan usaha lainnya, Anda menyatakan dan menjamin bahwa Anda memiliki kecakapan dan wewenang untuk mengikat secara hukum entitas atau badan usaha tersebut dan memberi kami semua dokumen yang menunjukkan kecakapan dan kewenangan hukum Anda.</li>
<li>Darlink dapat mengakses dan menggunakan Website Darlink, atau area atau fitur tertentu dari Website Darlink, seperti menyelesaikan proses verifikasi, memenuhi kriteria kualitas atau kelayakan tertentu, atau memeriksa riwayat pemesanan dan pembatalan Pengguna.</li>
<li>Darlink tidak bertanggung jawab atas konfirmasi identitas Pengguna mana pun. Terlepas dari hal di atas, untuk tujuan transparansi dan pencegahan penipuan, dan sebagaimana diizinkan oleh undang-undang yang berlaku, kami dapat, tetapi tidak berkewajiban untuk (i) meminta Pengguna untuk memberikan bentuk identifikasi pemerintah atau informasi lain atau melakukan pemeriksaan tambahan yang dirancang untuk membantu memverifikasi identitas atau latar belakang Pengguna, (ii) menyaring Pengguna terhadap basis data pihak ketiga atau sumber lain dan meminta laporan dari penyedia layanan, dan (iii) jika kami memiliki informasi yang cukup untuk mengidentifikasi Pengguna, memperoleh laporan dari catatan publik tentang hukuman pidana atau yang setara versi pemeriksaan latar belakang di yurisdiksi setempat Anda (jika tersedia).</li>
<li>4.PERUBAHAN SYARAT DAN KETENTUAN</li>
<li>Darlink berhak untuk mengubah atau memperbarui Syarat dan Ketentuan ini setiap saat untuk mencerminkan umpan balik pelanggan atau perubahan dalam praktik kami. Anda dianjurkan untuk secara berkala meninjau Syarat dan Ketentuan ini untuk pembaruan dan informasi terbaru. Sebelum berlakunya perubahan Syarat dan Ketentuan, kami akan mengumumkan rencana perubahan Syarat dan Ketentuan ini beserta rencana tanggal berlaku atas perubahan tersebut pada Website Darlink.</li>
<li>5.PENDAFTARAN AKUN</li>
<li>Anda harus mendaftarkan akun ("Akun Darlink") untuk mengakses dan menggunakan fitur-fitur tertentu dari Website Darlink, seperti melakukan transaksi atas Produk Darlink. Jika Anda mendaftarkan Akun Darlink untuk perusahaan atau badan hukum lainnya, Anda menyatakan dan menjamin bahwa Anda memiliki wewenang untuk mengikat secara hukum entitas tersebut dan memberi kami semua izin dan lisensi yang diberikan dalam Syarat dan Ketentuan ini.</li>
<li>Anda dapat mendaftarkan Akun Darlink menggunakan alamat email dan membuat kata sandi.</li>
<li>Anda harus memberikan informasi yang akurat, terkini, dan lengkap selama proses pendaftaran dan selalu memperbarui informasi Akun Darlink dan halaman profil Akun Darlink publik Anda.</li>
<li>Anda bertanggung jawab untuk menjaga kerahasiaan dan keamanan kredensial Akun Darlink Anda dan tidak boleh mengungkapkan kredensial Anda kepada pihak ketiga mana pun. Anda harus segera memberi tahu Darlink jika Anda mengetahui atau memiliki alasan untuk mencurigai bahwa kredensial Anda telah hilang, dicuri, disalahgunakan, atau dikompromikan atau jika ada penggunaan Akun Darlink Anda yang sebenarnya atau diduga tidak sah. Anda bertanggung jawab atas setiap dan semua aktivitas yang dilakukan melalui Akun Darlink Anda, kecuali jika aktivitas tersebut tidak diizinkan oleh Anda dan Anda tidak lalai (seperti gagal melaporkan penggunaan yang tidak sah atau hilangnya kredensial Anda).</li>
<li>Darlink dapat mengaktifkan fitur yang memungkinkan Anda memberi wewenang kepada Pengguna lain atau pihak ketiga tertentu untuk mengambil tindakan tertentu yang memengaruhi Akun Darlink Anda. Misalnya, kami dapat mengizinkan Pengguna yang memenuhi syarat atau pihak ketiga tertentu untuk memesan Produk Darlink atas nama Pengguna lain. Fitur-fitur ini tidak mengharuskan Anda membagikan kredensial Anda dengan orang lain. Tidak ada pihak ketiga yang diberi wewenang oleh Darlink untuk meminta kredensial Anda, dan Anda tidak boleh meminta kredensial Pengguna lain.</li>
<li>6.PENGGUNAAN KONTEN</li>
<li>Darlink dapat, atas kebijakannya sendiri, memungkinkan Pengguna untuk mengakses dan melihat konten apa pun yang disediakan oleh Darlink sendiri di atau melalui Website Darlink, termasuk konten Darlink milik sendiri dan konten apa pun yang dilisensikan atau diizinkan untuk digunakan oleh atau melalui Darlink dari pihak ketiga ("Konten Website").</li>
<li>Website Darlink dan Konten Website mungkin secara keseluruhan atau sebagian dilindungi oleh hak cipta, merek dagang, dan/atau hukum dan peraturan lokal, nasional dan internasional yang berlaku. Anda mengakui dan menyetujui bahwa Website Darlink dan Konten Website, termasuk semua hak kekayaan intelektual terkait, adalah milik eksklusif Darlink dan/atau pemberi lisensinya atau memberi wewenang kepada pihak ketiga. Anda tidak akan menggunakan, menyalin, mengadaptasi, memodifikasi, menyiapkan karya turunan dari, mendistribusikan, melisensikan, menjual, mentransfer, menampilkan secara publik, melakukan secara publik, mentransmisikan, menyiarkan, atau mengeksploitasi Website Darlink atau Konten Website. Tidak ada lisensi atau hak yang diberikan kepada Anda secara tersirat atau sebaliknya berdasarkan hak kekayaan intelektual yang dimiliki atau dikendalikan oleh Darlink atau pemberi lisensinya.</li>
<li></li>
<li>7.PENILAIAN DAN ULASAN</li>
<li>Dalam jangka waktu tertentu setelah menyelesaikan pencairan dana tunai, Anda dapat meninggalkan ulasan publik ("Ulasan") dan mengirimkan penilaian bintang ("Penilaian") untuk Produk Darlink yang Anda pilih. Penilaian atau Ulasan mencerminkan pendapat Pengguna individu dan tidak mencerminkan pendapat Darlink. Akurasi atas Penilaian dan Ulasan tidak diverifikasi oleh Darlink.</li>
<li>Penilaian dan Ulasan oleh Pengguna harus bersifat akurat dan tidak boleh mengandung bahasa yang menyinggung atau mencemarkan nama baik.</li>
<li>Pengguna dilarang memanipulasi sistem Penilaian dan Ulasan dengan cara apa pun, seperti memerintahkan pihak ketiga untuk menulis Ulasan positif atau negatif pada Website Darlink.</li>
<li>8.PENGAKHIRAN DAN PENANGGUHAN</li>
<li>Darlink berhak, tetapi tidak berkewajiban, untuk memantau Website Darlink, untuk menentukan kepatuhan terhadap Syarat dan Ketentuan ini dan setiap aturan pengoperasian lainnya yang ditetapkan oleh Darlink.</li>
<li>Darlink berhak dan atas kebijakannya sendiri dan mutlak, untuk menghentikan, menangguhkan, atau menghentikan layanan apa pun yang ditawarkan oleh atau melalui Website Darlink serta Website Darlink kapan saja.</li>
<li>Semua ketentuan dalam Syarat dan Ketentuan ini menurut sifatnya akan tetap berlaku setelah pengakhiran, termasuk, tanpa batasan, ketentuan kepemilikan, ganti rugi, dan batasan tanggung jawab.</li>
<li></li>
<li></li>
<li>9.BATASAN TANGGUNG JAWAB</li>
<li>a.Dengan menggunakan Website Darlink, Anda sepenuhnya melepaskan, membela (atas pilihan Darlink), mengganti kerugian dan menahan Darlink dan afiliasi dan anak perusahaannya, termasuk namun tidak terbatas pada pejabat, direktur, karyawan dan agen mereka, tidak berbahaya dari dan terhadap setiap klaim, kewajiban, tuntutan hukum (perdata atau pidana), tuntutan ganti rugi, kerugian, dan pengeluaran (langsung atau tidak langsung), termasuk, tanpa batasan, biaya hukum, pajak dan akuntansi yang wajar, yang timbul dari atau dengan cara apa pun terkait dengan (i) pelanggaran Anda terhadap Syarat dan Ketentuan ini atau kebijakan atau standar kami, (ii) penggunaan Website Darlink atau Produk Darlink oleh Anda secara tidak benar, atau (iii) pelanggaran Anda terhadap hukum, peraturan atau hak pihak ketiga yang berlaku.</li>
<li>b.Darlink tidak menjamin keakuratan informasi dan keterangan pada Konten Website mengingat Konten Website dapat berupa konten milik Darlink sendiri dan konten apa pun yang diizinkan untuk digunakan oleh atau melalui Darlink dari pihak ketiga.</li>
<li>c.Setiap pencairan harus dilakukan secara langsung ke konsumen atau metode pembayaran lain yang tersedia di Darlink. Darlink tidak bertanggung jawab apabila pencairan tidak dilakukan secara langsung ke metode pembayaran resmi di Darlink.</li>
<li>10.HUKUM YANG MENGATUR DAN PENYELESAIAN SENGKETA</li>
<li>Syarat dan Ketentuan ini dan semua persyaratan khusus dan tambahan lainnya yang mengatur penggunaan atau akses Anda ke Website Darlink akan diatur dan ditafsirkan sesuai dengan hukum Republik Indonesia.</li>
<li>Setiap perselisihan, baik kontraktual atau tidak, yang timbul dari atau sehubungan dengan Syarat dan Ketentuan ini (termasuk setiap pertanyaan mengenai keberadaannya, keabsahan atau penghentiannya) akan diselesaikan bersama secara musyawarah dalam waktu 30 (tiga puluh) hari kalender sejak pemberitahuan perselisihan tersebut.</li>
<li>Jika penyelesaian di atas tidak dapat dicapai dalam waktu 30 (tiga puluh) hari kalender sejak pemberitahuan perselisihan diterima oleh salah satu pihak (yaitu Darlink dan Pengguna), perselisihan akan dirujuk dan akhirnya diselesaikan melalui yurisdiksi kantor kepaniteraan pengadilan Jakarta dan tidak menutup kemungkinan menunjuk Lembaga penyelesaian sengketa lainnya yang telah disepakati.</li>
<li>Syarat dan Ketentuan ini disediakan untuk Anda bahasa Indonesia.</li>
</ol>
                </div>
                <br>
                <br>
                <a 
                  style="font-size: 12px; cursor: pointer;"
                  @click="goToLogin"
                ><u>Back</u></a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </main>
</template>

<script>
// import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonSwitch from "@/components/ArgonSwitch.vue";
// import ArgonButton from "@/components/ArgonButton.vue";
// import axios from "./../../../libs/axios.js";
// import axios from "axios";
import { mapMutations } from "vuex";
import logoWhite from "@/assets/img/darlink.png";

export default {
  name: "SuperadminLogin",
  components: {
    // ArgonInput,
    // ArgonSwitch,
    // ArgonButton
  },
  data() {
    return {
      input: {
        username: "",
        password: ""
      },
      email: "",
      logoWhite,
    };
  },
  created() {
    // var token = window.localStorage.getItem("TOKEN")
    // var type = window.localStorage.getItem("SideMenuType")

    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
  },
  methods: {
    goToLogin() {
      // Use Vue Router to navigate to the Login page
      this.$router.push({ name: 'superadmin.login' });
    },
    verifyRecaptchaAndLogin() {
      // const recaptchaSiteKey = "6LeQnFonAAAAADw7ZcM8kC-qg26J_uCSPIbXZaI7"; // Replace with your site key
      // <!--Darlink-web.rekretgroup.com key-->
      // const recaptchaSiteKey = "6LeQeF0nAAAAAChi9a2CPaG7d9JiE7sZ9qJBhB2R"; // Replace with your site key
      // <!--Darlink.id key-->
      const recaptchaSiteKey = "6LcOWugnAAAAAJSe4THmPn3SYJYhsIdtlh0RqiHA"; // Replace with your site key

      // Execute the reCAPTCHA verification
      window.grecaptcha.execute(recaptchaSiteKey, { action: "login" }).then((token) => {
        this.GoLogin(token);
      });
    },
    ...mapMutations(["toggleDefaultLayout"])
  }
};
</script>
