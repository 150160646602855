<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="mx-auto col-lg-12 col-12">
          <div class="mt-4 card card-body">
            <h6 class="mb-0">Update Profile - Fincoy 1 Jakarta</h6>
            <hr class="my-3 horizontal dark" />
            <label for="projectName" class="form-label">Nama Fincoy Ho</label>
            <input id="projectName" type="text" class="form-control" value="Fincoy 1" readonly/>
            <!-- <div class="mt-4 row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>Status Fincoy</label>
                  <div class="form-check form-switch ms-1">
                    <input
                      id="flexSwitchCheckDefault"
                      v-model="isActive"
                      class="form-check-input"
                      type="checkbox"
                      data-type="warning"
                      data-content="Once a project is made private, you cannot revert it to a public project."
                      data-title="Warning"
                      data-icon="ni ni-bell-55"
                    />
                    <label
                      class="form-check-label"
                      for="flexSwitchCheckDefault"
                    >
                      <span
                        class="badge badge-md"
                        :class="{
                          'badge-danger': !isActive,
                          'badge-success': isActive,
                        }"
                      >
                        {{ (isActive) ? 'AKTIF' : 'NON-AKTIF' }}
                      </span>
                    </label>
                  </div>
                </div>
              </div> 
            </div> -->
            <div class="row">
              <div class="col-6">
                <label for="projectName" class="form-label">Nama PIC</label>
                <input id="projectName" type="text" class="form-control" v-model="picName" />
              </div>
              <div class="col-6">
                <label for="projectName" class="form-label">NIK/NIP</label>
                <input id="projectName" type="text" class="form-control" v-model="personalID" />
              </div>
              <div class="col-6">
                <label for="projectName" class="form-label">Email PIC</label>
                <input id="projectName" type="text" class="form-control" v-model="email" />
              </div>
              <div class="col-6">
                <label for="projectName" class="form-label">Daerah/Lokasi</label>
                <input id="projectName" type="text" class="form-control" v-model="location" />
              </div>
            </div>
            <label class="mt-4">Alamat Kantor</label>
            <div id="editor">
              <p>Hello World!</p>
              <p>Some initial <strong>bold</strong> text</p>
              <p><br /></p>
            </div>
            <div class="mt-4 d-flex justify-content-end">
              <router-link to="./aso-central">
                <button type="button" name="button" class="m-0 btn btn-light">
                  Batal
                </button>
              </router-link>
              <button
                type="button"
                name="button"
                class="m-0 btn bg-gradient-success ms-2"
                @click="submit"
              >

                <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span v-else>Update Data</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
//   import flatPickr from "vue-flatpickr-component";
//   import Dropzone from "dropzone";
  import Choices from "choices.js";
  import Quill from "quill";
  import axios from "axios";
  
  export default {
    name: "FincoyHoNew",
    components: {
    //   flatPickr,
    },
    data() {
      return {
        date: "",
        endDate: "",
        config: {
          allowInput: true,
        },
        isActive: false,
        picName: "",
        personalID: "",
        email: "",
        location: "",
        companyEmail: "",
        address: "",
        department: "",
        editor: "",
        isLoading: false,
      };
    },
    methods: {
      loadData() {
        var token = window.localStorage.getItem("TOKEN")
        axios.get("https://api.darlink.id/v1/fincoy/pic/info", {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          }
        }).then((response) => {
          console.log(response.data);
          this.picName = response.data.content.displayname;
          this.personalID = response.data.content.personal_id;
          this.location = response.data.content.location;
          this.email = response.data.content.email;
          this.isActive = response.data.content.is_active;
          this.companyEmail = response.data.content.company_email;
          this.address = response.data.content.address;
          this.department = response.data.content.department

          // Clear the editor's content
          if (this.editor) {
            this.editor.root.innerHTML = '';
          }

          // Insert the new content if address is not empty
          if (this.address) {
            this.editor.clipboard.dangerouslyPasteHTML(0, this.address);
          }
        }).catch((error) => {
          console.log(error);
        })
      },
      submit() {
        this.isLoading = true;
        var token = window.localStorage.getItem("TOKEN")
        const form = new FormData()
        
        form.append("displayname", this.picName);
        form.append("personal_id", this.personalID);
        form.append("location", this.location);
        form.append("phone", this.phone);
        form.append("company_email", this.companyEmail);
        form.append("address", this.address);
        form.append("department", this.department);

        axios.post("https://api.darlink.id/v1/fincoy/pic/info", form ,{
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          }
        }).then(() => {
          this.isLoading = false;
          this.$swal({
            title: "Berhasil",
            text: "Ubah profil Fincoy berhasil dilakukan",
            icon: "success",
            confirmButtonText: "Tutup",
            confirmButtonColor: "#3085d6",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push({ name: "Fincoy Profile" })
            }
          });
        }).catch((err) => {
          this.isLoading = false;
          console.log(err);
          if (err.response && err.response.data && err.response.data.validation) {
              const validationErrors = err.response.data.validation;
              let errorMessage = "Validation Error:\n";
              validationErrors.forEach((error) => {
                errorMessage += `${error.mesg}\n`;
              });
              this.$swal({
                title: "Validation Error",
                text: errorMessage,
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "#d33",
              });
            } else {
              this.$swal({
                title: "Error",
                text: err.response.data.message,
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "#d33",
              });
            }
        })
      }
    }
    ,
    beforeMount() {
      if (localStorage.getItem("SideMenuType") !== "Fincoy") {
          this.$router.go(-1);
      }
    },
    mounted() {
      this.loadData();
      if (document.getElementById("editor")) {
        this.editor = new Quill("#editor", {
          theme: "snow", // Specify theme in configuration
        });
      }

      // Listen for text change events
      this.editor.on("text-change", () => {
        this.address = this.editor.root.innerHTML;
      });
  
      if (document.getElementById("choices-category")) {
        var element = document.getElementById("choices-category");
        new Choices(element, {
          searchEnabled: false,
          allowHTML: true,
          itemSelectText: '',
        });
      }
  
      if (document.getElementById("choices-aso")) {
        var element1 = document.getElementById("choices-aso");
        new Choices(element1, {
          searchEnabled: false,
          allowHTML: true,
          itemSelectText: '',
          position: 'auto',
        });
      }
    },
  };
  </script>
  