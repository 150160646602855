<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Pencairan Dana ke User</h5>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <label for="projectName" class="form-label">Dari Tgl</label>
                <argon-input type="date" placeholder="Date" value="2023-11-23" />
              </div>
              <div class="col-md-2">
                <label for="projectName" class="form-label">Sampai Tgl</label>
                <argon-input type="date" placeholder="Date" value="2023-11-23" />
              </div>
              <div class="col-md-3">
                <label for="projectName" class="form-label">Aso Cabang</label>
                <select
                  id="choices-aso"
                  class="form-control"
                  name="choices-aso"
                >
                  <option value="Choice 1" selected="">ASO Cabang Jakarta</option>
                  <option value="Choice 2">ASO Cabang Bandung</option>
                  <option value="Choice 3">ASO Cabang Bekasi</option>
                  <option value="Choice 4">ASO Cabang Medan</option>
                  <option value="Choice 5">ASO Cabang Bogor</option>
                </select>
              </div>
              <div class="col-md-2">
                <label for="projectName" class="form-label">Status Client</label>
                <select
                  id="choices-aso"
                  class="form-control"
                  name="choices-aso"
                >
                  <option value="Choice 1" selected="">All</option>
                  <option value="Choice 2">Low</option>
                  <option value="Choice 3">High</option>
                </select>
              </div>
              <div class="col-md-5">
                <label for="projectName" class="form-label">Action</label>
                <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <a
                    href="#"
                    class="mb-0 btn bg-gradient-success btn-sm"
                    >+&nbsp; Filter</a
                  >
                  <button
                    type="button"
                    class="mx-1 mb-0 btn btn-outline-success btn-sm"
                  >
                    Riset
                  </button>
                </div>
              </div>
              </div>
            </div>
          </div>
          
          <div class="px-0 pb-0 card-body pt-0">
            <div class="table-responsive">
              <table id="products-list" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Aso Cabang</th>
                    <th>Nama Client</th>
                    <th>T. Pinjaman</th>
                    <th>Nama Kendaraan</th>
                    <th>Status Client</th>
                    <th>Fincoy Pic</th>
                    <th>Fee ASO Cabang</th>
                    <th>Alasan Reject</th>
                    <th>Rejected Date</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <td>
                    <div class="d-flex align-items-center">
                    <argon-checkbox />
                        <p class="text-xs font-weight-bold ms-2 mb-0">ASO Cabang Jakarta</p>
                    </div>
                  </td>
                  <!-- <tr>
                    <td>
                        <div class="d-flex align-items-center">
                        <argon-checkbox />
                            <p class="text-xs font-weight-bold ms-2 mb-0">ASO Cabang Jakarta</p>
                        </div>
                    </td>
                    <td class="text-sm">Herman</td>
                    <td class="text-sm">
                      <div class="d-flex">
                        <h6 class="my-auto">Rp.100.000.000</h6>
                      </div>
                    </td>
                    <td class="text-sm">Yamaha nMax</td>
                    <td class="text-sm"><span class="badge badge-info badge-sm">Low</span></td>
                    <td class="text-sm">-</td>
                    <td class="text-sm">-</td>
                    <td class="text-sm">1.000.000</td>
                    <td class="text-sm">100.000</td>
                    <td><span class="badge badge-success badge-sm">Approve</span></td>
                    <td class="text-sm">
                      <a
                        href="javascript:;"
                        data-bs-original-title="Preview product"
                        data-bs-toggle="modal"
                        data-bs-target="#view-aso"
                      >
                        <i class="fas fa-eye text-secondary"></i>
                      </a>
                    </td>
                  </tr> -->
                  
                </tbody>
                <tfoot>
                  <tr>
                    <th>Aso Cabang</th>
                    <th>Nama Client</th>
                    <th>T. Pinjaman</th>
                    <th>Nama Kendaraan</th>
                    <th>Status Client</th>
                    <th>Fincoy Ho</th>
                    <th>Fincoy Pic</th>
                    <th>Total Fee</th>
                    <th>Fee Aso Cabang</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- button edit modal -->    
    <div
      id="view-aso"
      class="modal fade"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-lg-10">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="ModalLabel" class="modal-title">
              ASO Cabang  Jakarta
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div>
              <h6>ASO Cabang  Jakarta</h6>
              <p class="text-sm mb-0">
                Nama PIC
                <b>Herman</b> Nomor HP
                <b>1234567890</b>
              </p>
              <p class="text-sm">
                NIK/NPK :
                <b>243598234</b>
              </p>

              <p class="text-sm">
                Jumlah Anggota :
                <b>32</b>
              </p>
            </div>
            <p>Jalan Alamat nya/Lokasi.</p>

            <div>
              <p class="text-sm mb-0">
                Status  
                <span class="badge badge-success badge-sm">Aktif</span>
              </p>
              <p class="text-sm">
                NIK/NPK :
                <b>243598234</b>
              </p>
            </div>
            
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn bg-gradient-secondary btn-sm"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End button edit modal -->

    <div
      id="view-fincoy"
      class="modal fade"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-lg-10">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="ModalLabel" class="modal-title">
              Fincoy PIC
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="px-0 pb-0 card-body pt-0">
                <div class="table-responsive">
                    <table id="products-list" class="table table-flush">
                        <thead class="thead-light">
                        <tr>
                            <th>Fincoy HO</th>
                            <th>Fincoy PIC</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center">
                                    <argon-checkbox />
                                        <p class="text-xs font-weight-bold ms-2 mb-0">Fincoy HO A</p>
                                    </div>
                                </td>
                                <td class="text-sm">Herman</td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center">
                                    <argon-checkbox />
                                        <p class="text-xs font-weight-bold ms-2 mb-0">Fincoy HO B</p>
                                    </div>
                                </td>
                                <td class="text-sm">Lucy</td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center">
                                    <argon-checkbox />
                                        <p class="text-xs font-weight-bold ms-2 mb-0">Fincoy HO C</p>
                                    </div>
                                </td>
                                <td class="text-sm">Anton</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn bg-gradient-primary btn-sm"
              data-bs-dismiss="modal"
            >
              Assign
            </button>
            <button
              type="button"
              class="btn bg-gradient-secondary btn-sm"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import { DataTable } from "simple-datatables";
  import setTooltip from "@/assets/js/tooltip.js";
  import ArgonInput from "@/components/ArgonInput.vue";
  import ArgonCheckbox from "@/components/ArgonCheckbox.vue";
  import axios from 'axios';
  var columns = [
    {
      select: 0,
      render: function (data) {
        return `<div class="d-flex">
            <h6 class="my-auto ms-3">${data}</h6>
          </div>
        `
      }
    },
    {
      select: [1,2,3,4,5,6,7,9],
      render: function (data) {
        return `<span class="text-sm">${data}</span>
        `
      }
    },
    // row 2 text right
    {
      select: 2,
      render: function (data) {
        return `<span class="text-sm text-right">${data}</span>
        `
      }
    },
    {
      select: 4,
      render: function (data) {
        // make badge color based on status value if high then use badge danger, if low use badge info
        // let status high = badge-danger, status low = badge-info
        let status = (data === 'LOW') ? "badge-info" :  "badge-danger"
        return `
        <span class="badge ${status} badge-sm">
          ${data}
        </span>
        `
      },
    },
    // row 8 text align right and add tooltip
    {
      select: 8,
      render: function (data) {
        return `
        <span class="text-sm text-right" data-bs-toggle="tooltip" data-bs-placement="top" title="${data}">
          ${data}
        </span>
        `
      },
    },
    {
      select: 9,
      render: function (data) {
        // if have rejected_by then show badge danger, if not show badge success
        let status = (data === 1) ?   "badge-danger" : "badge-success" 
        return `
        <span class="badge ${status} badge-sm">
         Pencairan Dana ke Darlink
        </span>
        `
      },
    },
    {
      select: 10,
      render: function (data) {
        return `
          <!-- button edit modal -->
          <a
          class="text-sm"
          href="javascript: void(0);"
        >
          <span
            class="mx-3"
          >
            <i
             class="fas fa-eye text-secondary data-bs-toggle="tooltip" data-bs-placement="top" title="Detail"
              data-action="edit"
              data-id="${data}"
            ></i>
          </span>
        </a>
          <a
            href="javascript:;"
            class=""
            data-bs-toggle="tooltip"
            data-bs-original-title="Edit product"
          >
            <i class="fas fa-download text-secondary"></i>
          </a>
        `
      }
    },
  ]
  
  var labels = {
    placeholder: "Cari Data...",
    perPage: "Menampilkan {select} ASO Cabang per halaman",
    noRows: "Tidak ada Data yang ditampilkan",
    info: "Menampilkan {start} ke {end} dari {rows} ASO Cabang (Halaman {page} dari {pages} halaman)"
  }
  
  export default {
    name: "AsoCentral",
    components: {
      ArgonInput,
      ArgonCheckbox,
    },
    beforeMount() {
      if (localStorage.getItem("SideMenuType") !== "Fincoy") {
          this.$router.go(-1);
      }
    },
    mounted() {
      if (document.getElementById("products-list")) {
        this.datatable = new DataTable("#products-list", {
          searchable: true,
          fixedHeight: false,
          perPage: 15,
          columns: columns,
          labels: labels
        });
        this.datatable.body.addEventListener("click", this.handleAction)
        this.toggleLoading()
        this.loadOrderList()
      }
      setTooltip(this.$store.state.bootstrap);
    },
    beforeUnmount() {
      this.datatable.destroy()
      this.datatable = null
    },
    methods: {
      toggleLoading() {
        if (!this.datatable) {
          return
        }
        if (!this.isLoading) {
          this.isLoading = true
          this.datatable.setMessage("Memuat...")
          return
        }
        this.isLoading = false
      },
      
      handleAction(e) {
        if (e.target.getAttribute("data-action") === "edit") {
          let id = parseInt(e.target.getAttribute("data-id"), 10)
          this.edit(id)
        }
      },
      edit(id) {
        this.$router.push({
          name: "Fincoy Order Pencairan Dana Detail",
          params: {
            orderList: id
          }
        })
      },
      loadOrderList() {
        var token = window.localStorage.getItem("TOKEN")
        axios
          .get("https://api.darlink.id/v1/order?status=funded_platform", {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token
              // Authorization: "TEST_FINCOY_PIC123456789"
            }
          })
          .then((response) => {
            if (!this.datatable) {
              return
            }
            this.toggleLoading()
            if (response.data.code != 200) {
              return
            }
            if (response.data.tag === "content.order.empty") {
              this.datatable.setMessage("Tidak ada data yang ditampilkan")
              return
            }
            this.orderLists = response.data.content
            this.datatable.insert({
              data: this.orderLists.map((orderList) => {
                return [
                  // format number id 
                  // orderList.aso_branch_id.toLocaleString("id-ID", {
                  //   minimumIntegerDigits: 5,
                  //   useGrouping: false,
                  // }),
                  orderList.aso_branch.displayname,
                  orderList.client_name,
                  // orderList.total_loan,
                  orderList.total_loan.toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 2,
                  }),
                  orderList.leased_item_name,
                  orderList.client_status,
                  orderList.client_status,
                  // orderList.total_fee,
                  // format number with 2 decimal IDR format 
                  orderList.aso_central_fee.toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 2,
                  }),
                  orderList.reason,
                  // format date with time 
                  new Date(orderList.rejected_at).toLocaleString("id-ID", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                  }),
                  orderList.confirmed_by,
                  `${orderList.id}`
                ]
              })
            })
          })
          .catch((err) => {
            this.toggleLoading()
            console.log(err);
          });
      },
    }
  };
  </script>
  