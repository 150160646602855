<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="mx-auto col-lg-9 col-12">
          <div class="mt-4 card card-body">
            <h6 class="mb-0">Edit Staff</h6>
            <hr class="my-3 horizontal dark" />
  
            <div class="mt-4 row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>Status Staff</label>
                  <div class="form-check form-switch ms-1">
                    <input
                      id="flexSwitchCheckDefault"
                      v-model="isActive"
                      class="form-check-input"
                      type="checkbox"
                      data-type="warning"
                      data-content="Once a project is made private, you cannot revert it to a public project."
                      data-title="Warning"
                      data-icon="ni ni-bell-55"
                    />
                    <label
                      class="form-check-label"
                      for="flexSwitchCheckDefault"
                    >
                      <span
                        class="badge badge-md"
                        :class="{
                          'badge-danger': !isActive,
                          'badge-success': isActive,
                        }"
                      >
                        {{ (isActive) ? 'AKTIF' : 'NON-AKTIF' }}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
  
              <div class="row">
                  <div class="col-6">
                  <label for="projectName" class="form-label">Username</label>
                  <input id="projectName" v-model="username" type="text" class="form-control" disabled />
                  </div>
                  <div class="col-6">
                  <label for="projectName" class="form-label">Password</label>
                  <input id="projectName" v-model="password" type="password" class="form-control" />
                  </div>
                  <div class="col-6">
                  <label for="projectName" class="form-label">Nama Staff</label>
                  <input id="projectName" v-model="displayname" type="text" class="form-control" />
                  </div>
                  <div class="col-6">
                  <label for="projectName" class="form-label">Nomor HP Staff</label>
                  <input id="projectName" v-model="phonenumber" type="text" class="form-control" />
                  </div>
                  <div class="col-6">
                  <label for="projectName" class="form-label">Email</label>
                  <input id="projectName" v-model="email" type="text" class="form-control" />
                  </div>
                  <div class="col-6">
                  <label for="projectName" class="form-label">NIK/NPK</label>
                  <input id="projectName" v-model="personal_id" type="text" class="form-control" />
                  </div>
                  <div class="col-md-6">
                  <label for="projectName" class="form-label">Role </label>
                  <select
                    id="choices-aso-cabang"
                    v-model="selectedRole"
                    class="form-control"
                    name="choices-aso-cabang"
                  >
                    <option
                      v-for="(role, index) in roles"
                      :key="index"
                      :value="role.id"
                      selected=""
                    >
                      {{ role.name }}
                    </option>
                  </select>
                </div>
  
                  <div class="mt-4 d-flex justify-content-end">
                      <router-link to="./staff">
                          <button type="button" name="button" class="m-0 btn btn-light">
                              Batal
                          </button>
                      </router-link>
                      <button
                          type="submit"
                          name="button"
                          class="m-0 btn bg-gradient-success ms-2"
                          @click="submit()"
                      >
                          Edit Admin Staff
                      </button>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  // import flatPickr from "vue-flatpickr-component";
  // import Dropzone from "dropzone";
  import Choices from "choices.js";
  import axios from 'axios';
  
  export default {
    name: "NewStaff",
    components: {
      // flatPickr,
    },
    data() {
      return {
        selectedRole: [],
        roles: [],
        isActive: false,
        config: {
          allowInput: true,
        },
        username: "",
        password: "",
        displayname: "",
        phonenumber: "",
        email: "",
        personal_id: "",
      };
    },
    computed: {
        staffId() {
            if (!this.$route.params.staffId) {
                return null
            }
            return this.$route.params.staffId
        }
    },
    beforeMount() {
      if (localStorage.getItem("SideMenuType") !== "SuperAdmin") {
        this.$router.go(-1);
      }
    },
    mounted() {
      if (document.getElementById("choices-multiple-remove-button")) {
        var element = document.getElementById("choices-multiple-remove-button");
        const example = new Choices(element, {
          removeItemButton: true,
          allowHTML: true,
        });
  
        example.setChoices(
          [
            {
              value: "One",
              label: "Label One",
              disabled: true,
            },
            {
              value: "Two",
              label: "Label Two",
              selected: true,
            },
            {
              value: "Three",
              label: "Label Three",
            },
          ],
          "value",
          "label",
          false
        );
      }
      setTimeout(() => {
        this.loadRoles()
      }, 1000);
      
      var token = window.localStorage.getItem("TOKEN");
      if (!this.staffId) {
          return
      }
      axios.get(`https://api.darlink.id/v1/admin/${this.staffId}`, {
          withCredentials: true,
          headers: {
              Accept: "application/json",
              Authorization: token
          }
      }).then((response) => {
          this.username = response.data.content.username;
          this.isActive = response.data.content.is_active;
          this.displayname = response.data.content.displayname;
          this.phonenumber = response.data.content.phone;
          this.email = response.data.content.email;
          this.personal_id = response.data.content.personal_id;
          // this.selectedRoles = response.data.content.permissions;
      }).catch((error) => {
          console.log(error);
      })
      if (localStorage.getItem("SideMenuType") !== "SuperAdmin") {
          this.$router.go(-1);
      }
    },
    methods: {
      loadRoles() {
         var token = window.localStorage.getItem("TOKEN")
         axios
          .get("https://api.darlink.id/v1/admin/role", {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token
            }
          })
          .then((response) => {
            if (response.data.code != 200) {
              return
            }
  
            this.roles = response.data.content;
          })
          .catch((err) => {
            console.log(err);
          });
      },
      submit() {
        var token = window.localStorage.getItem("TOKEN")
  
        const newStaff = new FormData();
        newStaff.append("username", this.username);
        newStaff.append("displayname", this.displayname);
        newStaff.append("phone", this.phonenumber);
        newStaff.append("email", this.email);
        newStaff.append("personal_id", this.personal_id);
        newStaff.append("role_id", this.selectedRole);
        // newStaff.append("is_active", this.isActive);
        newStaff.append("status", (this.isActive) ? 'active' : 'inactive');
  
        axios
          .post(`https://api.darlink.id/v1/admin/${this.staffId}`, newStaff, 
          {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token  
          }
        })
        .then((response) => {
            this.$swal({
              title: "Berhasil",
              text: "Staff berhasil diedit",
              icon: "success",
              confirmButtonText: "Tutup",
              confirmButtonColor: "#3085d6",
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.push({ name: "superadmin.staff" })
              }
            });
            if (response.data.code != 201) {
              return
            }
            // this.$router.push({ name: "superadmin.aso-central" })
            // ROUTER BACK TO LIST ASO CENTRAL
          })
          .catch((err) => {
            console.log(err);
            if (err.response && err.response.data && err.response.data.validation) {
              const validationErrors = err.response.data.validation;
              let errorMessage = "Validation Error:\n";
              validationErrors.forEach((error) => {
                errorMessage += `${error.mesg}\n`;
              });
              this.$swal({
                title: "Validation Error",
                text: errorMessage,
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "#d33",
              });
            } else {
              this.$swal({
                title: "Error",
                text: err.response.message,
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "#d33",
              });
            }
          });
      }
    }
  };
  </script>
  