<template>
	<div class="py-4 container-fluid">
		<div class="row">
			<div class="col-12">
				<div class="card">
					<!-- Card header -->
					<div class="pb-0 card-header">
						<div class="d-lg-flex">
							<div>
								<h5 class="mb-0">Order List Process</h5>
							</div>
						</div>
						<div class="row">
							<div class="col-md-2">
								<label for="projectName" class="form-label">Dari Tgl</label>
								<argon-input type="date" placeholder="Date" v-model="filter.startDate" />
							</div>
							<div class="col-md-2">
								<label for="projectName" class="form-label">Sampai Tgl</label>
								<argon-input type="date" placeholder="Date" v-model="filter.endDate" />
							</div>
							<div class="col-md-5">
								<label for="projectName" class="form-label">Action</label>
								<div class="my-auto mt-4 ms-auto mt-lg-0">
									<div class="my-auto ms-auto">
										<a href="#" class="mb-0 btn bg-gradient-success btn-sm" @click="handleFilter">+&nbsp; Filter</a>
										<button type="button" class="mx-1 mb-0 btn btn-outline-success btn-sm" @click="handleReset">Reset</button>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="px-0 pb-0 card-body pt-0">
						<div class="table-responsive">
							<div class="row">
								<div class="col-lg-4" style="margin: 10px">
									<div class="input-group mb-3">
										<input type="text" class="form-control" v-model="searchQuery" placeholder="Search ..." />
									</div>

									<a href="#" class="mb-0 btn bg-gradient-success btn-sm" style="margin-right: 10px" @click="performSearch"
										>+&nbsp; search</a
									>
								</div>
							</div>

							<table id="products-list" class="table table-flush">
								<thead class="thead-light">
									<tr>
										<th>Nama Client</th>
										<th>Tenor</th>
										<th>T. Pinjaman</th>
										<th>Nama Kendaraan</th>
										<th>Status</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
								</tbody>
								<tfoot>
									<tr>
										<th>Aso Cabang</th>
										<th>Tenor</th>
										<th>Nama Client</th>
										<th>T. Pinjaman</th>
										<th>Nama Kendaraan</th>
										<th>Status Client</th>
										<th>Status</th>
										<th>Action</th>
									</tr>
								</tfoot>
							</table>

							<!-- DataTable's built-in pagination -->
							<nav aria-label="Page navigation">
								<ul class="pagination justify-content-end">
									<li class="page-item" :class="{ disabled: page === 1 }">
										<a class="page-link" href="javascript:void(0);" @click="loadPage(page - 1)"> Prev </a>
									</li>
									<li class="page-item" v-for="pageNumber in totalPages" :key="pageNumber" :class="{ active: pageNumber === page }">
										<a class="page-link" href="javascript:void(0);" @click="loadPage(pageNumber)">
											{{ pageNumber }}
										</a>
									</li>
									<li class="page-item" :class="{ disabled: page === totalPages }">
										<a class="page-link" href="javascript:void(0);" @click="loadPage(page + 1)"> Next </a>
									</li>
								</ul>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- button edit modal -->
		<div id="view-aso" class="modal fade" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog modal-lg mt-lg-10">
				<div class="modal-content">
					<div class="modal-header">
						<h5 id="ModalLabel" class="modal-title">ASO Cabang Jakarta</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div>
							<h6>ASO Cabang Jakarta</h6>
							<p class="text-sm mb-0">
								Nama PIC
								<b>Herman</b> Nomor HP
								<b>1234567890</b>
							</p>
							<p class="text-sm">
								NIK/NPK :
								<b>243598234</b>
							</p>

							<p class="text-sm">
								Jumlah Anggota :
								<b>32</b>
							</p>
						</div>
						<p>Jalan Alamat nya/Lokasi.</p>

						<div>
							<p class="text-sm mb-0">
								Status
								<span class="badge badge-success badge-sm">Aktif</span>
							</p>
							<p class="text-sm">
								NIK/NPK :
								<b>243598234</b>
							</p>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn bg-gradient-secondary btn-sm" data-bs-dismiss="modal">Close</button>
					</div>
				</div>
			</div>
		</div>
		<!-- End button edit modal -->

		<div id="view-fincoy" class="modal fade" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog modal-lg mt-lg-10">
				<div class="modal-content">
					<div class="modal-header">
						<h5 id="ModalLabel" class="modal-title">Fincoy PIC</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="px-0 pb-0 card-body pt-0">
							<div class="table-responsive">
								<table id="products-list" class="table table-flush">
									<thead class="thead-light">
										<tr>
											<th>Fincoy HO</th>
											<th>Fincoy PIC</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<div class="d-flex align-items-center">
													<argon-checkbox />
													<p class="text-xs font-weight-bold ms-2 mb-0">Fincoy HO A</p>
												</div>
											</td>
											<td class="text-sm">Herman</td>
										</tr>
										<tr>
											<td>
												<div class="d-flex align-items-center">
													<argon-checkbox />
													<p class="text-xs font-weight-bold ms-2 mb-0">Fincoy HO B</p>
												</div>
											</td>
											<td class="text-sm">Lucy</td>
										</tr>
										<tr>
											<td>
												<div class="d-flex align-items-center">
													<argon-checkbox />
													<p class="text-xs font-weight-bold ms-2 mb-0">Fincoy HO C</p>
												</div>
											</td>
											<td class="text-sm">Anton</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn bg-gradient-primary btn-sm" data-bs-dismiss="modal">Assign</button>
						<button type="button" class="btn bg-gradient-secondary btn-sm" data-bs-dismiss="modal">Close</button>
					</div>
				</div>
			</div>
		</div>
		<div id="view-approved" class="modal fade" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog modal-lg mt-lg-10">
				<div class="modal-content">
					<div class="modal-header">
						<h5 id="ModalLabel" class="modal-title">Approved Order</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="modal-body">
							<p>Masukkan Jumlah Nominal Pencairan</p>
							<div class="input-group mb-3">
								<span class="input-group-text" id="basic-addon1">Rp</span>
								<input v-model="reason" @input="formatNumber" type="text" placeholder="Keterangan" class="form-control" />
							</div>
							<!-- TENOR -->
							<p>Pilih tenor</p>
							<div class="d-flex gap-3 align-items-center">
								<select
									:value="this.changeSelect || this.tenor"
									class="form-select"
									aria-label="Default select example"
									@change="handleSelectTenor"
									:disabled="this.changeInput ? true : false"
								>
									<option selected value="" disabled>Pilih tenor</option>
									<option value="12">12</option>
									<option value="18">18</option>
									<option value="24">24</option>
									<option value="36">36</option>
									<option value="48">48</option>
									<option value="60">60</option>
								</select>
								<div class="text-secondary">atau</div>
								<input
									type="text"
									:value="this.changeInput || this.tenor"
									placeholder="Atau masukan jumlah tenor disini"
									class="form-control"
									@change="handleInputTenor"
									:disabled="this.changeSelect ? true : false"
								/>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							type="button"
							class="btn bg-gradient-secondary btn-sm"
							data-bs-dismiss="modal"
							id="dismiss-approved"
							@click="handleClose"
						>
							Close
						</button>
						<button @click="approveOrder" type="button" class="btn bg-gradient-success btn-sm">Approve Order</button>
					</div>
				</div>
			</div>
		</div>
		<!-- End button edit modal -->

		<!-- button edit modal -->
		<div id="view-reject" class="modal fade" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog modal-lg mt-lg-10">
				<div class="modal-content">
					<div class="modal-header">
						<h5 id="ModalLabel" class="modal-title">Reject Order</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="modal-body">
							<p>Masukan Keterangan di bawah ini</p>
							<select id="choices-reject" class="form-control" name="choices-reject">
								<option value="cancel" selected="">Cancel</option>
								<option value="reject">Reject</option>
							</select>
							<br />
							<input v-model="reason" type="text" placeholder="Keterangan" class="mb-3 form-control" />
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn bg-gradient-secondary btn-sm" data-bs-dismiss="modal">Close</button>
						<button @click="rejectOrder" type="button" class="btn bg-gradient-danger btn-sm">Reject Order</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { DataTable } from "simple-datatables";
import setTooltip from "@/assets/js/tooltip.js";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonCheckbox from "@/components/ArgonCheckbox.vue";
import axios from "axios";
var columns = [
	{
		select: 0,
		render: function (data) {
			return `<div class="d-flex">
            <h6 class="my-auto ms-3">${data}</h6>
          </div>
        `;
		},
	},
	{
		select: [1, 2, 3, 4, 5, 6, 7, 9],
		render: function (data) {
			return `<span class="text-sm">${data}</span>
        `;
		},
	},
	// row 2 text right
	{
		select: 2,
		render: function (data) {
			return `<span class="text-sm text-right">${data}</span>
        `;
		},
	},
	{
		select: 4,
		render: function () {
			return `
        <span class="badge badge-primary badge-sm">
          PROCESS
        </span>
        `;
		},
	},
	{
		select: 8,
		render: function (data) {
			return `
        <span class="text-sm text-right" data-bs-toggle="tooltip" data-bs-placement="top" title="${data}">
          ${data}
        </span>
        `;
		},
	},
	{
		select: 5,
		render: function (data) {
			return `
        <!-- button edit modal -->
          <a
            class="text-sm"
            href="javascript: void(0);"
          >
            <span
              class="mx-3"
            >
              <i
              class="fas fa-eye text-secondary data-bs-toggle="tooltip" data-bs-placement="top" title="Detail"
                data-action="edit"
                data-id="${data}"
              ></i>
            </span>
          </a>
          <!-- End button edit modal -->
          <!-- <a
            href="javascript:;"
            class="mx-3"
            data-bs-toggle="tooltip"
            data-bs-original-title="Edit product"
          >
            <i class="fas fa-download text-secondary"></i>
          </a> -->
          <a
            href="javascript:;"
            class="mx-3"
          >
            <i
              data-bs-target="#view-approved"
              data-bs-toggle="modal"
              data-id=${data}
              data-action="approve"
              class="fas fa-check text-secondary text-success"></i>
          </a>
          <a
            href="javascript:;"
            class="mx-3"
          >
            <i
              data-bs-target="#view-reject"
              data-bs-toggle="modal"
              data-id=${data}
              data-action="reject"
              class="fas fa-times text-secondary text-warning"></i>
          </a>
        `;
		},
	},
];

var labels = {
	placeholder: "Cari Data...",
	perPage: "Menampilkan {select} ASO Cabang per halaman",
	noRows: "Tidak ada Data yang ditampilkan",
	info: "Menampilkan {start} ke {end} dari {rows} ASO Cabang (Halaman {page} dari {pages} halaman)",
};

export default {
	name: "AsoCentral",
	components: {
		ArgonInput,
		ArgonCheckbox,
	},
	data() {
		return {
			reason: "",
			asoBranch: [],
			filter: {
				startDate: "",
				endDate: "",
				asoBranch: "",
			},
			page: 1,
			totalPages: 1,
			searchQuery: "",
			tenor: "",
			changeInput: "",
			changeSelect: "",
		};
	},
	mounted() {
		if (document.getElementById("products-list")) {
			this.datatable = new DataTable("#products-list", {
				processing: this.isLoading,
				searchable: false,
				fixedHeight: false,
				perPage: 16,
				columns: columns,
				labels: labels,
				paging: false,
				filters: {
					search: this.searchQuery,
				},
			});
			this.toggleLoading();
			this.loadOrderList();
		}
		this.datatable.body.addEventListener("click", this.handleAction);
		setTooltip(this.$store.state.bootstrap);
	},
	beforeUnmount() {
		this.datatable.destroy();
		this.datatable = null;
	},
	methods: {
		formatNumber(event) {
			let value = event.target.value.replace(/\D/g, "");
			this.reason = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},
		handleClose() {
			this.changeInput = "";
			this.changeSelect = "";
			this.reason = "";
		},
		handleSelectTenor(e) {
			this.changeSelect = e.target.value;
		},
		handleInputTenor(e) {
			this.changeInput = e.target.value;
		},
		formatCurrencyInput(value) {
			if (typeof value !== "number") {
				value = parseFloat(value.replace(/[^0-9.-]+/g, "")) || 0;
			}

			// Format the input value as currency
			const formattedValue = value.toLocaleString("en-ID", {
				style: "currency",
				currency: "IDR", // Change to your desired currency code
			});

        // Update the commission field with the formatted value
        this.reason = formattedValue;
      },
      toggleLoading() {
        if (!this.datatable) {
          return
        }
        if (!this.isLoading) {
          this.isLoading = true
          this.datatable.setMessage("Memuat...")
          return
        }
        this.isLoading = false
      },
      handleFilter() {
        if (this.datatable) {
          this.destroyDataTable(); // Destroy the current DataTable instance
        }
        
        if (document.getElementById("products-list")) {
          this.datatable = new DataTable("#products-list", {
            processing: this.isLoading,
            searchable: false,
            fixedHeight: false,
            perPage: 16,
            columns: columns,
            labels: labels,
            paging: false,
            filters: {
              // Add the search filter
              search: this.searchQuery,
            },
          });
          this.datatable.body.addEventListener("click", this.handleAction)
          this.toggleLoading()
          this.loadOrderList(this.filter)
        }
      },
      handleReset() {
        if (this.datatable) {
          this.destroyDataTable(); // Destroy the current DataTable instance
        }
        
        if (document.getElementById("products-list")) {
          this.datatable = new DataTable("#products-list", {
            processing: this.isLoading,
            searchable: false,
            fixedHeight: false,
            perPage: 16,
            columns: columns,
            labels: labels,
            paging: false,
            filters: {
              // Add the search filter
              search: this.searchQuery,
            },
          });
          this.datatable.body.addEventListener("click", this.handleAction)
          this.toggleLoading()
          this.loadOrderList()
        }
      },
      handleAction(e) {
        if (e.target.getAttribute("data-action") === "approve") {
          let id = parseInt(e.target.getAttribute("data-id"), 10)
          this.reason = "";
          this.modalapproveOrder(id)
        }
        if (e.target.getAttribute("data-action") === "reject") {
          let id = parseInt(e.target.getAttribute("data-id"), 10)
          this.reason = "";
          this.modalRejectOrder(id)
        }
        if (e.target.getAttribute("data-action") === "edit") {
          let id = parseInt(e.target.getAttribute("data-id"), 10)
          this.edit(id)
        }
      },
      edit(id) {
        this.$router.push({
          name: "Fincoy Order Proses Detail",
          params: {
            orderList: id
          }
        })
      },
      // open modal approve order
      modalapproveOrder(id){
        var token = window.localStorage.getItem("TOKEN")
        this.$store.dispatch("view-approved", id)
        // make function for button approve order
        this.approveOrder = function(){
          // get token from localstorage
          // var token = window.localStorage.getItem("TOKEN")
          // get reason from input
          var reason = this.reason
          // make data for request
          var data = {
            "reason": reason
          }
          // make request to api
          axios
            .post("https://api.darlink.id/v1/order/"+id+"/approve", data, {
              withCredentials: true,
              headers: {
                Accept: "application/json",
                Authorization: token,
                // Authorization: "TEST_FINCOY_PIC123456789",
                // multipart/form-data
                "Content-Type": "multipart/form-data"
              }
            })
            .then((response) => {
              // if response code not 200
              if (response.data.code != 200) {
                // show error message
                // sweetalert error
                this.$swal({
                  title: "Gagal",
                  text: response.data.message,
                  icon: "error",
                  button: "OK",
                })
                return
              }
              this.$swal({
                title: "Berhasil",
                text: "Berhasil Approve Order",
                icon: "success",
                confirmButtonText: "OK",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload()
                }
              });
              this.$router.push({ name: "Fincoy Order Proses" })
              // reload page after 1000ms
              setTimeout(() => {
                window.location.reload()
              }, 4000)
            })
            .catch((error) => {
              // if error
              // show error message
              this.$store.dispatch("show-error", error)
            })
        }
      },
      modalRejectOrder(id){
        var token = window.localStorage.getItem("TOKEN")
        this.$store.dispatch("view-reject", id)
        // make function for button approve order
        this.rejectOrder = function(){
          // get token from localstorage
          // var token = window.localStorage.getItem("TOKEN")
          // get reason from input
          var reason = this.reason
          // make data for request
          var data = {
            "reason": reason
          }
          // make request to api
          axios
            .post("https://api.darlink.id/v1/order/"+id+"/reject", data, {
              withCredentials: true,
              headers: {
                Accept: "application/json",
                // Authorization: "TEST_FINCOY_PIC123456789",
                Authorization: token,
                // multipart/form-data
                "Content-Type": "multipart/form-data"
              }
            })
            .then((response) => {
              // if response code not 200
              if (response.data.code != 200) {
                // show error message
                // sweetalert error
                this.$swal({
                  title: "Gagal",
                  text: response.data.message,
                  icon: "error",
                  button: "OK",
                })
                return
              }
              // if response code 200
              // sweetalert success
              // this.$swal({
              //   title: "Berhasil Approve Order",
              //   text: response.data.message,
              //   icon: "success",
              //   button: "OK",
              // })
              this.$swal({
                title: "Berhasil",
                text: "Berhasil Reject Order",
                icon: "success",
                confirmButtonText: "OK",
              }).then((result) => {
                if (result.isConfirmed) {
                  // this.$router.push({ name: "Fincoy Order Proses" })
                  window.location.reload()
                }
              });
              this.$router.push({ name: "Fincoy Order Proses" })
              // reload page after 1000ms
              setTimeout(() => {
                window.location.reload()
              }, 4000)
            })
            .catch(() => {
              // if error
              // show error message
              this.$swal({
                  title: "Gagal",
                  text: "error data",
                  icon: "error",
                  button: "OK",
                })
            })
        }
      },      
      performSearch() {
        if (this.datatable) {
          this.destroyDataTable(); // Destroy the current DataTable instance
        }
        
        if (document.getElementById("products-list")) {
          this.datatable = new DataTable("#products-list", {
            processing: this.isLoading,
            searchable: false,
            fixedHeight: false,
            perPage: 16,
            columns: columns,
            labels: labels,
            paging: false,
            filters: {
              // Add the search filter
              search: this.searchQuery,
            },
          });

				this.page = 1;

          this.datatable.body.addEventListener("click", this.handleAction)
          this.toggleLoading()
          if (Object.keys(this.filter).some(key => this.filter[key] !== "")) {
            // Check if any property in the filter object is not empty
            this.loadOrderListFilter(this.filter);
          } else {
            this.loadOrderList();
          }
        }
      },
      loadOrderList(params_filter = {startDate: "", endDate: "", asoBranch: ""}) {
        var token = window.localStorage.getItem("TOKEN")
        axios
          .get(`https://api.darlink.id/v1/order?status=process&start=${params_filter.startDate}&end=${params_filter.endDate}`, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token
              // Authorization: "TEST_FINCOY_PIC123456789"
              // Authorization: "TEST_STAFF123456789"
            },
            params: {
              page: this.page, // Include the page parameter
              search: this.searchQuery, // Add the search query parameter
            },
          })
          .then((response) => {
            if (!this.datatable) {
              return
            }
            this.toggleLoading()
            if (response.data.code != 200) {
              return
            }
            if (response.data.tag === "content.order.empty") {
              this.datatable.setMessage("Tidak ada data yang ditampilkan")
              return
            }
            this.orderLists = response.data.content
            this.totalPages = response.data.pagination.total_pages; // Update total pages

					this.updateDataTable();
				})
				.catch((err) => {
					this.toggleLoading();
					console.log(err);
				});
		},
		destroyDataTable() {
			if (this.datatable) {
				this.datatable.destroy();
				this.datatable = null;
			}
		},
		updateDataTable() {
			// if (this.datatable) {
			//   this.datatable.destroy();
			// }

			// this.datatable = new DataTable("#products-list", {
			//   processing: this.isLoading,
			//   searchable: true,
			//   fixedHeight: false,
			//   perPage: 16,
			//   columns: columns,
			//   labels: labels,
			// });

			// this.datatable.body.addEventListener("click", this.handleAction);
			this.datatable.body.innerHTML = "";
			this.datatable.insert({
				data: this.orderLists.map((orderList) => {
					return [
						// format number id
						// orderList.aso_branch_id.toLocaleString("id-ID", {
						//   minimumIntegerDigits: 5,
						//   useGrouping: false,
						// }),
						// orderList.aso_branch.displayname,
						orderList.client_name,
						orderList.tenor.toLocaleString("id-ID"),
						// orderList.total_loan,
						orderList.total_loan.toLocaleString("id-ID", {
							style: "currency",
							currency: "IDR",
							minimumFractionDigits: 2,
						}),
						orderList.leased_item_name,
						// orderList.client_status,
						orderList.status,
						// orderList.total_fee,
						// format number with 2 decimal IDR format
						`${orderList.id}`,
					];
				}),
			});
		},
		// Function to load data for a specific page
		loadPage(page) {
			if (page < 1 || page > this.totalPages) {
				return;
			}

			this.page = page;
			// if (Object.keys(this.filter).some(key => this.filter[key] !== "")) {
			//   // Check if any property in the filter object is not empty
			//   this.loadOrderListFilter(this.filter);
			// } else {
			// this.loadOrderList(this.filter);
			// }
			if (this.datatable) {
				this.destroyDataTable(); // Destroy the current DataTable instance
			}

			if (document.getElementById("products-list")) {
				this.datatable = new DataTable("#products-list", {
					processing: this.isLoading,
					searchable: false,
					fixedHeight: false,
					perPage: 16,
					columns: columns,
					labels: labels,
					paging: false,
					filters: {
						// Add the search filter
						search: this.searchQuery,
					},
				});
				this.datatable.body.addEventListener("click", this.handleAction);
				this.toggleLoading();

				if (Object.keys(this.filter).some((key) => this.filter[key] !== "")) {
					// Check if any property in the filter object is not empty
					this.loadOrderListFilter(this.filter);
				} else {
					this.loadOrderList();
				}
			}
		},
	},
	formattedNumber: {
		get() {
			return this.reason;
		},
		set(newValue) {
			this.reason = newValue.replace(/\D/g, "");
		},
	},
};
</script>
