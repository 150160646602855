// import Vue from 'vue'

// axios
import axios from 'axios'
// import router from '../router'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // baseURL: "https://asesmenhut.kelana.id/api/",
  baseURL: process.env.VUE_APP_BASE_URL,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  headers: {
    'Content-Type': 'application/json',
  },
})

// axiosIns.interceptors.request.use(config => {
//   const token = localStorage.getItem('token')
//   config.headers = {
//     ...config.headers,
//     Authorization: `Bearer ${token}`,
//   }
//   return config
// })

// axiosIns.interceptors.response.use(
//   res => res,
//   err => {
//     if (err.response.status === 401) {
//       router.push('/login')
//     }
//   },
// )

// Vue.prototype.$http = axiosIns

export default axiosIns
