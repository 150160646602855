<template>
  <main class="main-content main-content-bg mt-0">
    <div
      class="page-header min-vh-100"
      style="
        background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-basic.jpg');
      "
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-7 mt-2">
            <div class="card border-0 mb-0" style="text-align: center;">
              <div class="card-header bg-transparent">
                <img
                  :src="logoWhite"
                  class="navbar-brand-img h-100"
                  alt="main_logo"
                />
                <h5 class="text-dark text-center mt-2 mb-3">
                  Sign in Update
                </h5>
              </div>
              <div class="card-body px-lg-5 pt-0">
                <div class="text-center text-muted mb-4">
                  <!-- <small>Super Admin</small> -->
                </div>
                <!-- <form role="form" class="text-start"> -->
                <div class="mb-3">
                  <argon-input
                    v-model="input.username"
                    type="text"
                    placeholder="Username / UserId"
                    aria-label="Email"
                  />
                </div>
                <div class="mb-3">
                  <argon-input
                    v-model="input.password"
                    type="password"
                    placeholder="Password"
                    aria-label="Password"
                  />
                </div>
                <argon-switch id="rememberMe" name="rememberMe" v-model="rememberMe">
                  Remember me
                </argon-switch>
                
                <div class="text-center">
                  <argon-button
                    color="success"
                    variant="gradient"
                    type="button"
                    full-width
                    class="my-4 mb-2"
                    @click="verifyRecaptchaAndLogin"
                    :disabled="input.username == '' || input.password == ''"
                    >Sign in</argon-button
                  >
                </div>

                <a 
                  data-bs-target="#view-approved"
                  data-bs-toggle="modal"
                  data-id="id"
                  data-action="approve" 
                >Forgot password?</a>
                <!-- </form> -->
                <br>
                <br>
                <span 
                  style="font-size: 12px; cursor: pointer;"
                  @click="goToPrivacyPolicy"
                ><u>Privacy Policy</u></span>
                <br>
                <span 
                  style="font-size: 12px; cursor: pointer;"
                  @click="goToTerm"
                ><u>Term Condition</u></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="view-approved"
        class="modal fade"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg mt-lg-10">
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="ModalLabel" class="modal-title">
                Forgot Password
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="modal-body">
                <p>Please input your email address to confirm reset password</p>
                <input
                  v-model="email"
                  type="text"
                  placeholder="Ex: example@abc.com"
                  class="mb-3 form-control"
                />
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn bg-gradient-secondary btn-sm"
                data-bs-dismiss="modal"
                id="dismiss-approved"
              >
                Close
              </button>
              <button
                @click="GoRecovery"
                type="button"
                class="btn bg-gradient-success btn-sm"
              >
                Sent
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- End button edit modal -->

    </div>
  </main>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
// import axios from "./../../../libs/axios.js";
import axios from "axios";
import { mapMutations } from "vuex";
import logoWhite from "@/assets/img/darlink.png";

export default {
  name: "SuperadminLogin",
  components: {
    ArgonInput,
    ArgonSwitch,
    ArgonButton
  },
  data() {
    return {
      input: {
        username: "",
        password: ""
      },
      email: "",
      logoWhite,
      rememberMe: false // Add rememberMe property
    };
  },
  created() {
    var token = window.localStorage.getItem("TOKEN")
    var type = window.localStorage.getItem("SideMenuType")

    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();

    if (token !== null && type === "SuperAdmin") {
      return this.$router.push("/superadmin/dashboard");
    }
    if (token !== null && type === "AsoCentral") {
      return this.$router.push("/aso/dashboard");
    }
    if (token !== null && type === "Fincoy") {
      return this.$router.push("/fincoy/dashboard");
    }
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
  },
  methods: {
    goToPrivacyPolicy() {
      // Use Vue Router to navigate to the Privacy Policy page
      this.$router.push({ name: 'superadmin.privacy' });
    },
    goToTerm() {
      this.$router.push({ name: 'superadmin.term' });
    },
    approveOrder (){
      this.$swal({
        title: "Success",
        text: "Email sent",
        icon: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload()
        }
      });
    },
    verifyRecaptchaAndLogin() {
      // const recaptchaSiteKey = "6LeQnFonAAAAADw7ZcM8kC-qg26J_uCSPIbXZaI7"; // Replace with your site key
      // <!--Darlink-web.rekretgroup.com key-->
      // const recaptchaSiteKey = "6LeQeF0nAAAAAChi9a2CPaG7d9JiE7sZ9qJBhB2R"; // Replace with your site key
      // <!--Darlink.id key-->
      const recaptchaSiteKey = "6LcOWugnAAAAAJSe4THmPn3SYJYhsIdtlh0RqiHA"; // Replace with your site key

      // Execute the reCAPTCHA verification
      window.grecaptcha.execute(recaptchaSiteKey, { action: "login" }).then((token) => {
        this.GoLogin(token);
      });
    },
    GoRecovery() {
      if (this.input.email === "") {
        this.$swal({
          title: "Gagal Masuk",
          text: "Email tidak boleh kosong",
          icon: "danger",
          confirmButtonText: "Tutup",
          confirmButtonColor: "#3085d6",
        })
      }
      const recove = new FormData();

      recove.append("email", this.email);
      
      axios
        .post("https://api.darlink.id/v1/recovery", recove, {
          headers: {
            Accept: "application/json"
          }
        })
        .then((response) => {
          console.log(response)
          this.$swal({
            title: "Berhasil",
            text: "Email terkirim",
            icon: "success",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload()
            }
          });
        })
        .catch((err) => {
          this.$swal({
            title: "Gagal",
            text: "Terjadi kendala proses email",
            icon: "danger",
            confirmButtonText: "Tutup",
            confirmButtonColor: "#3085d6",
          })
          console.log(err.toJSON());
        });
    },
    GoLogin(recaptchaToken) {
      if (this.input.username === "" || this.input.password === "") {
        this.$swal({
          title: "Gagal Masuk",
          text: "Username & Password tidak boleh kosong",
          icon: "danger",
          confirmButtonText: "Tutup",
          confirmButtonColor: "#3085d6",
        })
      }
      const authenticable = new FormData();
      
      authenticable.append("email", this.input.username);
      authenticable.append("password", this.input.password);
      authenticable.append("remember_me", (this.rememberMe) ? 0 : 1);
      authenticable.append("recaptcha_token", recaptchaToken); // Add the reCAPTCHA token to the request
      
      axios
        .post("https://api.darlink.id/v1/authenticate", authenticable, {
          headers: {
            Accept: "application/json"
          }
        })
        .then((response) => {
          window.localStorage.setItem("TOKEN", response.data.session.token);
          if (response.data.session.user_type === "admin") {
            window.localStorage.setItem("SideMenuType", "SuperAdmin");
            // router 
            this.$router.push("/superadmin/dashboard");
          }
          if (response.data.session.user_type === "staff") {
            window.localStorage.setItem("SideMenuType", "AsoCentral");
            // router
            this.$router.push("/aso/dashboard");
          }
          if (response.data.session.user_type === "fincoy_pic") {
            window.localStorage.setItem("SideMenuType", "Fincoy");
            // router
            this.$router.push("/fincoy/dashboard");
          }

          // window.localStorage.setItem("SideMenuType", "SuperAdmin");

        })
        .catch((err) => {
          if (err.response && err.response.status === 404) {
            // Handle 404 error here, which corresponds to "Wrong id or password!"
            this.$swal({
              title: "Gagal Masuk",
              text: "id atau password salah!",
              icon: "error",
              confirmButtonText: "Tutup",
              confirmButtonColor: "#3085d6",
            });
          } else if (err.response && err.response.status === 409){ 
            this.$swal({
              title: "Gagal Masuk",
              text: "account sedang digunakan!",
              icon: "error",
              confirmButtonText: "Tutup",
              confirmButtonColor: "#3085d6",
            });
          } else {
            // Handle other types of errors here
            this.$swal({
              title: "Gagal Masuk",
              text: "Terjadi kendala proses login",
              icon: "error",
              confirmButtonText: "Tutup",
              confirmButtonColor: "#3085d6",
            });
          }
          // console.log(err.toJSON());
        });
    },
    ...mapMutations(["toggleDefaultLayout"])
  }
};
</script>
