<template>
	<div class="py-4 container-fluid">
		<div class="row">
			<div class="col-12">
				<div class="card">
					<!-- Card header -->
					<div class="pb-0 card-header">
						<div class="d-lg-flex">
							<div>
								<h5 class="mb-0">Report Order</h5>
							</div>
						</div>
						<div class="row">
							<div class="col-md-2">
								<label for="projectName" class="form-label">Dari Tgl</label>
								<argon-input type="date" placeholder="Date" v-model="filter.startDate" />
							</div>
							<div class="col-md-2">
								<label for="projectName" class="form-label">Sampai Tgl</label>
								<argon-input type="date" placeholder="Date" v-model="filter.endDate" />
							</div>
							<div class="col-md-3">
								<label for="projectName" class="form-label">Fincoy Ho</label>
								<select id="choices-fincoy" class="form-control" name="choices-fincoy" v-model="filter.fincoyPic">
									<option value="">All</option>
									<option v-for="(dataFincoyHO, index) in fincoyHO" :value="dataFincoyHO.id" :key="index">
										{{ dataFincoyHO.name }}
									</option>
								</select>
							</div>
							<div class="col-md-3">
								<label for="projectName" class="form-label">Aso Central</label>
								<select id="choices-aso" class="form-control" name="choices-aso" v-model="filter.central">
									<option value="">All</option>
									<option v-for="(dataAsoCentral, index) in asoCentral" :value="dataAsoCentral.id" :key="index">
										{{ dataAsoCentral.name }}
									</option>
								</select>
							</div>
							<div class="col-md-2">
								<label for="projectName" class="form-label">Status</label>
								<select id="choices-aso" class="form-control" name="choices-aso" v-model="filter.status">
									<option value="" selected="">All</option>
									<option value="pending">Pending</option>
									<option value="assigned">Assign</option>
									<option value="process">Prosess</option>
									<option value="rejected">Reject</option>
									<option value="approved">Approve</option>
									<option value="funded_user">Pencairan D.Konsumen</option>
									<option value="completed">Completed</option>
								</select>
							</div>
							<div class="col-md-5">
								<label for="projectName" class="form-label">Action</label>
								<div class="my-auto mt-4 ms-auto mt-lg-0">
									<div class="my-auto ms-auto">
										<button
											class="mt-1 mb-0 btn btn-outline-success btn-sm export mt-sm-0"
											data-type="csv"
											type="button"
											name="button"
											style="margin-right: 10px"
											@click="exportExcel(filter)"
										>
											<div v-if="isDownloading" class="d-flex align-items-center justify-content-start gap-3">
												<span class="spinner-border spinner-border-sm" role="status">
													<span class="visually-hidden"></span>
												</span>
												<span>Downloading...</span>
											</div>
											<div v-else>
												<i class="text-success text-gradient ni ni-chart-bar-32"></i>
												Export
											</div>
										</button>

										<button
											class="mt-1 mb-0 btn btn-outline-success btn-sm export mt-sm-0"
											type="button"
											name="button"
											style="margin-right: 10px"
											@click="handleReset"
										>
											Reset
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- button edit modal -->
		<div id="view-aso" class="modal fade" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog modal-lg mt-lg-10">
				<div class="modal-content">
					<div class="modal-header">
						<h5 id="ModalLabel" class="modal-title">ASO Cabang Jakarta</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div>
							<h6>ASO Cabang Jakarta</h6>
							<p class="text-sm mb-0">
								Nama PIC
								<b>Herman</b> Nomor HP
								<b>1234567890</b>
							</p>
							<p class="text-sm">
								NIK/NPK :
								<b>243598234</b>
							</p>

							<p class="text-sm">
								Jumlah Anggota :
								<b>32</b>
							</p>
						</div>
						<p>Jalan Alamat nya/Lokasi.</p>

						<div>
							<p class="text-sm mb-0">
								Status
								<span class="badge badge-success badge-sm">Aktif</span>
							</p>
							<p class="text-sm">
								NIK/NPK :
								<b>243598234</b>
							</p>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn bg-gradient-secondary btn-sm" data-bs-dismiss="modal">Close</button>
					</div>
				</div>
			</div>
		</div>
		<!-- End button edit modal -->
	</div>
</template>

<script>
import { DataTable } from "simple-datatables";
import setTooltip from "@/assets/js/tooltip.js";
import ArgonInput from "@/components/ArgonInput.vue";
import axios from "axios";
// import Choices from "choices.js";

export default {
	name: "ReportAsoCabang",
	components: {
		ArgonInput,
	},
	data() {
		return {
			datatable: null,
			isLoading: false,
			personInCharges: [],
			orderLists: [],
			selectedAso: null,
			asoCentral: [],
			fincoyHO: [],
			filter: {
				startDate: "",
				endDate: "",
				fincoyPic: "",
				central: "",
				status: "",
			},
			isDownloading: false,
		};
	},
	beforeMount() {
		if (localStorage.getItem("SideMenuType") !== "SuperAdmin") {
			this.$router.go(-1);
		}
	},
	mounted() {
		if (document.getElementById("products-list")) {
			const dataTableSearch = new DataTable("#products-list", {
				searchable: true,
				fixedHeight: false,
				perPage: 7,
			});

			document.querySelectorAll(".export").forEach(function (el) {
				el.addEventListener("click", function () {
					var type = el.dataset.type;

					var data = {
						type: type,
						filename: "soft-ui-" + type,
					};

					if (type === "csv") {
						data.columnDelimiter = "|";
					}

					dataTableSearch.export(data);
				});
			});
		}
		setTooltip(this.$store.state.bootstrap);

		this.loadFincoyHO();
		this.loadAsoCentral();
	},
	methods: {
		loadFincoyHO() {
			var token = window.localStorage.getItem("TOKEN");
			axios
				.get("https://api.darlink.id/v1/fincoy?status=active", {
					withCredentials: true,
					headers: {
						Accept: "application/json",
						Authorization: token,
					},
				})
				.then((response) => {
					this.fincoyHO = response.data.content;
				});
		},
		loadAsoCentral() {
			var token = window.localStorage.getItem("TOKEN");
			axios
				.get("https://api.darlink.id/v1/aso-central?status=active", {
					withCredentials: true,
					headers: {
						Accept: "application/json",
						Authorization: token,
					},
				})
				.then((response) => {
					this.asoCentral = response.data.content;
				});
		},
		handleReset() {
			this.filter.startDate = "";
			this.filter.endDate = "";
			this.filter.status = "";
			this.filter.central = "";
			this.filter.fincoyPic = "";
		},
		async exportExcel(params_filter = { startDate: "", endDate: "", status: "" }) {
			var token = window.localStorage.getItem("TOKEN");
			try {
				this.isDownloading = true;
				const config = {
					methods: "GET",
					url: "https://api.darlink.id/v1/order",
					responseType: "blob",
					withCredentials: true,
					headers: {
						Accept: "application/json",
						Authorization: token,
					},
					params: {
						status: params_filter.status,
						start: params_filter.startDate,
						end: params_filter.endDate,
						aso_branch: params_filter.central,
						fincoy_ho: params_filter.fincoyPic,
						export: "xlsx",
					},
				};
				const response = await axios.request(config);
				const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = blobUrl;
				link.setAttribute("download", "filename.xlsx");
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				this.isDownloading = false;
			} catch (error) {
				this.isDownloading = false;
				this.$swal({
					title: "Error",
					text: `${error?.response?.data?.message}`,
					icon: "error",
					confirmButtonText: "Close",
					confirmButtonColor: "#3085d6",
				});
			}
		},
	},
};
</script>
