<template>
  <div>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="mx-auto col-lg-9 col-12">
          <div class="mt-4 card card-body">
            <h6 class="mb-0">Assign Order</h6>
            <hr class="my-3 horizontal dark" />
            <span>* Total {{percent}} Fincoy PIC yang diperbolehkan dalam 1x melakukan <i>assign</i></span>
            
            <div class="table-responsive">
              <table id="products-list" class="table table-flush">
                  <thead class="thead-light">
                  <tr>
                      <th>Fincoy HO</th>
                      <th>Fincoy PIC</th>
                  </tr>
                  </thead>
                  <tbody>
                       <!-- looping for fincoy  -->
                      <tr v-for="fincoy,i in personInCharges" :key="i">
                          <td>
                              <div class="d-flex align-items-center">
                                <argon-checkbox v-model="selectedAssignees" :value="fincoy.checked" @change="toggleCheckbox(fincoy)" />
                                  <p class="text-xs font-weight-bold ms-2 mb-0">{{ (fincoy.fincoy) ? fincoy.fincoy.name : "-" }}</p>
                              </div>
                          </td>
                          <td class="text-sm">{{ fincoy.location }}</td>
                      </tr>
                  </tbody>
              </table>
          </div>
            <div class="mt-4 d-flex justify-content-end">
              <button
                type="button"
                name="button"
                class="m-0 btn btn-light"
                @click="$router.push({ name: 'aso.order-pending' })"
              >
                Batal
              </button>
              <button
                type="button"
                name="button"
                class="m-0 btn bg-gradient-success ms-2"
                @click="submit()"
              >
                Assign Order
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  // import ArgonInput from "@/components/ArgonInput.vue";
  import ArgonCheckbox from "@/components/ArgonCheckbox.vue";
import axios from 'axios';

export default {
  name: "AssignOrder",
  components: {
    // ArgonInput,
    ArgonCheckbox
  },
  data() {
    return {
      // assignees checkbox save reactivity
      percent: "",
      dataOrder: [],
      selectedAssignees: [],
      personInCharges: [],
      fincoy: [],
      allow_fincoy: [],
      // Validations
      validations: []
    };
  },
  computed: {
    orderID() {
      if (!this.$route.params.orderList) {
        return null
      }
      return this.$route.params.orderList
    },
    // selectedPersons() {
    //   return this.personInCharges.filter(fincoy => fincoy.checked);
    // }
  },
  beforeMount() {
    if (localStorage.getItem("SideMenuType") !== "AsoCentral") {
        this.$router.go(-1);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.loadOrder()
      this.fincoyList()
      this.allowedfincoyList()
    })
  },
  methods: {
    toggleCheckbox(fincoy) {
      fincoy.checked = !fincoy.checked;
    },
    isError(tag) {
      let message = this.validations.find(validation => {
        if (validation.tag === tag) {
          return true
        }
        return false
      })

      if (!message) {
        return false
      }

      return true
    },
    fincoyList() {
      var token = window.localStorage.getItem("TOKEN")
      axios
        .get("https://api.darlink.id/v1/aso-central/1/fincoy-pic", {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          }
        })
        .then((response) => {
          this.personInCharges = response.data.content
          this.loadPercentage()
        })
        .catch((err) => {
          console.log(err);
        });
      },
    allowedfincoyList() {
    var token = window.localStorage.getItem("TOKEN")
    axios
      .get("https://api.darlink.id/v1/order/" + this.orderID + "/assignables", {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          Authorization: token
        }
      })
      .then((response) => {
        this.allow_fincoy = response.data.content
        
      })
      .catch((err) => {
        console.log(err);
      });
    },
    loadPercentage() {
      var token = window.localStorage.getItem("TOKEN")

      axios
        .get("https://api.darlink.id/v1/aso-central/get-percentage",
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token,
          }
        })
        .then((response) => {
          if (response.data.code != 200) {
            return
          }
          
          const resp = Math.ceil((response.data.content[1].value / 100) * this.personInCharges.length)
          console.log(response.data.content[1].value / 100)
          console.log(this.personInCharges.length)
          console.log("percent = ", resp)
          
          this.percent = resp
        })
        .catch((err) => {
          console.log(err.response)
        });
    },
    loadOrder() {
      var token = window.localStorage.getItem("TOKEN")

      if (!this.orderID) {
        return
      }
      axios.defaults.baseURL = 'https://darlinkapi.rekananbos.com';
      axios.defaults.withCredentials = true; // Hanya jika Anda perlu mengirim kredensial

      axios
        .get("https://api.darlink.id/v1/order/" + this.orderID,
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token,
          }
        })
        .then((response) => {
          if (response.data.code != 200) {
            return
          }
          this.dataOrder = response.data.content
          // array of assignees
          this.assignees = response.data.content.assignees
        })
        .catch((err) => {
          console.log(err.response)
        });
    },
    submit() {
      var token = window.localStorage.getItem("TOKEN")

      // const orderAssign = new FormData();
      // // save assignees checkbox to formdata
      // this.assignees.forEach(assignee => {
      //   orderAssign.append("assignees[]", assignee)
      // })
      // const selectedAssignees = this.personInCharges.filter(fincoy => fincoy.checked) .map(fincoy => `"assignees: ${fincoy.id}"`);
      // assignees for post request body with value assignees: id
      
      // const requestBody = {
        //   assignees: selectedAssignees.map(String)
        // }
        
        // request body with value array of assignees: "assignees: id 1", "assignees: id 2
        
        // create assignees array for not array value
        // const assignees = selectedAssignees.map(fincoy => fincoy.id);
        // const assignees = new FormData();
        // save assignees checkbox to formdata checkbox fincoy id
        
        const data = new FormData();
        // selectedAssignees checkbox get id from personInCharges array and save to formdata assignees 
        this.personInCharges.forEach(fincoy => {
          if (fincoy.checked) {
            data.append("assignees", `${fincoy.id}`)
            // data.append("assignees", fincoy.id)
          }
        })
        console.log(data)
        // assginees for string value
        
        // const selectedAssignees = this.personInCharges.filter(fincoy => fincoy.checked).map(fincoy => `${fincoy.id}`).map(String);
        // const selectedAssignees = this.personInCharges.filter(fincoy => fincoy.checked).map(fincoy => `${fincoy.id}`);
        // const selectedAssignees = this.personInCharges.filter(fincoy => fincoy.checked).map(fincoy => `"assignees: ${fincoy.id}"`);
        
        
        // const selectedAssignees = this.personInCharges.filter(fincoy => fincoy.checked).map(fincoy => `${fincoy.id}`).map(String);
      
        axios.defaults.baseURL = 'https://darlinkapi.rekananbos.com';
        axios.defaults.withCredentials = true; // Hanya jika Anda perlu mengirim kredensial
        // axios
          // .post("https://api.darlink.id/v1/order/" + this.orderID + "/assign", {assignees: selectedAssignees},
          // .post("https://api.darlink.id/v1/order/" + this.orderID + "/assign", ,
          axios({
            method: "post",
            url: "https://api.darlink.id/v1/order/" + this.orderID + "/assign",
            data,
            headers: { 
              "Content-Type": "multipart/form-data", 
              Authorization: token,
              Accept: "application/json",
            },
          })
          // {
          //   withCredentials: true,
          //   headers: {
          //     Accept: "application/json",
          //     // Authorization: "TEST_STAFF123456789",
          //     Authorization: token,
          //     "Content-Type": "multipart/form-data"
          //   }
          // })
          .then((response) => {
            // SWEETALLERT
            this.$swal({
              title: "Berhasil",
              text: "Assign berhasil dilakukan",
              icon: "success",
              confirmButtonText: "Tutup",
              confirmButtonColor: "#3085d6",
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.push({ name: "aso.order-pending" })
              }
            });
            this.$router.push({ name: "aso.order-pending" })
            if (response.data.code != 200) {
              return
            }
          })
          .catch((err) => {
            let errorMessage = "";
            if (err.response && err.response.data && err.response.data.validation) {
              const validationErrors = err.response.data.validation;
              let errorMessage = "Validation Error:\n";
              validationErrors.forEach((error) => {
                errorMessage += `${error.mesg}\n`;
              });
              this.$swal({
                title: "Validation Error",
                text: errorMessage,
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "#d33",
              });
            } else {
              if (err.response.data.tag.includes("content.") && err.response.data.tag.endsWith(".error")) {
                errorMessage = err.response.data.tag.replace("content.", "").replace(".error", "");
              } else {
                errorMessage = err.response.data.tag;
              }

              this.$swal({
                title: "Error",
                text: errorMessage,
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "#d33",
              });
            }
          });
      }
  }
};
</script>