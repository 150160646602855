<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="mx-auto col-lg-9 col-12">
        <div class="mt-4 card card-body">
          <h6 class="mb-0">Tambah Role Staff</h6>
          <hr class="my-3 horizontal dark" />

          <div class="mt-4 row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>Status Role Staff</label>
                <div class="form-check form-switch ms-1">
                  <input
                    id="flexSwitchCheckDefault"
                    v-model="isActive"
                    class="form-check-input"
                    type="checkbox"
                    data-type="warning"
                    data-content="Once a project is made private, you cannot revert it to a public project."
                    data-title="Warning"
                    data-icon="ni ni-bell-55"
                  />
                  <label
                    class="form-check-label"
                    for="flexSwitchCheckDefault"
                  >
                    <span
                      class="badge badge-md"
                      :class="{
                        'badge-danger': !isActive,
                        'badge-success': isActive,
                      }"
                    >
                      {{ isActive ? 'AKTIF' : 'NON-AKTIF' }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <label for="projectName" class="form-label">Nama Role</label>
              <input id="projectName" v-model="displayname" type="text" class="form-control" />
            </div>
            <div class="col-6">
              <label class="form-label">Pilih Role</label>
              <div class="form-group">
                <argon-checkbox
                  v-for="role in roles"
                  :key="role.value"
                  :value="role.value"
                  :label="role.label"
                  :selected-roles="selectedRoles"
                  @update:selectedRoles="handleRoleSelection"
                ></argon-checkbox>
              </div>
            </div>

            <div class="mt-4 d-flex justify-content-end">
              <router-link to="./aso-role-staff">
                <button type="button" name="button" class="m-0 btn btn-light">
                  Batal
                </button>
              </router-link>
              <button
                type="button"
                name="button"
                class="m-0 btn bg-gradient-success ms-2"
                @click="submit"
              >
                Tambah Role Staff
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonCheckbox from "@/components/ArgonCheckboxCustom.vue";
import axios from 'axios';

export default {
  name: "NewAsoCentral",
  components: {
    ArgonCheckbox,
  },
  data() {
    return {
      displayname: "",
      isActive: false,
      roles: [
        { value: "dashboard", label: "Dashboard" },
        { value: "perm_aso_branch", label: "Staff" },
        { value: "perm_aso_central", label: "Fincoy PIC" },
        { value: "perm_fincoy_ho", label: "Agent" },
        { value: "perm_withdraw", label: "Order" },
        { value: "perm_management_info", label: "Management Info" },
        { value: "perm_report", label: "Report" },
        // Add other roles here
      ],
      selectedRoles: [],
    };
  },
  methods: {
    handleRoleSelection({ value, isChecked }) {
      if (isChecked) {
        this.selectedRoles = [...this.selectedRoles, value];
      } else {
        this.selectedRoles = this.selectedRoles.filter((role) => role !== value);
      }
    },
    submit() {
      var token = window.localStorage.getItem("TOKEN")

      // Create the payload object
      const roleData = new FormData();

      roleData.append("name", this.displayname);
      roleData.append("grants", JSON.stringify(this.selectedRoles));
      roleData.append("status", this.isActive ? 'active' : 'inactive');

      // Send the payload to the API
      axios
        .post("https://api.darlink.id/v1/aso-central/role", roleData, {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token,
          },
        })
        .then((response) => {
          // Handle the API response
          this.$swal({
            title: "Berhasil",
            text: "Role Staff baru berhasil ditambahkan",
            icon: "success",
            confirmButtonText: "Tutup",
            confirmButtonColor: "#3085d6",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push({ name: "aso.staff.role" });
            }
          });
          if (response.data.code != 201) {
            return;
          }
          console.log(response);
        })
        .catch((error) => {
          // Handle any errors
          this.$swal({
            title: "Gagal",
            text: error,
            icon: "danger",
            confirmButtonText: "Tutup",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push({ name: "superadmin.staff.role" });
            }
          });
          console.error(error);
        });
    },
  },
};
</script>
