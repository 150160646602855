<template>
  <div>
    <label>
      <input
        type="checkbox"
        :value="value"
        :checked="isChecked"
        @change="updateSelection"
      />
      <span>{{ label }}</span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    selectedRoles: {
      type: Array,
      required: true,
    },
  },
  computed: {
    isChecked() {
      return this.selectedRoles.includes(this.value);
    },
  },
  methods: {
    updateSelection() {
      const isChecked = this.selectedRoles.includes(this.value);

      this.$emit("update:selectedRoles", {
        value: this.value,
        isChecked: !isChecked,
      });
    },
  },
};
</script>
