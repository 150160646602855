<template>
  <div
    id="sidenav-collapse-main"
    class="collapse navbar-collapse w-auto h-auto h-100"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-Main
          nav-text="Dashboards"
          :collapse="false"
          :class="getRoutelvl2() === 'dashboard' ? 'active' : ''"
          url="/aso/dashboard"
          :aria-controls="''"
        >
          <template #icon>
            <i class="ni ni-shop text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-Main>
      </li>

      <!-- <li class="nav-item" >
        <sidenav-collapse
          collapse-ref="staff"
          nav-text="Staff"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
        >
          <template #icon>
            <i class="ni ni-badge text-success text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4"> -->
              <!-- nav links -->
              <!-- <sidenav-item
                :to="{ name: 'aso.staff.role' }"
                mini-icon="D"
                text="Role Staff"
              />
              <sidenav-item
                :to="{ name: 'aso.staff' }"
                mini-icon="D"
                text="Staff List"
              /> -->
              <!-- <sidenav-item :to="{ name: 'CRM' }" mini-icon="C" text="CRM" /> -->
            <!-- </ul>
          </template>
        </sidenav-collapse>
      </li> -->

      <li class="nav-item">
        <sidenav-Main
          nav-text="Update Password"
          :collapse="false"
          :class="getRoutelvl2() === 'update-password' ? 'active' : ''"
          url="/aso/update-password"
          :aria-controls="''"
        >
          <template #icon>
            <i class="ni ni-archive-2 text-success text-sm opacity-10"></i>
          </template>
        </sidenav-Main>
      </li>

      <!-- <li class="nav-item" v-if="permission.includes('aso_branch')"> -->
      <li class="nav-item">
        <sidenav-Main
          nav-text="Aso Cabang"
          :collapse="false"
          :class="getRoutelvl2() === 'aso-cabang' ? 'active' : getRoutelvl2() === 'new-aso-cabang' ? 'active' : ''"
          url="/aso/aso-cabang"
          :aria-controls="''"
        >
          <template #icon>
            <i class="ni ni-archive-2 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-Main>
      </li>
      <li class="nav-item">
        <sidenav-Main
          nav-text="Fincoy PIC"
          :collapse="false"
          :class="getRoutelvl2() === 'fincoy-pic' ? 'active' : ''"
          url="/aso/fincoy-pic"
          :aria-controls="''"
        >
          <template #icon>
            <i class="ni ni-send text-info text-sm opacity-10"></i>
          </template>
        </sidenav-Main>
      </li>

      <li class="nav-item">
        <sidenav-Main
          nav-text="Agents"
          :collapse="false"
          :class="getRoutelvl2() === 'aso-agent' ? 'active' : ''"
          url="/aso/aso-agent"
          :aria-controls="''"
        >
          <template #icon>
            <i class="ni ni-single-02 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-Main>
      </li>

      <!-- <li class="nav-item" v-if="permission.includes('order')"> -->
      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="Order"
          nav-text="Order"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
        >
          <template #icon>
            <i class="ni ni-tag text-warning text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item
                :to="{ name: 'aso.order-pending' }"
                mini-icon="D"
                :text="formattedText"
              />
              <sidenav-item
                :to="{ name: 'aso.order-assign' }"
                mini-icon="D"
                :text="formattedTextAssign"
              />
              <sidenav-item
                :to="{ name: 'aso.order-process' }"
                mini-icon="D"
                :text="formattedTextProcess"
              />
              <sidenav-item
                :to="{ name: 'aso.order-reject' }"
                mini-icon="D"
                :text="formattedTextReject"
              />
              <sidenav-item
                :to="{ name: 'aso.order-approved' }"
                mini-icon="D"
                :text="formattedTextApprove"
              />
              <sidenav-item
                :to="{ name: 'Aso Order Pencairan Dana User' }"
                mini-icon="D"
                :text="formattedTextCair"
              />
              <!-- <sidenav-item
                :to="{ name: 'Aso Order Pencairan Dana Darlink' }"
                mini-icon="D"
                text="Pencariran Dana Darlink"
              /> -->
              <!-- <sidenav-item
                :to="{ name: 'Order ASO List' }"
                mini-icon="D"
                text="Pencairan Dana User"
              />
              <sidenav-item
                :to="{ name: 'Order ASO List' }"
                mini-icon="D"
                text="Pencariran Dana Darlink"
              /> -->
              <!-- <sidenav-item
                :to="{ name: 'aso.order-completed' }"
                mini-icon="D"
                text="Completed"
              /> -->
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item" >
        <sidenav-Main
          nav-text="Management Info"
          :collapse="false"
          :class="getRoutelvl2() === 'managementinfo-list' ? 'active' : ''"
          url="/aso/managementinfo-list"
          :aria-controls="''"
        >
          <template #icon>
            <i class="ni ni-air-baloon text-info text-sm opacity-10"></i>
          </template>
        </sidenav-Main>
      </li>

      <li class="nav-item" >
        <sidenav-Main
          nav-text="History Log"
          :collapse="false"
          :class="getRoutelvl2() === 'aso.histories' ? 'active' : ''"
          url="/aso/log-history"
          :aria-controls="''"
        >
          <template #icon>
            <i class="ni ni-books text-danger text-sm opacity-10"></i>
          </template>
        </sidenav-Main>
      </li>

      <li class="nav-item" >
        <sidenav-collapse
          collapse-ref="report"
          nav-text="Report"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
        >
          <template #icon>
            <i class="ni ni-badge text-danger text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item
                :to="{ name: 'aso.report.order' }"
                mini-icon="D"
                text="Report Order"
              />
              <sidenav-item
                :to="{ name: 'aso.report.branches' }"
                mini-icon="D"
                text="Report Aso Cabang"
              />
              <sidenav-item
                :to="{ name: 'aso.report.agent' }"
                mini-icon="A"
                text="Report Agent"
              />
              <!-- <sidenav-item :to="{ name: 'CRM' }" mini-icon="C" text="CRM" /> -->
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <!-- <div class="mt-2 d-flex">
          <h6 class="mb-0">Light / Dark</h6>
          <div class="form-check form-switch ps-0 ms-auto my-auto">
            <input
              class="form-check-input mt-1 ms-auto"
              type="checkbox"
              :checked="$store.state.darkMode"
              @click="darkMode"
            />
          </div>
        </div> -->
    </ul>
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavMain from "./SidenavMain.vue";
import axios from 'axios';

export default {
  name: "SidenavList",
  components: {
    SidenavItem,
    SidenavCollapse,
    // SidenavCard,
    SidenavMain,
    // SidenavCollapseItem
  },
  data() {
      return {
        counted: 0,
        countedAssign: 0,
        countedProcess: 0,
        countedReject: 0,
        countedApprove: 0,
        countedCair: 0,
      }
    },
  computed: {
    formattedText() {
      return 'Order Pending (' + this.counted + ')';
    },
    formattedTextAssign() {
      return 'Order Assign (' + this.countedAssign + ')';
    },
    formattedTextProcess() {
      return 'Order Process (' + this.countedProcess + ')';
    },
    formattedTextReject() {
      return 'Order Reject (' + this.countedReject + ')';
    },
    formattedTextApprove() {
      return 'Order Approve (' + this.countedApprove + ')';
    },
    formattedTextCair() {
      return 'Pencairan Dana Kons (' + this.countedCair + ')';
    },
  },
  mounted() {
    // Check if the token is empty
    var token = window.localStorage.getItem("TOKEN")
    if (!token) {
      // Redirect to "superadmin.login"
      this.$router.push({ name: "superadmin.login" });
      return;
    }

    this.loadCounted();
    this.loadCountedAssign();
    this.loadCountedProcess();
    this.loadCountedReject();
    this.loadCountedApprove();
    this.loadCountedCair();
    
    // Set up the interval to call loadCounted() every 30 seconds
    this.interval = setInterval(() => {
      this.loadCounted();
      this.loadCountedAssign();
      this.loadCountedProcess();
      this.loadCountedReject();
      this.loadCountedApprove();
      this.loadCountedCair();
    }, 30000); // 30 seconds in milliseconds
  },
  beforeUnmount() {
    // Clear the interval to prevent memory leaks
    clearInterval(this.interval);
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    getRoutelvl2() {
      const routeArr = this.$route.path.split("/");
      return routeArr[2];
    },
    loadCounted() {
        var token = window.localStorage.getItem("TOKEN")
        axios
          .get("https://api.darlink.id/v1/order/watch", {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token
            }
          })
          .then((response) => {
            if (response.data.code != 200) {
              return
            }
            
            if(response.data.tag != "content.order.empty")
              this.counted = response.data.content.pending
            else
              this.counted = 0
            // console.log(this.orderLists)
            
          })
          .catch((err) => {
            // this.toggleLoading()
            console.log(err);
          });
      },
    loadCountedAssign() {
        var token = window.localStorage.getItem("TOKEN")
        axios
          .get("https://api.darlink.id/v1/order?status=assigned", {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token
            }
          })
          .then((response) => {
            if (response.data.code != 200) {
              return
            }
            
            if(response.data.pagination)
            this.countedAssign = response.data.pagination.total_items
            // console.log(this.orderLists)
            
          })
          .catch((err) => {
            // this.toggleLoading()
            console.log(err);
          });
      },
    loadCountedProcess() {
        var token = window.localStorage.getItem("TOKEN")
        axios
          .get("https://api.darlink.id/v1/order?status=process", {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token
            }
          })
          .then((response) => {
            if (response.data.code != 200) {
              return
            }

            if(response.data.pagination)
            this.countedProcess = response.data.pagination.total_items
            // console.log(this.orderLists)
            
          })
          .catch((err) => {
            // this.toggleLoading()
            console.log(err);
          });
      },
    loadCountedReject() {
        var token = window.localStorage.getItem("TOKEN")
        axios
          .get("https://api.darlink.id/v1/order?status=rejected", {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token
            }
          })
          .then((response) => {
            if (response.data.code != 200) {
              return
            }

            if(response.data.pagination)
            this.countedReject = response.data.pagination.total_items
            // console.log(this.orderLists)
            
          })
          .catch((err) => {
            // this.toggleLoading()
            console.log(err);
          });
      },
    loadCountedApprove() {
        var token = window.localStorage.getItem("TOKEN")
        axios
          .get("https://api.darlink.id/v1/order?status=approved", {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token
            }
          })
          .then((response) => {
            if (response.data.code != 200) {
              return
            }
            
            if(response.data.pagination)
            this.countedApprove = response.data.pagination.total_items
            // console.log(this.orderLists)
            
          })
          .catch((err) => {
            // this.toggleLoading()
            console.log(err);
          });
      },
    loadCountedCair() {
        var token = window.localStorage.getItem("TOKEN")
        axios
          .get("https://api.darlink.id/v1/order?status=funded_user", {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token
            }
          })
          .then((response) => {
            if (response.data.code != 200) {
              return
            }

            if(response.data.pagination)
            this.countedCair = response.data.pagination.total_items
            // console.log(this.orderLists)
            
          })
          .catch((err) => {
            // this.toggleLoading()
            console.log(err);
          });
      },
  }
};
</script>
