<template>
  <div class="py-2 container-fluid">
    <div class="row">
          <div class="col-md-12">
            <div class="mt-4 card card-body">
              <div>
                <p class="text-sm mb-0">
                  <span v-if="dataOrder.fincoy_ho">
                    Nama Fincoy HO:
                    <b>{{ dataOrder.fincoy_ho.name }}</b> 
                    <br />
                        Lokasi Fincoy HO:
                    <b>{{ dataOrder.fincoy_ho.location }}</b> 
                    <br />
                  </span>
                  Nama PIC:
                  <b>{{ dataOrder.displayname }}</b> 
                  <br />
                  ID:
                  <b>{{ dataOrder.personal_id }}</b> 
                  <br />
                  Email:
                  <b>{{ dataOrder.email }}</b>
                  <br />
                  Company Email:
                  <b>{{ dataOrder.company_email }}</b>
                  <br />
                  Department:
                  <b>{{ dataOrder.department }}</b>
                  <br />
                  Alamat:
                  <b>{{ dataOrder.address }}</b>
                  <br />
                </p>
              </div>

              <!--Image-->
              <div v-if="dataOrder.company_id_scan">
                  <img :src="dataOrder.company_id_scan" alt="Additional Document" style="width: 20%; height: auto;"/>
              </div>

              <div class="modal-footer mt-2">
                <button
                  type="button"
                  @click="$router.push({ name: 'superadmin.list-fincoy-pic' })"
                  class="btn bg-gradient-secondary btn-sm"
                >
                  Kembali
                </button>
              </div>
            </div>

          </div>
        </div>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    data(){
      return{
        dataOrder: {
          agent: {}, // Initialize agent object
          aso_branch: {},
          fincoy_pic: {},
          fincoy_ho: {},
        },
        created: "",
        updated: "",
      }
    },
    beforeMount() {
      if (localStorage.getItem("SideMenuType") !== "SuperAdmin") {
          this.$router.go(-1);
      }
    },
    mounted(){
      this.getDataOrder()
    },
    computed: {
      orderID() {
        if (!this.$route.params.orderList) {
          return null
        }
        return this.$route.params.orderList
      },
      
    },
    methods: {
      getDataOrder() {
        var token = window.localStorage.getItem("TOKEN")
        axios
          .get("https://api.darlink.id/v1/fincoy/pic/" +  this.orderID, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token,
              // allow cors 
              // "Access-Control-Allow-Origin": "*",
              // "Access-Control-Allow-Credentials": true,
            }
          })
          .then((response) => {
            this.dataOrder = response.data.content;
            this.dataOrder.fincoy_ho = response.data.content.fincoy;
            
            this.created = new Date(this.dataOrder.created_at).toLocaleDateString("id-ID", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    // hour
                    hour: "numeric",
                    // minute
                    minute: "numeric",
                  });
            this.updated = new Date(this.dataOrder.updated_at).toLocaleDateString("id-ID", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    // hour
                    hour: "numeric",
                    // minute
                    minute: "numeric",
                  });
          })
          .catch((err) => {
            console.log(err);
          }
        );
      },
    }
  }
</script>