<template>
  <nav
    id="navbarBlur"
    :class="`${
      !isNavFixed
        ? 'navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none'
        : `navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none position-sticky ${
            darkMode ? 'bg-default' : 'bg-white'
          } left-auto top-2 z-index-sticky`
    } ${isRTL ? 'top-0 position-sticky z-index-sticky' : ''}`"
    v-bind="$attrs"
    data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs
        :current-page="currentRouteName"
        :current-directory="currentDirectory"
      />
      <div
        id="navbar"
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="isRTL ? 'px-0' : 'me-sm-4'"
      >
        <div
          class="pe-md-3 d-flex align-items-center"
          :class="isRTL ? 'me-md-auto' : 'ms-md-auto'"
        >
        </div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-xl-none ps-3 pe-3 d-flex align-items-center">
            <a
              href="#"
              @click="toggleSidebar"
              class="p-0 nav-link text-white"
              id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
              </div>
            </a>
          </li>
          <li class="nav-item d-flex align-items-center">
            <a
              class="px-0 nav-link font-weight-bold"
              :class="
                isNavFixed && !darkMode ? ' opacity-8 text-dark' : 'text-white'
              "
              href="javascript:void(0);"
              @click="confirmLogout"
            >
              <i class="fa fa-user" :class="isRTL ? 'ms-sm-2' : 'me-sm-1'"></i>
              <span class="d-sm-inline d-none" style="margin-right:10px;">Hi, {{ user_name }}</span>
              <i class="fa fa-sign-out" :class="isRTL ? 'ms-sm-2' : 'me-sm-1'"></i>
              <span class="d-sm-inline d-none">Logout</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapState, mapMutations, mapActions } from "vuex";
import axios from 'axios';

export default {
  name: "Navbar",
  components: {
    Breadcrumbs
  },
  data() {
    return {
      showMenu: false,
      user_name: "",
    };
  },
  mounted() {
    this.loadInfo()

    // Set up the interval to call loadCounted() every 30 seconds
    this.interval = setInterval(() => {
      this.loadInfo();
    }, 300000); // 30 seconds in milliseconds
  },
  computed: {
    ...mapState(["isRTL", "isNavFixed", "darkMode"]),
    currentRouteName() {
      return this.pageName;
    },
    currentDirectory() {
      let dir = this.$route.path.split("/")[1];
      return dir.charAt(0).toUpperCase() + dir.slice(1);
    },
    pageName() {
      if (!this.$route.meta) {
        return ""
      }
      if (!this.$route.meta.page) {
        return ""
      }

      return this.$route.meta.page
    }
  },
  created() {
    this.minNav;
  },
  beforeUpdate() {
    this.toggleNavigationOnMobile();
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),
    toggleNavigationOnMobile() {
      if (window.innerWidth < 1200) {
        this.navbarMinimize();
      }
    },
    confirmLogout() {
      this.$swal({
        title: "Anda yakin akan keluar?",
        text: "Keluar dari dasbor web untuk keamanan dan pengunaan akun diperangkat lain.",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
         this.logout()
        }
      });
    },
    logout() {
      var token = window.localStorage.getItem("TOKEN")
      // var type = window.localStorage.getItem("SideMenuType")

      if (token === null) {
        return
      }
      window.localStorage.removeItem('TOKEN');


      // if (token === null && type === "SuperAdmin") {
      //   return this.$router.push("/authentication/signin/superadmin");
      // }
      // if (token === null && type === "AsoCentral") {
      //   return this.$router.push("/authentication/signin/asocentral");
      // }
      // if (token === null && type === "Fincoy") {
      //   return this.$router.push("/authentication/signin/fincoy");
      // }
      
       axios
        .post("https://api.darlink.id/v1/deauthenticate", new FormData(), {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          }
        })
        .then(() => {
          return this.$router.push("/");
          // window.localStorage.removeItem('TOKEN');
          // refresh page
          // window.location.reload();

          // if (token === null && type === "SuperAdmin") {
          //   return this.$router.push("/authentication/signin/superadmin");
          // }
          // if (token !== null && type === "AsoCentral") {
          //   return this.$router.push("/authentication/signin/asocentral");
          // }
          // if (token !== null && type === "Fincoy") {
          //   return this.$router.push("/authentication/signin/fincoy");
          // }
        })
        .catch((err) => {
          console.log(err.toJSON());
        });
    },
    loadInfo() {
       var token = window.localStorage.getItem("TOKEN")
       let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://api.darlink.id/v1/authentication-status',
          headers: { 
            'Accept': 'application/json', 
            'Authorization': token
          }
        };

        axios.request(config)
        .then((response) => {
          this.user_name = response.data.session.display_name;
        })
        .catch((error) => {
          console.log(error);
          // Redirect to "superadmin.login"
          this.$router.push({ name: "superadmin.login" });
          return;
        });

    },
  }
};
</script>
