<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">All Aso Cabang</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <router-link to="./new-aso-cabang">
                  <a
                    class="mb-0 btn bg-gradient-success btn-sm"
                    target="_blank"
                    >+&nbsp; Tambah Aso Cabang</a>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <div class="row">
                <div class="col-lg-4" style="margin: 10px;">
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      v-model="searchQuery"
                      placeholder="Search ..."
                    />
                  </div>
                  
                  <a
                    href="#"
                    class="mb-0 btn bg-gradient-success btn-sm"
                    style="margin-right: 10px;"
                    @click="performSearch"
                    >+&nbsp; search</a
                  >
                </div>
              </div>

              <table id="products-list" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Nama Aso</th>
                    <th>Nama PIC</th>
                    <th>Nomor Hp</th>
                    <th>NIK PIC</th>
                    <th>Type</th>
                    <th>Lokasi</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody></tbody>
                <tfoot>
                  <tr>
                    <th>Product</th>
                    <th>Category</th>
                    <th>Price</th>
                    <th>SKU</th>
                    <th>Quantity</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </tfoot>
              </table>

              <!-- DataTable's built-in pagination -->
              <nav aria-label="Page navigation">
                <ul class="pagination justify-content-end">
                  <li class="page-item" :class="{ disabled: page === 1 }">
                    <a class="page-link" href="javascript:void(0);" @click="loadPage(page - 1)">
                      Prev
                    </a>
                  </li>
                  <li class="page-item" v-for="pageNumber in totalPages" :key="pageNumber" :class="{ active: pageNumber === page }">
                    <a class="page-link" href="javascript:void(0);" @click="loadPage(pageNumber)">
                      {{ pageNumber }}
                    </a>
                  </li>
                  <li class="page-item" :class="{ disabled: page === totalPages }">
                    <a class="page-link" href="javascript:void(0);" @click="loadPage(page + 1)">
                      Next
                    </a>
                  </li>
                </ul>
              </nav>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- button edit modal -->
    <div
      id="view-aso"
      class="modal fade"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-lg-10">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="ModalLabel" class="modal-title">
              ASO Cabang Jakarta
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div>
              <h6>ASO Cabang Jakarta</h6>
              <p class="text-sm mb-0">
                Nama PIC
                <b>Herman</b> Nomor HP
                <b>1234567890</b>
              </p>
              <p class="text-sm">
                NIK/NPK :
                <b>243598234</b>
              </p>

              <p class="text-sm">
                Jumlah Anggota :
                <b>32</b>
              </p>
            </div>
            <p>Jalan Alamat nya/Lokasi.</p>

            <div>
              <p class="text-sm mb-0">
                Type
                <span class="badge badge-success badge-sm">Internal</span>
              </p>
              <p class="text-sm mb-0">
                Status
                <span class="badge badge-success badge-sm">Aktif</span>
              </p>
              <p class="text-sm">
                NIK/NPK :
                <b>243598234</b>
              </p>
            </div>

          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn bg-gradient-secondary btn-sm"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End button edit modal -->
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import setTooltip from "@/assets/js/tooltip.js";
import axios from 'axios';
var columns = [
  {
    select: 0,
    render: function (data) {
      return `<div class="d-flex">
          <h6 class="my-auto ms-3">${data}</h6>
        </div>
      `
    }
  },
  {
    select: [1,2,3,4,5],
    render: function (data) {
      return `<span class="text-sm">${data}</span>
      `
    }
  },
  {
    select: 4,
    render: function (data) {
      return `<span class="text-sm">${data}</span>
      `
    }
  },
  {
    select: 6,
    render: function (data) {
      let status = (data === 'true') ? "AKTIF" :  "TIDAK AKTIF"
      let color = (data === 'true') ? "badge-success" :  "badge-danger"
      return `
      <span class="badge ${color} badge-sm">
        ${status}
      </span>
      `
    },
  },
  {
    select: 7,
    render: function (data) {
      return `
        <!-- button edit modal -->
       <a
          class="text-sm"
          href="javascript: void(0);"
        >
          <span
            class="mx-3"
          >
            <i
            class="fas fa-eye text-secondary data-bs-toggle="tooltip" data-bs-placement="top" title="Detail"
              data-action="view"
              data-id="${data}"
            ></i>
          </span>
        </a>
        <a
          class="text-sm"
          href="javascript:void(0);"
        >
          <span
            class="mx-3"
          >
            <i
             class="fas fa-user-edit text-secondary"
             data-action="edit"
             data-id="${data}"
            ></i>
          </span>
        </a>
      `
    }
  },
]

var labels = {
  placeholder: "Cari Data...",
  perPage: "Menampilkan {select} ASO Cabang per halaman",
  noRows: "Tidak ada data yang ditampilkan",
  info: "Menampilkan {start} ke {end} dari {rows} ASO Cabang (Halaman {page} dari {pages} halaman)"
}

export default {
  name: "AsoCentral",
  beforeMount() {
    if (localStorage.getItem("SideMenuType") !== "AsoCentral") {
        this.$router.go(-1);
    }
  },  
  data() {
    return {
      hasData: false,
      datatable: null,
      asoBranches: [],
      isLoading: false,
      page: 1, // Current page
      totalPages: 1, // Total number of pages
      searchQuery: '',
    }
  },
  mounted() {
    if (document.getElementById("products-list")) {
      this.datatable = new DataTable("#products-list", {
        processing: this.isLoading,
        searchable: false,
        fixedHeight: false,
        perPage: 16,
        columns: columns,
        labels: labels,
        paging: false,
        filters: {
          // Add the search filter
          search: this.searchQuery,
        },
      });
      this.datatable.body.addEventListener("click", this.handleAction)
      this.toggleLoading();
      this.loadASOBranches();
    }
    setTooltip(this.$store.state.bootstrap);
  },
  updated() {
    if (this.datatable && this.asoBranches.length === 0) {
      this.datatable.setMessage("Tidak ada data yang ditampilkan"); // Show empty label
    }
  },
  beforeUnmount() {
    this.datatable.destroy();
    this.datatable = null;
  },
  methods: {
    toggleLoading() {
      if (!this.datatable) {
        return;
      }
      if (!this.isLoading) {
        this.isLoading = true;
        this.datatable.setMessage("Memuat...");
        return;
      }
      this.isLoading = false;
    },
    handleAction(e) {
      if (e.target.getAttribute("data-action") === "edit") {
        let id = parseInt(e.target.getAttribute("data-id"), 10)
        this.editData(id, "aso.branches.edit")
      }
      if (e.target.getAttribute("data-action") === "view") {
        let id = parseInt(e.target.getAttribute("data-id"), 10)
        this.edit(id)
      }
    },
    edit(id) {
      this.$router.push({
        name: "aso.branches.detail",
        params: {
          orderList: id
        }
      })
    },
    editData(id, url) {
      this.$router.push({
        name: url,
        params: {
          id: id
        }
      })
    },
    performSearch() {
      if (this.datatable) {
        this.destroyDataTable(); // Destroy the current DataTable instance
      }
      
      if (document.getElementById("products-list")) {
        this.datatable = new DataTable("#products-list", {
          processing: this.isLoading,
          searchable: false,
          fixedHeight: false,
          perPage: 16,
          columns: columns,
          labels: labels,
          paging: false,
          filters: {
            // Add the search filter
            search: this.searchQuery,
          },
        });

        this.page = 1;

        this.datatable.body.addEventListener("click", this.handleAction)
        this.toggleLoading()
        this.loadASOBranches()
      }
    },
    loadASOBranches() {
      var token = window.localStorage.getItem("TOKEN");
      axios
        .get("https://api.darlink.id/v1/aso-branch", {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          },
          params: {
            page: this.page, // Include the page parameter
            search: this.searchQuery, // Add the search query parameter
          },
        })
        .then((response) => {
          if (!this.datatable) {
            return;
          }
          this.toggleLoading();
          if (response.data.code !== 200) {
            console.log(response.data.message);
            return;
          }

          if (response.data.tag === "user.aso-branch.empty") {
            this.hasData = false; // Set hasData to false if the API response indicates empty data
          } else {
            this.asoBranches = response.data.content;
            this.hasData = this.asoBranches.length > 0; // Set hasData based on the response
            this.totalPages = response.data.pagination.total_pages; // Update total pages

            this.updateDataTable();
            // this.datatable.insert({
            //   data: this.asoBranches.map((asoBranch) => {
            //     return [
            //       asoBranch.displayname,
            //       asoBranch.pic_name,
            //       asoBranch.phone,
            //       asoBranch.personal_id,
            //       asoBranch.type,
            //       asoBranch.location,
            //       (asoBranch.is_active) ? 'true' : 'false',
            //       `${asoBranch.id}`
            //     ];
            //   })
            // });
          }
          if (!this.hasData) {
            this.datatable.setMessage("Tidak ada data yang ditampilkan"); // Show empty label
          }
        })
        .catch((err) => {
          this.toggleLoading();
          console.log(err);
        });
    },
    destroyDataTable() {
      if (this.datatable) {
        this.datatable.destroy();
        this.datatable = null;
      }
    },
    // Function to update the DataTable
    updateDataTable() {
      // if (this.datatable) {
      //   this.datatable.destroy();
      // }

      // this.datatable = new DataTable("#products-list", {
      //   processing: this.isLoading,
      //   searchable: true,
      //   fixedHeight: false,
      //   perPage: 16,
      //   columns: columns,
      //   labels: labels,
      // });

      // this.datatable.body.addEventListener("click", this.handleAction);
      this.datatable.body.innerHTML = '';
      this.datatable.insert({
        data: this.asoBranches.map((asoBranch) => {
          return [
            asoBranch.displayname,
            asoBranch.pic_name,
            asoBranch.phone,
            asoBranch.personal_id,
            asoBranch.type,
            asoBranch.location,
            (asoBranch.is_active) ? 'true' : 'false',
            `${asoBranch.id}`
          ];
        })
      });
    },
    // Function to load data for a specific page
    loadPage(page) {
      if (page < 1 || page > this.totalPages) {
        return;
      }

      this.page = page;
      // this.loadASOBranches();
      if (this.datatable) {
        this.destroyDataTable(); // Destroy the current DataTable instance
      }
      
      if (document.getElementById("products-list")) {
        this.datatable = new DataTable("#products-list", {
          processing: this.isLoading,
          searchable: false,
          fixedHeight: false,
          perPage: 16,
          columns: columns,
          labels: labels,
          paging: false,
          filters: {
            // Add the search filter
            search: this.searchQuery,
          },
        });
        this.datatable.body.addEventListener("click", this.handleAction)
        this.toggleLoading()
        this.loadASOBranches()
      }
    },
  }
};
</script>
