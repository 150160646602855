<template>
  <div
    id="sidenav-collapse-main"
    class="collapse navbar-collapse w-auto h-auto h-100"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-Main
          nav-text="Dashboards"
          :collapse="false"
          :class="getRoutelvl2() === 'dashboard' ? 'active' : ''"
          url="/superadmin/dashboard"
          :aria-controls="''"
        >
          <template #icon>
            <i class="ni ni-shop text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-Main>
      </li>
      <li class="nav-item" v-if="permission.includes('aso_central')">
        <sidenav-Main
          nav-text="Aso Central"
          :collapse="false"
          :class="getRoutelvl2() === 'aso-central' ? 'active' : getRoutelvl2() === 'new-aso-central' ? 'active' : ''"
          url="/superadmin/aso-central"
          :aria-controls="''"
        >
          <template #icon>
            <i class="ni ni-world text-success text-sm opacity-10"></i>
          </template>
        </sidenav-Main>
      </li>
      <li class="nav-item" v-if="permission.includes('aso_branch')">
        <sidenav-Main
          nav-text="Aso Cabang"
          :collapse="false"
          :class="getRoutelvl2() === 'aso-cabang' ? 'active' : ''"
          url="/superadmin/aso-cabang"
          :aria-controls="''"
        >
          <template #icon>
            <i class="ni ni-archive-2 text-success text-sm opacity-10"></i>
          </template>
        </sidenav-Main>
      </li>
      <li class="nav-item">
        <sidenav-Main
          nav-text="Agents"
          :collapse="false"
          :class="getRoutelvl2() === 'aso-agent' ? 'active' : ''"
          url="/superadmin/aso-agent"
          :aria-controls="''"
        >
          <template #icon>
            <i class="ni ni-single-02 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-Main>
      </li>
      <li class="nav-item" v-if="permission.includes('fincoy_pic')">
        <sidenav-Main
          nav-text="Fincoy PIC"
          :collapse="false"
          :class="getRoutelvl2() === 'fincoy-pic' ? 'active' : ''"
          url="/superadmin/fincoy-pic"
          :aria-controls="''"
        >
          <template #icon>
            <i class="ni ni-send text-info text-sm opacity-10"></i>
          </template>
        </sidenav-Main>
      </li>
      <!-- <li class="nav-item">
        <sidenav-Main
          nav-text="Agents"
          :collapse="false"
          :class="getRoutelvl2() === 'aso-agent' ? 'active' : ''"
          url="/superadmin/aso-agent"
          :aria-controls="''"
        >
          <template #icon>
            <i class="ni ni-single-02 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-Main>
      </li> -->
      <li class="nav-item" v-if="permission.includes('fincoy_ho')">
        <sidenav-Main
          nav-text="Fincoy HO"
          :collapse="false"
          :class="getRoutelvl2() === 'fincoy-ho' ? 'active' : ''"
          url="/superadmin/fincoy-ho"
          :aria-controls="''"
        >
          <template #icon>
            <i class="ni ni-active-40 text-secondary text-sm opacity-10"></i>
          </template>
        </sidenav-Main>
      </li>
      <li class="nav-item" v-if="permission.includes('order')">
        <sidenav-collapse
          collapse-ref="Order"
          nav-text="Order"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
        >
          <template #icon>
            <i class="ni ni-tag text-warning text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item
                :to="{ name: 'Superadmin Pencairan Dana User' }"
                mini-icon="D"
                text="Pencairan Dana Konsumen"
              />
              <sidenav-item
                :to="{ name: 'superadmin.order-completed' }"
                mini-icon="D"
                text="Completed"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="permission.includes('withdraw')">
        <sidenav-Main
          nav-text="Proses Withdraw"
          :collapse="false"
          :class="getRoutelvl2() === 'prosess-wd' ? 'active' : ''"
          url="/superadmin/prosess-wd"
          :aria-controls="''"
        >
          <template #icon>
            <i class="ni ni-money-coins text-success text-sm opacity-10"></i>
          </template>
        </sidenav-Main>
      </li>
      <li class="nav-item" v-if="permission.includes('management_info')">
        <sidenav-Main
          nav-text="Management Info"
          :collapse="false"
          :class="getRoutelvl2() === 'managementinfo-list' ? 'active' : ''"
          url="/superadmin/managementinfo-list"
          :aria-controls="''"
        >
          <template #icon>
            <i class="ni ni-air-baloon text-info text-sm opacity-10"></i>
          </template>
        </sidenav-Main>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="staff"
          nav-text="Staff"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
        >
          <template #icon>
            <i class="ni ni-badge text-success text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item
                :to="{ name: 'superadmin.staff.role' }"
                mini-icon="D"
                text="Role Staff"
              />
              <sidenav-item
                :to="{ name: 'superadmin.staff' }"
                mini-icon="D"
                text="Staff List"
              />
              <!-- <sidenav-item :to="{ name: 'CRM' }" mini-icon="C" text="CRM" /> -->
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item" v-if="permission.includes('report')">
        <sidenav-collapse
          collapse-ref="report"
          nav-text="Report"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
        >
          <template #icon>
            <i class="ni ni-badge text-warning text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item
                :to="{ name: 'superadmin.report.order' }"
                mini-icon="D"
                text="Report Order"
              />
              <sidenav-collapse-item
                refer="reportorder"
                mini-icon="V"
                text="Report By ASO"
              >
                <template #nav-child-item>
                  <sidenav-item
                    :to="{ name: 'superadmin.report.aso-central' }"
                    mini-icon="L"
                    text="By Aso Central"
                  />
                  <sidenav-item
                    :to="{ name: 'superadmin.report.aso-branch' }"
                    mini-icon="D"
                    text="By Aso Cabang"
                  />
                  <sidenav-item
                    :to="{ name: 'superadmin.report.agent' }"
                    mini-icon="A"
                    text="By Agent"
                  />
                </template>
              </sidenav-collapse-item>
              <sidenav-collapse-item
                refer="ReportFincoy"
                mini-icon="V"
                text="Report Fincoy"
              >
                <template #nav-child-item>
                  <sidenav-item
                    :to="{ name: 'superadmin.report.fincoy-ho' }"
                    mini-icon="L"
                    text="Fincoy HO"
                  />
                  <sidenav-item
                    :to="{ name: 'superadmin.report.fincoy-pic' }"
                    mini-icon="D"
                    text="Fincoy PIC"
                  />
                </template>
              </sidenav-collapse-item>
              <!-- <sidenav-item :to="{ name: 'CRM' }" mini-icon="C" text="CRM" /> -->
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-Main
          nav-text="Settings"
          :collapse="false"
          :class="getRoutelvl2() === 'settings' ? 'active' : ''"
          url="/superadmin/settings"
          :aria-controls="''"
        >
          <template #icon>
            <i class="ni ni-settings text-success text-sm opacity-10"></i>
          </template>
        </sidenav-Main>
      </li>
    </ul>
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavMain from "./SidenavMain.vue";
import SidenavCollapseItem from "./SidenavCollapseItem.vue";
import axios from "axios";

export default {
  name: "SidenavListSuperadmin",
  data() {
    return {
      permission: []
    }
  },
  components: {
    SidenavItem,
    SidenavCollapse,
    SidenavMain,
    SidenavCollapseItem
  },
  mounted() {
    var token = window.localStorage.getItem("TOKEN")
    if (!token) {
      // Redirect to "superadmin.login"
      this.$router.push({ name: "superadmin.login" });
      return;
    }
    
    axios.get('https://api.darlink.id/v1/permission', {
      withCredentials: true,
      headers: {
        Accept: "application/json",
        Authorization: token
      }
    }).then((response) => {
      this.permission = response.data.content;
      console.log(this.permission);
    }).catch((error) => {
      console.log(error);
    })
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    getRoutelvl2() {
      const routeArr = this.$route.path.split("/");
      return routeArr[2];
    }
  }
};
</script>
