<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="mx-auto col-lg-12 col-12">
        <div class="mt-4 card card-body">
          <h6 class="mb-0">Tambah Fincoy PIC</h6>
          <hr class="my-3 horizontal dark" />

          <div class="mt-4 row">
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label for="projectName" class="form-label">Nama Fincoy HO </label>
                    <select v-model="fincoy_ho" class="form-control">
                    <template v-for="dataHo in listHo" :key="dataHo.id">
                      <option :value="dataHo.id">{{dataHo.name}}</option>
                    </template>
                  </select>
                </div>

              <div class="form-group">
                <label>Status Fincoy PIC</label>
                <div class="form-check form-switch ms-1">
                  <input
                    id="flexSwitchCheckDefault"
                    v-model="isActive"
                    class="form-check-input"
                    type="checkbox"
                    data-type="warning"
                    data-content="Once a project is made private, you cannot revert it to a public project."
                    data-title="Warning"
                    data-icon="ni ni-bell-55"
                    disabled
                  />
                  <label
                    class="form-check-label"
                    for="flexSwitchCheckDefault"
                  >
                    <span
                      class="badge badge-md"
                      :class="{
                        'badge-danger': !isActive,
                        'badge-success': isActive,
                      }"
                    >
                      {{ (isActive) ? 'AKTIF' : 'NON-AKTIF' }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label for="projectName" class="form-label">Username</label>
              <input id="projectName" v-model="username" type="text" class="form-control" />
            </div>
            <div class="col-6">
              <label for="projectName" class="form-label">Password</label>
              <input id="projectName" v-model="password" type="password" class="form-control" />
            </div>
            <div class="col-6">
              <label for="projectName" class="form-label">Nama PIC</label>
              <input id="projectName" v-model="pic_name" type="text" class="form-control" />
            </div>
            <div class="col-6">
              <label for="projectName" class="form-label">NIK/NIP</label>
              <input id="projectName" v-model="nik" type="text" class="form-control" maxlength="16"/>
            </div>
            <div class="col-6">
              <label class="form-label">Tanggal Lahir PIC</label>
              <flat-pickr
                v-model="endDate"
                class="form-control datetimepicker"
                placeholder="Please select end date"
                :config="config"
              ></flat-pickr>
            </div>
            <div class="col-6">
              <label for="projectName" class="form-label">Email PIC</label>
              <input id="projectName" v-model="email" type="text" class="form-control" />
            </div>
            <div class="col-6">
              <label for="projectName" class="form-label">Email Company</label>
              <input id="projectName" v-model="email_company" type="text" class="form-control" />
            </div>
            <div class="col-6">
              <label for="projectName" class="form-label">Lokasi Company</label>
              <input id="projectName" v-model="location" type="text" class="form-control" />
            </div>
            <div class="col-6">
              <label for="projectName" class="form-label">Jabatan PIC</label>
              <input id="projectName" v-model="job" type="text" class="form-control" />
            </div>
          </div>
          <label class="mt-4">Alamat Kantor</label>
          <div id="editor">
            <QuillEditor
              v-model:content="address"
              content-type="text"
              toolbar="minimal"
              theme="snow"
            />
          </div>
          <label class="mt-4 form-label">Upload Company ID CARD (.jpg, .png)</label>
          <form
            id="uploadId"
            class="form-control dropzone"
          >
            <div class="fallback">
              <input name="file" type="file" @change="handleFileChange" multiple />
            </div>
          </form>
          <div class="mt-4 d-flex justify-content-end">
            <router-link to="./fincoy-pic">
              <button type="button" name="button" class="m-0 btn btn-light">
                Batal
              </button>
            </router-link>
            <button
              type="button"
              name="button"
              class="m-0 btn bg-gradient-success ms-2"
              @click="submit"
              :disabled="buttonDisabled"
            >
              <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span v-else>Tambah Fincoy PIC</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import Dropzone from "dropzone";
import Choices from "choices.js";
import { QuillEditor } from '@vueup/vue-quill';
import axios from 'axios';

export default {
  name: "FincoyHoNew",
  components: {
    flatPickr,
    QuillEditor
  },
  data() {
    return {
      date: "",
      endDate: "",
      config: {
        allowInput: true,
      },
      listHo: [],
      username: "",
      password: "",
      pic_name: "",
      phonenumber: "",
      email: "",
      email_company: "",
      nik: "",
      job: "",
      address: "",
      idcard: "",
      fincoy_ho: "",
      isActive: false,
      orderPic: "",
      buttonDisabled: true,
      isLoading: false,
    };
  },
  mounted() {
    this.countPic();
    var token = window.localStorage.getItem("TOKEN")
    axios.get('https://api.darlink.id/v1/fincoy?status=active', {
      withCredentials: true,
      headers: {
        Accept: "application/json",
        Authorization: token
      }
    }).then((response) => {
      console.log(response.data.content);
      this.listHo = response.data.content;
    });
    // if (document.getElementById("editor")) {
    //   new Quill("#editor", {
    //     theme: "snow", // Specify theme in configuration
    //   });
    // }    

    if (document.getElementById("choices-category")) {
      var element = document.getElementById("choices-category");
      new Choices(element, {
        searchEnabled: false,
        allowHTML: true,
        itemSelectText: '',
      });
    }
    
    if (document.getElementById("choices-aso")) {
      var element1 = document.getElementById("choices-aso");
      new Choices(element1, {
        searchEnabled: false,
        allowHTML: true,
        itemSelectText: '',
        position: 'auto',
      });
    }

    this.initializeDropzone();
    // Dropzone.autoDiscover = false;
    // var drop = document.getElementById("dropzone");
    // new Dropzone(drop, {
    //   url: "/file/post",
    //   addRemoveLinks: true,
    // });
  },
  methods: {
    countPic() {
      var token = window.localStorage.getItem("TOKEN");
      axios
        .get("https://api.darlink.id/v1/fincoy/pic", {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          }
        })
        .then((response) => {
          this.orderPic = response.data.content;
          this.buttonDisabled = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    randomString(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      const charactersLength = characters.length;

      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return result;
    },
    generateCodefincoy() {
      // this.username = this.randomString(3);
      // this.password = this.username;

      // console.log("fincoy user = ", this.username)
    },
    initializeDropzone() {
      Dropzone.autoDiscover = false;
      const drop = document.getElementById("uploadId");
      const dropzone = new Dropzone(drop, {
        url: "https://api.darlink.id/v1/assets/upload",
        addRemoveLinks: true,
        acceptedFiles: 'image/*',
        sending: (file, xhr, formData) => {
          formData.append("context", "COMPANY_ID_CARD"); // Include the context parameter in the request
        },
        success: (file, response) => {
          console.log("Upload success:", response); // Handle the successful response
          // this.MOUDocs = response.content.url;
          this.idcard = response.content.name;
          console.log("id card = ", this.idcard);
        },
      });
      // Add any additional configuration or event listeners as needed

      // Move the handleFileChange logic here or modify it accordingly
      dropzone.on("addedfile", (file) => {
        // this.MOUDocs = file;
        console.log(file);
      });
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      const dropzone = Dropzone.forElement("#uploadId");
      dropzone.removeAllFiles();
      dropzone.addFile(file);
    },
    validateEmail(email) {
      // Regular expression for validating an Email
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    validateEmail2(email_company) {
      // Regular expression for validating a Phone number
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email_company);
    },
    validateNik(nik) {
      // Regular expression for validating a Phone number
      const phoneRegex = /^\d{16}$/;
      return phoneRegex.test(nik);
    },
    submit() {
      this.isLoading= true;
      this.generateCodefincoy();
      var token = window.localStorage.getItem("TOKEN")

      //Get File name
      // const fileName = this.MOUDocs ? this.MOUDocs.name : '';

      // Add email and phone format validation
      if (!this.validateEmail(this.email)) {
        this.isLoading= false;
        this.$swal({
          title: "Invalid Email",
          text: "Please enter a valid email address.",
          icon: "warning",
          confirmButtonText: "OK",
          confirmButtonColor: "#3085d6",
        });
        return;
      }

      if (!this.validateEmail2(this.email_company)) {
        this.isLoading= false;
        this.$swal({
          title: "Invalid Email",
          text: "Please enter a valid email company address.",
          icon: "warning",
          confirmButtonText: "OK",
          confirmButtonColor: "#3085d6",
        });
        return;
      }

      if (!this.validateNik(this.nik)) {
        this.isLoading= false;
        this.$swal({
          title: "Invalid NIK",
          text: "Please enter a valid 16-digit number.",
          icon: "warning",
          confirmButtonText: "OK",
          confirmButtonColor: "#3085d6",
        });
        return;
      }

      const picData = new FormData();
      console.log('HO = ', this.fincoy_ho);
      // picData.append("displayname", this.fincoy_ho);
      picData.append("displayname", this.pic_name);
      
      // if(this.orderPic === undefined)
      // {
      //   picData.append("username", `${this.username}-1`);
      // }
      // else
      // {
      //   picData.append("username", `${this.username}-${this.orderPic.length + 1}`);
      // }
      
      picData.append("username", this.username);
      picData.append("password", this.password);
      picData.append("email", this.email);
      picData.append("address", this.address);
      picData.append("personal_id", this.nik);
      // picData.append("personal_id_scan", this.idcard);
      picData.append("company_id_scan", this.idcard);
      picData.append("location", this.location);
      picData.append("address", this.address);
      picData.append("department", this.job);
      picData.append("dob", this.endDate);
      picData.append("company_email", this.email_company);
      
      picData.append("status", (this.isActive) ? 'active' : 'inactive');

      axios
        .post("https://api.darlink.id/v1/fincoy/" + this.fincoy_ho + "/pic", picData,
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          }
        })
        .then((response) => {
          this.isLoading= false;
          // SWEETALLERT
          this.$swal({
            title: "Berhasil",
            text: "Penambahan Fincoy PIC berhasil dilakukan",
            icon: "success",
            confirmButtonText: "Tutup",
            confirmButtonColor: "#3085d6",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push({ name: "aso.fincoy-pic" })
            }
          });

          if (response.data.code != 201) {
            return
          }
          console.log(response)
          this.$router.push({ name: "aso.fincoy-pic" })
        })
        .catch((err) => {
          this.isLoading= false;
          if (err.response && err.response.data && err.response.data.validation) {
            const validationErrors = err.response.data.validation;
            let errorMessage = "Validation Error:\n";
            validationErrors.forEach((error) => {
              errorMessage += `${error.mesg}\n`;
            });
            this.$swal({
              title: "Validation Error",
              text: errorMessage,
              icon: "error",
              confirmButtonText: "OK",
              confirmButtonColor: "#d33",
            });
          } else {
            this.$swal({
              title: "Error",
              text: err.response.data.message,
              icon: "error",
              confirmButtonText: "OK",
              confirmButtonColor: "#d33",
            });
          }
        });
    }
  }
};
</script>
