<template>
  <div
    id="sidenav-collapse-main"
    class="collapse navbar-collapse w-auto h-auto h-100"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-Main
          nav-text="Dashboards"
          :collapse="false"
          :class="getRoutelvl2() === 'dashboard' ? 'active' : ''"
          url="/fincoy/dashboard"
          :aria-controls="''"
        >
          <template #icon>
            <i class="ni ni-shop text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-Main>
      </li>
      <li class="nav-item">
        <sidenav-Main
          nav-text="Profile Fincoy"
          :collapse="false"
          :class="getRoutelvl2() === 'profile' ? 'active' : ''"
          url="/fincoy/profile"
          :aria-controls="''"
        >
          <template #icon>
            <i class="ni ni-single-02 text-success text-sm opacity-10"></i>
          </template>
        </sidenav-Main>
      </li>
      <li class="nav-item">
        <sidenav-Main
          nav-text="Update Password"
          :collapse="false"
          :class="getRoutelvl2() === 'update-password' ? 'active' : ''"
          url="/fincoy/update-password"
          :aria-controls="''"
        >
          <template #icon>
            <i class="ni ni-archive-2 text-success text-sm opacity-10"></i>
          </template>
        </sidenav-Main>
      </li>
      <li class="nav-item">
        <sidenav-Main
          nav-text="Price List"
          :collapse="false"
          :class="getRoutelvl2() === 'price-list' ? 'active' : ''"
          url="/fincoy/price-list"
          :aria-controls="''"
        >
          <template #icon>
            <i class="ni ni-active-40 text-secondary text-sm opacity-10"></i>
          </template>
        </sidenav-Main>
      </li>
      <li class="nav-item" >
        <sidenav-collapse
          collapse-ref="Order"
          nav-text="Order"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
        >
          <template #icon>
            <i class="ni ni-bell-55 text-info text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item
                :to="{ name: 'Fincoy Order Assign' }"
                mini-icon="D"
                :text="formattedTextAssign"
              />
              <sidenav-item
                :to="{ name: 'Fincoy Order Proses' }"
                mini-icon="D"
                :text="formattedTextProcess"
              />
              <sidenav-item
                :to="{ name: 'Fincoy Order Reject' }"
                mini-icon="D"
                :text="formattedTextReject"
              />
              <sidenav-item
                :to="{ name: 'Fincoy Order Approved' }"
                mini-icon="D"
                :text="formattedTextApprove"
              />
              <sidenav-item
                :to="{ name: 'Fincoy Order Pencairan Dana User' }"
                mini-icon="D"
                :text="formattedTextCair"
              />
              <!-- <sidenav-item
                :to="{ name: 'Fincoy Order Pencairan Dana Darlink' }"
                mini-icon="D"
                text="Pencariran Dana Darlink"
              />
              <sidenav-item
                :to="{ name: 'Fincoy Order Completed' }"
                mini-icon="D"
                text="Completed"
              /> -->
            </ul>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavMain from "./SidenavMain.vue";
import axios from 'axios';

export default {
  name: "SidenavListFincoy",
  components: {
    SidenavItem,
    SidenavCollapse,
    SidenavMain,
  },
  data() {
      return {
        counted: 0,
        countedAssign: 0,
        countedProcess: 0,
        countedReject: 0,
        countedApprove: 0,
        countedCair: 0,
      }
    },
  computed: {
    formattedTextAssign() {
      return 'Order Assign (' + this.countedAssign + ')';
    },
    formattedTextProcess() {
      return 'Order Process (' + this.countedProcess + ')';
    },
    formattedTextReject() {
      return 'Order Reject (' + this.countedReject + ')';
    },
    formattedTextApprove() {
      return 'Order Approve (' + this.countedApprove + ')';
    },
    formattedTextCair() {
      return 'Pencairan Dana Kons (' + this.countedCair + ')';
    },
  },
  mounted() {
    // Check if the token is empty
    var token = window.localStorage.getItem("TOKEN")
    if (!token) {
      // Redirect to "superadmin.login"
      this.$router.push({ name: "superadmin.login" });
      return;
    }

    // this.loadCounted();
    this.loadCountedAssign();
    this.loadCountedProcess();
    this.loadCountedReject();
    this.loadCountedApprove();
    this.loadCountedCair();
    
    // Set up the interval to call loadCounted() every 30 seconds
    this.interval = setInterval(() => {
      // this.loadCounted();
      this.loadCountedAssign();
      this.loadCountedProcess();
      this.loadCountedReject();
      this.loadCountedApprove();
      this.loadCountedCair();
    }, 30000); // 30 seconds in milliseconds
  },
  beforeUnmount() {
    // Clear the interval to prevent memory leaks
    clearInterval(this.interval);
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    getRoutelvl2() {
      const routeArr = this.$route.path.split("/");
      return routeArr[2];
    },
    loadCountedAssign() {
        var token = window.localStorage.getItem("TOKEN")
        axios
          .get("https://api.darlink.id/v1/order?status=assigned", {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token
            }
          })
          .then((response) => {
            if (response.data.code != 200) {
              return
            }

            if(response.data.tag == 'content.order.empty')
            {
              this.countedAssign = 0;
            }
            else
            {
              this.countedAssign = response.data.pagination.total_items
            }
            
            // console.log(this.orderLists)
            
          })
          .catch((err) => {
            // this.toggleLoading()
            console.log(err);
          });
      },
    loadCountedProcess() {
        var token = window.localStorage.getItem("TOKEN")
        axios
          .get("https://api.darlink.id/v1/order?status=process", {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token
            }
          })
          .then((response) => {
            if (response.data.code != 200) {
              return
            }

            if(response.data.tag == 'content.order.empty')
            {
              this.countedProcess = 0;
            }
            else
            {
              this.countedProcess = response.data.pagination.total_items
            }
            // console.log(this.orderLists)
            
          })
          .catch((err) => {
            // this.toggleLoading()
            console.log(err);
          });
      },
    loadCountedReject() {
        var token = window.localStorage.getItem("TOKEN")
        axios
          .get("https://api.darlink.id/v1/order?status=rejected", {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token
            }
          })
          .then((response) => {
            if (response.data.code != 200) {
              return
            }

            if(response.data.tag == 'content.order.empty')
            {
              this.countedReject = 0;
            }
            else
            {
              this.countedReject = response.data.pagination.total_items
            }
            // console.log(this.orderLists)
            
          })
          .catch((err) => {
            // this.toggleLoading()
            console.log(err);
          });
      },
    loadCountedApprove() {
        var token = window.localStorage.getItem("TOKEN")
        axios
          .get("https://api.darlink.id/v1/order?status=approved", {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token
            }
          })
          .then((response) => {
            if (response.data.code != 200) {
              return
            }
            
            if(response.data.tag == 'content.order.empty')
            {
              this.countedApprove = 0;
            }
            else
            {
              this.countedApprove = response.data.pagination.total_items
            }
            // console.log(this.orderLists)
            
          })
          .catch((err) => {
            // this.toggleLoading()
            console.log(err);
          });
      },
    loadCountedCair() {
        var token = window.localStorage.getItem("TOKEN")
        axios
          .get("https://api.darlink.id/v1/order?status=funded_user", {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token
            }
          })
          .then((response) => {
            if (response.data.code != 200) {
              return
            }

            if(response.data.tag == 'content.order.empty')
            {
              this.countedCair = 0;
            }
            else
            {
              this.countedCair = response.data.pagination.total_items
            }
            // console.log(this.orderLists)
            
          })
          .catch((err) => {
            // this.toggleLoading()
            console.log(err);
          });
      },
  }
};
</script>
