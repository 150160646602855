<template>
  <div class="card h-100">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex justify-content-between">
        <h6 class="mb-0">{{ title }}</h6>
      </div>
    </div>
    <div class="p-3 pb-0 mt-4 mb-4 card-body">
      <div class="row">
        <div class="col-7 text-start">
          <div class="chart">
            <!-- <canvas :id="chartId" class="chart-canvas" height="200"></canvas> -->
            <canvas :id="id" class="chart-canvas" height="200"></canvas>
          </div>
        </div>
        <div class="my-auto col-5" id="loadLabel">
           <!-- Use v-for to loop through the labels and display them -->
          <span
            v-for="(label, index) in chart.labels"
            :key="index"
            class="badge badge-md badge-dot me-4 d-block text-start"
          >
            <i class="bg-info"></i>
            <span class="text-xs text-dark">{{ label }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
export default {
  name: "ChannelsChartCard",
  props: {
    id: {
      type: String,
      default: "channels-chart-card",
    },
    title: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "300",
    },
    chart: {
      type: Object,
      required: true,
      labels: Array,
      datasets: {
        type: Object,
        required: true,
        label: String,
        data: Array,
      },
    },
  },
  data() {
    return {
      chartId: "chart-pie",
    };
  },
  mounted() {
    this.initChart()
  },
  methods: {
    initChart(){
      var pieChart = document.getElementById(this.id).getContext("2d");

      let chartStatus = Chart.getChart(this.id);
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }

      // Pie chart
      new Chart(pieChart, {
        type: "pie",
        data: {
          labels: this.chart.labels,
          // datasets: [
          //   {
          //     label: "Projects",
          //     weight: 9,
          //     cutout: 0,
          //     tension: 0.9,
          //     pointRadius: 2,
          //     borderWidth: 2,
          //     backgroundColor: ["#17c1e8", "#4BB543 ", "#3A416F", "#a8b8d8", "#91288d"],
          //     data: [15, 20, 12, 60, 10],
          //     fill: false,
          //   },
          // ],
          datasets: [
              {
                label: this.chart.datasets.label,
                weight: 9,
                cutout: 0,
                tension: 0.9,
                pointRadius: 2,
                borderWidth: 2,
                backgroundColor: "#3A416F",
                data: this.chart.datasets.data,
                fill: false,
              },
            ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: false,
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: false,
              },
            },
          },
        },
      });
    },
  }
};
</script>
