<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Daftar Management Info</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <router-link to="./management-info-new">
                  <a
                    class="mb-0 btn bg-gradient-success btn-sm"
                    target="_blank"
                    >+&nbsp; Tambah Info</a>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <div class="row">
                <div class="col-lg-4" style="margin: 10px;">
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      v-model="searchQuery"
                      placeholder="Search Fincoy PIC..."
                    />
                  </div>
                  
                  <a
                    href="#"
                    class="mb-0 btn bg-gradient-success btn-sm"
                    style="margin-right: 10px;"
                    @click="performSearch"
                    >+&nbsp; search</a
                  >
                </div>
              </div>

              <table id="products-list" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Judul</th>
                    <th>Target Role</th>
                    <!-- <th>Aso Central</th> -->
                    <th>Tanggal</th>
                    <!-- <th>Area</th> -->
                    <th>Posisi</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody></tbody>
                <tfoot>
                  <tr>
                    <th>Judul</th>
                    <th>Target Role</th>
                    <!-- <th>Aso Central</th> -->
                    <th>Tanggal</th>
                    <!-- <th>Area</th> -->
                    <th>Posisi</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </tfoot>
              </table>

              <!-- DataTable's built-in pagination -->
              <nav aria-label="Page navigation">
                <ul class="pagination justify-content-end">
                  <li class="page-item" :class="{ disabled: page === 1 }">
                    <a class="page-link" href="javascript:void(0);" @click="loadPage(page - 1)">
                      Prev
                    </a>
                  </li>
                  <li class="page-item" v-for="pageNumber in totalPages" :key="pageNumber" :class="{ active: pageNumber === page }">
                    <a class="page-link" href="javascript:void(0);" @click="loadPage(pageNumber)">
                      {{ pageNumber }}
                    </a>
                  </li>
                  <li class="page-item" :class="{ disabled: page === totalPages }">
                    <a class="page-link" href="javascript:void(0);" @click="loadPage(page + 1)">
                      Next
                    </a>
                  </li>
                </ul>
              </nav>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- button edit modal -->
    <div
      id="view-aso"
      class="modal fade"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-lg-10">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="ModalLabel" class="modal-title">
              ASO Central Jakarta
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div>
              <h6>ASO Central Jakarta</h6>
              <p class="text-sm mb-0">
                Nama PIC
                <b>Herman</b> Nomor HP
                <b>1234567890</b>
              </p>
              <p class="text-sm">
                NIK/NPK :
                <b>243598234</b>
              </p>

              <p class="text-sm">
                Jumlah Anggota :
                <b>32</b>
              </p>
            </div>
            <p>Jalan Alamat nya/Lokasi.</p>

            <div>
              <p class="text-sm mb-0">
                Status
                <span class="badge badge-success badge-sm">Aktif</span>
              </p>
              <p class="text-sm">
                NIK/NPK :
                <b>243598234</b>
              </p>
            </div>

          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn bg-gradient-secondary btn-sm"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End button edit modal -->
  </div>

  <div class="loading-overlay" v-if="isLoading">
    <!-- You can use a loading spinner or any other loading content here -->
    <div class="spinner"></div>
  </div>
</template>

<style scoped>
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .spinner {
    /* Style your loading spinner here */
    border: 5px solid rgba(0, 0, 0, 0.3);
    border-top: 5px solid #3498db; /* Blue color for the spinner */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
</style>

<script>
import { DataTable } from "simple-datatables";
import setTooltip from "@/assets/js/tooltip.js";
import axios from "axios";

var columns = [
  {
    select: 0,
    render: function (data) {
      return `<div class="d-flex">
          <h6 class="my-auto ms-3">${data}</h6>
        </div>
      `
    }
  },
  {
    select: [1,2,3,4,5],
    render: function (data) {
      // var permissions = ""
      // data.forEach(permission => {
      //   permissions = `${permissions}, ${permission}`
      // });
      return `<span class="text-sm">${data}</span>
      `
    }
  },
  // {
  //   select: 3,
  //   render: function (data) {
  //     var creationDate = new Date(data)
  //     // var permissions = ""
  //     // data.forEach(permission => {
  //     //   permissions = `${permissions}, ${permission}`
  //     // });
  //     return `<span class="text-sm">${creationDate.getDay() + 1}-${creationDate.getMonth()-1}-${creationDate.getFullYear()}</span>
  //     `
  //   }
  // },
  {
    select: 4,
    render: function (data) {
      let status = (data === 'true') ? "AKTIF" :  "TIDAK AKTIF"
      let color = (data === 'true') ? "badge-success" :  "badge-danger"
      return `
      <span class="badge ${color} badge-sm">
        ${status}
      </span>
      `
    },
  },
  {
    select: 5,
    render: function (data) {
      let datas = JSON.parse(data)
      return `
        <!-- button edit modal -->
        <!-- <a
          href="javascript:void(0);"
          data-bs-original-title="Preview product"
          data-bs-toggle="modal"
          data-bs-target="#view-aso-central""
        >
          <i class="fas fa-eye text-secondary"></i>
        </a> -->
        <a
          class="text-sm"
          href="javascript:void(0);"
        >
          <span
            class="mx-3"
          >
            <i
             class="fas fa-user-edit text-secondary"
             data-action="edit"
             data-id="${datas.id}"
            ></i>
          </span>
        </a>
        <a
          class="text-sm"
          href="javascript:void(0);"
        >
          <i
            class="fas fa-trash text-danger"
            data-action="delete"
            data-id="${datas.id}"
            data-name="${datas.title}"
          ></i>
        </a>
      `
    }
  },
]

var labels = {
  placeholder: "Cari Info...",
  perPage: "Menampilkan {select} Info per halaman",
  noRows: "Tidak ada Info yang ditampilkan",
  info: "Menampilkan {start} ke {end} dari {rows} Info (Halaman {page} dari {pages} halaman)"
}

export default {
  name: "ManagementInfoList",
  data() {
    return {
      isLoading: false,
      datatable: null,
      announcements: [],
      page: 1, // Current page
      totalPages: 1, // Total number of pages
      searchQuery: '',
    }
  },
  beforeMount() {
    if (localStorage.getItem("SideMenuType") !== "SuperAdmin") {
        this.$router.go(-1);
    }
  },
  mounted() {
    if (document.getElementById("products-list")) {
      this.datatable = new DataTable("#products-list", {
        processing: this.isLoading,
        searchable: false,
        fixedHeight: false,
        perPage: 16,
        columns: columns,
        labels: labels,
        paging: false,
        filters: {
          // Add the search filter
          search: this.searchQuery,
        },
      });
      this.datatable.body.addEventListener("click", this.handleAction)
      this.toggleLoading()
      this.loadAnnouncements()
    }
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.datatable.destroy()
    this.datatable = null
  },
  methods: {
    toggleLoading() {
      if (!this.datatable) {
        return
      }
      if (!this.isLoading) {
        this.isLoading = true
        this.datatable.setMessage("Memuat...")
        return
      }
      this.isLoading = false
    },
    // Function to update the DataTable
    performSearch() {
      if (this.datatable) {
        this.destroyDataTable(); // Destroy the current DataTable instance
      }
      
      if (document.getElementById("products-list")) {
        this.datatable = new DataTable("#products-list", {
          processing: this.isLoading,
          searchable: false,
          fixedHeight: false,
          perPage: 16,
          columns: columns,
          labels: labels,
          paging: false,
          filters: {
            // Add the search filter
            search: this.searchQuery,
          },
        });

        this.page = 1;

        this.datatable.body.addEventListener("click", this.handleAction)
        this.toggleLoading()
        this.loadAnnouncements()
      }
    },
    loadAnnouncements() {
      var token = window.localStorage.getItem("TOKEN")
      axios
        .get("https://api.darlink.id/v1/announcement", {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          },
          params: {
            page: this.page, // Include the page parameter
            search: this.searchQuery, // Add the search query parameter
          },
        })
        .then((response) => {
          this.toggleLoading()
          if (response.data.code != 200) {
            return
          }
          if (response.data.tag === "content.announcement.empty") {
            this.datatable.setMessage("Tidak ada data yang ditampilkan")
            return
          }
          this.announcements = response.data.content
          this.totalPages = response.data.pagination.total_pages; // Update total pages

          this.updateDataTable();
        })
        .catch((err) => {
          this.toggleLoading()
          console.log(err);
        });
    },
    destroyDataTable() {
      if (this.datatable) {
        this.datatable.destroy();
        this.datatable = null;
      }
    },
    // Function to update the DataTable
    updateDataTable() {
      // if (this.datatable) {
      //   this.datatable.destroy();
      // }

      // this.datatable = new DataTable("#products-list", {
      //   processing: this.isLoading,
      //   searchable: true,
      //   fixedHeight: false,
      //   perPage: 15,
      //   columns: columns,
      //   labels: labels,
      // });

      // this.datatable.body.addEventListener("click", this.handleAction);
      this.datatable.body.innerHTML = '';
      this.datatable.insert({
        data: this.announcements.map((announcement) => {
          return [
            announcement.title,
            announcement.audience_type ? announcement.audience_type : "-",
            // "",
            new Date(announcement.updated_at).toLocaleString("id-ID", {
              day: "numeric",
              month: "long",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              // second: "numeric",
            }),
            `${announcement.position}`,
            (announcement.is_active) ? 'true' : 'false',
            JSON.stringify(announcement),
          ]
        })
      })
    },

    // Function to load data for a specific page
    loadPage(page) {
      if (page < 1 || page > this.totalPages) {
        return;
      }

      this.page = page;
      // this.loadAnnouncements();
      if (this.datatable) {
        this.destroyDataTable(); // Destroy the current DataTable instance
      }
      
      if (document.getElementById("products-list")) {
        this.datatable = new DataTable("#products-list", {
          processing: this.isLoading,
          searchable: false,
          fixedHeight: false,
          perPage: 16,
          columns: columns,
          labels: labels,
          paging: false,
          filters: {
            // Add the search filter
            search: this.searchQuery,
          },
        });
        this.datatable.body.addEventListener("click", this.handleAction)
        this.toggleLoading()
        this.loadAnnouncements()
      }
    },
    handleAction(e) {
      if (e.target.getAttribute("data-action") === "edit") {
        let id = parseInt(e.target.getAttribute("data-id"), 10)

        this.editAnnouncement(id)
      }
      if (e.target.getAttribute("data-action") === "delete") {
        let id = parseInt(e.target.getAttribute("data-id"), 10)
        let name = e.target.getAttribute("data-name")

        this.deleteAnnouncement(id, name)
      }
    },
    editAnnouncement(id) {
      this.$router.push({
        name: "superadmin.management-info.edit",
        params: {
          announcement: id
        }
      })
    },
    deleteAnnouncement(id, name) {
      this.$swal({
        title: 'Konfirmasi',
        text: `Anda yakin ingin hapus "${name}"?`,
        icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Batal',
        }).then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            var token = window.localStorage.getItem("TOKEN");
            axios
            .post("https://api.darlink.id/v1/announcement/"+id+"/remove", 
            null,
            {
              withCredentials: true,
              headers: {
                Accept: "application/json",
                Authorization: token
              }
            })
            .then((response) => {
              this.isLoading = false;
              // if response code not 200
              if (response.data.code != 200) {
                // show error message
                // sweetalert error
                this.$swal({
                  title: "Gagal",
                  text: response.data.message,
                  icon: "error",
                  button: "OK",
                })
                return
              }
              this.$swal({
                title: "Berhasil",
                text: "Berhasil Hapus Annoucement",
                icon: "success",
                confirmButtonText: "OK",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload()
                }
              });
              this.$router.push({ name: "superadmin.management-info" })
              // reload page after 1000ms
              setTimeout(() => {
                window.location.reload()
              }, 4000)
            })
            .catch((err) => {
              this.isLoading = false;
              // if error
              // show error message
              this.$store.dispatch("show-error", err) 
              if (err.response && err.response.data && err.response.data.validation) {
                const validationErrors = err.response.data.validation;
                let errorMessage = "Validation Error:\n";
                validationErrors.forEach((error) => {
                  errorMessage += `${error.mesg}\n`;
                });
                this.$swal({
                  title: "Validation Error",
                  text: errorMessage,
                  icon: "error",
                  confirmButtonText: "OK",
                  confirmButtonColor: "#d33",
                });
              } else {
                this.$swal({
                  title: "Error",
                  text: err.response.data.message,
                  icon: "error",
                  confirmButtonText: "OK",
                  confirmButtonColor: "#d33",
                });
              }
            })
          }
        });
    }
  }
};
</script>
