<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Order List</h5>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <label for="projectName" class="form-label">Dari Tgl</label>
                <argon-input type="date" placeholder="Date" value="2023-11-23" />
              </div>
              <div class="col-md-2">
                <label for="projectName" class="form-label">Sampai Tgl</label>
                <argon-input type="date" placeholder="Date" value="2023-11-23" />
              </div>
              <div class="col-md-4">
                <label for="projectName" class="form-label">Fincoy Ho</label>
                <select
                  id="choices-aso"
                  class="form-control"
                  name="choices-aso"
                >
                  <option value="Choice 1" selected="">Fincoy HO 1</option>
                  <option value="Choice 2">Fincoy HO 2</option>
                  <option value="Choice 3">Fincoy HO 3</option>
                  <option value="Choice 4">Fincoy HO 4</option>
                  <option value="Choice 5">Fincoy HO 5</option>
                </select>
              </div>
              <div class="col-md-4">
                <label for="projectName" class="form-label">Status</label>
                <select
                  id="choices-aso"
                  class="form-control"
                  name="choices-aso"
                >
                  <option value="Choice 1" selected="">Pending</option>
                  <option value="Choice 2">Assign</option>
                  <option value="Choice 3">Prosess</option>
                  <option value="Choice 4">Reject</option>
                  <option value="Choice 5">Approve</option>
                  <option value="Choice 6">Pencairan D.Konsumen</option>
                  <option value="Choice 7">Pencairan D.Darlink</option>
                  <option value="Choice 8">completed</option>
                </select>
              </div>
              <div class="col-md-12">
                <label for="projectName" class="form-label">Action</label>
                <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <a
                    href="#"
                    class="mb-0 btn bg-gradient-success btn-sm"
                    >+&nbsp; Filter</a
                  >
                  <button
                    type="button"
                    class="mx-1 mb-0 btn btn-outline-success btn-sm"
                  >
                    Riset
                  </button>
                  
                  <button
                    type="button"
                    class="mx-1 mb-0 btn btn-outline-success btn-sm"
                    data-bs-original-title="Preview product"
                    data-bs-toggle="modal"
                    data-bs-target="#view-fincoy"
                  >
                        Fincoy Assign
                  </button>

                  <button
                    class="mt-1 mb-0 btn btn-outline-success btn-sm export mt-sm-0"
                    data-type="csv"
                    type="button"
                    name="button"
                  ><i class="text-success text-gradient ni ni-chart-bar-32"></i>
                    Export
                  </button>
                </div>
              </div>
              </div>
            </div>
          </div>
          
          <div class="px-0 pb-0 card-body pt-0">
            <div class="table-responsive">
              <table id="products-list" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Aso Cabang</th>
                    <th>Nama Client</th>
                    <th>T. Pinjaman</th>
                    <th>Nama Kendaraan</th>
                    <th>Status Client</th>
                    <th>Fincoy Ho</th>
                    <th>Fincoy Pic</th>
                    <th>Total Fee</th>
                    <th>Fee Aso Cabang</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                        <div class="d-flex align-items-center">
                        <argon-checkbox />
                            <p class="text-xs font-weight-bold ms-2 mb-0">ASO Cabang Jakarta</p>
                        </div>
                    </td>
                    <td class="text-sm">Herman</td>
                    <td class="text-sm">
                      <div class="d-flex">
                        <h6 class="my-auto">Rp.100.000.000</h6>
                      </div>
                    </td>
                    <td class="text-sm">Yamaha nMax</td>
                    <td class="text-sm"><span class="badge badge-info badge-sm">Low</span></td>
                    <td class="text-sm">-</td>
                    <td class="text-sm">-</td>
                    <td class="text-sm">1.000.000</td>
                    <td class="text-sm">100.000</td>
                    <td><span class="badge badge-success badge-sm">Approve</span></td>
                    <td class="text-sm">
                      <!-- button edit modal -->
                      <a
                        href="javascript:;"
                        data-bs-original-title="Preview product"
                        data-bs-toggle="modal"
                        data-bs-target="#view-aso"
                      >
                        <i class="fas fa-eye text-secondary"></i>
                      </a>
                      <!-- End button edit modal -->
                    </td>
                  </tr>
                  <tr>
                    <td>
                        <div class="d-flex align-items-center">
                        <argon-checkbox />
                            <p class="text-xs font-weight-bold ms-2 mb-0">ASO Cabang Bekasi</p>
                        </div>
                    </td>
                    <td class="text-sm">Herman</td>
                    <td class="text-sm">
                      <div class="d-flex">
                        <h6 class="my-auto">Rp.43.000.000</h6>
                      </div>
                    </td>
                    <td class="text-sm">Yamaha nMax</td>
                    <td class="text-sm"><span class="badge badge-danger badge-sm">High</span></td>
                    <td class="text-sm">-</td>
                    <td class="text-sm">-</td>
                    <td class="text-sm">1.000.000</td>
                    <td class="text-sm">100.000</td>
                    <td><span class="badge badge-success badge-sm">Approve</span></td>
                    <td class="text-sm">
                      <!-- button edit modal -->
                      <a
                        href="javascript:;"
                        data-bs-original-title="Preview product"
                        data-bs-toggle="modal"
                        data-bs-target="#view-aso"
                      >
                        <i class="fas fa-eye text-secondary"></i>
                      </a>
                      <!-- End button edit modal -->
                    </td>
                  </tr>
                  <tr>
                    <td>
                        <div class="d-flex align-items-center">
                        <argon-checkbox />
                            <p class="text-xs font-weight-bold ms-2 mb-0">ASO Cabang Jakarta</p>
                        </div>
                    </td>
                    <td class="text-sm">Herman</td>
                    <td class="text-sm">
                      <div class="d-flex">
                        <h6 class="my-auto">Rp.100.000.000</h6>
                      </div>
                    </td>
                    <td class="text-sm">Yamaha nMax</td>
                    <td class="text-sm"><span class="badge badge-info badge-sm">Low</span></td>
                    <td class="text-sm">-</td>
                    <td class="text-sm">-</td>
                    <td class="text-sm">-</td>
                    <td class="text-sm">-</td>
                    <td><span class="badge badge-danger badge-sm">Reject</span></td>
                    <td class="text-sm">
                      <!-- button edit modal -->
                      <a
                        href="javascript:;"
                        data-bs-original-title="Preview product"
                        data-bs-toggle="modal"
                        data-bs-target="#view-aso"
                      >
                        <i class="fas fa-eye text-secondary"></i>
                      </a>
                      <!-- End button edit modal -->
                    </td>
                  </tr>
                  <tr>
                    <td>
                        <div class="d-flex align-items-center">
                        <argon-checkbox />
                            <p class="text-xs font-weight-bold ms-2 mb-0">ASO Cabang Jakarta</p>
                        </div>
                    </td>
                    <td class="text-sm">Herman</td>
                    <td class="text-sm">
                      <div class="d-flex">
                        <h6 class="my-auto">Rp.100.000.000</h6>
                      </div>
                    </td>
                    <td class="text-sm">Yamaha nMax</td>
                    <td class="text-sm"><span class="badge badge-info badge-sm">Low</span></td>
                    <td class="text-sm">-</td>
                    <td class="text-sm">-</td>
                    <td class="text-sm">1.000.000</td>
                    <td class="text-sm">100.000</td>
                    <td><span class="badge badge-success badge-sm">Approve</span></td>
                    <td class="text-sm">
                      <!-- button edit modal -->
                      <a
                        href="javascript:;"
                        data-bs-original-title="Preview product"
                        data-bs-toggle="modal"
                        data-bs-target="#view-aso"
                      >
                        <i class="fas fa-eye text-secondary"></i>
                      </a>
                      <!-- End button edit modal -->
                    </td>
                  </tr>
                  <tr>
                    <td>
                        <div class="d-flex align-items-center">
                        <argon-checkbox />
                            <p class="text-xs font-weight-bold ms-2 mb-0">ASO Cabang Jakarta</p>
                        </div>
                    </td>
                    <td class="text-sm">Herman</td>
                    <td class="text-sm">
                      <div class="d-flex">
                        <h6 class="my-auto">Rp.100.000.000</h6>
                      </div>
                    </td>
                    <td class="text-sm">Yamaha nMax</td>
                    <td class="text-sm"><span class="badge badge-info badge-sm">Low</span></td>
                    <td class="text-sm">-</td>
                    <td class="text-sm">-</td>
                    <td class="text-sm">1.000.000</td>
                    <td class="text-sm">100.000</td>
                    <td><span class="badge badge-success badge-sm">Approve</span></td>
                    <td class="text-sm">
                      <!-- button edit modal -->
                      <a
                        href="javascript:;"
                        data-bs-original-title="Preview product"
                        data-bs-toggle="modal"
                        data-bs-target="#view-aso"
                      >
                        <i class="fas fa-eye text-secondary"></i>
                      </a>
                      <!-- End button edit modal -->
                    </td>
                  </tr>
                  <tr>
                    <td>
                        <div class="d-flex align-items-center">
                        <argon-checkbox />
                            <p class="text-xs font-weight-bold ms-2 mb-0">ASO Cabang Jakarta</p>
                        </div>
                    </td>
                    <td class="text-sm">Herman</td>
                    <td class="text-sm">
                      <div class="d-flex">
                        <h6 class="my-auto">Rp.100.000.000</h6>
                      </div>
                    </td>
                    <td class="text-sm">Yamaha nMax</td>
                    <td class="text-sm"><span class="badge badge-info badge-sm">Low</span></td>
                    <td class="text-sm">-</td>
                    <td class="text-sm">-</td>
                    <td class="text-sm">1.000.000</td>
                    <td class="text-sm">100.000</td>
                    <td><span class="badge badge-success badge-sm">Approve</span></td>
                    <td class="text-sm">
                      <!-- button edit modal -->
                      <a
                        href="javascript:;"
                        data-bs-original-title="Preview product"
                        data-bs-toggle="modal"
                        data-bs-target="#view-aso"
                      >
                        <i class="fas fa-eye text-secondary"></i>
                      </a>
                      <!-- End button edit modal -->
                    </td>
                  </tr>
                  <tr>
                    <td>
                        <div class="d-flex align-items-center">
                        <argon-checkbox />
                            <p class="text-xs font-weight-bold ms-2 mb-0">ASO Cabang Jakarta</p>
                        </div>
                    </td>
                    <td class="text-sm">Herman</td>
                    <td class="text-sm">
                      <div class="d-flex">
                        <h6 class="my-auto">Rp.100.000.000</h6>
                      </div>
                    </td>
                    <td class="text-sm">Yamaha nMax</td>
                    <td class="text-sm"><span class="badge badge-info badge-sm">Low</span></td>
                    <td class="text-sm">-</td>
                    <td class="text-sm">-</td>
                    <td class="text-sm">1.000.000</td>
                    <td class="text-sm">100.000</td>
                    <td><span class="badge badge-success badge-sm">Approve</span></td>
                    <td class="text-sm">
                      <!-- button edit modal -->
                      <a
                        href="javascript:;"
                        data-bs-original-title="Preview product"
                        data-bs-toggle="modal"
                        data-bs-target="#view-aso"
                      >
                        <i class="fas fa-eye text-secondary"></i>
                      </a>
                      <!-- End button edit modal -->
                    </td>
                  </tr>
                  <tr>
                    <td>
                        <div class="d-flex align-items-center">
                        <argon-checkbox />
                            <p class="text-xs font-weight-bold ms-2 mb-0">ASO Cabang Jakarta</p>
                        </div>
                    </td>
                    <td class="text-sm">Herman</td>
                    <td class="text-sm">
                      <div class="d-flex">
                        <h6 class="my-auto">Rp.100.000.000</h6>
                      </div>
                    </td>
                    <td class="text-sm">Yamaha nMax</td>
                    <td class="text-sm"><span class="badge badge-info badge-sm">Low</span></td>
                    <td class="text-sm">-</td>
                    <td class="text-sm">-</td>
                    <td class="text-sm">1.000.000</td>
                    <td class="text-sm">100.000</td>
                    <td><span class="badge badge-success badge-sm">Approve</span></td>
                    <td class="text-sm">
                      <!-- button edit modal -->
                      <a
                        href="javascript:;"
                        data-bs-original-title="Preview product"
                        data-bs-toggle="modal"
                        data-bs-target="#view-aso"
                      >
                        <i class="fas fa-eye text-secondary"></i>
                      </a>
                      <!-- End button edit modal -->
                    </td>
                  </tr>
                  <tr>
                    <td>
                        <div class="d-flex align-items-center">
                        <argon-checkbox />
                            <p class="text-xs font-weight-bold ms-2 mb-0">ASO Cabang Jakarta</p>
                        </div>
                    </td>
                    <td class="text-sm">Herman</td>
                    <td class="text-sm">
                      <div class="d-flex">
                        <h6 class="my-auto">Rp.100.000.000</h6>
                      </div>
                    </td>
                    <td class="text-sm">Yamaha nMax</td>
                    <td class="text-sm"><span class="badge badge-info badge-sm">Low</span></td>
                    <td class="text-sm">-</td>
                    <td class="text-sm">-</td>
                    <td class="text-sm">1.000.000</td>
                    <td class="text-sm">100.000</td>
                    <td><span class="badge badge-success badge-sm">Approve</span></td>
                    <td class="text-sm">
                      <!-- button edit modal -->
                      <a
                        href="javascript:;"
                        data-bs-original-title="Preview product"
                        data-bs-toggle="modal"
                        data-bs-target="#view-aso"
                      >
                        <i class="fas fa-eye text-secondary"></i>
                      </a>
                      <!-- End button edit modal -->
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th>Product</th>
                    <th>Category</th>
                    <th>Price</th>
                    <th>SKU</th>
                    <th>Quantity</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- button edit modal -->    
    <div
      id="view-aso"
      class="modal fade"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-lg-10">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="ModalLabel" class="modal-title">
              ASO Cabang  Jakarta
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div>
              <h6>ASO Cabang  Jakarta</h6>
              <p class="text-sm mb-0">
                Nama PIC
                <b>Herman</b> Nomor HP
                <b>1234567890</b>
              </p>
              <p class="text-sm">
                NIK/NPK :
                <b>243598234</b>
              </p>

              <p class="text-sm">
                Jumlah Anggota :
                <b>32</b>
              </p>
            </div>
            <p>Jalan Alamat nya/Lokasi.</p>

            <div>
              <p class="text-sm mb-0">
                Status  
                <span class="badge badge-success badge-sm">Aktif</span>
              </p>
              <p class="text-sm">
                NIK/NPK :
                <b>243598234</b>
              </p>
            </div>
            
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn bg-gradient-secondary btn-sm"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End button edit modal -->

    <div
      id="view-fincoy"
      class="modal fade"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-lg-10">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="ModalLabel" class="modal-title">
              Fincoy PIC
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="px-0 pb-0 card-body pt-0">
                <div class="table-responsive">
                    <table id="products-list" class="table table-flush">
                        <thead class="thead-light">
                        <tr>
                            <th>Fincoy HO</th>
                            <th>Fincoy PIC</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center">
                                    <argon-checkbox />
                                        <p class="text-xs font-weight-bold ms-2 mb-0">Fincoy HO A</p>
                                    </div>
                                </td>
                                <td class="text-sm">Herman</td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center">
                                    <argon-checkbox />
                                        <p class="text-xs font-weight-bold ms-2 mb-0">Fincoy HO B</p>
                                    </div>
                                </td>
                                <td class="text-sm">Lucy</td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center">
                                    <argon-checkbox />
                                        <p class="text-xs font-weight-bold ms-2 mb-0">Fincoy HO C</p>
                                    </div>
                                </td>
                                <td class="text-sm">Anton</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn bg-gradient-primary btn-sm"
              data-bs-dismiss="modal"
            >
              Assign
            </button>
            <button
              type="button"
              class="btn bg-gradient-secondary btn-sm"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import setTooltip from "@/assets/js/tooltip.js";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonCheckbox from "@/components/ArgonCheckbox.vue";
// import Choices from "choices.js";

export default {
  name: "ReportAsoCabang",
  components: {
    ArgonInput,
    ArgonCheckbox,
  },
  beforeMount() {
    if (localStorage.getItem("SideMenuType") !== "AsoCentral") {
        this.$router.go(-1);
    }
  },
  mounted() {
    if (document.getElementById("products-list")) {
      const dataTableSearch = new DataTable("#products-list", {
        searchable: true,
        fixedHeight: false,
        perPage: 7,
      });

      document.querySelectorAll(".export").forEach(function (el) {
        el.addEventListener("click", function () {
          var type = el.dataset.type;

          var data = {
            type: type,
            filename: "soft-ui-" + type,
          };

          if (type === "csv") {
            data.columnDelimiter = "|";
          }

          dataTableSearch.export(data);
        });
      });
    }
    setTooltip(this.$store.state.bootstrap);
    
  },
  methods: {
    showSwal(type) {
      console.log("asdasdf asdfasdf asdfasdf");
      if (type === "basic") {
        this.$swal({
          title: "Any fool can use a computer",
          type: type,
        });
      } else if (type === "success-message") {
        this.$swal({
          icon: "success",
          title: "Good Job!",
          text: "You clicked the button!",
          type: type,
        });
      } else if (type === "custom-html") {
        this.$swal({
          icon: "info",
          title: "<strong>HTML <u>example</u></strong>",
          html:
            "You can use <b>bold text</b>, " +
            '<a href="//sweetalert2.github.io">links</a> ' +
            "and other HTML tags",
          type: type,
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!',
          confirmButtonAriaLabel: "Thumbs up, great!",
          cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
          cancelButtonAriaLabel: "Thumbs down",
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        });
      } else if (type === "input-field") {
        this.$swal({
          title: "Submit your Github username",
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Look up",
          showLoaderOnConfirm: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
          preConfirm: (login) => {
            return fetch(`//api.github.com/users/${login}`)
              .then((response) => {
                if (!response.ok) {
                  throw new Error(response.statusText);
                }
                return response.json();
              })
              .catch((error) => {
                this.$swal.showValidationMessage(`Request failed: ${error}`);
              });
          },
          allowOutsideClick: () => !this.$swal.isLoading(),
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal({
              title: `${result.value.login}'s avatar`,
              imageUrl: result.value.avatar_url,
            });
          }
        });
      } else if (type === "title-and-text") {
        this.$swal({
          title: "Sweet!",
          text: "Modal with a custom image.",
          imageUrl: "https://unsplash.it/400/200",
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
        });
      } else if (type === "auto-close") {
        let timerInterval;
        this.$swal({
          title: "Auto close alert!",
          html: "I will close in <b></b> milliseconds.",
          timer: 2000,
          timerProgressBar: true,
          didOpen: () => {
            this.$swal.showLoading();
            const b = this.$swal.getHtmlContainer().querySelector("b");
            timerInterval = setInterval(() => {
              b.textContent = this.$swal.getTimerLeft();
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        });
      } else if (type === "warning-message-and-confirmation") {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            });
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === this.$swal.DismissReason.cancel
          ) {
            this.$swal({
              title: "Cancelled!",
              text: "Your imaginary file is safe :)",
              icon: "error",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            });
          }
        });
      } else if (type === "warning-message-and-cancel") {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancel",
          confirmButtonText: "Yes, delete it!",
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            });
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === this.$swal.DismissReason.cancel
          ) {
            this.$swal.dismiss;
          }
        });
      } else if (type === "rtl-language") {
        this.$swal({
          title: "هل تريد الاستمرار؟",
          icon: "question",
          iconHtml: "؟",
          confirmButtonText: "نعم",
          cancelButtonText: "لا",
          showCancelButton: true,
          showCloseButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        });
      }
    },
  },
};
</script>
