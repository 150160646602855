<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="mx-auto col-lg-12 col-12">
        <div class="mt-4 card card-body">
          <h6 class="mb-0">Ubah Fincoy Ho</h6>
          <hr class="my-3 horizontal dark" />
          <label for="fincoyHOName" class="form-label">Nama Fincoy Ho</label>
          <input id="fincoyHOName" v-model="fincoyHOName" type="text" class="form-control" />
          <div class="mt-4 row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>Status Fincoy Ho</label>
                <div class="form-check form-switch ms-1">
                  <input
                    id="flexSwitchCheckDefault"
                    v-model="isActive"
                    class="form-check-input"
                    type="checkbox"
                    data-type="warning"
                    data-content="Once a project is made private, you cannot revert it to a public project."
                    data-title="Warning"
                    data-icon="ni ni-bell-55"
                  />
                  <label
                    class="form-check-label"
                    for="flexSwitchCheckDefault"
                  >
                    <span
                      class="badge badge-md"
                      :class="{
                        'badge-danger': !isActive,
                        'badge-success': isActive,
                      }"
                    >
                      {{ (isActive) ? 'AKTIF' : 'NON-AKTIF' }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label for="PICNameInput" class="form-label">Nama PIC</label>
              <input id="PICNameInput" v-model="picName" type="text" class="form-control" />
            </div>
            <div class="col-6">
              <label for="IDInput" class="form-label">NIK/NIP</label>
              <input id="IDInput" v-model="personalID" type="text" class="form-control"  maxlength="16"/>
            </div>
            <div class="col-6">
              <label for="PICPhoneInput" class="form-label">Nomor HP PIC</label>
              <input id="PICPhoneInput" v-model="phone" type="text" class="form-control"  maxlength="13"/>
            </div>
            <div class="col-6">
              <label for="Location" class="form-label">Daerah/Lokasi</label>
              <input id="Location" v-model="location" type="text" class="form-control" />
            </div>
            <div class="col-6">
              <label class="form-label">Tanggal Mulai MOU</label>
              <flat-pickr
                v-model="MOUStartDate"
                class="form-control datetimepicker"
                placeholder="Please select end date"
                :config="config"
              ></flat-pickr>
            </div>
            <div class="col-6">
             <label class="form-label">Tanggal Akhir MOU</label>
              <flat-pickr
                v-model="MOUEndDate"
                class="form-control datetimepicker"
                placeholder="Please select end date"
                :config="config"
              ></flat-pickr>
            </div>
          </div>
          <label class="mt-4">Alamat Kantor</label>
          <textarea v-model="address" class="form-control" rows="5"></textarea>
          <label class="mt-4 form-label">Upload Berkas MOU</label>
          <div v-if="MOUDocsExist"><a :href="MOUDocsExist" target="_blank"><u>Exist MOU</u></a></div>
          <form
            id="uploadMOUInput"
            class="form-control dropzone"
          >
            <div class="fallback">
              <input name="file" type="file" multiple />
            </div>
          </form>
          <div class="mt-4 row">
            <div class="col-12">
              <h6>Pengaturan Komisi</h6>
            </div>
            <div
            v-for="(commission, commissionIndex) in commissions"
              :key="commissionIndex"
              class="row col-12"
            >
              <div class="col-3 col-md-3">
                <label for="commissionTotalLoan_{{commissionIndex}}" class="form-label">Jumlah Pinjaman</label>
                <!-- <input
                  id="commissionTotalLoan_{{commissionIndex}}"
                  v-model.number="commission.total_loan_operand"
                  type="text"
                  class="form-control" /> -->

                 <input
                    id="commissionTotalLoan_{{commissionIndex}}"
                    v-model.number="commission.total_loan_operand"
                    @blur="formatCurrencyInput(commission, 'total_loan_operand')"
                    type="text"
                    class="form-control"
                  />
              </div>
              <div class="col-3 col-md-1">
                <label for="commissionCondition_{{commissionIndex}}" class="form-label">Keterangan</label>
                <select
                  id="commissionCondition_{{commissionIndex}}"
                  v-model="commission.cond_operator"
                  class="form-control"
                  name="commissionConditionInput_{{commissionIndex}}"
                >
                  <option value="GREATER_THAN" selected="">&gt;</option>
                  <option value="LESS_THAN">&lt;</option>
                  <option value="EQUAL">=</option>
                  <option value="LESS_THAN_EQUAL">&lt;=</option>
                  <option value="GREATER_THAN_EQUAL">&gt;=</option>
                </select>
              </div>
              <div class="col-3 col-md-3">
                <label for="commissionTotalFee_{{commissionIndex}}" class="form-label">Jumlah Fee</label>
                <!-- <input
                  id="commissionTotalFee_{{commissionIndex}}"
                  v-model.number="commission.total_fee_operand"
                  type="text"
                  class="form-control"
                /> -->

                <input
                  id="commissionTotalFee_{{commissionIndex}}"
                  v-model.number="commission.total_fee_operand"
                  @blur="formatCurrencyInput(commission, 'total_fee_operand')"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="col-3 col-md-3">
                <label for="commissionTargetASOCentral_{{commissionIndex}}" class="form-label">Aso Central</label>
                <select
                  id="commissionTargetASOCentral_{{commissionIndex}}"
                  v-model="commission.aso_central_id"
                  class="form-control"
                  name="commissionTargetASOCentral_{{commissionIndex}}"
                >
                  <option
                    v-for="(asoCentral, asoCentralIndex) in asoCentrals"
                    :key="asoCentralIndex"
                    :value="asoCentral.id"
                  >
                    {{ asoCentral.name }}
                  </option>
                </select>
              </div>
              <div class="col-3 col-md-1">
                <label for="commissionFeePercentage_{{commissionIndex}}" class="form-label">Fee.D</label>
                <input
                  id="commissionFeePercentage_{{commissionIndex}}"
                  v-model.number="commission.distributed_fee_cut"
                  type="text"
                  class="form-control"
                />
              </div>

              <div v-if="commissionIndex === 0" class="col-3 col-md-1">
                <label for="commissionAction_{{commissionIndex}}" class="form-label">Aksi</label>
                <div>
                  <button
                    id="commissionAction_{{commissionIndex}}"
                    type="button"
                    class="m-0 btn btn-light me-2"
                    @click="addCommissionSetting"
                  >
                    <i class="fas fa-plus text-secondary text-success"></i>
                  </button>
                </div>
              </div>

              <div v-if="commissionIndex > 0 && commissions.length > 0" class="col-3 col-md-1">
                <label for="commissionAction_{{commissionIndex}}" class="form-label">Aksi</label>
                <div>
                  <button
                    id="commissionAction_{{commissionIndex}}"
                    type="button"
                    class="m-0 btn btn-light me-2"
                    @click="removeCommissionSetting(commissionIndex)"
                  >
                    <i class="fas fa-times text-secondary text-danger"></i>
                  </button>
                </div>
              </div>

            </div>
          </div>
          <div class="mt-4 d-flex justify-content-end">
            <button type="button" name="button" class="m-0 btn btn-light"
            @click="$router.push({ name: 'superadmin.fincoy-ho' })"
            >
              Batal
            </button>
            
            <button
              type="button"
              name="button"
              class="m-0 btn bg-gradient-success ms-2"
              @click="submit"
            >
              <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span v-else>Ubah Fincoy HO</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import Dropzone from "dropzone";
import axios from 'axios';

export default {
  name: "FincoyHoNew",
  components: {
    flatPickr,
  },
  data() {
    return {
      fincoyHOName: "",
      picName: "",
      personalID: "",
      phone: "",
      location: "",
      MOUDocs: "",
      MOUDocsExist: "",
      MOUStartDate: "",
      MOUEndDate: "",
      address: "",
      commissions: [
        {
          total_loan_operand: 0,
          cond_operator: "",
          total_fee_operand: 0,
          distributed_fee_cut: 0,
          aso_central_id: null,
        }
      ],
      asoCentrals: [],
      isActive: false,
      config: {
        allowInput: true,
      },
      isLoading: false,
    };
  },
  computed: {
    fincoyHOID() {
      if (!this.$route.params.fincoyHO) {
        return null
      }
      return this.$route.params.fincoyHO
    }
  },
  beforeMount() {
    if (localStorage.getItem("SideMenuType") !== "SuperAdmin") {
        this.$router.go(-1);
    }
  },
  mounted() {
    // Dropzone.autoDiscover = false;
    // var drop = document.getElementById("uploadMOUInput");
    // new Dropzone(drop, {
    //   url: "localhost:8000/v1/asset/upload",
    //   addRemoveLinks: true,
    // });
    this.initializeDropzone();
    this.loadFincoyHO()
    this.loadASOCentrals()
  },
  methods: {
    initializeDropzone() {
      Dropzone.autoDiscover = false;
      const drop = document.getElementById("uploadMOUInput");
      const dropzone = new Dropzone(drop, {
        url: "https://api.darlink.id/v1/assets/upload",
        addRemoveLinks: true,
        acceptedFiles: '.pdf',
        sending: (file, xhr, formData) => {
          formData.append("context", "MOU"); // Include the context parameter in the request
        },
        success: (file, response) => {
          console.log("Upload success:", response); // Handle the successful response
          // this.MOUDocs = response.content.url;
          this.MOUDocs = response.content.name;
        },
      });
      // Add any additional configuration or event listeners as needed

      // Move the handleFileChange logic here or modify it accordingly
      dropzone.on("addedfile", (file) => {
        // this.MOUDocs = file;
        console.log(file);
      });
    },
    toggleLoading() {
      if (!this.datatable) {
        return
      }
      if (!this.isLoading) {
        this.isLoading = true
        this.datatable?.setMessage("Memuat...")
        return
      }
      this.isLoading = false
    },
    isError(tag) {
      let message = this.validations.find(validation => {
        if (validation.tag === tag) {
          return true
        }
        return false
      })

      if (!message) {
        return false
      }

      return true
    },
    addCommissionSetting() {
      this.commissions.push({
          total_loan_operand: 0,
          cond_operator: "",
          total_fee_operand: 0,
          distributed_fee_cut: 0,
          aso_central_id: null,
        })
    },
    removeCommissionSetting(id) {
      this.commissions.splice(id, 1)
    },
    formatCurrencyInput(commission, field) {
      // Convert the input value to a number if it's not a number already
      let inputValue = commission[field];
      if (typeof inputValue !== "number") {
        inputValue = parseFloat(inputValue.replace(/[^0-9.-]+/g, "")) || 0;
      }

      // Format the input value as currency
      const formattedValue = inputValue.toLocaleString("en-ID", {
        style: "currency",
        currency: "IDR", // Change to your desired currency code
      });

      // Update the commission field with the formatted value
      commission[field] = formattedValue;
    },
    formatCurrency(value) {
      const formattedValue = value.toLocaleString("en-ID", {
          style: "currency",
          currency: "IDR", // Change to your desired currency code
      });
      // return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(value);
      return formattedValue;
    },
    loadASOCentrals() {
      var token = window.localStorage.getItem("TOKEN")
      axios
        .get("https://api.darlink.id/v1/aso-central?status=active", {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          }
        })
        .then((response) => {
          if (response.data.code != 200) {
            return
          }
          this.asoCentrals = response.data.content.map((asoCentral) => {
            return {
              id: asoCentral.id,
              name: asoCentral.name
            }
          })

        })
        .catch((err) => {
          console.log(err);
        });
    },
    loadFincoyHO() {
      var token = window.localStorage.getItem("TOKEN")

      if (!this.fincoyHOID) {
        return
      }

      axios
        .get("https://api.darlink.id/v1/fincoy/" + this.fincoyHOID,
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          }
        })
        .then((response) => {
          if (response.data.code != 200) {
            return
          }

          this.fincoyHOName = response.data.content.name
          this.picName = response.data.content.pic_name
          this.personalID = response.data.content.personal_id
          this.phone = response.data.content.phone
          this.location = response.data.content.location
          // this.MOUDocs = "https://api.darlink.id/v1/assets/stored/images/" + response.data.content.MOU
          // this.MOUDocs = 
          this.MOUDocsExist = response.data.content.MOU 
          this.MOUStartDate = new Date(response.data.content.MOUStart);
          this.MOUEndDate = new Date(response.data.content.MOUEnd);
          this.address = response.data.content.address
          // this.commissions = response.data.content.commissions
          this.commissions = response.data.content.commissions.map((commission) => {
            // Format the commission values into currency format
            commission.total_fee_operand = this.formatCurrency(commission.total_fee_operand);
            commission.total_loan_operand = this.formatCurrency(commission.total_loan_operand);
            return commission;
          });
          this.isActive = response.data.content.is_active
        })
        .catch((err) => {
          console.log(err.response)
        });
    },
    validatePhone(phone) {
      // Regular expression for validating a Phone number
      const phoneRegex = /^\d{11,13}$/;
      return phoneRegex.test(phone);
    },
    validateNik(personalID) {
      // Regular expression for validating a Phone number
      const phoneRegex = /^\d{16}$/;
      return phoneRegex.test(personalID);
    },
    submit() {
      this.isLoading = true;
      var token = window.localStorage.getItem("TOKEN")

      if (!this.validatePhone(this.phone)) {
        this.isLoading = false;
        this.$swal({
          title: "Invalid Phone Number",
          text: "Please enter a valid max 13-digit phone number.",
          icon: "warning",
          confirmButtonText: "OK",
          confirmButtonColor: "#3085d6",
        });
        return;
      }

      if (!this.validateNik(this.personalID)) {
        this.isLoading = false;
        this.$swal({
          title: "Invalid NIK",
          text: "Please enter a valid 16-digit number.",
          icon: "warning",
          confirmButtonText: "OK",
          confirmButtonColor: "#3085d6",
        });
        return;
      }

      // Parse this.MOUEndDate to ensure it's a Date object
      let MOUEndDate;
      if (typeof this.MOUEndDate === "string") {
        MOUEndDate = new Date(this.MOUEndDate);
      } else if (this.MOUEndDate instanceof Date) {
        MOUEndDate = this.MOUEndDate;
      } else {
        this.isLoading = false;
        // Show error message if the date is not valid
        this.$swal({
          title: "Invalid MOU End Date",
          text: "Please enter a valid MOU End Date.",
          icon: "warning",
          confirmButtonText: "OK",
          confirmButtonColor: "#3085d6",
        });
        return;
      }

      // Get the current date in ISO 8601 format
      const currentDate = new Date().toISOString();

      // Compare MOUEndDateISO with the current date
      if (MOUEndDate.toISOString() <= currentDate) {
        this.isLoading = false;
        // Show error message if MOUEndDate is not greater than the current date
        this.$swal({
          title: "Invalid MOU End Date",
          text: "MOU End Date must be greater than the current date.",
          icon: "warning",
          confirmButtonText: "OK",
          confirmButtonColor: "#3085d6",
        });
        return;
      }

      const fincoyHO = new FormData();

      fincoyHO.append("name", this.fincoyHOName);
      fincoyHO.append("pic_name", this.picName);
      fincoyHO.append("personal_id", this.personalID);
      fincoyHO.append("location", this.location);
      fincoyHO.append("phone", this.phone);
      fincoyHO.append("address", this.address);
      fincoyHO.append("mou", this.MOUDocs);
      // console.log('docs', this.MOUDocs);
      fincoyHO.append("dob", this.MOUStartDate);
      fincoyHO.append("mou_start", this.MOUStartDate);
      fincoyHO.append("mou_end", this.MOUEndDate);
      // fincoyHO.append("commission_schemes", JSON.stringify(this.commissions));
      fincoyHO.append("commission_schemes", JSON.stringify(this.commissions.map(commission => ({
        fincoy_ho_id: parseInt(this.fincoyHOID, 10),
        total_loan_operand: parseFloat(commission.total_loan_operand.replace(/[^0-9.-]+/g, "")), // Convert back to numeric value
        cond_operator: commission.cond_operator,
        total_fee_operand: parseFloat(commission.total_fee_operand.replace(/[^0-9.-]+/g, "")), // Convert back to numeric value
        distributed_fee_cut: commission.distributed_fee_cut,
        aso_central_id: commission.aso_central_id,
      }))));
      fincoyHO.append("status", (this.isActive) ? 'active' : 'inactive');

      axios
        .post("https://api.darlink.id/v1/fincoy/" + this.fincoyHOID, fincoyHO,
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          }
        })
        .then((response) => {
          this.isLoading = false;
          console.log(response)
          // if (response.data.code != 201) {
          //   return
          // }
          
          this.$swal({
            title: "Berhasil",
            text: "Fincoy HO berhasil dirubah",
            icon: "success",
            confirmButtonText: "Tutup",
            confirmButtonColor: "#3085d6",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push({ name: "superadmin.fincoy-ho" })
            }
          });
          
          this.$router.push({ name: "superadmin.fincoy-ho" })
        })
        .catch((err) => {
          this.isLoading = false;
          if (err.response && err.response.data && err.response.data.validation) {
            const validationErrors = err.response.data.validation;
            let errorMessage = "Validation Error:\n";
            validationErrors.forEach((error) => {
              errorMessage += `${error.mesg}\n`;
            });
            this.$swal({
              title: "Validation Error",
              text: errorMessage,
              icon: "error",
              confirmButtonText: "OK",
              confirmButtonColor: "#d33",
            });
          } else {
            this.$swal({
              title: "Error",
              text: err.response.data.message,
              icon: "error",
              confirmButtonText: "OK",
              confirmButtonColor: "#d33",
            });
          }
        });
    }
  }
};
</script>
