<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Semua History</h5>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <div class="row">
                <div class="col-lg-4" style="margin: 10px;">
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      v-model="searchQuery"
                      placeholder="Search ..."
                    />
                  </div>
                  
                  <a
                    href="#"
                    class="mb-0 btn bg-gradient-success btn-sm"
                    style="margin-right: 10px;"
                    @click="performSearch"
                    >+&nbsp; search</a
                  >
                </div>
              </div>

              <table id="products-list" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>PIC</th>
                    <th>ASO Central</th>
                    <th>Description / Action</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  
                </tbody>
                <tfoot>
                  <tr>
                    <th>Role</th>
                    <th>Nama</th>
                    <th>Description / Action</th>
                    <th>Date</th>
                  </tr>
                </tfoot>
              </table>

              <!-- DataTable's built-in pagination -->
              <nav aria-label="Page navigation">
                <ul class="pagination justify-content-end">
                  <li class="page-item" :class="{ disabled: page === 1 }">
                    <a class="page-link" href="javascript:void(0);" @click="loadPage(page - 1)">
                      Prev
                    </a>
                  </li>
                  <li class="page-item" v-for="pageNumber in totalPages" :key="pageNumber" :class="{ active: pageNumber === page }">
                    <a class="page-link" href="javascript:void(0);" @click="loadPage(pageNumber)">
                      {{ pageNumber }}
                    </a>
                  </li>
                  <li class="page-item" :class="{ disabled: page === totalPages }">
                    <a class="page-link" href="javascript:void(0);" @click="loadPage(page + 1)">
                      Next
                    </a>
                  </li>
                </ul>
              </nav>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- button edit modal -->
    <div
      id="view-aso"
      class="modal fade"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-lg-10">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="ModalLabel" class="modal-title">
              Data Agent
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div>
              <h6>ASO Central Jakarta</h6>
              <p class="text-sm mb-0">
                Nama Agent
                <b>Herman</b> Nomor HP
                <b>1234567890</b>
              </p>
              <p class="text-sm">
                NIK/NPK :
                <b>243598234</b>
              </p>
            </div>
            <p>Jalan Alamat nya/Lokasi.</p>

            <div>
              <p class="text-sm mb-0">
                Status
                <span class="badge badge-success badge-sm">Aktif</span>
              </p>
            </div>

          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn bg-gradient-secondary btn-sm"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End button edit modal -->
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import setTooltip from "@/assets/js/tooltip.js";
import axios from 'axios';

var columns = [
  {
    select: 0,
    render: function (data) {
      return `<div class="d-flex">
          <h6 class="my-auto ms-3">${data}</h6>
        </div>
      `
    }
  },
  {
    select: [1,2],
    render: function (data) {
      return `<span class="text-sm">${data}</span>
      `
    }
  },
]

var labels = {
  placeholder: "Cari History...",
  perPage: "Menampilkan {select} History per halaman",
  noRows: "Tidak ada History yang ditampilkan",
  info: "Menampilkan {start} ke {end} dari {rows} History (Halaman {page} dari {pages} halaman)"
}

export default {
  name: "Agents",
  data() {
    return {
      hasData: false,
      datatable: null,
      histories: [],
      isLoading: false,
      page: 1, // Current page
      totalPages: 1, // Total number of pages
      searchQuery: '',
    }
  },
  beforeMount() {
    if (localStorage.getItem("SideMenuType") !== "AsoCentral") {
        this.$router.go(-1);
    }
  },
  mounted() {
    if (document.getElementById("products-list")) {
      this.datatable = new DataTable("#products-list", {
        processing: this.isLoading,
        searchable: false,
        fixedHeight: false,
        perPage: 16,
        columns: columns,
        labels: labels,
        paging: false,
        filters: {
          // Add the search filter
          search: this.searchQuery,
        },
      });
      this.datatable.body.addEventListener("click", this.handleAction)
      this.toggleLoading()
      setTimeout(() => {
        this.loadFincoyHO()
      }, 1000)
    }
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.datatable.destroy()
    this.datatable = null
  },
  methods: {
    toggleLoading() {
      if (!this.datatable) {
        return
      }
      if (!this.isLoading) {
        this.isLoading = true
        this.datatable?.setMessage("Memuat...")
        return
      }
      this.isLoading = false
    },
    // Function to update the DataTable
    performSearch() {
      if (this.datatable) {
        this.destroyDataTable(); // Destroy the current DataTable instance
      }
      
      if (document.getElementById("products-list")) {
        this.datatable = new DataTable("#products-list", {
          processing: this.isLoading,
          searchable: false,
          fixedHeight: false,
          perPage: 16,
          columns: columns,
          labels: labels,
          paging: false,
          filters: {
            // Add the search filter
            search: this.searchQuery,
          },
        });

        this.page = 1;

        this.datatable.body.addEventListener("click", this.handleAction)
        this.toggleLoading()
        this.loadFincoyHO()
      }
    },
    loadFincoyHO() {
      var token = window.localStorage.getItem("TOKEN")
      axios
        .get("https://api.darlink.id/v1/aso-central/histories", {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          },
          params: {
            page: this.page, // Include the page parameter
            search: this.searchQuery, // Add the search query parameter
          },
        })
        .then((response) => {
          this.toggleLoading()
          if (response.data.code != 200) {
            return
          }

          if (response.data.tag === "content.history.empty") {
            this.hasData = false;
          }
          else
          {
            this.hasData = true;
            this.histories = response.data.content
            this.totalPages = response.data.pagination.total_pages; // Update total pages

            this.updateDataTable();
          }
          
          // this.datatable.insert({
          //   data: this.histories.map((history) => {
          //     return [
          //       history.fallback_displayname,
          //       history.fallback_contextname,
          //       history.description,
          //       new Date(history.recorded_at).toLocaleDateString("id-ID", {
          //         year: "numeric",
          //         month: "long",
          //         day: "numeric",
          //         // hour
          //         hour: "numeric",
          //         // minute
          //         minute: "numeric",
          //       }),
          //     ]
          //   })
          // })

          if (!this.hasData) {
            this.datatable.setMessage("Tidak ada data yang ditampilkan"); // Show empty label
          }
        })
        .catch((err) => {
          this.toggleLoading()
          console.log(err)
        });
    },
    destroyDataTable() {
      if (this.datatable) {
        this.datatable.destroy();
        this.datatable = null;
      }
    },
    // Function to update the DataTable
      updateDataTable() {
        // if (this.datatable) {
        //   this.datatable.destroy();
        // }

        // this.datatable = new DataTable("#products-list", {
        //   processing: this.isLoading,
        //   searchable: true,
        //   fixedHeight: false,
        //   perPage: 16,
        //   columns: columns,
        //   labels: labels,
        // });

        // this.datatable.body.addEventListener("click", this.handleAction);
        this.datatable.body.innerHTML = '';
        this.datatable.insert({
            data: this.histories.map((history) => {
              return [
                history.fallback_displayname,
                history.fallback_contextname,
                history.description,
                new Date(history.recorded_at).toLocaleDateString("id-ID", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  // hour
                  hour: "numeric",
                  // minute
                  minute: "numeric",
                }),
              ]
            })
          });
      },
      // Function to load data for a specific page
      loadPage(page) {
        if (page < 1 || page > this.totalPages) {
          return;
        }

        this.page = page;
        // this.loadFincoyHO(this.filter);
        if (this.datatable) {
          this.destroyDataTable(); // Destroy the current DataTable instance
        }
        
        if (document.getElementById("products-list")) {
          this.datatable = new DataTable("#products-list", {
            processing: this.isLoading,
            searchable: false,
            fixedHeight: false,
            perPage: 16,
            columns: columns,
            labels: labels,
            paging: false,
            filters: {
              // Add the search filter
              search: this.searchQuery,
            },
          });
          this.datatable.body.addEventListener("click", this.handleAction)
          this.toggleLoading()
          this.loadFincoyHO(this.filter);
        }
      },
  }
};
</script>
