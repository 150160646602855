<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">All Fincoy PIC</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <router-link to="/aso/new-fincoy-pic">
                  <a
                    class="mb-0 btn bg-gradient-success btn-sm"
                    target="_blank"
                    >+&nbsp; Tambah Fincoy PIC </a>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <div class="row">
                <div class="col-lg-4" style="margin: 10px;">
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      v-model="searchQuery"
                      placeholder="Search ..."
                    />
                  </div>
                  
                  <a
                    href="#"
                    class="mb-0 btn bg-gradient-success btn-sm"
                    style="margin-right: 10px;"
                    @click="performSearch"
                    >+&nbsp; search</a
                  >
                </div>
              </div>

              <table id="products-list" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Nama Fincoy Ho</th>
                    <th>Nama PIC</th>
                    <th>Username</th>
                    <th>Email</th>
                    <th>Lokasi</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>

                </tbody>
                <tfoot>
                  <tr>
                    <th>Nama Fincoy Ho</th>
                    <th>Nama PIC</th>
                    <th>Nomor Hp</th>
                    <th>Tanggal EXP</th>
                    <th>Lokasi</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </tfoot>
              </table>

              <!-- DataTable's built-in pagination -->
              <nav aria-label="Page navigation">
                <ul class="pagination justify-content-end">
                  <li class="page-item" :class="{ disabled: page === 1 }">
                    <a class="page-link" href="javascript:void(0);" @click="loadPage(page - 1)">
                      Prev
                    </a>
                  </li>
                  <li class="page-item" v-for="pageNumber in totalPages" :key="pageNumber" :class="{ active: pageNumber === page }">
                    <a class="page-link" href="javascript:void(0);" @click="loadPage(pageNumber)">
                      {{ pageNumber }}
                    </a>
                  </li>
                  <li class="page-item" :class="{ disabled: page === totalPages }">
                    <a class="page-link" href="javascript:void(0);" @click="loadPage(page + 1)">
                      Next
                    </a>
                  </li>
                </ul>
              </nav>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- button edit modal -->
    <div
      id="view-aso"
      class="modal fade"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-lg-10">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="ModalLabel" class="modal-title">
              ASO Central Jakarta
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div>
              <h6>ASO Central Jakarta</h6>
              <p class="text-sm mb-0">
                Nama PIC
                <b>Herman</b> Nomor HP
                <b>1234567890</b>
              </p>
              <p class="text-sm">
                NIK/NPK :
                <b>243598234</b>
              </p>

              <p class="text-sm">
                Jumlah Anggota :
                <b>32</b>
              </p>
            </div>
            <p>Jalan Alamat nya/Lokasi.</p>

            <div>
              <p class="text-sm mb-0">
                Status
                <span class="badge badge-success badge-sm">Aktif</span>
              </p>
              <p class="text-sm">
                NIK/NPK :
                <b>243598234</b>
              </p>
            </div>

          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn bg-gradient-secondary btn-sm"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End button edit modal -->
  </div>

  <div class="loading-overlay" v-if="isLoading">
    <!-- You can use a loading spinner or any other loading content here -->
    <div class="spinner"></div>
  </div>
</template>

<style scoped>
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .spinner {
    /* Style your loading spinner here */
    border: 5px solid rgba(0, 0, 0, 0.3);
    border-top: 5px solid #3498db; /* Blue color for the spinner */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
</style>

<script>
import { DataTable } from "simple-datatables";
import setTooltip from "@/assets/js/tooltip.js";
import axios from 'axios';

var columns = [
  {
    select: 0,
    render: function (data) {
      return `<div class="d-flex">
          <h6 class="my-auto ms-3">${data}</h6>
        </div>
      `
    }
  },
  {
    select: [1,2,3],
    render: function (data) {
      // var permissions = ""
      // data.forEach(permission => {
      //   permissions = `${permissions}, ${permission}`
      // });
      return `<span class="text-sm">${data}</span>
      `
    }
  },
  {
    select: 5,
    render: function (data) {
      let status = (data === 'true') ? "AKTIF" :  "TIDAK AKTIF"
      let color = (data === 'true') ? "badge-success" :  "badge-danger"
      return `
      <span class="badge ${color} badge-sm">
        ${status}
      </span>
      `
    },
  },
  {
    select: 6,
    render: function (data) {
      let datas = JSON.parse(data)
      return `
        <!-- button edit modal -->
        <a
          class="text-sm"
          href="javascript:void(0);"
        >
          <span
            class="mx-3"
          >
            <i
             class="fas fa-user-edit text-secondary"
             data-action="edit"
             data-id="${datas.id}"
            ></i>
          </span>
        </a>
        <a
          href="javascript:;"
          class="mx-3"
        >
          <i class="fas fa-trash text-secondary text-warning"
            data-action="delete"
            data-id="${datas.id}"
            data-name="${datas.displayname}"
            data-ho="${datas.fincoy_ho_id}"
          ></i>
        </a>
      `
    }
  },
]

var labels = {
  placeholder: "Cari Fincoy PIC...",
  perPage: "Menampilkan {select} Fincoy PIC per halaman",
  noRows: "Tidak ada data yang ditampilkan",
  info: "Menampilkan {start} ke {end} dari {rows} Fincoy PIC (Halaman {page} dari {pages} halaman)"
}

export default {
  name: "FincoyPic",
  data() {
    return {
      hasData: false,
      datatable: null,
      personInCharges: [],
      isLoading: false,
      page: 1, // Current page
      totalPages: 1, // Total number of pages
      searchQuery: '',
    }
  },
  mounted() {
    if (document.getElementById("products-list")) {
      this.datatable = new DataTable("#products-list", {
        processing: this.isLoading,
        searchable: false,
        fixedHeight: false,
        perPage: 16,
        columns: columns,
        labels: labels,
        paging: false,
        filters: {
          // Add the search filter
          search: this.searchQuery,
        },
      });
      this.datatable.body.addEventListener("click", this.handleAction);
      this.toggleLoading()

      this.loadAdmin()
    }
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.datatable.destroy()
    this.datatable = null
  },
  methods: {
    toggleLoading() {
      if (!this.datatable) {
        return
      }
      if (!this.isLoading) {
        this.isLoading = true
        this.datatable.setMessage("Memuat...")
        return
      }
      this.isLoading = false
    },
    handleAction(e) {
      if (e.target.getAttribute("data-action") === "edit") {
        let id = parseInt(e.target.getAttribute("data-id"), 10)
        this.editData(id, "aso.fincoy-pic.edit")
      }
      if (e.target.getAttribute("data-action") === "delete") {
        let id = parseInt(e.target.getAttribute("data-id"), 10)
        let name = e.target.getAttribute("data-name")
        let ho_id = e.target.getAttribute("data-ho")

        this.deleteAsoCentral(id, name, ho_id)
      }
    },
    editData(id, url) {
      this.$router.push({
        name: url,
        params: {
          id: id
        }
      })
    },
    deleteAsoCentral(id, name, ho_id) {
      this.$swal({
        title: `Anda yakin akan hapus "${name}" ?`,
        showCancelButton: true,
        confirmButtonText: "Hapus",
        cancelButtonText: "Batal",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-danger",
          cancelButton: "btn bg-gradient-success",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
        this.isLoading = true;
        //  this.removeASOCentral(id)
        var token = window.localStorage.getItem("TOKEN")
          axios
            .post(
            `https://api.darlink.id/v1/fincoy/${ho_id}/pic/${id}/remove`,
            null,  // pass null as the request body since it's a DELETE request
            {
              withCredentials: true,
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
              }
            }
          )
          .then((response) => {
            this.isLoading = false;
            // this.toggleLoading()
            if (response.data.code != 200) {
              return
            }

            this.$swal({
              title: "Berhasil",
              text: "ASO Central berhasil dihapus",
              icon: "success",
              confirmButtonText: "Tutup",
              confirmButtonColor: "#3085d6",
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.push({ name: "superadmin.aso-central" })
              }
            });
          })
          .catch((err) => {
            this.isLoading = false;
            console.log(err);
            if (err.response && err.response.data && err.response.data.validation) {
                const validationErrors = err.response.data.validation;
                let errorMessage = "Validation Error:\n";
                validationErrors.forEach((error) => {
                  errorMessage += `${error.mesg}\n`;
                });
                this.$swal({
                  title: "Validation Error",
                  text: errorMessage,
                  icon: "error",
                  confirmButtonText: "OK",
                  confirmButtonColor: "#d33",
                });
              } else {
                this.$swal({
                  title: "Error",
                  text: err.response.data.message,
                  icon: "error",
                  confirmButtonText: "OK",
                  confirmButtonColor: "#d33",
                });
              }
          });
          } 
      });
    },
    performSearch() {
      if (this.datatable) {
        this.destroyDataTable(); // Destroy the current DataTable instance
      }
      
      if (document.getElementById("products-list")) {
        this.datatable = new DataTable("#products-list", {
          processing: this.isLoading,
          searchable: false,
          fixedHeight: false,
          perPage: 16,
          columns: columns,
          labels: labels,
          paging: false,
          filters: {
            // Add the search filter
            search: this.searchQuery,
          },
        });

        this.page = 1;

        this.datatable.body.addEventListener("click", this.handleAction)
        this.toggleLoading()
        this.loadAdmin()
      }
    },
    loadAdmin() {
      var token = window.localStorage.getItem("TOKEN")
      axios
        .get("https://api.darlink.id/v1/fincoy/pic", {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          },
          params: {
            page: this.page, // Include the page parameter
            search: this.searchQuery, // Add the search query parameter
          },
        })
        .then((response) => {
          this.toggleLoading()
          if (response.data.code != 200) {
            return
          }          

          if (response.data.tag == "user.fincoy-pic.empty") {
            this.hasData = false; // Set hasData to false if the API response indicates empty data
          } else {
            this.hasData = true;
            this.personInCharges = response.data.content;
            this.totalPages = response.data.pagination.total_pages; // Update total pages

            this.updateDataTable();
            // this.datatable.insert({
            //   data: this.personInCharges.map((personInCharge) => {
            //     return [
            //       // personInCharge.fincoy.name,
            //       personInCharge.fincoy.name,
            //       personInCharge.displayname,
            //       personInCharge.username,
            //       personInCharge.email,
            //       // personInCharge.phone,
            //       personInCharge.location,
            //       (personInCharge.is_active) ? "true" : "false",
            //       `${personInCharge.id}`
            //     ]
            //   })
            // });
          }
          if (!this.hasData) {
            this.datatable.setMessage("Tidak ada data yang ditampilkan"); // Show empty label
          }
          
        })
        .catch((err) => {
          this.toggleLoading()
          console.log(err);
        });
    },
    destroyDataTable() {
      if (this.datatable) {
        this.datatable.destroy();
        this.datatable = null;
      }
    },
    // Function to update the DataTable
    updateDataTable() {
      // if (this.datatable) {
      //   this.datatable.destroy();
      // }
      // this.datatable = new DataTable("#products-list", {
      //   processing: this.isLoading,
      //   searchable: true,
      //   fixedHeight: false,
      //   perPage: 16,
      //   columns: columns,
      //   labels: labels,
      // });

      // this.datatable.body.addEventListener("click", this.handleAction);
      this.datatable.body.innerHTML = '';
      this.datatable.insert({
        data: this.personInCharges.map((personInCharge) => {
          return [
            // personInCharge.fincoy.name,
            personInCharge.fincoy ? personInCharge.fincoy.name : "-",
            personInCharge.displayname,
            personInCharge.username,
            personInCharge.email,
            // personInCharge.phone,
            personInCharge.location,
            (personInCharge.is_active) ? "true" : "false",
            // `${personInCharge.id}`
            JSON.stringify(personInCharge)
          ]
        })
      });
    },
    // Function to load data for a specific page
    loadPage(page) {
      if (page < 1 || page > this.totalPages) {
        return;
      }

      this.page = page;
      // this.loadAdmin();
      if (this.datatable) {
        this.destroyDataTable(); // Destroy the current DataTable instance
      }
      
      if (document.getElementById("products-list")) {
        this.datatable = new DataTable("#products-list", {
          processing: this.isLoading,
          searchable: false,
          fixedHeight: false,
          perPage: 16,
          columns: columns,
          labels: labels,
          paging: false,
          filters: {
            // Add the search filter
            search: this.searchQuery,
          },
        });
        this.datatable.body.addEventListener("click", this.handleAction)
        this.toggleLoading()
        this.loadAdmin()
      }
    },
  }
};
</script>
