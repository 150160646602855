<template>
	<div class="py-4 container-fluid">
		<div class="row">
			<div class="col-12">
				<div class="card">
					<!-- Card header -->
					<div class="pb-0 card-header">
						<div class="d-lg-flex">
							<div>
								<h5 class="mb-0">Semua Aso Central</h5>
							</div>
							<div class="my-auto mt-4 ms-auto mt-lg-0">
								<div class="my-auto ms-auto">
									<router-link to="./new-aso-central">
										<a class="mb-0 btn bg-gradient-success btn-sm" target="_blank">+&nbsp; Tambah Aso Central</a>
									</router-link>
								</div>
							</div>
						</div>
					</div>
					<div class="px-0 pb-0 card-body">
						<div class="table-responsive">
							<div class="row">
								<div class="col-lg-4 d-flex gap-1" style="margin: 20px">
									<div class="input-group mb-3">
										<input type="text" class="form-control" v-model="searchQuery" placeholder="Search ASO Central..." />
									</div>
									<button class="btn bg-gradient-success btn-sm" @click="performSearch">Search</button>
									<button class="btn btn-primary btn-sm" @click="handleReset">Reset</button>
								</div>
							</div>

							<table class="table table-flush mx-4 mt-5">
								<thead class="thead-light">
									<tr>
										<th class="tc-p">Nama Aso</th>
										<th class="tc-p">Nama PIC</th>
										<th class="tc-p">Nomor Hp</th>
										<th class="tc-p">NIK PIC</th>
										<th class="tc-p">Status</th>
										<th class="tc-p">Action</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, i) in asoCentrals" :key="i">
										<td class="tc-p">{{ item.name }}</td>
										<td class="tc-p">{{ item.pic_name }}</td>
										<td class="tc-p">{{ item.phone }}</td>
										<td class="tc-p">{{ item.personal_id }}</td>
										<td class="tc-p">
											<span :class="`badge ${item.is_active ? 'badge-success' : 'badge-danger'} badge-sm`">
												{{ item.is_active ? "AKTIF" : "TIDAK AKTIF" }}
											</span>
										</td>
										<td class="tc-p">
											<button class="custom-btn" @click="handleEdit(item.id)">
												<span class="me-1">
													<i class="fas fa-user-edit text-secondary" data-action="edit" data-id="${datas.id}"></i>
												</span>
											</button>
											<button class="custom-btn" @click="handleDelete(item.id, item.name)">
												<span class="ms-1">
													<i class="fas fa-trash text-danger"></i>
												</span>
											</button>
										</td>
									</tr>
								</tbody>
							</table>
							<div v-if="isError" class="text-center py-5">Tidak ada data</div>

							<!-- PAGINATION -->
							<div class="d-flex justify-content-center mt-10">
								<nav aria-label="Page navigation example">
									<ul class="pagination">
										<li class="page-item" :class="{ disabled: page === 1 }">
											<a class="page-link" href="#" aria-label="Previous" @click.prevent="changePage(page - 1)">
												<span aria-hidden="true">&laquo;</span>
											</a>
										</li>
										<li
											class="page-item"
											v-for="pageNumber in totalPagesArray"
											:key="pageNumber"
											:class="{ active: pageNumber === page }"
										>
											<a class="page-link" href="#" @click.prevent="changePage(pageNumber)">{{ pageNumber }}</a>
										</li>
										<li class="page-item" :class="{ disabled: page === totalPages }">
											<a class="page-link" href="#" aria-label="Next" @click.prevent="changePage(page + 1)">
												<span aria-hidden="true">&raquo;</span>
											</a>
										</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="loading-overlay" v-if="isLoading">
		<div class="spinner"></div>
	</div>
</template>

<style scoped>
.loading-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
	display: flex;
	justify-content: center;
	align-items: center;
}

.spinner {
	/* Style your loading spinner here */
	border: 5px solid rgba(0, 0, 0, 0.3);
	border-top: 5px solid #3498db; /* Blue color for the spinner */
	border-radius: 50%;
	width: 50px;
	height: 50px;
	animation: spin 2s linear infinite;
}

.tc-p {
	padding: 10px;
}
.custom-btn {
	background: none;
	border: none;
	outline: none;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>

<script>
import axios from "./../../../libs/axios.js";
export default {
	name: "AsoCentral",
	data() {
		return {
			datatable: null,
			asoCentrals: [],
			selectedAsoCentral: {},
			isLoading: false,
			isError: false,
			page: 1,
			totalPages: 1,
			searchQuery: "",
			queryParams: {
				search: "",
				page: 1,
				limit: 10,
			},
		};
	},
	beforeMount() {
		if (localStorage.getItem("SideMenuType") !== "SuperAdmin") {
			this.$router.go(-1);
		}
	},
	mounted() {
		this.loadASOCentrals(this.queryParams);
	},
	methods: {
		async performSearch() {
			this.queryParams = {
				...this.queryParams,
				search: this.searchQuery,
				page: 1,
			};
			await this.loadASOCentrals(this.queryParams);
		},
		handleReset() {
			this.searchQuery = "";
			this.queryParams.search = "";
			this.queryParams.page = 1;
			this.loadASOCentrals(this.queryParams);
		},
		handleEdit(id) {
			this.$router.push({
				name: "superadmin.aso-central.edit",
				params: {
					asoCentral: id,
				},
			});
		},
		handleDelete(id, name) {
			this.$swal({
				title: `Anda yakin akan hapus "${name}" ?`,
				showCancelButton: true,
				confirmButtonText: "Hapus",
				cancelButtonText: "Batal",
				reverseButtons: true,
				customClass: {
					confirmButton: "btn bg-gradient-success mx-2 mt-5",
					cancelButton: "btn bg-gradient-danger mx-2 mt-5",
				},
				buttonsStyling: false,
			}).then((result) => {
				if (result.isConfirmed) {
					this.isLoading = true;
					var token = window.localStorage.getItem("TOKEN");
					const url = `https://api.darlink.id/v1/aso-central/${id}/remove`;

					let config = {
						method: "post",
						maxBodyLength: Infinity,
						url: url,
						headers: {
							Accept: "application/json",
							Authorization: token,
						},
					};
					axios
						.request(config)
						.then((response) => {
							if (response.status === 200) {
								this.$swal({
									title: "Berhasil",
									text: "ASO Central berhasil dihapus",
									icon: "success",
									confirmButtonText: "Tutup",
									confirmButtonColor: "#3085d6",
								});
							}
							this.loadASOCentrals(this.queryParams);
							this.isLoading = false;
						})
						.catch((err) => {
							this.isLoading = false;
							if (err.response && err.response?.data && err.response?.data.validation) {
								const validationErrors = err.response?.data.validation;
								let errorMessage = "Validation Error:\n";
								validationErrors.forEach((error) => {
									errorMessage += `${error.mesg}\n`;
								});
								this.$swal({
									title: "Validation Error",
									text: errorMessage,
									icon: "error",
									confirmButtonText: "OK",
									confirmButtonColor: "#d33",
								});
							} else {
								this.$swal({
									title: "Error",
									text: err.response?.data.message,
									icon: "error",
									confirmButtonText: "OK",
									confirmButtonColor: "#d33",
								});
							}
						});
				}
			});
		},
		async loadASOCentrals(params) {
			const user_token = window.localStorage.getItem("TOKEN");
			try {
				this.setIsLoading = true;
				const url = "https://api.darlink.id/v1/aso-central";
				const config = {
					methods: "GET",
					url: url,
					withCredentials: true,
					params: params,
					headers: {
						Accept: "application/json",
						Authorization: user_token,
					},
				};
				const response = await axios.request(config);
				const data = response?.data.content;
				this.asoCentrals = data;
				this.pagination = response?.data.pagination;
				this.page = this.pagination?.page;
				this.totalPages = this.pagination?.total_pages;

				if (response.data.tag === "user.aso-central.empty") {
					return (this.isError = true);
				}
				this.isError = false;
				this.setIsLoading = false;
			} catch (error) {
				console.log("error : ", error.response);
				this.setIsLoading = false;
				this.setError = true;
			}
		},
		changePage(newPage) {
			if (newPage >= 1 && newPage <= this.totalPages) {
				this.queryParams.page = newPage;
				this.loadASOCentrals(this.queryParams);
			}
		},
	},
	computed: {
		totalPagesArray() {
			return Array.from({ length: this.totalPages }, (v, k) => k + 1);
		},
	},
};
</script>
