<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="mx-auto col-lg-9 col-12">
        <div class="mt-4 card card-body">
          <h6 class="mb-0">Tambah Aso Central</h6>
          <hr class="my-3 horizontal dark" />
          <label for="aso_central_name_input" class="form-label" >Nama ASO Central</label>
          <input
            id="aso_central_name_input"
            v-model="asoCentralName"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': isError('aso-central.create.name') }"
          />
          <div class="invalid-feedback">
            Invalid input!
          </div>
          <div class="mt-4 row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>Status Aso Central</label>
                <div class="form-check form-switch ms-1">
                  <input
                    id="flexSwitchCheckDefault"
                    v-model="isActive"
                    class="form-check-input"
                    type="checkbox"
                    data-type="warning"
                    data-content="Once a project is made private, you cannot revert it to a public project."
                    data-title="Warning"
                    data-icon="ni ni-bell-55"
                  />
                  <label
                    class="form-check-label"
                    for="flexSwitchCheckDefault"
                  >
                    <span
                      class="badge badge-md"
                      :class="{
                        'badge-danger': !isActive,
                        'badge-success': isActive,
                      }"
                    >
                      {{ (isActive) ? 'AKTIF' : 'NON-AKTIF' }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label for="pic_name_input" class="form-label">Nama PIC</label>
              <input
                id="pic_name_input"
                v-model="picName"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': isError('aso-central.create.pic_name') }"
              />
              <div class="invalid-feedback">
                Invalid input!
              </div>
            </div>
            <div class="col-6">
              <label for="email_input" class="form-label">Email</label>
              <input
                id="email_input"
                v-model="email"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': isError('aso-central.create.email') }"
              />
              <div class="invalid-feedback">
                Invalid input!
              </div>
            </div>
            <div class="col-6">
              <label for="username_input" class="form-label">Username</label>
              <input
                id="username_input"
                v-model="username"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': isError('aso-central.create.username') }"
                disabled
              />
              <div class="invalid-feedback">
                Invalid input!
              </div>
            </div>
            <div class="col-6">
              <label for="password_input" class="form-label">password</label>
              <input
                id="password_input"
                v-model="password"
                type="password"
                class="form-control"
                :class="{ 'is-invalid': isError('aso-central.create.password') }"
                disabled
              />
              <div class="invalid-feedback">
                Invalid input!
              </div>
            </div>
            <div class="col-6">
              <label for="personal_id_input" class="form-label">NIK/NPK</label>
              <input
                id="personal_id_input"
                v-model="personalID"
                type="text"
                class="form-control"
               :class="{ 'is-invalid': isError('aso-central.create.personal_id') }"
               maxlength="16"
              />
              <div class="invalid-feedback">
                Invalid input!
              </div>
            </div>
            <div class="col-6">
              <label for="phone_input" class="form-label">Nomor HP PIC</label>
              <input
                id="phone_input"
                v-model="phone"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': isError('aso-central.create.phone') }"
                maxlength="13"
              />
              <div class="invalid-feedback">
                Invalid input!
              </div>
            </div>
            <div class="col-6" style="visibility:hidden;">
              <label for="total_member_input" class="form-label">Jumlah Anggota</label>
              <input
                id="total_member_input"
                v-model="totalMember"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': isError('aso-central.create.total_member') }"
              />
              <div class="invalid-feedback">
                Invalid input!
              </div>
            </div>
            <div class="col-6" style="visibility:hidden;">
              <label for="location_input" class="form-label">Daerah/Lokasi</label>
              <input
                id="location_input"
                v-model="location"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': isError('aso-central.create.location') }"
              />
              <div class="invalid-feedback">
                Invalid input!
              </div>
            </div>
            <!-- <div class="col-6" style="visibility: hidden;">
              <label for="organization_type_input" class="form-label">Jenis Organisasi</label>
              <input
                id="organization_type_input"
                v-model="organizationType"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': isError('aso-central.create.organization_type') }"
              />
              <div class="invalid-feedback">
                Invalid input!
              </div>
            </div>
            <div class="col-6" style="visibility: hidden;">
              <label for="organization_name_input" class="form-label">Nama Organisasi</label>
              <input
                id="organization_name_input"
                v-model="organizationName"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': isError('aso-central.create.organization_name') }"
              />
              <div class="invalid-feedback">
                Invalid input!
              </div>
            </div> -->
          </div>
          <label class="mt-4">Alamat Kantor</label>
          <textarea v-model="address" class="form-control" rows="5"></textarea>
          <div class="mt-4 d-flex justify-content-end">
            <button
              type="button"
              name="button"
              class="m-0 btn btn-light"
              @click="$router.push({ name: 'superadmin.aso-central' })"
            >
              Batal
            </button>
            <button
              type="button"
              name="button"
              class="m-0 btn bg-gradient-success ms-2"
              @click="submit()"
            >
              <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span v-else>Tambah Aso Central</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
// import axios from "./../../../libs/axios.js";
import router from "./../../../router/index.js";


export default {
  name: "NewAsoCentral",
  components: {},
  router,
  data() {
    return {
      asoCentralName: "",
      picName: "",
      organizationName: "-",
      organizationType: "-",
      totalMember: "-",
      personalID: "",
      location: "-",
      address: "",
      username: "",
      email: "",
      password: "",
      phone: "",
      isActive: true,
      // Validations
      validations: [],
      isLoading: false,
    };
  },
  mounted() {
    if (localStorage.getItem("SideMenuType") !== "SuperAdmin") {
        this.$router.go(-1);
    }
  },
  methods: {
    isError(tag) {
      let message = this.validations.find(validation => {
        if (validation.tag === tag) {
          return true
        }
        return false
      })

      if (!message) {
        return false
      }

      return true
    },
    randomString(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      const charactersLength = characters.length;

      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return result;
    },
    generatePassword() {
      // this.password = this.randomString(10);
      this.password = this.personalID;
    },
    validateEmail(email) {
      // Regular expression for validating an Email
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    validatePhone(phone) {
      // Regular expression for validating a Phone number
      const phoneRegex = /^\d{11,13}$/;
      return phoneRegex.test(phone);
    },
    validateNik(personalID) {
      // Regular expression for validating a Phone number
      const phoneRegex = /^\d{16}$/;
      return phoneRegex.test(personalID);
    },
    submit() {
      this.isLoading = true;
      var token = window.localStorage.getItem("TOKEN")

      // Add email and phone format validation
      if (!this.validateEmail(this.email)) {
        this.isLoading = false;
        this.$swal({
          title: "Invalid Email",
          text: "Please enter a valid email address.",
          icon: "warning",
          confirmButtonText: "OK",
          confirmButtonColor: "#3085d6",
        });
        return;
      }

      if (!this.validatePhone(this.phone)) {
        this.isLoading = false;
        this.$swal({
          title: "Invalid Phone Number",
          text: "Please enter a valid max 13-digit phone number.",
          icon: "warning",
          confirmButtonText: "OK",
          confirmButtonColor: "#3085d6",
        });
        return;
      }

      if (!this.validateNik(this.personalID)) {
        this.isLoading = false;
        this.$swal({
          title: "Invalid NIK",
          text: "Please enter a valid 16-digit number.",
          icon: "warning",
          confirmButtonText: "OK",
          confirmButtonColor: "#3085d6",
        });
        return;
      }

      const asoCentral = new FormData();
      this.totalMember = 1;
      this.generatePassword();
      // console.log(this.password);
      asoCentral.append("name", this.asoCentralName);
      asoCentral.append("email", this.email);
      asoCentral.append("username", this.personalID);
      asoCentral.append("employee_id", this.personalID);
      asoCentral.append("password", this.password);
      asoCentral.append("pic_name", this.picName);
      asoCentral.append("personal_id", this.personalID);
      asoCentral.append("location", this.location);
      asoCentral.append("phone", this.phone);
      asoCentral.append("address", this.address);
      asoCentral.append("organization_name", this.organizationName);
      asoCentral.append("organization_type", this.organizationType);
      asoCentral.append("total_member", this.totalMember);
      asoCentral.append("status", (this.isActive) ? 'active' : 'inactive');

      const url = "https://api.darlink.id/v1/aso-central"

        axios
          .post(url, asoCentral,
          {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token
            }
          })
          .then((response) => {
            this.isLoading = false;
            this.$swal({
              title: "Berhasil",
              text: "Aso Central berhasil ditambahkan",
              icon: "success",
              confirmButtonText: "Tutup",
              confirmButtonColor: "#3085d6",
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.push({ name: "superadmin.aso-central" })
              }
            });
            this.$router.push({ name: "superadmin.aso-central" })
            if (response.data.code != 201) {
              return
            }
            console.log(response)
            // this.$router.push({ name: "superadmin.aso-central" })
            // ROUTER BACK TO LIST ASO CENTRAL
          })
          .catch((err) => {
            this.isLoading = false;
            console.log(err)
            if (err.response && err.response.data && err.response.data.validation) {
              const validationErrors = err.response.data.validation;
              let errorMessage = "Validation Error:\n";
              validationErrors.forEach((error) => {
                errorMessage += `${error.mesg}\n`;
              });
              this.$swal({
                title: "Validation Error",
                text: errorMessage,
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "#d33",
              });
            } else {
              this.$swal({
                title: "Error",
                text: err.response.data.message,
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "#d33",
              });
            }
          });
      }
    },
};
</script>
