<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="mx-auto col-lg-12 col-12">
          <div class="mt-4 card card-body">
            <h6 class="mb-0">Update Password - Fincoy 1 Jakarta</h6>
            <hr class="my-3 horizontal dark" />
            <div class="row">
              <div class="col-4">
                <label for="projectName" class="form-label">Current password</label>
                <input id="projectName" type="text" class="form-control" v-model="currentPassword" />
              </div>
              <div class="col-4">
                <label for="projectName" class="form-label">New Password</label>
                <input id="projectName" type="text" class="form-control" v-model="newPassword" />
              </div>
              <div class="col-4">
                <label for="projectName" class="form-label">Confirm new Password</label>
                <input id="projectName" type="text" class="form-control" v-model="confirmPassword" />
              </div>
            </div>
            <div class="mt-4 d-flex justify-content-end">
              <router-link to="./aso-central">
                <button type="button" name="button" class="m-0 btn btn-light">
                  Batal
                </button>
              </router-link>
              <button
                type="button"
                name="button"
                class="m-0 btn bg-gradient-success ms-2"
                @click="submit"
              >
                <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span v-else>Update Password</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
//   import Quill from "quill";
  import axios from "axios";
  
  export default {
    name: "FincoyUpdatePassword",
    components: {
    //   flatPickr,
    },
    data() {
      return {
        date: "",
        endDate: "",
        config: {
          allowInput: true,
        },
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        isLoading: false,
      };
    },
    beforeMount() {
      if (localStorage.getItem("SideMenuType") !== "Fincoy") {
          this.$router.go(-1);
      }
    },
    mounted() {
    },
    methods: {
      submit() {
        this.isLoading = true;
        var token = window.localStorage.getItem("TOKEN")
        const form = new FormData()
        
        form.append("old_password", this.currentPassword);
        form.append("new_password", this.newPassword);
        form.append("retype_new_password", this.confirmPassword);

        axios.post("https://api.darlink.id/v1/fincoy/pic/password", form ,{
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          }
        }).then((response) => {
          this.isLoading = false;
          if (response.data.code != 200) {
            return
          }

          // SWEETALLERT
          this.$swal({
            title: "Berhasil",
            text: "Password berhasil diupdate",
            icon: "success",
            confirmButtonText: "Tutup",
            confirmButtonColor: "#3085d6",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push({ name: "Fincoy Update Password" })
            }
          });

          console.log(response)
          this.$router.push({ name: "Fincoy Update Password" })
        }).catch((err) => {
          this.isLoading = false;
          console.log(err);
          if (err.response && err.response.data && err.response.data.validation) {
              const validationErrors = err.response.data.validation;
              let errorMessage = "Validation Error:\n";
              validationErrors.forEach((error) => {
                errorMessage += `${error.mesg}\n`;
              });
              this.$swal({
                title: "Validation Error",
                text: errorMessage,
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "#d33",
              });
            } else {
              this.$swal({
                title: "Error",
                text: err.response.data.message,
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "#d33",
              });
            }
        })
      }
    }
  };
  </script>
  