<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Report By Agent</h5>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <label for="projectName" class="form-label">Dari Tgl</label>
                <argon-input type="date" placeholder="Date" v-model="filter.startDate" />
              </div>
              <div class="col-md-2">
                <label for="projectName" class="form-label">Sampai Tgl</label>
                <argon-input type="date" placeholder="Date" v-model="filter.endDate" />
              </div>
              <!-- <div class="col-md-3">
                <label for="projectName" class="form-label">Aso Central </label>
                <select
                  id="choices-aso"
                  class="form-control"
                  name="choices-aso"
                >
                  <option value="Choice 1" selected="">ASO Central  Jakarta</option>
                  <option value="Choice 2">ASO Central  Bandung</option>
                  <option value="Choice 3">ASO Central  Bekasi</option>
                  <option value="Choice 4">ASO Central  Medan</option>
                  <option value="Choice 5">ASO Central  Bogor</option>
                </select>
              </div>
              <div class="col-md-3">
                <label for="projectName" class="form-label">Aso Cabang </label>
                <select
                  id="choices-aso-cabang"
                  class="form-control"
                  name="choices-aso-cabang"
                >
                  <option value="Choice 1" selected="">ASO Cabang  Jakarta</option>
                  <option value="Choice 2">ASO Cabang  Bandung</option>
                  <option value="Choice 3">ASO Cabang  Bekasi</option>
                  <option value="Choice 4">ASO Cabang  Medan</option>
                  <option value="Choice 5">ASO Cabang  Bogor</option>
                </select>
              </div> -->
              <div class="col-md-4">
                <label for="projectName" class="form-label">Action</label>
                <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <a
                    href="#"
                    class="mb-0 btn bg-gradient-success btn-sm"
                    @click="handleFilter"
                    >+&nbsp; Filter</a
                  >
                  <button
                    type="button"
                    class="mx-1 mb-0 btn btn-outline-success btn-sm"
                    @click="handleReset"
                  >
                    Reset
                  </button>
                  <button
                    class="mt-1 mb-0 btn btn-outline-success btn-sm export mt-sm-0"
                    data-type="csv"
                    type="button"
                    name="button"
                    @click="handleExport"
                  ><i class="text-success text-gradient ni ni-chart-bar-32"></i>
                    Export
                  </button>
                </div>
              </div>
              </div>
            </div>
          </div>

          <div class="px-0 pb-0 card-body pt-0">
            <div class="table-responsive">
              <!-- <div class="row">
                <div class="col-lg-4" style="margin: 10px;">
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      v-model="searchQuery"
                      placeholder="Search Fincoy PIC..."
                    />
                  </div>
                  
                  <a
                    href="#"
                    class="mb-0 btn bg-gradient-success btn-sm"
                    style="margin-right: 10px;"
                    @click="performSearch"
                    >+&nbsp; search</a
                  >
                </div>
              </div> -->

              <table id="products-list" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Nama Aso Cabang </th>
                    <th>Nama Agent</th>
                    <th>Order Proses</th>
                    <th>Order Approve</th>
                    <th>Order Pending</th>
                    <th>Order Reject</th>
                    <th>Order Completed</th>
                    <!-- <th>Action</th> -->
                  </tr>
                </thead>
                <tbody></tbody>
                <tfoot>
                  <tr>
                    <th>Product</th>
                    <th>Category</th>
                    <th>Price</th>
                    <th>SKU</th>
                    <th>Quantity</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </tfoot>
              </table>

              <!-- DataTable's built-in pagination -->
              <nav aria-label="Page navigation">
                <ul class="pagination justify-content-end">
                  <li class="page-item" :class="{ disabled: page === 1 }">
                    <a class="page-link" href="javascript:void(0);" @click="loadPage(page - 1)">
                      Prev
                    </a>
                  </li>
                  <li class="page-item" v-for="pageNumber in totalPages" :key="pageNumber" :class="{ active: pageNumber === page }">
                    <a class="page-link" href="javascript:void(0);" @click="loadPage(pageNumber)">
                      {{ pageNumber }}
                    </a>
                  </li>
                  <li class="page-item" :class="{ disabled: page === totalPages }">
                    <a class="page-link" href="javascript:void(0);" @click="loadPage(page + 1)">
                      Next
                    </a>
                  </li>
                </ul>
              </nav>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- button edit modal -->
    <div
      id="view-aso"
      class="modal fade"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-lg-10">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="ModalLabel" class="modal-title">
              ASO Cabang  Jakarta
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div>
              <h6>ASO Cabang  Jakarta</h6>
              <p class="text-sm mb-0">
                Nama PIC
                <b>Herman</b> Nomor HP
                <b>1234567890</b>
              </p>
              <p class="text-sm">
                NIK/NPK :
                <b>243598234</b>
              </p>

              <p class="text-sm">
                Jumlah Anggota :
                <b>32</b>
              </p>
            </div>
            <p>Jalan Alamat nya/Lokasi.</p>

            <div>
              <p class="text-sm mb-0">
                Status
                <span class="badge badge-success badge-sm">Aktif</span>
              </p>
              <p class="text-sm">
                NIK/NPK :
                <b>243598234</b>
              </p>
            </div>

          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn bg-gradient-secondary btn-sm"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End button edit modal -->
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import setTooltip from "@/assets/js/tooltip.js";
import ArgonInput from "@/components/ArgonInput.vue";
import Choices from "choices.js";
import axios from 'axios';

var labels = {
  placeholder: "Cari report Agent...",
  perPage: "Menampilkan {select} report per halaman",
  noRows: "Tidak ada report yang ditampilkan",
  info: "Menampilkan {start} ke {end} dari {rows} report (Halaman {page} dari {pages} halaman)"
}

var columns = [
  {
    select: 0,
    render: function (data) {
      return `<div class="d-flex">
          <h6 class="my-auto ms-3">${data}</h6>
        </div>
      `
    }
  },
  {
    select: 1,
    render: function (data) {
      return `<td class="text-sm">${data}</td>
      `
    }
  },
  {
    select: 2,
    render: function (data) {
      return `<span class="badge badge-primary badge-sm">${data}</span>
      `
    }
  },
  {
    select: 3,
    render: function (data) {
      return `<span class="badge badge-success badge-sm">${data}</span>
      `
    }
  },
  {
    select: 4,
    render: function (data) {
      return `<span class="badge badge-info badge-sm">${data}</span>
      `
    }
  },
  {
    select: 5,
    render: function (data) {
      return `<span class="badge badge-danger badge-sm">${data}</span>
      `
    }
  },
  {
    select: 6,
    render: function (data) {
      return `<span class="badge badge-success badge-sm">${data}</span>
      `
    }
  },
  {
    select: 7,
    render: function () {
      return `
      <a
        href="javascript:;"
        data-bs-original-title="Preview product"
        data-bs-toggle="modal"
        data-bs-target="#view-aso"
      >
        <i class="fas fa-eye text-secondary"></i>
      </a>
      `
    }
  },
]

export default {
  name: "ReportAgent",
  components: {
    ArgonInput,
  },
  data() {
    return {
      datatable: null,
      agents: [],
      page: 1, // Current page
      totalPages: 1, // Total number of pages
      filter: {
        startDate: "",
        endDate: "",
        // asoCentral: "",
        // asoCabang: "",
      },
      searchQuery: '',
      currentDate: new Date().toISOString().split("T")[0], // Current date in YYYY-MM-DD format
    }
  },
  mounted() {
    if (document.getElementById("products-list")) {
      if (this.datatable) {
        this.datatable.destroy();
      }

      this.datatable = new DataTable("#products-list", {
        processing: this.isLoading,
        searchable: false,
        fixedHeight: false,
        perPage: 16,
        columns: columns,
        labels: labels,
        paging: false,
        filters: {
          // Add the search filter
          search: this.searchQuery,
        },
      });

      // document.querySelectorAll(".export").forEach(function (el) {
      //   el.addEventListener("click", function () {
      //     var type = el.dataset.type;

      //     var data = {
      //       type: type,
      //       filename: "soft-ui-" + type,
      //     };

      //     if (type === "csv") {
      //       data.columnDelimiter = "|";
      //     }

      //     this.datatable.export(data);
      //   });
      // });
    }

    this.filter.startDate = this.filter.startDate || this.currentDate;
    this.filter.endDate = this.filter.endDate || this.currentDate;
    setTimeout(() => {
        this.loadAgentReport()
      }, 1000)

    setTooltip(this.$store.state.bootstrap);
    if (document.getElementById("choices-aso")) {
      var element1 = document.getElementById("choices-aso");
      new Choices(element1, {
        searchEnabled: false,
        allowHTML: true,
        itemSelectText: '',
        position: 'auto',
      });
    }
  },
  methods: {
    toggleLoading() {
      if (!this.datatable) {
        return
      }
      if (!this.isLoading) {
        this.isLoading = true
        this.datatable.setMessage("Memuat...")
        return
      }
      this.isLoading = false
    },
    handleFilter() {
      this.filter.startDate = this.filter.startDate || this.currentDate;
      this.filter.endDate = this.filter.endDate || this.currentDate;

      if (this.datatable) {
        this.destroyDataTable(); // Destroy the current DataTable instance
      }
      
      if (document.getElementById("products-list")) {
        this.datatable = new DataTable("#products-list", {
          processing: this.isLoading,
          searchable: false,
          fixedHeight: false,
          perPage: 16,
          columns: columns,
          labels: labels,
          paging: false,
          filters: {
            // Add the search filter
            search: this.searchQuery,
          },
        });
        this.datatable.body.addEventListener("click", this.handleAction)
        this.toggleLoading()
        this.loadAgentReport(this.filter)
      }
    },
    handleReset() {
      this.filter.startDate = this.currentDate;
      this.filter.endDate = this.currentDate;

      if (this.datatable) {
        this.destroyDataTable(); // Destroy the current DataTable instance
      }
      
      if (document.getElementById("products-list")) {
        this.datatable = new DataTable("#products-list", {
          processing: this.isLoading,
          searchable: false,
          fixedHeight: false,
          perPage: 16,
          columns: columns,
          labels: labels,
          paging: false,
          filters: {
            // Add the search filter
            search: this.searchQuery,
          },
        });
        this.datatable.body.addEventListener("click", this.handleAction)
        this.toggleLoading()
        this.loadAgentReport()
      }
    },
    handleExport() {
      this.exportExcel(this.filter);
    },
    performSearch() {
      if (this.datatable) {
        this.destroyDataTable(); // Destroy the current DataTable instance
      }
      
      if (document.getElementById("products-list")) {
        this.datatable = new DataTable("#products-list", {
          processing: this.isLoading,
          searchable: false,
          fixedHeight: false,
          perPage: 16,
          columns: columns,
          labels: labels,
          paging: false,
          filters: {
            // Add the search filter
            search: this.searchQuery,
          },
        });

        this.page = 1;

        this.datatable.body.addEventListener("click", this.handleAction)
        this.toggleLoading()
        if (Object.keys(this.filter).some(key => this.filter[key] !== "")) {
          // Check if any property in the filter object is not empty
          this.loadAgentReport(this.filter);
        } else {
          this.loadAgentReport();
        }
      }
    },
    exportExcel(params_filter = {startDate: "", endDate: ""}) {
      const token = window.localStorage.getItem("TOKEN");
      axios
        .get(
          `https://api.darlink.id/v1/report/agent?export=xlsx&start=${params_filter.startDate}&end=${params_filter.endDate}`,
          {
            responseType: "blob", // Set the response type to blob to get the Excel file as a blob
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token,
            },
          }
        )
        .then((response) => {
          // Create a blob URL from the response data
          const blobUrl = window.URL.createObjectURL(new Blob([response.data]));

          // Create a temporary link element to trigger the download
          const link = document.createElement("a");
          link.href = blobUrl;
          link.setAttribute("download", `export-withdraw-user-${params_filter.startDate}-${params_filter.endDate}.xlsx`); // Set the desired filename for the downloaded file
          document.body.appendChild(link);

          // Click the link to trigger the download
          link.click();

          // Remove the temporary link element
          document.body.removeChild(link);
        })
        .catch(() => {
          // Handle any errors here
          this.$swal({
            title: "Error",
            text: "Failed to download Excel file",
            icon: "error",
            confirmButtonText: "Close",
            confirmButtonColor: "#3085d6",
          });
        });
    },
    loadAgentReport(params_filter = {startDate: this.currentDate, endDate: this.currentDate}) {
      console.log(params_filter)
      var token = window.localStorage.getItem("TOKEN")
      axios
        // .get("https://api.darlink.id/v1/report/agent", {
        .get(`https://api.darlink.id/v1/report/agent?start=${params_filter.startDate}&end=${params_filter.endDate}`, {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          },
          params: {
            page: this.page, // Include the page parameter
            search: this.searchQuery, // Add the search query parameter
          },
        })
        .then((response) => {
          if (response.data.code != 200) {
            return
          }

          this.agents = response.data.content
          this.totalPages = response.data.pagination.total_pages; // Update total pages

          this.updateDataTable();
          // this.datatable.insert({
          //   data: this.agents.map((agent) => {
          //     return [
          //       agent.context,
          //       agent.authored_by,
          //       agent.processing_order,
          //       agent.approved_order,
          //       agent.pending_order,
          //       agent.rejected_order,
          //       agent.completed_order,
          //     ]
          //   })
          // })
        })
        .catch((err) => {
          console.log(err);
        });
    },
    destroyDataTable() {
      if (this.datatable) {
        this.datatable.destroy();
        this.datatable = null;
      }
    },
    // Function to update the DataTable
    updateDataTable() {
      // if (this.datatable) {
      //   this.datatable.destroy();
      // }

      // this.datatable = new DataTable("#products-list", {
      //   processing: this.isLoading,
      //   searchable: true,
      //   fixedHeight: false,
      //   perPage: 16,
      //   columns: columns,
      //   labels: labels,
      // });

      // this.datatable.body.addEventListener("click", this.handleAction);
      this.datatable.body.innerHTML = '';
      this.datatable.insert({
        data: this.agents.map((agent) => {
          return [
            agent.context,
            agent.authored_by,
            agent.processing_order ? `${agent.processing_order}` : "0",
            agent.approved_order ? `${agent.approved_order}` : "0",
            agent.pending_order ? `${agent.pending_order}` : "0",
            agent.rejected_order ? `${agent.rejected_order}` : "0",
            agent.completed_order ? `${agent.completed_order}` : "0",
          ]
        })
      })
    },
    // Function to load data for a specific page
    loadPage(page) {
      if (page < 1 || page > this.totalPages) {
        return;
      }

      this.page = page;
      // this.loadAgentReport();
      if (this.datatable) {
        this.destroyDataTable(); // Destroy the current DataTable instance
      }
      
      if (document.getElementById("products-list")) {
        this.datatable = new DataTable("#products-list", {
          processing: this.isLoading,
          searchable: false,
          fixedHeight: false,
          perPage: 16,
          columns: columns,
          labels: labels,
          paging: false,
          filters: {
            // Add the search filter
            search: this.searchQuery,
          },
        });
        this.datatable.body.addEventListener("click", this.handleAction)
        this.toggleLoading()
        // this.loadASOCentrals()
        if (Object.keys(this.filter).some(key => this.filter[key] !== "")) {
          // Check if any property in the filter object is not empty
          this.loadAgentReport(this.filter);
        } else {
          this.loadAgentReport();
        }
      }
    },
    showSwal(type) {
      console.log("asdasdf asdfasdf asdfasdf");
      if (type === "basic") {
        this.$swal({
          title: "Any fool can use a computer",
          type: type,
        });
      } else if (type === "success-message") {
        this.$swal({
          icon: "success",
          title: "Good Job!",
          text: "You clicked the button!",
          type: type,
        });
      } else if (type === "custom-html") {
        this.$swal({
          icon: "info",
          title: "<strong>HTML <u>example</u></strong>",
          html:
            "You can use <b>bold text</b>, " +
            '<a href="//sweetalert2.github.io">links</a> ' +
            "and other HTML tags",
          type: type,
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!',
          confirmButtonAriaLabel: "Thumbs up, great!",
          cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
          cancelButtonAriaLabel: "Thumbs down",
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        });
      } else if (type === "input-field") {
        this.$swal({
          title: "Submit your Github username",
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Look up",
          showLoaderOnConfirm: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
          preConfirm: (login) => {
            return fetch(`//api.github.com/users/${login}`)
              .then((response) => {
                if (!response.ok) {
                  throw new Error(response.statusText);
                }
                return response.json();
              })
              .catch((error) => {
                this.$swal.showValidationMessage(`Request failed: ${error}`);
              });
          },
          allowOutsideClick: () => !this.$swal.isLoading(),
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal({
              title: `${result.value.login}'s avatar`,
              imageUrl: result.value.avatar_url,
            });
          }
        });
      } else if (type === "title-and-text") {
        this.$swal({
          title: "Sweet!",
          text: "Modal with a custom image.",
          imageUrl: "https://unsplash.it/400/200",
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
        });
      } else if (type === "auto-close") {
        let timerInterval;
        this.$swal({
          title: "Auto close alert!",
          html: "I will close in <b></b> milliseconds.",
          timer: 2000,
          timerProgressBar: true,
          didOpen: () => {
            this.$swal.showLoading();
            const b = this.$swal.getHtmlContainer().querySelector("b");
            timerInterval = setInterval(() => {
              b.textContent = this.$swal.getTimerLeft();
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        });
      } else if (type === "warning-message-and-confirmation") {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            });
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === this.$swal.DismissReason.cancel
          ) {
            this.$swal({
              title: "Cancelled!",
              text: "Your imaginary file is safe :)",
              icon: "error",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            });
          }
        });
      } else if (type === "warning-message-and-cancel") {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancel",
          confirmButtonText: "Yes, delete it!",
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            });
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === this.$swal.DismissReason.cancel
          ) {
            this.$swal.dismiss;
          }
        });
      } else if (type === "rtl-language") {
        this.$swal({
          title: "هل تريد الاستمرار؟",
          icon: "question",
          iconHtml: "؟",
          confirmButtonText: "نعم",
          cancelButtonText: "لا",
          showCancelButton: true,
          showCloseButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        });
      }
    },
  },
};
</script>
