<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Report Order</h5>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <label for="projectName" class="form-label">Dari Tgl</label>
                <argon-input type="date" placeholder="Date" v-model="filter.startDate" />
              </div>
              <div class="col-md-2">
                <label for="projectName" class="form-label">Sampai Tgl</label>
                <argon-input type="date" placeholder="Date" v-model="filter.endDate" />
              </div>
              <div class="col-md-2">
                <label for="projectName" class="form-label">Status</label>
                <select
                  id="choices-aso"
                  class="form-control"
                  name="choices-aso"
                  v-model="filter.status"
                >
                  <option value="" selected="">All</option>
                  <option value="pending">Pending</option>
                  <option value="assigned">Assign</option>
                  <option value="process">Prosess</option>
                  <option value="rejected">Reject</option>
                  <option value="approved">Approve</option>
                  <option value="funded_user">Pencairan D.Konsumen</option>
                  <!-- <option value="Choice 7">Pencairan D.Darlink</option> -->
                  <option value="completed">Completed</option>
                </select>
              </div>
              <div class="col-md-5">
                <label for="projectName" class="form-label">Action</label>
                <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <!-- <a
                    href="#"
                    class="mb-0 btn bg-gradient-success btn-sm"
                    style="margin-right: 10px;"
                    >+&nbsp; Filter</a
                  > -->
                  <!-- <button
                    type="button"
                    class="mx-1 mb-0 btn btn-outline-success btn-sm"
                  >
                    Riset
                  </button> -->
                  <button
                    class="mt-1 mb-0 btn btn-outline-success btn-sm export mt-sm-0"
                    data-type="csv"
                    type="button"
                    name="button"
                    @click="exportExcel(filter)"
                  ><i class="text-success text-gradient ni ni-chart-bar-32"></i>
                    Export
                  </button>
                </div>
              </div>
              </div>
            </div>
          </div>

          <div class="px-0 pb-0 card-body pt-0" style="margin-right: 10px; margin-bottom: 30px;">
            <!-- <div class="table-responsive">
              <table id="products-list" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Aso Cabang</th>
                    <th>Aso Central</th>
                    <th>Nama Client</th>
                    <th>T. Pinjaman</th>
                    <th>Nama Kendaraan</th>
                    <th>Status Client</th>
                    <th>Fincoy Ho</th>
                    <th>Fincoy Pic</th>
                    <th>Total Fee</th>
                    <th>Fee Aso Cabang</th>
                    <th>Fee ASO Cabang</th>
                    <th>Fee ASO Agent Cabang</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>ASO Cabang Jakarta</td>
                    <td>ASO Central Jakarta</td>
                    <td class="text-sm">Herman</td>
                    <td class="text-sm">
                      <div class="d-flex">
                        <h6 class="my-auto">Rp.100.000.000</h6>
                      </div>
                    </td>
                    <td class="text-sm">Yamaha nMax</td>
                    <td class="text-sm"><span class="badge badge-info badge-sm">Low</span></td>
                    <td class="text-sm">Fincoy Ho 1</td>
                    <td class="text-sm">Fincoy 1 PIC A</td>
                    <td class="text-sm">1.000.000</td>
                    <td class="text-sm">100.000</td>
                    <td class="text-sm">600.000</td>
                    <td class="text-sm">300.000</td>
                    <td><span class="badge badge-success badge-sm">Approve</span></td>
                    <td class="text-sm">
                      <a
                        href="javascript:;"
                        data-bs-original-title="Preview product"
                        data-bs-toggle="modal"
                        data-bs-target="#view-aso"
                      >
                        <i class="fas fa-eye text-secondary"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th>Aso Cabang</th>
                    <th>Aso Central</th>
                    <th>Nama Client</th>
                    <th>T. Pinjaman</th>
                    <th>Nama Kendaraan</th>
                    <th>Status Client</th>
                    <th>Fincoy Ho</th>
                    <th>Fincoy Pic</th>
                    <th>Total Fee</th>
                    <th>Fee Aso Cabang</th>
                    <th>Fee ASO Cabang</th>
                    <th>Fee ASO Agent Cabang</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </tfoot>
              </table>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- button edit modal -->
    <div
      id="view-aso"
      class="modal fade"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-lg-10">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="ModalLabel" class="modal-title">
              ASO Cabang  Jakarta
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div>
              <h6>ASO Cabang  Jakarta</h6>
              <p class="text-sm mb-0">
                Nama PIC
                <b>Herman</b> Nomor HP
                <b>1234567890</b>
              </p>
              <p class="text-sm">
                NIK/NPK :
                <b>243598234</b>
              </p>

              <p class="text-sm">
                Jumlah Anggota :
                <b>32</b>
              </p>
            </div>
            <p>Jalan Alamat nya/Lokasi.</p>

            <div>
              <p class="text-sm mb-0">
                Status
                <span class="badge badge-success badge-sm">Aktif</span>
              </p>
              <p class="text-sm">
                NIK/NPK :
                <b>243598234</b>
              </p>
            </div>

          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn bg-gradient-secondary btn-sm"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End button edit modal -->
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import setTooltip from "@/assets/js/tooltip.js";
import ArgonInput from "@/components/ArgonInput.vue";
import axios from 'axios'
// import Choices from "choices.js";

export default {
  name: "ReportAsoCabang",
  components: {
    ArgonInput,
  },
  data() {
    return {
      datatable: null,
      isLoading: false,
      personInCharges: [],
      orderLists: [],
      selectedAso: null,
      asoCentral: [],
      fincoyHO: [],
      filter: {
        startDate: "",
        endDate: "",
        // fincoyPic: "",
        // central: "",
        status: "",
      }
    }
  },
  mounted() {
    if (document.getElementById("products-list")) {
      const dataTableSearch = new DataTable("#products-list", {
        searchable: true,
        fixedHeight: false,
        perPage: 7,
      });

      document.querySelectorAll(".export").forEach(function (el) {
        el.addEventListener("click", function () {
          var type = el.dataset.type;

          var data = {
            type: type,
            filename: "soft-ui-" + type,
          };

          if (type === "csv") {
            data.columnDelimiter = "|";
          }

          dataTableSearch.export(data);
        });
      });
    }
    setTooltip(this.$store.state.bootstrap);

    // this.loadFincoyHO()
    // this.loadAsoCentral()
  },
  methods: {
    // loadFincoyHO() {
    //   var token = window.localStorage.getItem("TOKEN")
    //   axios.get('https://api.darlink.id/v1/fincoy/', {
    //     withCredentials: true,
    //     headers: {
    //       Accept: "application/json",
    //       Authorization: token
    //     }
    //   }).then((response) => {
    //     this.fincoyHO = response.data.content;
    //   })
    // },
    // loadAsoCentral() {
    //   var token = window.localStorage.getItem("TOKEN")
    //   axios.get('https://api.darlink.id/v1/aso-central/', {
    //     withCredentials: true,
    //     headers: {
    //       Accept: "application/json",
    //       Authorization: token
    //     }
    //   }).then((response) => {
    //     this.asoCentral = response.data.content;
    //   })
    // },
    exportExcel(params_filter = {startDate: "", endDate: "", status: ""}) {
      var token = window.localStorage.getItem("TOKEN");
      axios
        .get(
          `https://api.darlink.id/v1/order?status=${params_filter.status}&start=${params_filter.startDate}&end=${params_filter.endDate}&export=xlsx`,
          {
            responseType: "blob", // Set the response type to blob to get the Excel file as a blob
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token,
            },
          }
        )
        .then((response) => {
          // Handle the JSON response (if needed)
          console.log(response.data); // This will log the JSON data to the console

          // Create a blob URL from the response data
          const blobUrl = window.URL.createObjectURL(new Blob([response.data]));

          // Create a temporary link element to trigger the download
          const link = document.createElement("a");
          link.href = blobUrl;
          link.setAttribute("download", "filename.xlsx"); // Set the desired filename for the downloaded file
          document.body.appendChild(link);

          // Click the link to trigger the download
          link.click();

          // Remove the temporary link element
          document.body.removeChild(link);
        })
        .catch(() => {
          // Handle any errors here
          this.$swal({
            title: "Error",
            text: "Failed to download Excel file",
            icon: "error",
            confirmButtonText: "Close",
            confirmButtonColor: "#3085d6",
          });
        });
    },
  },
};
</script>
