<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Semua Proses Withdraw</h5>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <label for="projectName" class="form-label">Dari Tgl</label>
                <argon-input type="date" placeholder="Date" v-model="filter.startDate" />
              </div>
              <div class="col-md-2">
                <label for="projectName" class="form-label">Sampai Tgl</label>
                <argon-input type="date" placeholder="Date" v-model="filter.endDate" />
              </div>
              <div class="col-md-4">
                <label for="projectName" class="form-label">Action</label>
                <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <a
                    href="#"
                    class="mb-0 btn bg-gradient-success btn-sm"
                    style="margin-right: 10px;"
                    @click="handleFilter"
                    >+&nbsp; Filter</a
                  >

                  <button
                    class="mt-1 mb-0 btn btn-outline-success btn-sm export mt-sm-0"
                    type="button"
                    name="button"
                    style="margin-right: 10px;"
                    @click="handleReset"
                  >
                    Reset
                  </button>

                  <button
                    class="mt-1 mb-0 btn btn-outline-success btn-sm export mt-sm-0"
                    data-type="csv"
                    type="button"
                    name="button"
                    @click="handleExport"
                  ><i class="text-success text-gradient ni ni-chart-bar-32"></i>
                    Export
                  </button>
                </div>
              </div>
              </div>
            </div>
          </div>
          
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <!-- <div class="row">
                <div class="col-lg-4" style="margin: 10px;">
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      v-model="searchQuery"
                      @input="performSearch"
                      placeholder="Search ..."
                    />
                  </div>
                </div>
              </div> -->

              <table id="products-list" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Nama Aso Central</th>
                    <th>Nama Aso Cabang</th>
                    <th>Nama</th>
                    <!-- <th>Nomor Hp</th> -->
                    <!-- <th>Role</th> -->
                    <th>Jumlah Withdraw</th>
                    <th>Saldo Sebelum</th>
                    <th>Saldo Sesudah</th>
                    <!-- <th>Lokasi</th> -->
                    <th>Tanggal Pengajuan</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody></tbody>
                <tfoot>
                  <tr>
                  </tr>
                </tfoot>
              </table>

              <!-- DataTable's built-in pagination -->
              <nav aria-label="Page navigation">
                <ul class="pagination justify-content-end">
                  <li class="page-item" :class="{ disabled: page === 1 }">
                    <a class="page-link" href="javascript:void(0);" @click="loadPage(page - 1)">
                      Prev
                    </a>
                  </li>
                  <li class="page-item" v-for="pageNumber in totalPages" :key="pageNumber" :class="{ active: pageNumber === page }">
                    <a class="page-link" href="javascript:void(0);" @click="loadPage(pageNumber)">
                      {{ pageNumber }}
                    </a>
                  </li>
                  <li class="page-item" :class="{ disabled: page === totalPages }">
                    <a class="page-link" href="javascript:void(0);" @click="loadPage(page + 1)">
                      Next
                    </a>
                  </li>
                </ul>
              </nav>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- button edit modal -->
    <div
      id="view-aso"
      class="modal fade"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-lg-10">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="ModalLabel" class="modal-title">
              ASO Central Jakarta
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div>
              <h6>ASO Central Jakarta</h6>
              <p class="text-sm mb-0">
                Nama PIC
                <b>Herman</b> Nomor HP
                <b>1234567890</b>
              </p>
              <p class="text-sm">
                NIK/NPK :
                <b>243598234</b>
              </p>

              <p class="text-sm">
                Jumlah Anggota :
                <b>32</b>
              </p>
            </div>
            <p>Jalan Alamat nya/Lokasi.</p>

            <div>
              <p class="text-sm mb-0">
                Status
                <span class="badge badge-success badge-sm">Selesai</span>
              </p>
              <p class="text-sm">
                NIK/NPK :
                <b>243598234</b>
              </p>
            </div>

          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn bg-gradient-secondary btn-sm"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End button edit modal -->
  </div>

  <div class="loading-overlay" v-if="isLoading">
    <!-- You can use a loading spinner or any other loading content here -->
    <div class="spinner"></div>
  </div>
</template>

<style scoped>
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .spinner {
    /* Style your loading spinner here */
    border: 5px solid rgba(0, 0, 0, 0.3);
    border-top: 5px solid #3498db; /* Blue color for the spinner */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
</style>

<script>
import { DataTable } from "simple-datatables";
import setTooltip from "@/assets/js/tooltip.js";
import ArgonInput from "@/components/ArgonInput.vue";
import Swal from 'sweetalert2';
import axios from 'axios';

var columns = [
  {
    select: 0,
    render: function (data) {
      return `<div class="d-flex">
          <h6 class="my-auto ms-3">${data}</h6>
        </div>
      `
    }
  },
  {
    select: [1,2,3,4,5],
    render: function (data) {
      return `<td class="text-sm">${data}</td>
      `
    }
  },
  {
    select: 7,
    render: function (data) {
      if(data == "pending")
      {
        return `
          <td>
            <span class="badge ${(data) != "pending" ? "badge-success" : "badge-info"} badge-sm"
              >${(data) != "pending" ? "APPROVE" : "Pending"}</span
            >
          </td>
        `
      }
      else if(data == "rejected")
      {
        return `
          <td>
            <span class="badge ${(data) != "pending" ? "badge-danger" : "badge-info"} badge-sm"
              >${(data) != "pending" ? "REJECT" : "Pending"}</span
            >
          </td>
        `
      }
      else
      {
        return `
          <td>
            <span class="badge ${(data) != "pending" ? "badge-success" : "badge-info"} badge-sm"
              >${(data) != "pending" ? "APPROVE" : "Pending"}</span
            >
          </td>
        `
      }
    }
  },
  {
    select: 8,
    render: function (data) {
      let datas = JSON.parse(data)

      if(datas.status == "pending")
      {
        return `
          <!-- button edit modal -->
          <a
            class="text-sm"
            href="javascript: void(0);"
          >
            <span
              class="mx-3"
            >
              <i
              class="fas fa-eye text-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Detail"
                data-action="edit"
                data-id="${datas.id}"
              ></i>
            </span>
          </a>
          <a
            class="text-sm"
            href="javascript: void(0);"
          >
            <span
              class="mx-3"
            >
            <i
              class="fas fa-check text-success data-bs-toggle="tooltip" data-bs-placement="top" title="Detail"
                data-action="approve"
                data-id="${datas.id}"
                data-name="${datas.user_name}"
              ></i>
          </a>
          <a
            href="javascript:;"
            class="mx-3"
          >
            <i 
              data-id=${datas.id}
              data-name="${datas.user_name}"
              data-action="deactive" 
              class="fas fa-times text-secondary text-warning"></i>
          </a>
        `
      }
      else
      {
        return `
          <!-- button edit modal -->
          <a
            class="text-sm"
            href="javascript: void(0);"
          >
            <span
              class="mx-3"
            >
              <i
              class="fas fa-eye text-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Detail"
                data-action="edit"
                data-id="${datas.id}"
              ></i>
            </span>
          </a>
        `
      }
    }
  }
]

var labels = {
  placeholder: "Cari proses withdraw...",
  perPage: "Menampilkan {select} proses withdraw per halaman",
  noRows: "Tidak ada proses withdraw yang ditampilkan",
  info: "Menampilkan {start} ke {end} dari {rows} proses withdraw (Halaman {page} dari {pages} halaman)"
}

export default {
  name: "ProsesWD",
  components: {
    ArgonInput,
  },
  data() {
    return {
      datatable: null,
      withdrawRequests: [],
      withdrawRequestsFilter: [],
      isLoading: false,
      filter: {
        startDate: "",
        endDate: "",
        fincoyPic: "",
      },
      page: 1, // Current page
      totalPages: 1, // Total number of pages
      searchQuery: '',
    }
  },
  beforeMount() {
    if (localStorage.getItem("SideMenuType") !== "SuperAdmin") {
        this.$router.go(-1);
    }
  },
  mounted() {
    if (document.getElementById("products-list")) {
      this.datatable = new DataTable("#products-list", {
        processing: this.isLoading,
        searchable: false,
        fixedHeight: false,
        perPage: 16,
        columns: columns,
        labels: labels,
        paging: false,
        filters: {
          // Add the search filter
          search: this.searchQuery,
        },
      });
      this.datatable.body.addEventListener("click", this.handleAction);
      this.toggleLoading()
      this.loadWithdrawRequests()
    }
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.datatable.destroy()
    this.datatable = null
  },
  methods: {
    handleFilter() {
      if (this.datatable) {
        this.destroyDataTable(); // Destroy the current DataTable instance
      }
      
      if (document.getElementById("products-list")) {
        this.datatable = new DataTable("#products-list", {
          processing: this.isLoading,
          searchable: false,
          fixedHeight: false,
          perPage: 16,
          columns: columns,
          labels: labels,
          paging: false,
          filters: {
            // Add the search filter
            search: this.searchQuery,
          },
        });
        this.datatable.body.addEventListener("click", this.handleAction)
        this.toggleLoading()
        this.loadWithdrawRequestsFilter(this.filter)
      }
    },
    handleReset() {
      this.filter.startDate = ""
      this.filter.endDate = ""
      
      if (this.datatable) {
        this.destroyDataTable(); // Destroy the current DataTable instance
      }
      
      if (document.getElementById("products-list")) {
        this.datatable = new DataTable("#products-list", {
          processing: this.isLoading,
          searchable: false,
          fixedHeight: false,
          perPage: 16,
          columns: columns,
          labels: labels,
          paging: false,
          filters: {
            // Add the search filter
            search: this.searchQuery,
          },
        });
        this.datatable.body.addEventListener("click", this.handleAction)
        this.toggleLoading()
        this.loadWithdrawRequests()
      }
    },
    handleExport() {
      this.exportExcel(this.filter);
    },
    handleAction(e) {
      if (e.target.getAttribute("data-action") === "approve") {
        let id = e.target.getAttribute("data-id");
        let name = e.target.getAttribute("data-name");
        Swal.fire({
          title: 'Konfirmasi',
          text: `Anda yakin ingin menerima permintaan penarikan dana dari "${name}"?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Batal',
        }).then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            var token = window.localStorage.getItem("TOKEN");
            axios
            .post("https://api.darlink.id/v1/withdraw/"+id+"/acknowledge", 
            null,
            {
              withCredentials: true,
              headers: {
                Accept: "application/json",
                Authorization: token
              }
            })
            .then((response) => {
              this.isLoading = false;
              console.log(response)
              // if response code not 200
              // if (response.data.code != 201) {
              //   // show error message
              //   // sweetalert error
              //   this.$swal({
              //     title: "Gagal",
              //     text: response.data.message,
              //     icon: "error",
              //     button: "OK",
              //   })
              //   return
              // }
              this.$swal({
                title: "Berhasil",
                text: "Approve Request Withdrawal",
                icon: "success",
                confirmButtonText: "OK",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload()
                }
              });
              this.$router.push({ name: "superadmin.process-withdraw" })
              // reload page after 1000ms
              setTimeout(() => {
                window.location.reload()
              }, 4000)
            })
            .catch((err) => {
              this.isLoading = false;
              // if error
              // show error message
              this.$store.dispatch("show-error", err)
              if (err.response && err.response.data && err.response.data.validation) {
                const validationErrors = err.response.data.validation;
                let errorMessage = "Validation Error:\n";
                validationErrors.forEach((error) => {
                  errorMessage += `${error.mesg}\n`;
                });
                this.$swal({
                  title: "Validation Error",
                  text: errorMessage,
                  icon: "error",
                  confirmButtonText: "OK",
                  confirmButtonColor: "#d33",
                });
              } else {
                this.$swal({
                  title: "Error",
                  text: err.response.data.message,
                  icon: "error",
                  confirmButtonText: "OK",
                  confirmButtonColor: "#d33",
                });
              }
            })
          }
        })
      }
      if (e.target.getAttribute("data-action") === "deactive") {
        let id = e.target.getAttribute("data-id");
        let name = e.target.getAttribute("data-name");
        Swal.fire({
          title: 'Konfirmasi',
          text: `Anda yakin ingin menolak pencarian dana dari "${name}"?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Batal',
        }).then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            var token = window.localStorage.getItem("TOKEN");
            axios
            .post("https://api.darlink.id/v1/withdraw/"+id+"/reject", 
            null,
            {
              withCredentials: true,
              headers: {
                Accept: "application/json",
                Authorization: token
              }
            })
            .then((response) => {
              this.isLoading = false;
              // if response code not 200
              if (response.data.code != 200) {
                // show error message
                // sweetalert error
                this.$swal({
                  title: "Berhasil",
                  // text: response.data.message,
                  text: "Reject Request Withdrawal",
                  icon: "success",
                  button: "OK",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.reload()
                  }
                });
                return;
              }
              this.$swal({
                title: "Berhasil",
                text: "Reject request Withdrawal",
                icon: "success",
                confirmButtonText: "OK",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload()
                }
              });
              this.$router.push({ name: "superadmin.process-withdraw" })
              // reload page after 1000ms
              setTimeout(() => {
                window.location.reload()
              }, 4000)
            })
            .catch((err) => {
              this.isLoading = false;
              // if error
              // show error message
              this.$store.dispatch("show-error", err)
              if (err.response && err.response.data && err.response.data.validation) {
                const validationErrors = err.response.data.validation;
                let errorMessage = "Validation Error:\n";
                validationErrors.forEach((error) => {
                  errorMessage += `${error.mesg}\n`;
                });
                this.$swal({
                  title: "Validation Error",
                  text: errorMessage,
                  icon: "error",
                  confirmButtonText: "OK",
                  confirmButtonColor: "#d33",
                });
              } else {
                this.$swal({
                  title: "Error",
                  text: err.response.data.message,
                  icon: "error",
                  confirmButtonText: "OK",
                  confirmButtonColor: "#d33",
                });
              }
            })
          }
        })
      }
      if (e.target.getAttribute("data-action") === "edit") {
          let id = parseInt(e.target.getAttribute("data-id"), 10)
          this.edit(id)
      }
    },
    edit(id) {
      this.$router.push({
        name: "superadmin.process-withdraw.detail",
        params: {
          orderList: id
        }
      })
    },
    toggleLoading() {
      if (!this.datatable) {
        return
      }
      if (!this.isLoading) {
        this.isLoading = true
        this.datatable.setMessage("Memuat...")
        return
      }
      this.isLoading = false
    },
    performSearch() {
      if (this.datatable) {
        this.destroyDataTable(); // Destroy the current DataTable instance
      }
      
      if (document.getElementById("products-list")) {
        this.datatable = new DataTable("#products-list", {
          processing: this.isLoading,
          searchable: false,
          fixedHeight: false,
          perPage: 16,
          columns: columns,
          labels: labels,
          paging: false,
          filters: {
            // Add the search filter
            search: this.searchQuery,
          },
        });

        this.page = 1;

        this.datatable.body.addEventListener("click", this.handleAction)
        this.toggleLoading()
        if (Object.keys(this.filter).some(key => this.filter[key] !== "")) {
          // Check if any property in the filter object is not empty
          this.loadWithdrawRequestsFilter(this.filter);
        } else {
          this.loadWithdrawRequests();
        }
      }
    },
    loadWithdrawRequests() {
      var token = window.localStorage.getItem("TOKEN")
      axios
        .get("https://api.darlink.id/v1/withdraw/", {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          },
          params: {
            page: this.page, // Include the page parameter
            search: this.searchQuery, // Add the search query parameter
          },
        })
        .then((response) => {
          this.toggleLoading()
          if (response.data.code != 200) {
            this.isLoading = false
            return
          }
          if (response.data.tag === "content.withdraw-request.empty") {
            this.datatable.setMessage("Tidak ada data yang ditampilkan")
            return
          }
          this.withdrawRequests = response.data.content
          this.totalPages = response.data.pagination.total_pages; // Update total pages

          this.updateDataTable();
          // this.datatable.insert({
          //   data: this.withdrawRequests.map((withdrawRequests) => {
          //     let request_with = withdrawRequests.before_balance - withdrawRequests.after_balance;
              
          //     return [
          //       withdrawRequests.aso_central_name,
          //       withdrawRequests.aso_branch_name,
          //       withdrawRequests.user_type == 'agent' ? withdrawRequests.user_name : withdrawRequests.aso_branch_name,
          //       // withdrawRequests.amount.toLocaleString("id-ID", {
          //       //     style: "currency",
          //       //     currency: "IDR",
          //       //     minimumFractionDigits: 2,
          //       //   }),
          //       request_with.toLocaleString("id-ID", {
          //           style: "currency",
          //           currency: "IDR",
          //           minimumFractionDigits: 2,
          //         }),
          //       withdrawRequests.before_balance.toLocaleString("id-ID", {
          //           style: "currency",
          //           currency: "IDR",
          //           minimumFractionDigits: 2,
          //         }),
          //       withdrawRequests.after_balance.toLocaleString("id-ID", {
          //           style: "currency",
          //           currency: "IDR",
          //           minimumFractionDigits: 2,
          //         }),
          //       new Date(withdrawRequests.created_at).toLocaleDateString("id-ID", {
          //           year: "numeric",
          //           month: "long",
          //           day: "numeric",
          //           // hour
          //           hour: "numeric",
          //           // minute
          //           minute: "numeric",
          //         }),
          //       withdrawRequests.status,
          //       `${withdrawRequests.id}`
          //     ]
          //   })
          // })
          this.isLoading = false
        })
        .catch((err) => {
          this.toggleLoading()
          console.log(err);
        });
    },
    loadWithdrawRequestsFilter(params_filter = {startDate: "", endDate: ""}) {
      var token = window.localStorage.getItem("TOKEN")
      axios
        .get(`https://api.darlink.id/v1/withdraw/?start=${params_filter.startDate}&end=${params_filter.endDate}`, {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          },
          params: {
            page: this.page, // Include the page parameter
            search: this.searchQuery, // Add the search query parameter
          },
        })
        .then((response) => {
          this.toggleLoading()
          if (response.data.code != 200) {
            this.isLoading = false
            return
          }
          if (response.data.tag === "content.withdraw-request.empty") {
            this.datatable.setMessage("Tidak ada data yang ditampilkan")
            return
          }
          this.withdrawRequestsFilter = response.data.content
          this.totalPages = response.data.pagination.total_pages; // Update total pages

          this.updateDataTableFilter();

          // // Clear existing data and rows from the DataTable
          // this.datatable.clear();
          // this.datatable.destroy();

          // // Insert new data into the DataTable
          // this.datatable = new DataTable("#products-list", {
          //   searchable: true,
          //   fixedHeight: false,
          //   perPage: 15,
          //   columns: columns,
          //   labels: labels
          // });
          // this.datatable.body.addEventListener("click", this.handleAction);

          // this.datatable.insert({
          //   data: this.withdrawRequestsFilter.map((withdrawRequestsFilter) => {
          //     let request_with = withdrawRequestsFilter.before_balance - withdrawRequestsFilter.after_balance;

          //     return [
          //       withdrawRequestsFilter.aso_central_name,
          //       withdrawRequestsFilter.aso_branch_name,
          //       withdrawRequestsFilter.user_type == 'agent' ? withdrawRequestsFilter.user_name : withdrawRequestsFilter.aso_branch_name,
          //       request_with.toLocaleString("id-ID", {
          //           style: "currency",
          //           currency: "IDR",
          //           minimumFractionDigits: 2,
          //         }),
          //       withdrawRequestsFilter.before_balance.toLocaleString("id-ID", {
          //           style: "currency",
          //           currency: "IDR",
          //           minimumFractionDigits: 2,
          //         }),
          //       withdrawRequestsFilter.after_balance.toLocaleString("id-ID", {
          //           style: "currency",
          //           currency: "IDR",
          //           minimumFractionDigits: 2,
          //         }),
          //       new Date(withdrawRequestsFilter.created_at).toLocaleDateString("id-ID", {
          //           year: "numeric",
          //           month: "long",
          //           day: "numeric",
          //           // hour
          //           hour: "numeric",
          //           // minute
          //           minute: "numeric",
          //         }),
          //       withdrawRequestsFilter.status,
          //       `${withdrawRequestsFilter.id}`
          //     ]
          //   })
          // })
          this.isLoading = false
        })
        .catch((err) => {
          this.toggleLoading()
          console.log(err);
        });
    },
    destroyDataTable() {
      if (this.datatable) {
        this.datatable.destroy();
        this.datatable = null;
      }
    },
    // Function to update the DataTable
    updateDataTableFilter() {
      // if (this.datatable) {
      //   this.datatable.destroy();
      // }

      // this.datatable = new DataTable("#products-list", {
      //   processing: this.isLoading,
      //   searchable: true,
      //   fixedHeight: false,
      //   perPage: 16,
      //   columns: columns,
      //   labels: labels,
      // });
      // this.datatable.body.addEventListener("click", this.handleAction);
      this.datatable.body.innerHTML = '';
      this.datatable.insert({
        data: this.withdrawRequestsFilter.map((withdrawRequestsFilter) => {
          let request_with = withdrawRequestsFilter.before_balance - withdrawRequestsFilter.after_balance;

          return [
            withdrawRequestsFilter.aso_central_name,
            withdrawRequestsFilter.aso_branch_name,
            withdrawRequestsFilter.user_type == 'agent' ? withdrawRequestsFilter.user_name : withdrawRequestsFilter.aso_branch_name,
            request_with.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 2,
              }),
            withdrawRequestsFilter.before_balance.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 2,
              }),
            withdrawRequestsFilter.after_balance.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 2,
              }),
            new Date(withdrawRequestsFilter.created_at).toLocaleDateString("id-ID", {
                year: "numeric",
                month: "long",
                day: "numeric",
                // hour
                hour: "numeric",
                // minute
                minute: "numeric",
              }),
            withdrawRequestsFilter.status,
            // `${withdrawRequestsFilter.id}`
            JSON.stringify(withdrawRequestsFilter),
          ]
        })
      })
    },
    updateDataTable() {
      // if (this.datatable) {
      //   this.datatable.destroy();
      // }

      // this.datatable = new DataTable("#products-list", {
      //   processing: this.isLoading,
      //   searchable: true,
      //   fixedHeight: false,
      //   perPage: 16,
      //   columns: columns,
      //   labels: labels,
      // });

      // this.datatable.body.addEventListener("click", this.handleAction);
      this.datatable.body.innerHTML = '';
      this.datatable.insert({
        data: this.withdrawRequests.map((withdrawRequests) => {
          let request_with = withdrawRequests.before_balance - withdrawRequests.after_balance;
          
          return [
            withdrawRequests.aso_central_name,
            withdrawRequests.aso_branch_name,
            withdrawRequests.user_type == 'agent' ? withdrawRequests.user_name : withdrawRequests.aso_branch_name,
            // withdrawRequests.amount.toLocaleString("id-ID", {
            //     style: "currency",
            //     currency: "IDR",
            //     minimumFractionDigits: 2,
            //   }),
            request_with.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 2,
              }),
            withdrawRequests.before_balance.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 2,
              }),
            withdrawRequests.after_balance.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 2,
              }),
            new Date(withdrawRequests.created_at).toLocaleDateString("id-ID", {
                year: "numeric",
                month: "long",
                day: "numeric",
                // hour
                hour: "numeric",
                // minute
                minute: "numeric",
              }),
            withdrawRequests.status,
            // `${withdrawRequests.id}`
            JSON.stringify(withdrawRequests),
          ]
        })
      })
    },
    // Function to load data for a specific page
    loadPage(page) {
      if (page < 1 || page > this.totalPages) {
        return;
      }

      this.page = page;

      if (this.datatable) {
        this.destroyDataTable(); // Destroy the current DataTable instance
      }
      
      if (document.getElementById("products-list")) {
        this.datatable = new DataTable("#products-list", {
          processing: this.isLoading,
          searchable: false,
          fixedHeight: false,
          perPage: 16,
          columns: columns,
          labels: labels,
          paging: false,
          filters: {
            // Add the search filter
            search: this.searchQuery,
          },
        });
        this.datatable.body.addEventListener("click", this.handleAction)
        this.toggleLoading()
        // this.loadASOCentrals()
        if (Object.keys(this.filter).some(key => this.filter[key] !== "")) {
          // Check if any property in the filter object is not empty
          this.loadWithdrawRequestsFilter(this.filter);
        } else {
          this.loadWithdrawRequests();
        }
      }
    },
    exportExcel(params_filter = {startDate: "", endDate: ""}) {
        const token = window.localStorage.getItem("TOKEN");
        axios
          .get(
            `https://api.darlink.id/v1/withdraw?export=xlsx&start=${params_filter.startDate}&end=${params_filter.endDate}`,
            {
              responseType: "blob", // Set the response type to blob to get the Excel file as a blob
              withCredentials: true,
              headers: {
                Accept: "application/json",
                Authorization: token,
              },
            }
          )
          .then((response) => {
            // Create a blob URL from the response data
            const blobUrl = window.URL.createObjectURL(new Blob([response.data]));

            // Create a temporary link element to trigger the download
            const link = document.createElement("a");
            link.href = blobUrl;
            link.setAttribute("download", `export-withdraw-user-${params_filter.startDate}-${params_filter.endDate}.xlsx`); // Set the desired filename for the downloaded file
            document.body.appendChild(link);

            // Click the link to trigger the download
            link.click();

            // Remove the temporary link element
            document.body.removeChild(link);
          })
          .catch(() => {
            // Handle any errors here
            this.$swal({
              title: "Error",
              text: "Failed to download Excel file",
              icon: "error",
              confirmButtonText: "Close",
              confirmButtonColor: "#3085d6",
            });
          });
      },
  },
};
</script>
