<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Order List</h5>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <label for="projectName" class="form-label">Dari Tgl</label>
                <argon-input type="date" placeholder="Date"  v-model="filter.startDate" />
              </div>
              <div class="col-md-2">
                <label for="projectName" class="form-label">Sampai Tgl</label>
                <argon-input type="date" placeholder="Date"  v-model="filter.endDate" />
              </div>
              <div class="col-md-4">
                <label for="projectName" class="form-label">Fincoy Ho</label>
                <select
                  id="choices-aso"
                  class="form-control"
                  name="choices-aso"
                  v-model="filter.fincoyPic"
                >
                  <option value="">All</option>
                  <option v-for="(dataFincoyHO, index) in fincoyHO" :value="dataFincoyHO.id" :key="index">{{ dataFincoyHO.name }}</option>
                </select>
              </div>
              <div class="col-md-4">
                <label for="projectName" class="form-label">Action</label>
                <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <a
                    href="#"
                    class="mb-0 btn bg-gradient-success btn-sm"
                    style="margin-right: 10px;"
                    @click="handleFilter"
                    >+&nbsp; Filter</a
                  >

                  <button
                    class="mt-1 mb-0 btn btn-outline-success btn-sm export mt-sm-0"
                    type="button"
                    name="button"
                    style="margin-right: 10px;"
                    @click="handleReset"
                  >
                    Reset
                  </button>
                
                  <button
                    class="mt-1 mb-0 btn btn-outline-success btn-sm export mt-sm-0"
                    data-type="csv"
                    type="button"
                    name="button"
                    @click="handleExport"
                  ><i class="text-success text-gradient ni ni-chart-bar-32"></i>
                    Export
                  </button>
                </div>
              </div>
              </div>
            </div>
          </div>
          
          <div class="px-0 pb-0 card-body pt-0">
            <div class="table-responsive">
              <div class="row">
                <div class="col-lg-4" style="margin: 10px;">
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      v-model="searchQuery"
                      placeholder="Search ..."
                    />
                  </div>
                  
                  <a
                    href="#"
                    class="mb-0 btn bg-gradient-success btn-sm"
                    style="margin-right: 10px;"
                    @click="performSearch"
                    >+&nbsp; search</a
                  >
                </div>
              </div>

              <table id="products-list" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Aso Cabang</th>
                    <th>Nama Client</th>
                    <th>T. Pinjaman</th>
                    <th>Nama Kendaraan</th>
                    <th>Nama Agent</th>
                    <!-- <th>Fincoy Ho</th>
                    <th>Fincoy Pic</th> -->
                    <th>Total Fee</th>
                    <th>Fee Aso Cabang</th>
                    <th>Fee Agent</th>
                    <th>Tanggal Buat</th>
                    <th>Tanggal Selesai</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- <tr>
                    <td>
                        <div class="d-flex align-items-center">
                        <argon-checkbox />
                            <p class="text-xs font-weight-bold ms-2 mb-0">ASO Cabang Jakarta</p>
                        </div>
                    </td>
                    <td class="text-sm">Herman</td>
                    <td class="text-sm">
                      <div class="d-flex">
                        <h6 class="my-auto">Rp.100.000.000</h6>
                      </div>
                    </td>
                    <td class="text-sm">Yamaha nMax</td>
                    <td class="text-sm"><span class="badge badge-info badge-sm">Low</span></td>
                    <td class="text-sm">-</td>
                    <td class="text-sm">-</td>
                    <td class="text-sm">1.000.000</td>
                    <td class="text-sm">100.000</td>
                    <td><span class="badge badge-success badge-sm">Approve</span></td>
                    <td class="text-sm">
                      <a
                        href="javascript:;"
                        data-bs-original-title="Preview product"
                        data-bs-toggle="modal"
                        data-bs-target="#view-aso"
                      >
                        <i class="fas fa-eye text-secondary"></i>
                      </a>
                    </td>
                  </tr> -->
                  
                </tbody>
                <tfoot>
                  <tr>
                    <th>Aso Cabang</th>
                    <th>Nama Client</th>
                    <th>T. Pinjaman</th>
                    <th>Nama Kendaraan</th>
                    <th>Status Client</th>
                    <th>Fincoy Ho</th>
                    <th>Fincoy Pic</th>
                    <th>Total Fee</th>
                    <th>Fee Aso Cabang</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </tfoot>
              </table>

              <!-- DataTable's built-in pagination -->
              <nav aria-label="Page navigation">
                <ul class="pagination justify-content-end">
                  <li class="page-item" :class="{ disabled: page === 1 }">
                    <a class="page-link" href="javascript:void(0);" @click="loadPage(page - 1)">
                      Prev
                    </a>
                  </li>
                  <li class="page-item" v-for="pageNumber in totalPages" :key="pageNumber" :class="{ active: pageNumber === page }">
                    <a class="page-link" href="javascript:void(0);" @click="loadPage(pageNumber)">
                      {{ pageNumber }}
                    </a>
                  </li>
                  <li class="page-item" :class="{ disabled: page === totalPages }">
                    <a class="page-link" href="javascript:void(0);" @click="loadPage(page + 1)">
                      Next
                    </a>
                  </li>
                </ul>
              </nav>

            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- button edit modal -->    
    <div
      id="view-aso"
      class="modal fade"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-lg-10">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="ModalLabel" class="modal-title">
              ASO Cabang  Jakarta
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div>
              <h6>ASO Cabang  Jakarta</h6>
              <p class="text-sm mb-0">
                Nama PIC
                <b>Herman</b> Nomor HP
                <b>1234567890</b>
              </p>
              <p class="text-sm">
                NIK/NPK :
                <b>243598234</b>
              </p>

              <p class="text-sm">
                Jumlah Anggota :
                <b>32</b>
              </p>
            </div>
            <p>Jalan Alamat nya/Lokasi.</p>

            <div>
              <p class="text-sm mb-0">
                Status  
                <span class="badge badge-success badge-sm">Aktif</span>
              </p>
              <p class="text-sm">
                NIK/NPK :
                <b>243598234</b>
              </p>
            </div>
            
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn bg-gradient-secondary btn-sm"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End button edit modal -->

    <div
      id="view-fincoy"
      class="modal fade"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-lg-10">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="ModalLabel" class="modal-title">
              Fincoy PIC
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="px-0 pb-0 card-body pt-0">
                <div class="table-responsive">
                    <table id="products-list" class="table table-flush">
                        <thead class="thead-light">
                        <tr>
                            <th>Fincoy HO</th>
                            <th>Fincoy PIC</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center">
                                    <argon-checkbox />
                                        <p class="text-xs font-weight-bold ms-2 mb-0">Fincoy HO A</p>
                                    </div>
                                </td>
                                <td class="text-sm">Herman</td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center">
                                    <argon-checkbox />
                                        <p class="text-xs font-weight-bold ms-2 mb-0">Fincoy HO B</p>
                                    </div>
                                </td>
                                <td class="text-sm">Lucy</td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center">
                                    <argon-checkbox />
                                        <p class="text-xs font-weight-bold ms-2 mb-0">Fincoy HO C</p>
                                    </div>
                                </td>
                                <td class="text-sm">Anton</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn bg-gradient-primary btn-sm"
              data-bs-dismiss="modal"
            >
              Assign
            </button>
            <button
              type="button"
              class="btn bg-gradient-secondary btn-sm"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import { DataTable } from "simple-datatables";
  import setTooltip from "@/assets/js/tooltip.js";
  import ArgonInput from "@/components/ArgonInput.vue";
  import ArgonCheckbox from "@/components/ArgonCheckbox.vue";
  import axios from 'axios';
  var columns = [
    {
      select: 0,
      render: function (data) {
        return `<div class="d-flex">
            <h6 class="my-auto ms-3">${data}</h6>
          </div>
        `
      }
    },
    {
      select: [1,2,3,4,5,6,7,9],
      render: function (data) {
        return `<span class="text-sm">${data}</span>
        `
      }
    },
    // row 2 text right
    {
      select: 2,
      render: function (data) {
        return `<span class="text-sm text-right">${data}</span>
        `
      }
    },
    // {
    //   select: 4,
    //   render: function (data) {
    //     // make badge color based on status value if high then use badge danger, if low use badge info
    //     // let status high = badge-danger, status low = badge-info
    //     let status = (data === 'LOW') ? "badge-info" :  "badge-danger"
    //     return `
    //     <span class="badge ${status} badge-sm">
    //       ${data}
    //     </span>
    //     `
    //   },
    // },
    // row 8 text align right and add tooltip
    // {
    //   select: 8,
    //   render: function (data) {
    //     return `
    //     <span class="text-sm text-right" data-bs-toggle="tooltip" data-bs-placement="top" title="${data}">
    //       ${data}
    //     </span>
    //     `
    //   },
    // },
    {
      select: 10,
      render: function (data) {
        // make badge color based on status value if high then use badge danger, if low use badge info
        // let status high = badge-danger, status low = badge-info
        let status = (data === 1) ? "badge-info" :  "badge-success"
        return `
        <span class="badge ${status} badge-sm">
          Completed
        </span>
        `
      },
    },
    {
      select: 11,
      render: function (data) {
        return `
          <!-- button edit modal -->
          <a
          class="text-sm"
          href="javascript: void(0);"
        >
          <span
            class="mx-3"
          >
            <i
             class="fas fa-eye text-secondary data-bs-toggle="tooltip" data-bs-placement="top" title="Detail"
              data-action="edit"
              data-id="${data}"
            ></i>
          </span>
        </a>
        `
      }
    },
  ]
  
  var labels = {
    placeholder: "Cari Data...",
    perPage: "Menampilkan {select} ASO Cabang per halaman",
    noRows: "Tidak ada Data yang ditampilkan",
    info: "Menampilkan {start} ke {end} dari {rows} ASO Cabang (Halaman {page} dari {pages} halaman)"
  }
  
  export default {
    name: "AsoCentral",
    components: {
      ArgonInput,
      ArgonCheckbox,
    },
    data() {
      return {
        datatable: null,
        isLoading: false,
        personInCharges: [],
        orderLists: [],
        selectedAso: null,
        modalId: null,
        selectedData: {}, // Data yang akan ditampilkan di modal
        showModal: false,
        fincoyHO: [],
        filter: {
          startDate: "",
          endDate: "",
          fincoyPic: "",
        },
        page: 1, // Current page
        totalPages: 1, // Total number of pages
        searchQuery: '',
      }
    },
    beforeMount() {
      if (localStorage.getItem("SideMenuType") !== "SuperAdmin") {
          this.$router.go(-1);
      }
    },
    mounted() {
      if (document.getElementById("products-list")) {
        this.datatable = new DataTable("#products-list", {
          processing: this.isLoading,
          searchable: false,
          fixedHeight: false,
          perPage: 16,
          columns: columns,
          labels: labels,
          paging: false,
          filters: {
            // Add the search filter
            search: this.searchQuery,
          },
        });
        this.datatable.body.addEventListener("click", this.handleAction)
        this.toggleLoading()
        this.loadOrderList()
        this.loadFincoyHO()
      }
      // this.datatable.body.addEventListener("click", this.handleAction)
      setTooltip(this.$store.state.bootstrap);
    },
    beforeUnmount() {
      this.datatable.destroy()
      this.datatable = null
    },
    methods: {
      handleFilter() {
        if (this.datatable) {
          this.destroyDataTable(); // Destroy the current DataTable instance
        }
        
        if (document.getElementById("products-list")) {
          this.datatable = new DataTable("#products-list", {
            processing: this.isLoading,
            searchable: false,
            fixedHeight: false,
            perPage: 16,
            columns: columns,
            labels: labels,
            paging: false,
            filters: {
              // Add the search filter
              search: this.searchQuery,
            },
          });
          this.datatable.body.addEventListener("click", this.handleAction)
          this.toggleLoading()
          this.loadOrderListFilter(this.filter)
        }
      },
      handleReset() {
        this.filter.startDate = ""
        this.filter.endDate = ""
        this.filter.fincoyPic = ""

        if (this.datatable) {
          this.destroyDataTable(); // Destroy the current DataTable instance
        }
        
        if (document.getElementById("products-list")) {
          this.datatable = new DataTable("#products-list", {
            processing: this.isLoading,
            searchable: false,
            fixedHeight: false,
            perPage: 16,
            columns: columns,
            labels: labels,
            paging: false,
            filters: {
              // Add the search filter
              search: this.searchQuery,
            },
          });
          this.datatable.body.addEventListener("click", this.handleAction)
          this.toggleLoading()
          this.loadOrderList()
        }
      },
      handleExport() {
        this.exportExcel(this.filter);
      },
      toggleLoading() {
        if (!this.datatable) {
          return
        }
        if (!this.isLoading) {
          this.isLoading = true
          this.datatable.setMessage("Memuat...")
          return
          
        }
        this.isLoading = false
      },
      handleAction(e) {
        if (e.target.getAttribute("data-action") === "assign") {
          let id = parseInt(e.target.getAttribute("data-id"), 10)
          // let id = parseInt(e.target.dataset.id)  
          console.log(id, e.target)
          this.assignOrder(id)
        }
        // modal handle
        if (e.target.getAttribute("data-action") === "assignModal") {
          let id = parseInt(e.target.getAttribute("data-id"), 10)
          this.assignOrder(id)
        }
        if (e.target.getAttribute("data-action") === "edit") {
          let id = parseInt(e.target.getAttribute("data-id"), 10)
          this.edit(id)
        }
      },
      edit(id) {
        this.$router.push({
          name: "superadmin.order-completed.detail",
          params: {
            orderList: id
          }
        })
      },
      loadFincoyHO() {
        var token = window.localStorage.getItem("TOKEN")
        axios.get('https://api.darlink.id/v1/fincoy?status=active', {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          }
        }).then((response) => {
          this.fincoyHO = response.data.content;
        })
      },
      performSearch() {
        if (this.datatable) {
          this.destroyDataTable(); // Destroy the current DataTable instance
        }
        
        if (document.getElementById("products-list")) {
          this.datatable = new DataTable("#products-list", {
            processing: this.isLoading,
            searchable: false,
            fixedHeight: false,
            perPage: 16,
            columns: columns,
            labels: labels,
            paging: false,
            filters: {
              // Add the search filter
              search: this.searchQuery,
            },
          });

          this.page = 1;

          this.datatable.body.addEventListener("click", this.handleAction)
          this.toggleLoading()
          this.loadOrderList()
        }
      },
      loadOrderListFilter(params_filter = {startDate: "", endDate: "", fincoyPic: ""}) {
        var token = window.localStorage.getItem("TOKEN")
        axios
          .get(`https://api.darlink.id/v1/order?status=completed&start=${params_filter.startDate}&end=${params_filter.endDate}&fincoy_pic=${params_filter.fincoyPic}`, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token
            },
            params: {
              page: this.page, // Include the page parameter
              search: this.searchQuery, // Add the search query parameter
            },
          })
          .then((response) => {
            if (!this.datatable) {
              return
            }
            this.toggleLoading()
            if (response.data.code != 200) {
              return
            }
            if (response.data.tag === "content.order.empty") {
              this.datatable.setMessage("Tidak ada data yang ditampilkan")
              return
            }
            this.orderLists = response.data.content
            this.totalPages = response.data.pagination.total_pages; // Update total pages

            this.updateDataTableFilter();
            // this.datatable.insert({
            //   data: this.orderLists.map((orderList) => {
            //     return [
            //       // format number id 
            //       orderList.aso_branch.displayname,
            //       orderList.client_name,
            //       // orderList.total_loan,
            //       orderList.total_loan.toLocaleString("id-ID", {
            //         style: "currency",
            //         currency: "IDR",
            //         minimumFractionDigits: 2,
            //       }),
            //       orderList.leased_item_name,
            //       orderList.agent.displayname,
            //       // orderList.total_fee,
            //       // format number with 2 decimal IDR format 
            //       orderList.total_fee.toLocaleString("id-ID", {
            //         style: "currency",
            //         currency: "IDR",
            //         minimumFractionDigits: 2,
            //       }),
            //       orderList.aso_branch_fee.toLocaleString("id-ID", {
            //         style: "currency",
            //         currency: "IDR",
            //         minimumFractionDigits: 2,
            //       }),
            //       orderList.agent_fee.toLocaleString("id-ID", {
            //         style: "currency",
            //         currency: "IDR",
            //         minimumFractionDigits: 2,
            //       }),
            //       orderList.client_status,
            //       `${orderList.id}`
            //     ]
            //   })
            // })
          })
          .catch((err) => {
            this.toggleLoading()
            console.log(err);
          });
      },
      loadOrderList() {
        var token = window.localStorage.getItem("TOKEN")
        axios
          .get(`https://api.darlink.id/v1/order?status=completed`, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              Authorization: token
            },
            params: {
              page: this.page, // Include the page parameter
              search: this.searchQuery, // Add the search query parameter
            },
          })
          .then((response) => {
            if (!this.datatable) {
              return
            }
            this.toggleLoading()
            if (response.data.code != 200) {
              return
            }
            if (response.data.tag === "content.order.empty") {
              this.datatable.setMessage("Tidak ada data yang ditampilkan")
              return
            }
            this.orderLists = response.data.content
            this.totalPages = response.data.pagination.total_pages; // Update total pages

            this.updateDataTable();
            // this.datatable.insert({
            //   data: this.orderLists.map((orderList) => {
            //     return [
            //       // format number id 
            //       orderList.aso_branch.displayname,
            //       orderList.client_name,
            //       // orderList.total_loan,
            //       orderList.total_loan.toLocaleString("id-ID", {
            //         style: "currency",
            //         currency: "IDR",
            //         minimumFractionDigits: 2,
            //       }),
            //       orderList.leased_item_name,
            //       orderList.agent.displayname,
            //       // orderList.total_fee,
            //       // format number with 2 decimal IDR format 
            //       orderList.total_fee.toLocaleString("id-ID", {
            //         style: "currency",
            //         currency: "IDR",
            //         minimumFractionDigits: 2,
            //       }),
            //       orderList.aso_branch_fee.toLocaleString("id-ID", {
            //         style: "currency",
            //         currency: "IDR",
            //         minimumFractionDigits: 2,
            //       }),
            //       orderList.agent_fee.toLocaleString("id-ID", {
            //         style: "currency",
            //         currency: "IDR",
            //         minimumFractionDigits: 2,
            //       }),
            //       orderList.client_status,
            //       `${orderList.id}`
            //     ]
            //   })
            // })
          })
          .catch((err) => {
            this.toggleLoading()
            console.log(err);
          });
      },
      // Function to update the DataTable
      updateDataTableFilter() {
        // if (this.datatable) {
        //   this.datatable.destroy();
        // }

        // this.datatable = new DataTable("#products-list", {
        //   processing: this.isLoading,
        //   searchable: true,
        //   fixedHeight: false,
        //   perPage: 16,
        //   columns: columns,
        //   labels: labels,
        // });

        // this.datatable.body.addEventListener("click", this.handleAction);
        this.datatable.body.innerHTML = '';
        this.datatable.insert({
          data: this.orderLists.map((orderList) => {
            return [
              // format number id 
              orderList.aso_branch.displayname,
              orderList.client_name,
              // orderList.total_loan,
              orderList.total_loan.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 2,
              }),
              orderList.leased_item_name,
              orderList.agent.displayname,
              // orderList.total_fee,
              // format number with 2 decimal IDR format 
              orderList.total_fee ? orderList.total_fee.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 2,
              }) : "-",
              orderList.aso_branch_fee ? orderList.aso_branch_fee.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 2,
              }) : "-",
              orderList.agent_fee ? orderList.agent_fee.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 2,
              }) : "-",
              orderList.created_at !== null
                ? new Date(orderList.created_at).toLocaleDateString("id-ID", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    // hour
                    hour: "numeric",
                    // minute
                    minute: "numeric",
                  })
                : "-",
              new Date(orderList.completed_at ? orderList.completed_at : 'Tidak ada data').toLocaleDateString("id-ID", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
              }),
              orderList.client_status,
              `${orderList.id}`
            ]
          })
        })
      },
      updateDataTable() {
        // if (this.datatable) {
        //   this.datatable.destroy();
        // }

        // this.datatable = new DataTable("#products-list", {
        //   processing: this.isLoading,
        //   searchable: true,
        //   fixedHeight: false,
        //   perPage: 16,
        //   columns: columns,
        //   labels: labels,
        // });

        // this.datatable.body.addEventListener("click", this.handleAction);
        this.datatable.body.innerHTML = '';
        this.datatable.insert({
          data: this.orderLists.map((orderList) => {
            return [
              // format number id 
              orderList.aso_branch.displayname,
              orderList.client_name,
              // orderList.total_loan,
              orderList.total_loan.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 2,
              }),
              orderList.leased_item_name,
              orderList.agent.displayname,
              // orderList.total_fee,
              // format number with 2 decimal IDR format 
              orderList.total_fee ? orderList.total_fee.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 2,
              }) : "-",
              orderList.aso_branch_fee ? orderList.aso_branch_fee.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 2,
              }) : "-",
              orderList.agent_fee ? orderList.agent_fee.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 2,
              }) : "-",
              orderList.created_at !== null
                ? new Date(orderList.created_at).toLocaleDateString("id-ID", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    // hour
                    hour: "numeric",
                    // minute
                    minute: "numeric",
                  })
                : "-",
              new Date(orderList.completed_at ? orderList.completed_at : 'Tidak ada data').toLocaleDateString("id-ID", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
              }),
              orderList.client_status,
              `${orderList.id}`
            ]
          })
        })
      },
      destroyDataTable() {
        if (this.datatable) {
          this.datatable.destroy();
          this.datatable = null;
        }
      },
      // Function to load data for a specific page
      loadPage(page) {
        if (page < 1 || page > this.totalPages) {
          return;
        }

        this.page = page;

        if (this.datatable) {
        this.destroyDataTable(); // Destroy the current DataTable instance
      }
      
      if (document.getElementById("products-list")) {
        this.datatable = new DataTable("#products-list", {
          processing: this.isLoading,
          searchable: false,
          fixedHeight: false,
          perPage: 16,
          columns: columns,
          labels: labels,
          paging: false,
          filters: {
            // Add the search filter
            search: this.searchQuery,
          },
        });
        this.datatable.body.addEventListener("click", this.handleAction)
        this.toggleLoading()
        
        if (Object.keys(this.filter).some(key => this.filter[key] !== "")) {
          // Check if any property in the filter object is not empty
          this.loadOrderListFilter(this.filter);
        } else {
          this.loadOrderList();
        }
      }
        
      },
      exportExcel(params_filter = {startDate: "", endDate: "", fincoyPic: ""}) {
        const token = window.localStorage.getItem("TOKEN");
        axios
          .get(
            `https://api.darlink.id/v1/order?status=completed&export=xlsx&start=${params_filter.startDate}&end=${params_filter.endDate}&fincoy_pic=${params_filter.fincoyPic}`,
            {
              responseType: "blob", // Set the response type to blob to get the Excel file as a blob
              withCredentials: true,
              headers: {
                Accept: "application/json",
                Authorization: token,
              },
            }
          )
          .then((response) => {
            // Create a blob URL from the response data
            const blobUrl = window.URL.createObjectURL(new Blob([response.data]));

            // Create a temporary link element to trigger the download
            const link = document.createElement("a");
            link.href = blobUrl;
            link.setAttribute("download", `export-completed-${params_filter.startDate}-${params_filter.endDate}.xlsx`); // Set the desired filename for the downloaded file
            document.body.appendChild(link);

            // Click the link to trigger the download
            link.click();

            // Remove the temporary link element
            document.body.removeChild(link);
          })
          .catch(() => {
            // Handle any errors here
            this.$swal({
              title: "Error",
              text: "Failed to download Excel file",
              icon: "error",
              confirmButtonText: "Close",
              confirmButtonColor: "#3085d6",
            });
          });
      },
    }
  };
  </script>
  