<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="mx-auto col-lg-9 col-12">
        <div class="mt-4 card card-body">
          <h6 class="mb-0">Tambah Aso Cabang</h6>
          <hr class="my-3 horizontal dark" />
          <div class="mt-4 row">
            <div class="col-9 col-md-9">
              <div class="form-group">
                <label for="aso_central_name_input" class="form-label" >Nama ASO Cabang</label>
                <input
                  id="aso_central_name_input"
                  v-model="displayname"
                  type="text"
                  class="form-control"
                />
                <label>Status Aso Cabang</label>
                <div class="form-check form-switch ms-1">
                  <input
                    id="flexSwitchCheckDefault"
                    v-model="isActive"
                    class="form-check-input"
                    type="checkbox"
                    data-type="warning"
                    data-content="Once a project is made private, you cannot revert it to a public project."
                    data-title="Warning"
                    data-icon="ni ni-bell-55"
                    disabled
                  />
                  <label
                    class="form-check-label"
                    for="flexSwitchCheckDefault"
                  >
                    <span
                      class="badge badge-md"
                      :class="{
                        'badge-danger': !isActive,
                        'badge-success': isActive,
                      }"
                    >
                      {{ (isActive) ? 'AKTIF' : 'NON-AKTIF' }}
                    </span>
                  </label>
                </div>
              </div>
            </div>

            <div class="col-3 col-md-3">
              <label for="projectName" class="form-label">Jenis Aso Cabang </label>
              <select
                id="choices-aso"
                v-model="type_cabang"
                class="form-control"
                name="choices-aso"
                @change="onChange($event)"
              >
                  <option value="" selected="">-- Pilih --</option>
                  <option value="internal">Internal</option>
                  <option value="external">Eksternal</option>
              </select>
            </div>

          </div>
          <div class="row">
            <div class="col-6">
              <label for="username" class="form-label">Username</label>
              <input id="username" v-model="username"  type="text" class="form-control" disabled />
            </div>
            <div class="col-6">
              <label for="password" class="form-label">Password</label>
              <input id="password" v-model="password"  type="password" class="form-control" disabled />
            </div>
            <div class="col-6">
              <label for="email" class="form-label">Email</label>
              <input id="email" v-model="email"  type="text" class="form-control" />
            </div>
            <div class="col-6">
              <label for="fullname" class="form-label">Nama Lengkap PIC</label>
              <input id="fullname" v-model="fullname" type="text" class="form-control" />
            </div>
            <div class="col-6">
              <label for="nik" class="form-label">NIK/NPK</label>
              <input id="nik" v-model="nik" type="text" class="form-control" 
              maxlength="16"
              />
            </div>

            <div v-if="show" class="col-6">
              <label for="hp" class="form-label">Nomor HP PIC</label>
              <input id="hp" v-model="phonenumber" type="text" class="form-control" 
              maxlength="13"
              />
            </div>

            <div v-if="show" class="col-6">
              <label for="location" class="form-label">Daerah/Lokasi</label>
              <input id="location" v-model="location" type="text" class="form-control" />
            </div>

            <div v-if="show" class="col-6">
              <label for="employee" class="form-label">Jumlah Anggota / Karyawan</label>
              <input id="employee" v-model="employee" type="text" class="form-control" />
            </div>

            <div v-if="show" class="col-6">
              <label for="organization_type" class="form-label">Jenis Organisasi</label>
              <input id="organization_type" v-model="organization_type" type="text" class="form-control" />
            </div>

            <div v-if="show" class="col-6">
              <label for="organization_name" class="form-label">Nama Organisasi</label>
              <input id="organization_name" v-model="organization_name" type="text" class="form-control" />
            </div>
          </div>

          <div v-if="show" >
            <label class="mt-4">Alamat Kantor</label>
            <QuillEditor
              v-model:content="address"
              content-type="text"
              toolbar="minimal"
              theme="snow"
            />
          </div>

          <label class="mt-4 form-label">Upload ID Card (.jpg, .png)</label>
          <form
            id="uploadId"
            class="form-control dropzone"
          >
            <div class="fallback">
              <input name="file" type="file" @change="handleFileChange" multiple />
            </div>
          </form>

          <div class="row">
            <label class="mt-4" style="color: red;">*Total Persentase Fee di bagi dari 100 % (Cth: Aso Cabang 60%, Agent 40%)</label>
            <div class="col-6">
              <label for="fee_aso_cabang" class="form-label">Persentase Fee Aso Cabang (%)</label>
              <input id="fee_aso_cabang" type="text" class="form-control" @change="calculateFeeAgent" />
            </div>

            <div class="col-6">
              <label for="fee_agent" class="form-label">Persentase Fee Agent Aso Cabang (%)</label>
              <!-- <input id="fee_agent" type="text" class="form-control" :value="100 - feeAsoCabang" disabled /> -->
              <input id="fee_agent" type="text" class="form-control" disabled />
            </div>
          </div>

          <div class="mt-4 d-flex justify-content-end">
            <router-link to="/aso/aso-cabang">
              <button type="button" name="button" class="m-0 btn btn-light">
                Batal
              </button>
            </router-link>
            <button
              type="button"
              name="button"
              class="m-0 btn bg-gradient-success ms-2"
              @click="submit"
            >
              <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span v-else>Tambah Aso Cabang</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Choices from "choices.js";
import Dropzone from "dropzone";
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import axios from 'axios';

export default {
  name: "NewAsoCentral",
  components: {
    QuillEditor
  },
  beforeMount() {
    if (localStorage.getItem("SideMenuType") !== "AsoCentral") {
      this.$router.go(-1);
    }
  },
  data() {
    return {
      date: "",
      endDate: "",
      config: {
        allowInput: true,
      },
      role: "",
      show: false,
      feeAsoCabang: 0,
      feeAgent: 0,
      username: "",
      password: "",
      fullname: "",
      phonenumber: "",
      email: "",
      nik: "",
      location: "",
      employee: "",
      organization_type: "",
      organization_name: "",
      address: "",
      idcard: "",
      type_cabang: "",
      displayname: "",
      isLoading: false,
    };
  },
  mounted() {
    if (document.getElementById("choices-multiple-remove-button")) {
      var element = document.getElementById("choices-multiple-remove-button");
      const example = new Choices(element, {
        removeItemButton: true,
        allowHTML: true,
      });

      example.setChoices(
        [
          {
            value: "One",
            label: "Label One",
            disabled: true,
          },
          {
            value: "Two",
            label: "Label Two",
            selected: true,
          },
          {
            value: "Three",
            label: "Label Three",
          },
        ],
        "value",
        "label",
        false
      );
    }

    this.initializeDropzone();
  },
  methods: {
    initializeDropzone() {
      Dropzone.autoDiscover = false;
      const drop = document.getElementById("uploadId");
      const dropzone = new Dropzone(drop, {
        url: "https://api.darlink.id/v1/assets/upload",
        addRemoveLinks: true,
        acceptedFiles: 'image/*',
        sending: (file, xhr, formData) => {
          formData.append("context", "ID_CARD"); // Include the context parameter in the request
        },
        success: (file, response) => {
          console.log("Upload success:", response); // Handle the successful response
          // this.MOUDocs = response.content.url;
          this.idcard = response.content.name;
        },
      });
      // Add any additional configuration or event listeners as needed

      // Move the handleFileChange logic here or modify it accordingly
      dropzone.on("addedfile", (file) => {
        // this.MOUDocs = file;
        console.log(file);
      });
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      const dropzone = Dropzone.forElement("#uploadId");
      dropzone.removeAllFiles();
      dropzone.addFile(file);
    },
    onChange(event) {
      if (event.target.value === "internal") {
        this.show = false;
        document.getElementById("fee_aso_cabang").value = 0;
        document.getElementById("fee_agent").value = 0;
        document.getElementById("fee_aso_cabang").setAttribute('disabled', '');
        
      } else {
        this.show = true;
        document.getElementById("fee_aso_cabang").removeAttribute('disabled');
      }
    },
    calculateFeeAgent() {
      this.feeAsoCabang = parseFloat(document.getElementById("fee_aso_cabang").value);

      document.getElementById("fee_agent").value = 100 - this.feeAsoCabang;
      this.feeAgent = 100 - this.feeAsoCabang;
    },
    validateFeeTotal() {
      const feeAgent = 100 - this.feeAsoCabang;

      if (this.feeAsoCabang + feeAgent !== 100) {
        alert("The total of fee_aso_cabang and fee_agent must be 100.");
      } else {
        console.log("Values are valid.");
      }
    },
    randomString(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      const charactersLength = characters.length;

      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return result;
    },
    generatePassword() {
      // this.password = this.randomString(10);
      if(this.type_cabang != 'internal'){
        this.password = this.phonenumber;
      }
      else
      {
        this.password = this.nik;
      }
    },
    validateEmail(email) {
      // Regular expression for validating an Email
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    validatePhone(phone) {
      // Regular expression for validating a Phone number
      const phoneRegex = /^\d{11,13}$/;
      return phoneRegex.test(phone);
    },
    validateNik(personalID) {
      // Regular expression for validating a Phone number
      const phoneRegex = /^\d{16}$/;
      return phoneRegex.test(personalID);
    },
    submit() {
      this.isLoading = true;
      var token = window.localStorage.getItem("TOKEN")

      //Get File name
      // const fileName = this.MOUDocs ? this.MOUDocs.name : '';
      // Add email and phone format validation
      if (!this.validateEmail(this.email)) {
        this.isLoading = false;
        this.$swal({
          title: "Invalid Email",
          text: "Please enter a valid email address.",
          icon: "warning",
          confirmButtonText: "OK",
          confirmButtonColor: "#3085d6",
        });
        return;
      }
      
      if(this.type_cabang != 'internal'){
        if (!this.validatePhone(this.phonenumber)) {
          this.isLoading = false;
          this.$swal({
            title: "Invalid Phone Number",
            text: "Please enter a valid max 13-digit phone number.",
            icon: "warning",
            confirmButtonText: "OK",
            confirmButtonColor: "#3085d6",
          });
          return;
        }
      }

      if (!this.validateNik(this.nik)) {
        this.isLoading = false;
        this.$swal({
          title: "Invalid NIK",
          text: "Please enter a valid 16-digit number.",
          icon: "warning",
          confirmButtonText: "OK",
          confirmButtonColor: "#3085d6",
        });
        return;
      }

      const branchData = new FormData();
      this.generatePassword();
      branchData.append("displayname", this.displayname);
      branchData.append("pic_name", this.fullname);
      branchData.append("username", this.type_cabang == 'internal' ? this.nik : this.phonenumber);
      branchData.append("password", this.password);
      branchData.append("email", this.email);
      branchData.append("phone", this.phonenumber);
      branchData.append("address", this.address);
      branchData.append("personal_id_scan", this.idcard);
      branchData.append("personal_id", this.nik);
      branchData.append("location", this.location);
      branchData.append("total_employees", this.employee);
      branchData.append("organization_name", this.organization_name);
      branchData.append("organization_type", this.organization_type);
      branchData.append("type", this.type_cabang);
      branchData.append("branch_fee_cut", this.feeAsoCabang);
      branchData.append("agent_fee_cut", this.feeAgent);
      branchData.append("status", (this.isActive) ? 'active' : 'inactive');

      const url = "https://api.darlink.id/v1/aso-branch"

      axios
        .post(url, branchData,
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          }
        })
        .then((response) => {
          this.isLoading = false;
          // SWEETALLERT
          this.$swal({
            title: "Berhasil",
            text: "Penambahan ASO Cabang berhasil dilakukan",
            icon: "success",
            confirmButtonText: "Tutup",
            confirmButtonColor: "#3085d6",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push({ name: "aso.branches" })
            }
          });

          if (response.data.code != 201) {
            return
          }
          console.log(response)
          this.$router.push({ name: "aso.branches" })
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err.response)
          if (err.response && err.response.data && err.response.data.validation) {
            const validationErrors = err.response.data.validation;
            let errorMessage = "Validation Error:\n";
            validationErrors.forEach((error) => {
              errorMessage += `${error.mesg}\n`;
            });
            this.$swal({
              title: "Validation Error",
              text: errorMessage,
              icon: "error",
              confirmButtonText: "OK",
              confirmButtonColor: "#d33",
            });
          } else {
            this.$swal({
              title: "Error",
              text: err.response.data.message,
              icon: "error",
              confirmButtonText: "OK",
              confirmButtonColor: "#d33",
            });
          }
        });
    }
  }
};
</script>
