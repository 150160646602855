<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Semua Staff</h5>
              </div>

              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <router-link to="./new-aso-staff">
                  <a
                    class="mb-0 btn bg-gradient-success btn-sm"
                    target="_blank"
                    >+&nbsp; Tambah Staff</a>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <table id="products-list" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Nama Staff</th>
                    <th>Username</th>
                    <th>Email</th>
                    <th>Nomor Hp</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody></tbody>
                <tfoot>
                  <tr>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- button edit modal -->
    <div
      id="view-aso"
      class="modal fade"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-lg-10">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="ModalLabel" class="modal-title">
              Data Agent
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div>
              <h6>ASO Central Jakarta</h6>
              <p class="text-sm mb-0">
                Nama Agent
                <b>Herman</b> Nomor HP
                <b>1234567890</b>
              </p>
              <p class="text-sm">
                NIK/NPK :
                <b>243598234</b>
              </p>
            </div>
            <p>Jalan Alamat nya/Lokasi.</p>

            <div>
              <p class="text-sm mb-0">
                Status
                <span class="badge badge-success badge-sm">Aktif</span>
              </p>
            </div>

          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn bg-gradient-secondary btn-sm"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End button edit modal -->
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import setTooltip from "@/assets/js/tooltip.js";
import axios from 'axios';

var columns = [
  {
    select: 0,
    render: function (data) {
      return `<div class="d-flex">
          <h6 class="my-auto ms-3">${data}</h6>
        </div>
      `
    }
  },
  {
    select: [1,2,3],
    render: function (data) {
      // var permissions = ""
      // data.forEach(permission => {
      //   permissions = `${permissions}, ${permission}`
      // });
      return `<span class="text-sm">${data}</span>
      `
    }
  },
  {
    select: 4,
    render: function (data) {
      let status = (data === 'true') ? "AKTIF" :  "TIDAK AKTIF"
      let color = (data === 'true') ? "badge-success" :  "badge-danger"
      return `
      <span class="badge ${color} badge-sm">
        ${status}
      </span>
      `
    },
  },
  {
    select: 5,
    render: function () {
      return `
        <!-- button edit modal -->
        <a
          href="javascript:;"
          class="mx-3 text-sm"
          data-bs-toggle="tooltip"
          data-bs-original-title="Edit product"
        >
          <i class="fas fa-user-edit text-secondary"></i>
        </a>
        <a
          class="text-sm"
          href="javascript:;"
          data-bs-toggle="tooltip"
          data-bs-original-title="Delete product"
        >
          <i class="fas fa-trash text-secondary text-warning"></i>
        </a>
      `
    }
  },
]

var labels = {
  placeholder: "Cari staff...",
  perPage: "Menampilkan {select} staff per halaman",
  noRows: "Tidak ada staff yang ditampilkan",
  info: "Menampilkan {start} ke {end} dari {rows} staff (Halaman {page} dari {pages} halaman)"
}

export default {
  name: "FincoyPic",
  data() {
    return {
      datatable: null,
      admins: [],
      isLoading: false
    }
  },
  mounted() {
    if (document.getElementById("products-list")) {
      this.datatable = new DataTable("#products-list", {
        searchable: true,
        fixedHeight: false,
        perPage: 7,
        columns: columns,
        labels: labels
      });
      this.toggleLoading()
      this.loadAdmin()
    }
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.datatable.destroy()
    this.datatable = null
  },
  methods: {
    toggleLoading() {
      if (!this.datatable) {
        return
      }
      if (!this.isLoading) {
        this.isLoading = true
        this.datatable.setMessage("Memuat...")
        return
      }
      this.isLoading = false
    },
    loadAdmin() {
      var token = window.localStorage.getItem("TOKEN")
      axios
        .get("https://api.darlink.id/v1/aso-central/staff", {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: token
          }
        })
        .then((response) => {
          this.toggleLoading()
          if (response.data.code != 200) {
            return
          }

          this.admins = response.data.content
          this.datatable.insert({
            data: this.admins.map((admin) => {
              return [
                admin.displayname,
                admin.username,
                admin.email,
                admin.phone,
                (admin.is_active) ? "true" : "false"
              ]
            })
          })
        })
        .catch((err) => {
          this.toggleLoading()
          console.log(err);
        });
    }
  }
};
</script>
